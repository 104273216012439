export enum AnalyticsEvent {
    // SignIn/Out Auth events handled by amplifys 'autotrack'
    // Page Views - handled on page load
    PAGE_VIEW = "Page View",
    // Authentication
    PIN_ENTRY = "Pin Authentication",
    BIOMETRIC_ENTRY = "Biomentric Authentication",
    RESET_PASSWORD = "Password Reset",
    RESET_PASSWORD_FAIL = "Password Reset Failure",
    NEW_PASSWORD_FAIL = "Password Change Failure",
    NEW_SMS_CODE_FAIL = "Get SMS Code Failure",
    WRONG_SMS_CODE = "Wrong SMS Code",
    FIND_DOCTORS = "Nearby Doctor Search",
    // Assessments
    ASSESSMENT_CREATE = "Assessment Created",
    ASSESSMENT_UPDATED = "Assessment Updated",
    ASSESSMENT_DELETED = "Assessment Deleted",
    DOCTORS_CONTACTED = "Doctors Contacted",
    DOCTOR_CONFIRMED = "Doctor added to assessment",
    // Support
    CONTACTED_SUPPORT = "Contacted Support",
    // Claims
    CLAIM_OPENED = "Claim Opened",
    CLAIM_UPDATED = "Claim Updated",
    CLAIM_APPROVED = "Claim Approved",
    CLAIM_ASSIGNED = "Claim Assigned",
    CLAIM_REASSIGNED = "Claim Reassigned",
    CLAIM_REJECTED = "Claim Rejected",
    CLAIM_REOPENED = "Claim Reopened",
    CLAIM_CANCELLED = "Claim Cancelled",
    CLAIM_PAID = "Claim Paid",
    CLAIM_UNPAID = "Claim Unpaid",
    // Claims made by Lambda
    CLAIM_OPENED_2 = "Claim Opened 2",
    CLAIM_UPDATED_2 = "Claim Updated 2",
    CLAIM_APPROVED_2 = "Claim Approved 2",
    CLAIM_UNAPPROVED_2 = "Claim Unapproved 2",
    CLAIM_ASSIGNED_2 = "Claim Assigned 2",
    CLAIM_REASSIGNED_2 = "Claim Reassigned 2",
    CLAIM_UNASSIGNED_2 = "Claim Unassigned 2",
    CLAIM_REJECTED_2 = "Claim Rejected 2",
    CLAIM_REOPENED_2 = "Claim Reopened 2",
    CLAIM_CANCELLED_2 = "Claim Cancelled 2",
    CLAIM_PAID_2 = "Claim Paid 2",
    CLAIM_UNPAID_2 = "Claim Unpaid 2",
    // Assessments made by Lambda
    ASSESSMENT_CREATED_2 = "Assessment Created 2",
    ASSESSMENT_AMHP_CHANGED_2 = "Assessment AMHP Changed 2",
    ASSESSMENT_LOCATION_CHANGED_2 = "Assessment Location Changed 2",
    ASSESSMENT_UPDATED_2 = "Assessment Updated 2",
    ASSESSMENT_DELETED_2 = "Assessment Deleted 2",
    DOCTOR_VISIT_REMOVED_2 = "Doctor Visit Removed 2",
    DOCTOR_VISIT_CONFIRMED_2 = "Doctor Visit Added 2",
    BACKDATED_VISIT_2 = "Backdated Doctor Visit 2", // synthetic event, do not create directly
    ASSESSMET_WITH_VISIT_2 = "Assessments with Visit 2", // synthetic event, do not create directly
    DOCTOR_VISIT_UPDATED_2 = "Doctor Visit Updated 2",
    // Video Meeting, Lambda Triggered
    USER_JOINED_VIDEO_MEETING = "User Joined Video Meeting",
    // availability
    AVAILABILITY_CREATED = "Availability Created",
    AVAILABILITY_CREATED_2 = "Availability Created 2",
    HOLIDAY_CREATED = "Holiday Created",
    // Add user
    USER_ADDED = "User Added",
    AMHP_ADDED = "AMHP Added",
    DOCTOR_ADDED_TO_SITE = "Doctor Added to Site",
    // Errors
    GP_NOT_FOUND = "GP Not Found",
    // GMC Check
    DOCTOR_STATUS_EXPIRING = "Doctor Status Expiring",
    DOCTOR_STATUS_EXPIRED = "Doctor Status Expired",
    DOCTOR_STATUS_SURNAME_MISMATCH = "Doctor Status Surname Mismatch",
  
    // MHA Forms - AMHP Events
    MHA_FORM_AMHP_CREATED = "MHA Form AMHP Created",
    MHA_FORM_AMHP_SENT_EXTERNAL = "MHA Form AMHP Sent to Doctor",
    MHA_FORM_AMHP_SENT_EXTERNAL_FAILURE = "MHA Form AMHP Sent to Doctor Failure",
    MHA_FORM_AMHP_SIGNED = "MHA Form AMHP Signed",
    MHA_FORM_AMHP_EXPORTED = "MHA Form AMHP Exported",
    MHA_FORM_AMHP_EXPORTED_FAILURE = "MHA Form AMHP Exported Failure",
    MHA_FORM_AMHP_DELETED = "MHA Form AMHP Deleted",
    MHA_FORM_AMHP_DELETED_FAILURE = "MHA Form AMHP Deleted Failure",
  
    // MHA Forms - Doctor Events
    MHA_FORM_DOCTOR_CREATED = "MHA Form Doctor Created",
    MHA_FORM_DOCTOR_SIGNED = "MHA Form Doctor Signed",
    MHA_FORM_DOCTOR_REMOVED_SIGNATURE = "MHA Form Doctor Removed Signature",
    MHA_FORM_DOCTOR_DELETED = "MHA Form Doctor Deleted",
    MHA_FORM_DOCTOR_DELETED_FAILURE = "MHA Form Doctor Deleted Failure",
  
    // MHA Forms - Generic Events
    MHA_FORM_MOVED_IN_PROGRESS = "MHA Form Moved to In Progress",
    MHA_FORM_VIEWED = "MHA Form Viewed",
    MHA_FORMS_ENQUIRY = "MHA Form Enquiry",
    MHA_FORM_ATTACHED_TO_ASSESSMENT = "MHA Form Attached To Assessment",
    MHA_FORM_ATTACHED_TO_ASSESSMENT_FAILURE = "MHA Form Attached To Assessment Failure",
    MHA_FORM_REASSIGNED = "MHA Form Reassigned",
    MHA_FORM_REASSIGNED_FAILURE = "MHA Form Reassigned Failure",
  
    // MHA Forms - Lambda Generated
    MHA_FORM_AMHP_CREATED_2 = "MHA Form AMHP Created 2",
    MHA_FORM_DOCTOR_SIGNED_2 = "MHA Form Doctor Signed 2",
    MHA_FORM_AMHP_SENT_EXTERNAL_2 = "MHA Form AMHP Sent to Doctor 2",
    MHA_FORM_AMHP_SIGNED_2 = "MHA Form AMHP Signed 2",
    MHA_FORM_AMHP_EXPORTED_2 = "MHA Form AMHP Exported 2",
    MHA_FORM_DOCTOR_CREATED_2 = "MHA Form Doctor Created_2",
    MHA_FORM_AMHP_DELETED_2 = "MHA Form AMHP Deleted 2",
    MHA_FORM_MOVED_IN_PROGRESS_2 = "MHA Form Moved to In Progress 2",
    MHA_FORM_ATTACHED_TO_ASSESSMENT_2 = "MHA Form Attached To Assessment 2",
  }
  
  export const reportingEvents = [
    AnalyticsEvent.ASSESSMENT_AMHP_CHANGED_2,
    AnalyticsEvent.ASSESSMENT_CREATED_2,
    AnalyticsEvent.ASSESSMENT_DELETED_2,
    AnalyticsEvent.ASSESSMENT_LOCATION_CHANGED_2,
    AnalyticsEvent.ASSESSMENT_UPDATED_2,
    AnalyticsEvent.AVAILABILITY_CREATED_2,
    AnalyticsEvent.CLAIM_APPROVED_2,
    AnalyticsEvent.CLAIM_ASSIGNED_2,
    AnalyticsEvent.CLAIM_CANCELLED_2,
    AnalyticsEvent.CLAIM_OPENED_2,
    AnalyticsEvent.CLAIM_PAID_2,
    AnalyticsEvent.CLAIM_REASSIGNED_2,
    AnalyticsEvent.CLAIM_REJECTED_2,
    AnalyticsEvent.CLAIM_REOPENED_2,
    AnalyticsEvent.CLAIM_UNAPPROVED_2,
    AnalyticsEvent.CLAIM_UNASSIGNED_2,
    AnalyticsEvent.CLAIM_UNPAID_2,
    AnalyticsEvent.CLAIM_UPDATED_2,
    AnalyticsEvent.DOCTOR_VISIT_CONFIRMED_2,
    AnalyticsEvent.DOCTOR_VISIT_REMOVED_2,
    AnalyticsEvent.DOCTOR_VISIT_UPDATED_2,
  ];
  