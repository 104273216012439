import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import { Grid, Button, InputAdornment, Modal } from "@mui/material";
import {
  StyledCheckBox,
  StyledInput,
  StyledSelect,
} from "components/uiElements";
import { useAPI, useAuth, usePopups } from "hooks";
import useUnsavedChangesWarning from "hooks/useUnsavedChangesWarning";
import {
  CreateOrganisationUserInput,
  DeleteOrganisationUserInput,
  DeleteUserInput,
  User,
  OrganisationUser,
  UpdateUserInput,
  FindOrgUserResponseItem,
  Organisation,
  OrgType,
  ReenableUserInput,
} from "@s12solutions/types";
import * as COMMON from "common/constants/common";
import * as MESSAGES from "common/constants/messages";
import { StyledMultipleSelect } from "components/uiElements/StyledMultipleSelect";
import { LoadingButton } from "@mui/lab";
import CopyToClipboardButton from "components/clipBoard";
import { useNavigate, useLocation } from "react-router-dom";
import { compact, isEqual, uniqBy } from "lodash";
import ActivityDialog from "components/activityDialog";
import {
  GetUserActivitiesResponseItem,
  GetUserActivitiesInput,
  CreateClaimUserInput,
  CreateClaimUserResponseItem,
} from "common/types/commonTypes";
import { SSO_LIST, SSO_OPTION_LIST } from "common/constants/options";
import { isValidPhone } from "common/validators";
import { OrgUserFormSchema } from "utils/zod/schemas/OrgUserFormSchema";
import { FormErrors } from "common/types/formErrors";
import { resetInput, validateFormDataAsync, validateInput } from "utils/zod";
import { validateInputForZodEffectAsync } from "utils/zod/validateInput";
import EventModalContent from "components/EventModal/EventModal";
import {
  LABEL_CLAIM_APPROVER,
  LABEL_CLAIM_PAYER,
  LABEL_EMAIL,
  LABEL_FORCE_MFA,
  LABEL_NAME,
  LABEL_ORGANISATIONS,
  LABEL_PHONE,
  LABEL_SSO,
} from "common/constants/fields";

interface ChipArray {
  key: string;
  value: string;
  type?: OrgType;
}

interface OrgFormProps {
  handleCancel?: () => void;
  isEditing: boolean;
  orgUser?: FindOrgUserResponseItem;
}

type OrganisationListData = {
  ccgs: Organisation[];
  csus: Organisation[];
};
const useOrganisationListData = (): {
  loading: boolean;
  error: ReturnType<typeof useAPI>["error"];
  data: OrganisationListData;
} => {
  const {
    data: ccgs,
    loading: ccgsLoading,
    error: ccgsError,
  } = useAPI<Organisation[]>({
    method: "GET",
    fieldName: "listOrganisations",
  });
  const {
    data: csus,
    loading: csusLoading,
    error: csusError,
  } = useAPI<Organisation[]>({
    method: "GET",
    fieldName: "listOrganisations",
  });

  // Memoised to prevent infinite loop
  const memoisedData: OrganisationListData = useMemo(
    () => ({
      ccgs: ccgs || [],
      csus: csus || [],
    }),
    [ccgs, csus]
  );

  const loading = ccgsLoading || csusLoading;
  const error = ccgsError || csusError;

  return {
    loading,
    error,
    data: memoisedData,
  };
};

const OrgUserForm: React.FC<OrgFormProps> = ({
  handleCancel,
  orgUser,
  isEditing,
}) => {
  const readOnly = useAuth().user?.attributes["custom:limit_admin"];

  const location = useLocation();
  const navigate = useNavigate();

  if (!!location?.state) {
    let { isSameMenu = false } = location?.state as { isSameMenu: boolean };
    if (!!isSameMenu) {
      navigate(location.pathname, {
        state: { isSameMenu: false },
      });
      handleCancel && handleCancel();
    }
  }

  const { handleConfirmation, handleBannerMessage, handleSnackbar, closeSnackbar } = usePopups();
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const organisationList = useRef<ChipArray[]>([]);
  const [initialChips, setInitialChips] = useState<ChipArray[]>([]);
  const [orgChips, setOrgChips] = useState<ChipArray[]>([]);
  const [addChips, setAddChips] = useState<ChipArray[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [deletedChips, setDeletedChips] = useState<ChipArray[]>([]);
  const initialOrgUserDetails = useMemo(
    () => ({
      claimApprover: orgUser ? !!orgUser.claimApprover : false,
      claimPayer: orgUser ? !!orgUser.claimPayer : false,
      mfa: orgUser ? orgUser.mfa : false,
      sso:
        orgUser && orgUser?.sso === "Microsoft"
          ? "Microsoft"
          : orgUser
            ? "None"
            : "",
      name: orgUser?.name ?? "",
      email: orgUser?.email ?? "",
      phone: orgUser?.phone ?? "",
      userOrgs: compact(orgUser?.userOrgs) ?? [],
    }),
    [orgUser]
  );

  const [showAudit, setAudit] = useState(false);
  const userDetails = useRef(initialOrgUserDetails);

  const [orgUserDetails, setOrgUserDetails] = useState(userDetails.current);

  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    data: dialogData,
    loading: dialogLoading,
    error: dialogError,
    trigger: refetch,
  } = useAPI<GetUserActivitiesResponseItem, GetUserActivitiesInput>({
    method: "GET",
    fieldName: "getUserActivities",
    manual: true,
  });

  const {
    loading: createClaimUserLoading,
    error: createClaimUserError,
    trigger: createClaimUser,
  } = useAPI<CreateClaimUserResponseItem, { input: CreateClaimUserInput }>({
    method: "POST",
    fieldName: "createClaimUser",
    manual: true,
    onCompleted: (createClaimUserData) => {
      if (!createClaimUserData || createClaimUserError) {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.UNEXPECTED_ERROR_MESSAGE
        );
        return;
      }

      if (createClaimUserData.phoneExists) {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.DUPLICATE_PHONE_ERROR_MESSAGE
        );
        return;
      }

      if (createClaimUserData.userAlreadyExistsAndActive) {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.USER_ALREADY_EXISTS_MESSAGE
        );
        return;
      }

      if (createClaimUserData.createClaimUser) {
        handleBannerMessage(
          COMMON.TYPE_SUCCESS,
          MESSAGES.ADDED_SUCCESS.replace(
            "{name}",
            `Organisation User ${createClaimUserData.createClaimUser.name}`
          )
        );
        resetData();
        return;
      }
    },
  });

  // This function deals with the reactivation operation of the orgUser
  const {
    trigger: reenableUser,
    loading: reenableLoading,
  } = useAPI<User | null, { input: ReenableUserInput }>({
    method: "PUT",
    fieldName: "reenableUser",
    manual: true,
  });

  const handleReenable = useCallback(() => {
    if (!orgUser) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.INVALID_USER_MESSAGE);
      return;
    }
    handleConfirmation(MESSAGES.CONFIRMATION_MESSAGE, () => {
      reenableUser({
        input: {
          id: orgUser.username,
          type: "ORGCLAIMS",
        },
      })
        .then((value) => {
          if (!value || !value.data || !!value.error) {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          } else {
            handleBannerMessage(
              COMMON.TYPE_SUCCESS,
              MESSAGES.RESTORED_SUCCESS.replace(
                "{name}",
                `${MESSAGES.CLAIMS_MESSAGE}${COMMON.EMPTY_SPACE}${orgUser?.name}`
              )
            );
            handleCancel && handleCancel();
          }
        })
        .catch(() => {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        });
    });
  }, [handleConfirmation, handleBannerMessage, reenableUser, orgUser, handleCancel]);

  const { trigger: fetchPhoneInfo, error: fetchPhoneInfoError } = useAPI<{
    checkPhoneNumberExists: {
      exists: boolean;
    } | null;
  }>({
    method: "GET",
    fieldName: "checkPhoneNumberExists",
    manual: true,
  });

  // For Phone Validation
  const OrgUserFormSchemaPhoneMFA = OrgUserFormSchema.pick({
    mfa: true,
    phone: true,
  });

  const phoneRefinements = (
    obj: typeof OrgUserFormSchema | typeof OrgUserFormSchemaPhoneMFA
  ) => {
    return obj
      .refine((data) => !(data.mfa && data.phone.trim().length === 0), {
        message: "Phone is required",
        path: ["phone"],
      })
      .refine(
        async (data) => {
          if (isValidPhone(data.phone)) {
            let response = await fetchPhoneInfo({
              filter: {
                ...(!!orgUser && { id: orgUser.username }),
                phone: data.phone,
              },
            });

            return !response.data?.checkPhoneNumberExists?.exists;
          }
          return true;
        },
        {
          message: MESSAGES.DUPLICATE_PHONE_ERROR_MESSAGE,
          path: ["phone"],
        }
      );
  };

  const OrgUserFormSchemaPickedForMFAAndPhoneRefined = phoneRefinements(
    OrgUserFormSchemaPhoneMFA
  );

  // For Form Submission Validation
  const OrgUserFormSchemaRefined = phoneRefinements(OrgUserFormSchema);

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const addOrDeleteChip = useCallback(
    (newValue: ChipArray[]) => {
      if (newValue.length > orgChips.length) {
        let temAdd = newValue.filter((val) => !orgChips.includes(val));
        if (temAdd.length > 0 && !initialChips.includes(temAdd[0])) {
          setAddChips((add) => uniqBy([...add, temAdd[0]], (val) => val.key));
        } else {
          setDeletedChips((del) =>
            uniqBy(
              del.filter((i) => i.key !== temAdd[0].key),
              (val) => val.key
            )
          );
        }
      } else if (newValue.length < orgChips.length) {
        let temDelete = orgChips.filter((val) => !newValue.includes(val));
        if (temDelete.length > 0 && initialChips.includes(temDelete[0])) {
          setDeletedChips((del) =>
            uniqBy([...del, temDelete[0]], (val) => val.key)
          );
        } else {
          setAddChips((add) =>
            uniqBy(
              add.filter((val) => !temDelete.includes(val)),
              (val) => val.key
            )
          );
        }
      }
    },
    [initialChips, orgChips]
  );

  const addOrgUser = useCallback(async () => {
    validateFormDataAsync(
      OrgUserFormSchemaRefined,
      {
        ...orgUserDetails,
      },
      () => {
        createClaimUser({
          input: {
            name: orgUserDetails.name,
            email: orgUserDetails.email,
            phone: orgUserDetails.phone || undefined,
            mfa: orgUserDetails.mfa,
            sso: orgUserDetails.sso,
            claimApprover: orgUserDetails.claimApprover,
            claimPayer: orgUserDetails.claimPayer,
            addingOrgs: compact(
              uniqBy(orgChips, (val) => val.key).map((val) => {
                if (!val.type) {
                  return null;
                }
                return {
                  organisationUserOrganisationId: val.key,
                  type: val.type,
                };
              })
            ),
          },
        });
      },
      setFormErrors
    );
  }, [OrgUserFormSchemaRefined, createClaimUser, orgChips, orgUserDetails]);
  //Reset Add Org User data
  const resetData = () => {
    //This function should develop when Add orgUser feature available
    setOrgUserDetails({
      name: "",
      email: "",
      phone: "",
      userOrgs: [],
      claimApprover: false,
      claimPayer: false,
      mfa: false,
      sso: "",
    });
    setOrgChips([]);
    setFormErrors({});
  };

  const {
    trigger: deleteUser,
    loading: deleteLoading,
    error: deleteError,
  } = useAPI<User | null, { input: DeleteUserInput }>({
    method: "PUT",
    fieldName: "deleteUser",
    manual: true,
  });

  const deleteCCGUser = useCallback(() => {
    if (!orgUser) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.INVALID_USER_MESSAGE);
      return;
    }
    const organisationsArray = orgUserDetails.userOrgs?.map((item) =>
      JSON.stringify(item)
    );
    handleConfirmation(
      MESSAGES.CONFIRM_DELETED.replace("this item", `${orgUser?.name}`),
      () => {
        deleteUser({
          input: {
            id: orgUser.username,
            type: "ORGCLAIMS",
            organisations: organisationsArray,
          },
        })
          .then((value) => {
            if (value && value?.data) {
              handleBannerMessage(
                COMMON.TYPE_SUCCESS,
                MESSAGES.DELETED_SUCCESS.replace(
                  "{name}",
                  `CCG User${COMMON.EMPTY_SPACE}${value?.data.id}`
                )
              );
              let timer = setTimeout(() => {
                handleCancel && handleCancel();
              }, 4000);
              return () => {
                clearTimeout(timer);
              };
            } else {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          })
          .catch(() => {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          });
      },
      "Warning!"
    );
  }, [
    deleteUser,
    handleBannerMessage,
    handleCancel,
    handleConfirmation,
    orgUser,
    orgUserDetails.userOrgs,
  ]);

  const {
    trigger: updateClaimUser,
    loading: updateClaimUserLoading,
    error: updateError,
  } = useAPI<
    User | null,
    {
      input: UpdateUserInput;
    }
  >({
    method: "PUT",
    fieldName: "updateClaimUser",
    manual: true,
  });

  const {
    trigger: addNewOrg,
    loading: addNewOrgLoading,
    error: addNewOrgError,
  } = useAPI<
    OrganisationUser | null,
    {
      input: CreateOrganisationUserInput;
    }
  >({
    method: "POST",
    fieldName: "createOrganisationUser",
    manual: true,
  });

  const {
    trigger: deleteOrg,
    loading: deleteOrgLoading,
    error: deleteOrgError,
  } = useAPI<
    OrganisationUser | null,
    {
      input: DeleteOrganisationUserInput;
    }
  >({
    method: "PUT",
    fieldName: "deleteOrganisationUser",
    manual: true,
  });

  const {
    data: organisationListData,
    loading: organisationListLoading,
    error: organisationListError,
  } = useOrganisationListData();

  userDetails.current = initialOrgUserDetails;

  useEffect(() => {
    let orgList: ChipArray[] = [];

    if (!organisationList.current || organisationList.current.length === 0) {
      let value = organisationListData ?? { ccgs: [], csus: [] };
      let temp: ChipArray[] = [];

      value.ccgs.forEach((item) => {
        temp.push({ key: item.id, value: item.name, type: item.type });
      });
      value.csus.forEach((item) => {
        temp.push({ key: item.id, value: item.name, type: item.type });
      });
      temp = temp.sort((a, b) => a.value.localeCompare(b.value));
      organisationList.current = uniqBy(temp, (val) => val.key);

      orgList = temp;
    } else {
      orgList = organisationList.current;
    }
    const temp = orgUser
      ? compact(
        compact(orgUser.userOrgs).map((item) => {
          // Only add to the list of a valid returned CCG

          return orgList.find(
            (i) => i.key === item.organisationUserOrganisationId
          );
        })
      )
      : [];
    setInitialChips(uniqBy(temp, (val) => val.key));
    setOrgChips(uniqBy(temp, (val) => val.key));
  }, [orgUser, organisationListData]);

  //check page whether edit or not
  useEffect(() => {
    if (
      isEqual(orgChips, initialChips) &&
      isEqual(orgUserDetails, userDetails.current)
    ) {
      setPristine();
      setIsUpdate(false);
    } else {
      setDirty();
      setIsUpdate(true);
    }
  }, [setDirty, setPristine, orgChips, initialChips, orgUserDetails]);

  const orgUserUpdate = useCallback(async () => {
    try {
      if (!isUpdate) {
        handleBannerMessage(COMMON.TYPE_INFO, MESSAGES.NOTHING_TO_UPDATE);
        return;
      }

      if (!orgUser) {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.UNEXPECTED_ERROR_MESSAGE
        );
        return;
      }

      validateFormDataAsync(
        OrgUserFormSchemaRefined,
        {
          ...orgUserDetails,
        },
        async () => {
          try {
            const addResult = await Promise.all(
              uniqBy(addChips, (val) => val.key)?.map(async (item) => {
                if (!item.type) {
                  return await Promise.reject();
                }
                return await addNewOrg({
                  input: {
                    organisationUserOrganisationId: item.key,
                    organisationUserUserId: orgUser?.username,
                    type: item.type,
                  },
                });
              })
            );

            if (
              addResult.some(
                (result) =>
                  !result ||
                  (result && !result.data) ||
                  (result && !!result.error)
              )
            ) {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
              return;
            }

            const deleteResult = await Promise.all(
              uniqBy(deletedChips, (val) => val.key)?.map(async (item) => {
                if (!orgUserDetails || !orgUserDetails.userOrgs) {
                  return await Promise.reject();
                }
                let temp = compact(orgUserDetails.userOrgs).find(
                  (i) => i.organisationUserOrganisationId === item.key
                );
                if (!temp) {
                  return await Promise.reject();
                }
                return await deleteOrg({
                  input: {
                    id: temp.id,
                  },
                });
              })
            );

            if (
              deleteResult.some(
                (result) =>
                  !result ||
                  (result && !result.data) ||
                  (result && !!result.error)
              )
            ) {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
              return;
            }
            const result = await updateClaimUser({
              input: {
                id: orgUser.username,
                name: orgUserDetails.name,
                email: orgUserDetails.email,
                phone: orgUserDetails.phone,
                mfa: orgUserDetails.mfa,
                sso:
                  orgUserDetails.sso === "Microsoft"
                    ? orgUserDetails.sso
                    : undefined,
                claimApprover: orgUserDetails.claimApprover,
                claimPayer: orgUserDetails.claimPayer,
              },
            });

            if (result && result.data && !result.error) {
              handleBannerMessage(
                COMMON.TYPE_SUCCESS,
                MESSAGES.UPDATED_SUCCESS.replace(
                  "{name}",
                  "User Organisation(s)"
                )
              );
              setAddChips([]);
              setDeletedChips([]);
              setInitialChips(orgChips);
              userDetails.current = orgUserDetails;
            } else {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          } catch {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          }
        },
        setFormErrors
      );
    } catch (e) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    isUpdate,
    orgUser,
    OrgUserFormSchemaRefined,
    orgUserDetails,
    handleBannerMessage,
    addChips,
    deletedChips,
    updateClaimUser,
    addNewOrg,
    deleteOrg,
    orgChips,
  ]);

  // Use Effects
  useEffect(() => {
    if (
      fetchPhoneInfoError ||
      organisationListError ||
      deleteOrgError ||
      addNewOrgError ||
      updateError ||
      deleteError ||
      dialogError ||
      createClaimUserError
    ) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    addNewOrgError,
    createClaimUserError,
    deleteError,
    deleteOrgError,
    dialogError,
    fetchPhoneInfoError,
    handleBannerMessage,
    organisationListError,
    updateError,
  ]);

  // Handles displaying and closing 'user deleted' snackbar
  useEffect(() => {
    if (orgUser?.deleted) {
      handleSnackbar(COMMON.TYPE_ERROR, MESSAGES.USER_DELETED, -1, true)
    }
    return () => {
      closeSnackbar();
    };

  }, [orgUser, handleSnackbar, closeSnackbar])

  return (
    <>
      <Grid
        xs={12}
        direction="column"
        mt={3}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          borderRadius: 1,
          minHeight: window.innerHeight - 85,
        }}
      >
        {Prompt}
        <ActivityDialog
          data={dialogData?.getUserActivities ?? []}
          open={dialogOpen}
          loading={dialogLoading}
          setDialogOpen={setDialogOpen}
          error={dialogError}
        />
        <Grid container spacing={3} xs={12}>
          <Grid
            container
            item
            spacing={3}
            xs={6}
            sx={{
              position: "relative",
              overflow: "auto",
            }}
          >
            <Grid item mt={1} xs={12}>
              <StyledInput
                id="ccg_org_name"
                name="Name"
                label={LABEL_NAME}
                value={orgUserDetails.name}
                disabled={readOnly || !!orgUser?.deleted}
                required
                endAdornment={
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id="ccg_org_name"
                      value={orgUserDetails.name}
                    />
                  </InputAdornment>
                }
                error={!!formErrors.name}
                errorText={formErrors.name}
                onBlur={(event) => {
                  validateInput(
                    OrgUserFormSchema,
                    "name",
                    { name: event.target.value },
                    setFormErrors
                  );
                }}
                onChange={(event) => {
                  setOrgUserDetails((val) => ({
                    ...val,
                    name: event.target.value,
                  }));
                  resetInput("name", setFormErrors);
                }}
              />
            </Grid>
            <Grid item mt={1} xs={12}>
              <StyledInput
                id="ccg_org_email"
                name="Contact Email"
                required
                label={LABEL_EMAIL}
                value={orgUserDetails.email}
                endAdornment={
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id="ccg_org_email"
                      value={orgUserDetails.email}
                    />
                  </InputAdornment>
                }
                maxLength={255}
                disabled={readOnly || !!orgUser?.deleted}
                error={!!formErrors.email}
                errorText={formErrors.email}
                onBlur={(event) => {
                  validateInput(
                    OrgUserFormSchema,
                    "email",
                    { email: event.target.value },
                    setFormErrors
                  );
                }}
                onChange={(event) => {
                  setOrgUserDetails((val) => ({
                    ...val,
                    email: event.target.value,
                  }));
                  resetInput("email", setFormErrors);
                }}
              />
            </Grid>
            <Grid item mt={1} xs={12}>
              <StyledInput
                id="ccg_org_phone"
                name="Contact Phone"
                required={!!orgUserDetails.mfa}
                label={LABEL_PHONE}
                value={orgUserDetails.phone}
                endAdornment={
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id="ccg_org_phone"
                      value={orgUserDetails.phone ?? ""}
                    />
                  </InputAdornment>
                }
                helperText={MESSAGES.PHONE_NUMBER_FORMAT_HELPER_MESSAGE}
                maxLength={255}
                disabled={readOnly || !!orgUser?.deleted}
                error={!!formErrors.phone}
                errorText={formErrors.phone}
                onBlur={(event) => {
                  validateInputForZodEffectAsync(
                    OrgUserFormSchemaPickedForMFAAndPhoneRefined,
                    "phone",
                    {
                      phone: event.target.value ?? "",
                      mfa: orgUserDetails.mfa,
                    },
                    setFormErrors
                  );
                }}
                onChange={(event) => {
                  setOrgUserDetails((val) => ({
                    ...val,
                    phone: event.target.value,
                  }));
                  resetInput("phone", setFormErrors);
                }}
              />
            </Grid>

            <Grid item mt={1} xs={12}>
              <StyledSelect
                id="ccg_org_sso"
                name="sso"
                label={LABEL_SSO}
                required
                value={orgUserDetails.sso}
                endAdornment={
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id="ccg_org_sso"
                      value={orgUserDetails.phone ?? ""}
                    />
                  </InputAdornment>
                }
                menuItems={SSO_OPTION_LIST}
                error={!!formErrors.sso}
                errorText={formErrors.sso}
                helperText={
                  orgUserDetails.sso === SSO_LIST.Microsoft
                    ? MESSAGES.SSO_SELECTED_HELPER_MESSAGE
                    : COMMON.EMPTY_STRING
                }
                onBlur={(event) => {
                  validateInput(
                    OrgUserFormSchema,
                    "sso",
                    { sso: String(event.target.value) },
                    setFormErrors
                  );
                }}
                onChange={(event) => {
                  setOrgUserDetails((val) => ({
                    ...val,
                    sso: (event.target.value as string) ?? "",
                  }));
                  resetInput("sso", setFormErrors);
                }}
                disabled={readOnly || (orgUser && orgUser.sso === "Microsoft") || !!orgUser?.deleted}
              />
            </Grid>

            <Grid item mt={1} xs={12}>
              <StyledMultipleSelect
                id="Organisations"
                autoFocus={true}
                options={organisationList.current}
                loading={organisationListLoading}
                handlingState={orgChips}
                handlingFunction={(_event, newValue) => {
                  setOrgChips(uniqBy(newValue, (val) => val.key));
                  addOrDeleteChip(uniqBy(newValue, (val) => val.key));
                }}
                label={LABEL_ORGANISATIONS}
                placeholder="Type here for search "
                disabled={readOnly || !!orgUser?.deleted}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={2}
            xs={6}
            display="flex"
            flexDirection="column"
          >
            {!!orgUser && (
              <Grid item ml={8} mt={1}>
                <LoadingButton
                  id=""
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={async () => {
                    setDialogOpen(true);
                    refetch({
                      username: orgUser.username,
                    });
                  }}
                  style={{ marginRight: 10 }}
                >
                  Login Activity
                </LoadingButton>

                <LoadingButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => setAudit(true)}
                >
                  Audit
                </LoadingButton>
              </Grid>
            )}

            <Grid item ml={8} mt={1}>
              <StyledCheckBox
                id="force-mfa"
                label={LABEL_FORCE_MFA}
                checked={orgUserDetails.mfa}
                handleChange={() => {
                  setOrgUserDetails((val) => ({ ...val, mfa: !val.mfa }));
                }}
                disabled={readOnly || !!orgUser?.mfa || !!orgUser?.deleted}
              />

              <StyledCheckBox
                id="claim-approver"
                label={LABEL_CLAIM_APPROVER}
                checked={orgUserDetails.claimApprover}
                handleChange={() => {
                  setOrgUserDetails((val) => {
                    return { ...val, claimApprover: !val.claimApprover };
                  });
                }}
                disabled={readOnly || !!orgUser?.deleted}
              />

              <StyledCheckBox
                id="claim-payer"
                label={LABEL_CLAIM_PAYER}
                checked={orgUserDetails.claimPayer}
                handleChange={() => {
                  setOrgUserDetails((val) => {
                    return { ...val, claimPayer: !val.claimPayer };
                  });
                }}
                disabled={readOnly || !!orgUser?.deleted}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} mt={3} display={"flex"} >
          {!!orgUser?.deleted ? (<>
            <Grid item>
              <LoadingButton
                id={"restore"}
                variant="outlined"
                color="secondary"
                size="large"
                loading={reenableLoading}
                onClick={handleReenable}
                disabled={readOnly}
              >
                Restore AMHP
              </LoadingButton>
            </Grid>
          </>) : (<>
            <LoadingButton
              loading={
                addNewOrgLoading ||
                deleteOrgLoading ||
                updateClaimUserLoading ||
                createClaimUserLoading
              }
              sx={{
                marginRight: 1,
              }}
              variant="contained"
              onClick={() =>
                isEditing && orgUser ? orgUserUpdate() : addOrgUser()
              }
              disabled={readOnly}
            >
              {isEditing ? "Update User" : "Add User"}
            </LoadingButton>
            {isEditing && orgUser && (
              <LoadingButton
                loading={deleteLoading}
                disabled={readOnly}
                sx={{
                  marginRight: 1,
                  marginLeft: 1,
                }}
                color="error"
                variant="contained"
                onClick={() => deleteCCGUser()}
              >
                Delete User
              </LoadingButton>
            )}
          </>)}
          <Button
            sx={{
              marginLeft: 1,
            }}
            variant="outlined"
            onClick={
              isEditing && orgUser
                ? () => {
                  handleConfirmation(
                    MESSAGES.CONFIRM_CANCEL,
                    () => {
                      handleCancel && handleCancel();
                    },
                    "Confirm"
                  );
                }
                : () => {
                  handleConfirmation(
                    MESSAGES.CONFIRM_CLEAR,
                    resetData,
                    "Warning!"
                  );
                }
            }
            disabled={readOnly}
          >
            {isEditing && orgUser ? "Cancel" : "Clear"}
          </Button>

        </Grid>
        {orgUser?.username && (
          <Modal open={showAudit} onClose={() => setAudit(false)}>
            <EventModalContent
              filter={{
                fieldName: "getAuditLog",
                category: "orgUser",
                id: orgUser.username,
              }}
            />
          </Modal>
        )}
      </Grid>
    </>
  );
};

export default React.memo(OrgUserForm);
