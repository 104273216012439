import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Hooks
import { useAPI, usePopups } from "hooks";

// Material UI Imports
import { Card, Grid, Typography as Text } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

// Custom Imports
import UserSelect from "components/userSelect";

// Custom Types
import { User, ResendWelcomeEmailResponse } from "@s12solutions/types";

const ResendWelcomeEmail: React.FC = () => {
  // Hooks
  const navigate = useNavigate();
  const location: any = useLocation();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
  }
  const { handleConfirmation, handleSnackbar } = usePopups();
  const { trigger: resendWelcomeEmail } = useAPI<
    ResendWelcomeEmailResponse,
    { id: string }
  >({
    method: "POST",
    fieldName: "resendWelcomeEmail",
    manual: true,
    onCompleted: (data) => {
      if (!data) return;
      const { success, error } = data;

      if (success) {
        handleSnackbar("success", "Resend welcome email successful.");
      } else if (error) {
        handleSnackbar("error", error);
      }
    },
    onError: () => {
      handleSnackbar("error", "An unknown error occurred, please try again.");
    },
  });

  // Actions
  const handleResendWelcomeEmail = useCallback(
    (user: User) => {
      handleConfirmation(
        "Are you sure you want to resend welcome email?",
        () => {
          resendWelcomeEmail({
            id: user.id,
          });
        }
      );
    },
    [handleConfirmation, resendWelcomeEmail]
  );

  return (
    <Grid
      xs={12}
      m={1}
      p={2}
      component={Card}
      direction="column"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 1,
        minHeight: window.innerHeight - 85,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Text variant="h1">Resend Welcome Email</Text>
        </Grid>
        <Grid item xs={12} mt={1}>
          <UserSelect
            userType="All"
            customActions={[
              {
                icon: <CheckCircle color="success" />,
                label: "Resend Welcome Email",
                onClick: handleResendWelcomeEmail,
              },
            ]}
            customOnClickRowData={handleResendWelcomeEmail}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(ResendWelcomeEmail);
