import React from "react";

// Material UI Imports
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Grid } from "@mui/material";

// Custom Types
import Search from "components/search/Search";
import {
  HYPHEN,
  LABEL_SEARCH_AMHP,
  PLACEHOLDER_SEARCH,
} from "common/constants/common";
import {
  ID_EMAIL,
  ID_NAME,
  ID_PHONE,
  LABEL_NAME,
  LABEL_PHONE,
  LABEL_EMAIL,
  ID_DELETED,
  LABEL_STATUS,
} from "common/constants/fields";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { User } from "@s12solutions/types";
import { useNavigate, useLocation } from "react-router-dom";

// Custom Types

interface AmhpSelectProps {
  setAmhpId: React.Dispatch<React.SetStateAction<string | null>>;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  searchString: string;
  amhpsData: User[];
  amhpsLoading: boolean;
  amhpsError: any;
}

const AMHPSelectForm: React.FC<AmhpSelectProps> = ({
  setAmhpId,
  setSearchString,
  searchString,
  amhpsData,
  amhpsLoading,
  amhpsError,
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
  }

  return (
    <Grid container spacing={3} mt={1}>
      <Grid item sm={12}>
        <Search
          rowsPerPageOptions={[5, 10, 25, 100]}
          autoFocus={true}
          error={amhpsError}
          loading={amhpsLoading}
          searchString={searchString}
          handleSearch={setSearchString}
          searchLabel={LABEL_SEARCH_AMHP}
          headerData={[
            {
              field: "actions",
              type: "actions",
              width: 80,
              getActions: (params: any) => [
                <GridActionsCellItem
                  icon={<EditIcon color="primary" />}
                  label="Edit"
                  onClick={() => {
                    setAmhpId(params.row.id as string)
                  }}
                />,
                <GridActionsCellItem
                  icon={<OpenInNewIcon color="primary" />}
                  label="New Tab"
                  onClick={() => {
                    const queryString = encodeURIComponent(params.row.id);
                    window.open(
                      window.location.href + "?" + queryString,
                      "_blank"
                    );
                  }}
                />,
              ],
            },
            {
              headerName: LABEL_NAME,
              field: ID_NAME,
              minWidth: 200,
              flex: 1,
            },
            {
              headerName: LABEL_EMAIL,
              field: ID_EMAIL,
              minWidth: 200,
              flex: 1,
            },
            {
              headerName: LABEL_PHONE,
              field: ID_PHONE,
              minWidth: 200,
              flex: 1,
              valueGetter: (params: any) => params.row.phone || HYPHEN,
            },
            {
              headerName: LABEL_STATUS,
              field: ID_DELETED,
              minWidth: 200,
              flex: 1,
              valueGetter: (params: any) =>
                params.row.deleted ? "deleted" : "-",
            },
          ]}
          rowData={amhpsData}
          placeholder={PLACEHOLDER_SEARCH}
          onClickRowData={(event: any) => {
            setAmhpId(event.id);
          }}
        />
      </Grid>
    </Grid>
  );
};

export { AMHPSelectForm };
