import { AnalyticsEvent } from "common/reporting/events";
import * as Sentry from "@sentry/browser";


// TODO Need To handle errors appropriately
export const networkError: S12ErrorInput = {
    message: "No network connection.",
    errorCode: 0,
    networkError: new Error(),
  };
  
  export const formatError: S12ErrorInput = {
    message:
      "Saving failed because information or format entered is incorrect or not allowed. Please check and try again.",
    errorCode: 4000,
  };
  
  export const unauthenticatedError: S12ErrorInput = {
    message: "You must be logged in to complete this action. Please login and try again.",
    errorCode: 4010,
  };
  
  export const forbiddenError: S12ErrorInput = {
    message: "Not allowed.",
    errorCode: 4030,
  };
  
  export const getError: S12ErrorInput = {
    message: "Unable to retrieve information. Please check your internet connection and try again.",
    errorCode: 4040,
    networkError: new Error(),
  };
  
  export const getAssessmentError: S12ErrorInput = {
    message: "Unable to retrieve assessment information. Please check your internet connection and try again.",
    errorCode: 4041,
  };
  
  export const getAddressError: S12ErrorInput = {
    message: "Unable to find address. Please check your information and try again.",
    errorCode: 4042,
  };
  
  export const generalClientError: S12ErrorInput = {
    message: "Error logged. Please contact our Support Team if the problem persists.",
    errorCode: 5000,
  };
  
  export const saveError: S12ErrorInput = {
    message: "Unable to save information. Please check your internet connection and try again.",
    errorCode: 5010,
  };
  
  export const createAssessmentError: S12ErrorInput = {
    message: "Unable to create assessment. Please check your internet connection and try again.",
    errorCode: 5011,
  };
  
  export const updateAssessmentError: S12ErrorInput = {
    message: "Could not update assessment. Please check your internet connection and try again.",
    errorCode: 5012,
  };
  
  export const createClaimError: S12ErrorInput = {
    message: "Unable to create claim. Please check your internet connection and try again.",
    errorCode: 5013,
  };
  
  export const updateClaimError: S12ErrorInput = {
    message: "Could not submit claim. Please check your internet connection and try again.",
    errorCode: 5014,
  };
  
  export const cancelClaimError: S12ErrorInput = {
    message: "Could not decline claim. Please check your internet connection and try again.",
    errorCode: 5015,
  };
  
  export const updateDoctorProfileError: S12ErrorInput = {
    message: "Could not update profile. Please check your internet connection and try again.",
    errorCode: 5016,
  };
  
  export const deleteAssessmentError: S12ErrorInput = {
    message: "Could not delete assessment. Please check your internet connection and try again.",
    errorCode: 5017,
  };
  
  export const deleteHolidayError: S12ErrorInput = {
    message: "Could not delete time Away. Please check your internet connection and try again.",
    errorCode: 5018,
  };
  
  export const deleteError: S12ErrorInput = {
    message: "Could not delete information. Please check your internet connection and try again.",
    errorCode: 5020,
  };
  
  export const removeVisitError: S12ErrorInput = {
    message: "Unable to remove doctor from visit. Please check your internet connection and try again.",
    errorCode: 5021,
  };
  
  export const deleteAvailabilityError: S12ErrorInput = {
    message: "Unable to delete availability. Please check your internet connection and try again.",
    errorCode: 5022,
  };
  
  export const addVisitError: S12ErrorInput = {
    message: "Could not add doctor to visit. Please check your internet connection and try again.",
    errorCode: 5023,
  };
  
  export const acceptEulaError: S12ErrorInput = {
    message: "Unable to save acceptance of terms. Please check your internet connection and try again.",
    errorCode: 5024,
  };
  
  export const resetPasswordError: S12ErrorInput = {
    eventType: AnalyticsEvent.RESET_PASSWORD_FAIL,
    message:
      "Could not reset password. Please check your internet connection or contact support if this problem persists",
    errorCode: 5025,
  };
  
  export const sendToDoctorFormError: S12ErrorInput = {
    message: "Unable to send form to doctor.  Please check your internet connection and try again.",
    errorCode: 5026,
  };
  
  export const updateFormStatusError: S12ErrorInput = {
    message:
      "Changes to form not synced. Please check your internet connection or contact support if this problem persists",
    errorCode: 5027,
  };
  
  export const sendEmailToAddressError: S12ErrorInput = {
    message:
      "Could not send email to selected address. Please check your internet connection or contact support if this problem persists",
    errorCode: 5028,
  };
  
  export const saveFormError: S12ErrorInput = {
    message: "Unable to save form. Please check your internet connection and try again.",
    errorCode: 5029,
  };
  
  export const downloadFormError: S12ErrorInput = {
    message: "Could not download form. Please check your internet connection or contact support if this problem persists",
    errorCode: 5030,
  };
  
  export const deleteFormError: S12ErrorInput = {
    message: "Could not delete form. Please check your internet connection or contact support if this problem persists",
    errorCode: 5031,
  };
  
  export const reassignFormError: S12ErrorInput = {
    message: "Could not reassign form. Please check your internet connection or contact support if this problem persists",
    errorCode: 5032,
  };
  
  export const newPasswordError: S12ErrorInput = {
    eventType: AnalyticsEvent.NEW_PASSWORD_FAIL,
    message:
      "Could not update password. Please check your internet connection or contact support if this problem persists",
    errorCode: 5033,
  };
  
  export const newSMSCodeError: S12ErrorInput = {
    eventType: AnalyticsEvent.NEW_SMS_CODE_FAIL,
    message:
      "Unable to request confirmation code. Please check your internet connection or contact support if this problem persists",
    errorCode: 5034,
  };

export interface S12ErrorInput {
    eventType?: AnalyticsEvent;
    message: string;
    errorCode?: number;
    stack?: string;
    additional?: string;
    networkError?: Error | null;
  }

export interface S12Error {
    id: string;
    displayText?: string;
    errorCode?: number;
    eventType?: any;
    message: string;
    additional?: string;
    stack?: string;
    isNetworkError?: boolean;
  }
  
  export class S12Error extends Error implements S12Error {
    constructor(input: S12ErrorInput, baseError?: Error | string) {
      const { message, errorCode, additional, stack } = input;
      super(message);
      this.message = message;
      this.errorCode = errorCode;
      this.additional =
        additional || (baseError && baseError instanceof Error ? baseError.message : (baseError || "").toString());
      this.stack = stack || (baseError && baseError instanceof Error ? baseError.stack : undefined);
  
      this.isNetworkError = !!input.networkError;
  
      this.id =
        Date.now().toString(36) +
        Math.random()
          .toString(36)
          .substring(2);
  
      this.eventType = input.eventType;
  
      return this;
    }
  
    save() {
      if (this.eventType) {
        return;
      }
  
      if (!!process.env.REACT_APP_SENTRY_KEY && !this.isNetworkError) {
        Sentry.withScope(scope => {
          this.additional && scope.setExtra("additional", JSON.stringify(this.additional));
          this.errorCode && scope.setExtra("code", this.errorCode);
          scope.setExtra("client_generated_id", this.id);
          Sentry.captureException(this);
        });
      }
    }
  
    saveAndLog(infoWithComponentStack: { componentStack?: string }) {
      if (this.eventType) {
        return;
      }
      if (!!process.env.REACT_APP_SENTRY_KEY && !this.isNetworkError) {
        Sentry.withScope(scope => {
          this.additional && scope.setExtra("additional", JSON.stringify(this.additional));
          this.errorCode && scope.setExtra("code", this.errorCode);
          scope.setExtra("client_generated_id", this.id);
          if (infoWithComponentStack.componentStack) {
            scope.setExtra("component_stack", infoWithComponentStack.componentStack);
          }
          Sentry.captureException(this);
        });
      }
  
      if (process.env.REACT_APP_ENV !== "prod" && process.env.REACT_APP_ENV !== "production") {
        console.log("RL ERROR - ", this.errorCode, infoWithComponentStack);
      }
    }
  }
  