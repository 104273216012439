import * as React from "react";
import { S12Error, generalClientError } from "./Error";
import { ErrorBoundaryMessage } from "./ErrorBoundaryMessage";

interface Props {
  children: React.ReactNode;
}
interface State {
  hasError: boolean;
  error?: S12Error;
}

// TODO Need To handle errors appropriately
export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: string | Error) {
    // Update state so the next render will show the fallback UI.
    if (error instanceof S12Error) {
      return {
        hasError: true,
        error: error,
      };
    } else {
      return {
        hasError: true,
        error: new S12Error({
          ...generalClientError,
          stack: error instanceof Error ? error.stack : undefined,
        }),
      };
    }
  }

  componentDidCatch(error: string | Error, info: any) {
    if (error instanceof S12Error) {
      error.saveAndLog(info);
    } else {
      const newError = new S12Error({
        ...generalClientError,
        stack: error instanceof Error ? error.stack : undefined,
        additional: JSON.stringify({
          stack: info.componentStack,
          message: error.toString(),
        }),
      });
      newError.saveAndLog(info);
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryMessage error={this.state.error || new S12Error(generalClientError)} />
      );
    }

    return this.props.children;
  }
}

