import { Box, Card, Grid, Typography as Text } from "@mui/material";
import Progress from "components/cirularProgress/Progress";
import { useAPI } from "hooks";
import React, { useState } from "react";
import GlobalSettingOption from "./GlobalSettingOption";
import {
  DataGridPro,
  GridActionsCellItem,
  GridOverlay,
} from "@mui/x-data-grid-pro";
import * as constants from "common/constants/theme";
import EditIcon from "@mui/icons-material/Edit";
import { LABEL_NAME } from "common/constants/fields";

// Types
type GetGlobalSettingsResponseItem = {
  id: string | undefined;
  updatedBy: string | undefined;
  updatedAt: string | undefined;
}[];

/** Global settings main page */
const GlobalSettings: React.FC = () => {
  // States
  const [globalSettingValues, setGlobalSettingValues] =
    useState<GetGlobalSettingsResponseItem>([]);

  const [pageSize, setPageSize] = useState(10);

  const [tableLoading, setTableLoading] = useState<boolean>(true);

  const [settingRowSelection, setSettingRowSelection] = useState<any | null>(
    null
  );

  // API GET
  useAPI<GetGlobalSettingsResponseItem, any>({
    method: "GET",
    fieldName: "getGlobalSettings",
    onCompleted(data) {
      setGlobalSettingValues((prevGlobalSettingValues) => [
        ...prevGlobalSettingValues,
        ...data!.map((setting: any) => ({
          id: setting.id,
          updatedAt: setting.updatedAt,
          updatedBy: setting.updatedBy,
        })),
      ]);
      setTableLoading(false);
    },
  });

  // Main return
  return (
    <>
      <Progress loading={tableLoading}>
        <Grid
          xs={12}
          m={1}
          p={2}
          component={Card}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 1,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Text variant="h1">Global Settings</Text>
            </Grid>
            {!!settingRowSelection ? (
              <GlobalSettingOption
                id={settingRowSelection.id}
                handleCancel={() => {
                  setSettingRowSelection(null);
                }}
              ></GlobalSettingOption>
            ) : (
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <Box sx={{ width: "100%" }}>
                    <DataGridPro
                      disableColumnPinning={true}
                      disableColumnReorder={true}
                      onPageSizeChange={(newPageSize: number) =>
                        setPageSize(newPageSize)
                      }
                      pagination
                      pageSize={pageSize}
                      autoHeight={true}
                      density="compact"
                      hideFooterSelectedRowCount={true}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: "bold",
                        },
                        "& .MuiDataGrid-row": {
                          ":hover": {
                            cursor: "pointer",
                            backgroundColor: constants.lightBaseGrey,
                          },
                        },
                        "& .super-app-theme--even": {
                          backgroundColor: constants.lightGrey,
                        },
                        "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                        {
                          outline: "none",
                        },
                      }}
                      getRowClassName={(params) =>
                        `super-app-theme--${params.indexRelativeToCurrentPage % 2 === 0
                          ? "even"
                          : "odd"
                        }`
                      }
                      getRowId={(row) => row.id!}
                      columns={[
                        {
                          field: "actions",
                          type: "actions",
                          width: 5,
                          getActions: (params: any) => [
                            <GridActionsCellItem
                              icon={<EditIcon color="primary" />}
                              label="Edit"
                              onClick={() => {
                                setSettingRowSelection(params.row);
                              }}
                            />,
                          ],
                        },
                        {
                          headerName: LABEL_NAME,
                          field: "id",
                          minWidth: 200,
                          flex: 1,
                        },
                      ]}
                      rows={globalSettingValues}
                      onRowClick={(event) => {
                        setSettingRowSelection(event.row);
                      }}
                      components={{
                        NoRowsOverlay: () => (
                          <GridOverlay>
                            <Text>{"No Matching "}</Text>
                          </GridOverlay>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Progress>
    </>
  );
};

export default React.memo(GlobalSettings);
