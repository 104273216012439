import {
  INVALID_EMAIL_ERROR_MESSAGE,
  INVALID_POSTCODE_ERROR_MESSAGE,
  INVALID_PHONE_OR_INPUT_ERROR_MESSAGE,
  INVALID_EMAIL_OR_INPUT_ERROR_MESSAGE,
} from "common/constants/messages";
import { isValidPhone, isValidPostCode } from "common/validators";
import { uniqBy } from "lodash";
import { z } from "zod";

export const AMHPTeamFormSchema = z.object({
  commonAddresses: z
    .array(
      z.object({
        location: z
          .object({
            lat: z.number(),
            lon: z.number(),
          })
          .optional()
          .nullable(),
        locationName: z
          .object({
            address: z.string().optional().nullable(),
            addressNotes: z.string().optional().nullable(),
            postcode: z.string(),
            city: z.string().optional().nullable(),
          })
          .optional()
          .nullable(),
        ccgId: z.string(),
        ccgName: z.string().optional().nullable(),
        itemText: z.string().optional().nullable(),
        id: z.string(),
      })
    )
    .refine(
      (data) => uniqBy(data, (param) => param.id).length === data.length,
      "Address already added"
    ),
  siteAmhpTeamsId: z.string().min(1, "Site is required"),
  featureFlags: z.object({
    amhpShouldProvideNhsNumber: z.boolean(),
    amhpTeamVideoMeetings: z.boolean(),
    amhpTeamMHAExternalDoctors: z.boolean(),
    amhpTeamMHAForms: z.boolean(),
    amhpTeamMhaHospitalEmails: z.boolean(),
    amhpTeamEmailFormsToSelf: z.boolean(),
    amhpTeamEmailFormsToApprovedLists: z.boolean(),
    amhpTeamDownloadForms: z.boolean(),
    amhpTeamUsesJointMedRecs: z.boolean(),
    amhpTeamInbox: z.boolean(),
    amhpTeamUsesConveyanceForms: z.boolean(),
    docInformalMedRecs: z.boolean(),
    requiresSSO: z.boolean(),
    requiresMFA: z.boolean(),
  }),
  name: z.string().min(1, "Team name is required"),
  fullTeamName: z.string().optional().nullable(),
  hospitalEmails: z
    .array(
      z.object({
        hierarchy: z.array(z.string()),
        hospital: z.string(),
        wardName: z.string(),
        wardEmail: z.string(),
        id: z.string(),
      })
    )
    .refine(
      (data) => uniqBy(data, (param) => param.id).length === data.length,
      "Duplicate hospital ward"
    ),
  localAuthority: z.string(),
  mhaInboxInstructions: z.string(),
  emails: z
    .array(
      z.object({
        key: z.string().email(INVALID_EMAIL_OR_INPUT_ERROR_MESSAGE),
        label: z.string().email(INVALID_EMAIL_OR_INPUT_ERROR_MESSAGE),
      })
    )
    .refine(
      (data) => uniqBy(data, (param) => param.label).length === data.length,
      "Duplicate email"
    ),
  mhaEmailAddresses: z
    .array(
      z.object({
        hierarchy: z.array(z.string()),
        groupName: z.string(),
        recipientName: z.string(),
        recipientEmail: z.string(),
        id: z.string(),
      })
    )
    .refine(
      (data) => uniqBy(data, (param) => param.id).length === data.length,
      "Duplicate recipient"
    ),
  phoneNumbers: z
    .array(
      z.object({
        key: z
          .string()
          .refine(
            (key) => isValidPhone(key),
            INVALID_PHONE_OR_INPUT_ERROR_MESSAGE
          ),
        label: z
          .string()
          .refine(
            (label) => isValidPhone(label),
            INVALID_PHONE_OR_INPUT_ERROR_MESSAGE
          ),
      })
    )
    .refine(
      (data) => uniqBy(data, (param) => param.label).length === data.length,
      "Duplicate phone number"
    ),
  users: z.array(
    z
      .object({
        amhp: z
          .object({
            email: z.string(),
            id: z.string(),
            name: z.string(),
          })
          .passthrough()
          .optional()
          .nullable(),
      })
      .passthrough()
  ),
});

export const AMHPTeamFormSchemaWithRefinements = AMHPTeamFormSchema.refine(
  (data) =>
    !(
      data.featureFlags.amhpTeamInbox &&
      data.mhaInboxInstructions.trim().length === 0
    ),
  {
    message: "MHA inbox instruction required",
    path: ["mhaInboxInstructions"],
  }
)
  .refine(
    (data) =>
      !(
        data.featureFlags.amhpTeamEmailFormsToApprovedLists &&
        data.mhaEmailAddresses.length === 0
      ),
    { message: "Approved recipient/s required", path: ["mhaEmailAddresses"] }
  )
  .refine(
    (data) =>
      !(
        data.featureFlags.amhpTeamMhaHospitalEmails &&
        data.hospitalEmails.length === 0
      ),
    { message: "Hospital email/s required", path: ["hospitalEmails"] }
  );

export const MHAInboxSchemaRefinement = AMHPTeamFormSchema.pick({
  featureFlags: true,
  mhaInboxInstructions: true,
}).refine(
  (data) =>
    !(
      data.featureFlags.amhpTeamInbox &&
      data.mhaInboxInstructions.trim().length === 0
    ),
  {
    message: "MHA inbox instruction required",
    path: ["mhaInboxInstructions"],
  }
);
export const HospitalEmailsSchemaRefinement = AMHPTeamFormSchema.pick({
  featureFlags: true,
  hospitalEmails: true,
}).refine(
  (data) =>
    !(
      data.featureFlags.amhpTeamMhaHospitalEmails &&
      data.hospitalEmails.length === 0
    ),
  { message: "Hospital email/s required", path: ["hospitalEmails"] }
);
export const ApprovedRecipientsSchemaRefinement = AMHPTeamFormSchema.pick({
  featureFlags: true,
  mhaEmailAddresses: true,
}).refine(
  (data) =>
    !(
      data.featureFlags.amhpTeamEmailFormsToApprovedLists &&
      data.mhaEmailAddresses.length === 0
    ),
  { message: "Approved recipient/s required", path: ["mhaEmailAddresses"] }
);

export const ApprovedRecipientFormSchema = z.object({
  groupName: z.string().min(1, "Group name is required"),
  recipientName: z.string().min(1, "Recipient name is required"),
  recipientEmail: z.string().email(INVALID_EMAIL_ERROR_MESSAGE),
});

export const ManualAddressFormSchema = z.object({
  line1: z.string(),
  line2: z.string(),
  // Made the city validation to accept at least two characters
  // as the shortest city name in England is having two characters
  city: z.string().min(2, "City is required"),
  code: z
    .string()
    .refine((val) => isValidPostCode(val), INVALID_POSTCODE_ERROR_MESSAGE),
});

export const CCGDetailsFormSchema = z.object({
  ccgId: z.string().min(3, "CCG is required"),
});
