import React, {
  useEffect,
  useCallback,
  useMemo,
  useReducer,
  useState,
  useRef,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import _ from "lodash";
// Material UI Imports
import {
  Button,
  Card,
  Grid,
  InputAdornment,
  SelectChangeEvent,
  Typography,
  Box,
  FormControlLabel,
  Stack,
  Chip,
  Modal,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// Custom Types
import {
  Site,
  AdminCreateS12DoctorInput,
  S12DoctorListItem,
  User,
  DeleteUserInput,
  Mht,
  DisableUserInput,
  ReenableUserInput,
  EventResponse,
  S12Doctor,
  StatusCheckResponse,
  AdminUpdateS12DoctorInput,
  PhoneNumberExistsFilterInput,
  Gender,
} from "@s12solutions/types";
import { StyledSelect } from "components/uiElements/StyledSelect";
import { StyledCheckBox, StyledInput } from "components/uiElements";
import { useAPI, useAuth, usePopups } from "hooks";
import { StyledMultipleSelect } from "components/uiElements/StyledMultipleSelect";
import { isValidGMC, isValidPhone, isValidPostCode } from "common/validators";
import * as FIELDS from "common/constants/fields";
import * as COMMON from "common/constants/common";
import * as MESSAGES from "common/constants/messages";
import {
  GENDER_LIST,
  GENDER_OPTION_LIST,
  SSO_LIST,
  SSO_OPTION_LIST,
} from "common/constants/options";
import { DoctorState, DoctorActionTypes } from "common/types/actions";
import { doctorFormReducer } from "common/functions/reducers";
import specialties from "common/types/specialties";
import languages from "common/types/languages";
import { isEqual } from "lodash";
import useUnsavedChangesWarning from "hooks/useUnsavedChangesWarning";
import { useDebounce } from "use-debounce";
import CopyToClipboardButton from "components/clipBoard";
import dayjs from "dayjs";
import { DoctorFormSchema } from "utils/zod/schemas/DoctorFormSchema.zod";
import { FormErrors } from "common/types/formErrors";
import { resetInput, validateFormDataAsync, validateInput } from "utils/zod";
import {
  validateInputForZodEffect,
  validateInputForZodEffectAsync,
} from "utils/zod/validateInput";
import ActivityDialog from "components/activityDialog";

import {
  GetUserActivitiesResponseItem,
  GetUserActivitiesInput,
} from "common/types/commonTypes";
import EventModalContent from "components/EventModal/EventModal";

interface DoctorFormProps {
  handleCancel?: any;
  handleSubmit?: () => void;
  user?: S12DoctorListItem | null;
  title: string;
}

const DoctorForm: React.FC<DoctorFormProps> = ({
  handleCancel,
  user,
  title,
}) => {
  const readOnly = useAuth().user?.attributes["custom:limit_admin"];

  const location: any = useLocation();
  const navigate = useNavigate();
  if (location?.state?.isSameMenu && handleCancel) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
    handleCancel();
  }
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [showAudit, setAudit] = useState(false);

  const {
    data: dialogData,
    loading: dialogLoading,
    error: dialogError,
    trigger: refetch,
  } = useAPI<GetUserActivitiesResponseItem, GetUserActivitiesInput>({
    method: "GET",
    fieldName: "getUserActivities",
    manual: true,
  });

  // Initial Values
  let initialState = useMemo<DoctorState>(() => {
    return {
      gmc: user?.gmc ?? COMMON.EMPTY_STRING,
      name: user?.name ?? COMMON.EMPTY_STRING,
      legalName: user?.legalName ?? COMMON.EMPTY_STRING,
      email: user?.email ?? COMMON.EMPTY_STRING,
      phone: user?.phone ?? COMMON.EMPTY_STRING,
      phone2: user?.phone2 ?? COMMON.EMPTY_STRING,
      location: {
        lat: user?.location?.lat ?? 0.0,
        lon: user?.location?.lon ?? 0.0,
      },
      locationName: {
        postcode: user?.locationName?.postcode ?? COMMON.EMPTY_STRING,
        address: user?.locationName?.address ?? "unspecified",
        addressNotes: user?.locationName?.addressNotes ?? undefined,
        city: user?.locationName?.city ?? undefined,
      },
      postcode: user?.locationName?.postcode ?? COMMON.EMPTY_STRING,
      gender:
        user?.gender === "M"
          ? GENDER_LIST.Male
          : user?.gender === "F"
          ? GENDER_LIST.Female
          : user?.gender === "U"
          ? GENDER_LIST.Undefined
          : COMMON.EMPTY_STRING,
      employer: user?.employer ?? COMMON.EMPTY_STRING,
      siteName: user?.siteName ?? COMMON.EMPTY_STRING,
      mhts:
        user && user.mhtEmployers && user.mhtEmployers.items
          ? user.mhtEmployers.items.map((val) => {
              if (!val) return {} as { key: string; value: string };
              return {
                key: val?.mht.id,
                value: val?.mht.abbreviation,
              };
            })
          : ([] as { key: string; value: string }[]),
      languages:
        user?.languages?.map((val) => {
          if (!val) return {} as { key: string; value: string };
          return {
            key: val.toString(),
            value: val.toString(),
          };
        }) ?? ([] as { key: string; value: string }[]),
      specialties:
        user?.specialties?.map((val) => {
          if (!val) return {} as { key: string; value: string };
          return {
            key: val.toString(),
            value: val.toString(),
          };
        }) ?? ([] as { key: string; value: string }[]),
      notes: user?.notes ?? COMMON.EMPTY_STRING,
      sso:
        user && user.sso && user.sso === SSO_LIST.Microsoft
          ? SSO_LIST.Microsoft
          : user
          ? SSO_LIST.None
          : COMMON.EMPTY_STRING,
      mfa: user?.mfa ?? false,
      isOutsideEngland: user?.isOutsideEngland ?? false,
      hideFromSearch: user?.hideFromSearch ?? false,
      disabled: user?.disabled ?? false,
    };
  }, [user]);

  const constantState = useRef(initialState);

  // States Manager
  const [state, dispatch] = useReducer(
    doctorFormReducer,
    constantState.current
  );

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  // Previous Values
  const {
    handleConfirmation,
    handleBannerMessage,
    handleSnackbar,
    closeSnackbar,
  } = usePopups();

  // Queries
  const {
    data: siteData,
    error: siteDataLoadError,
    loading: siteDataLoading,
  } = useAPI<Site[] | null>({
    method: "GET",
    fieldName: "listSites",
  });
  const {
    data: mhtData,
    loading: mhtLoading,
    error: mhtError,
  } = useAPI<Mht[] | null>({
    method: "GET",
    fieldName: "listMHTs",
  });

  const [debouncedGMC] = useDebounce(
    state.gmc.length > 0 && isValidGMC(state.gmc) ? state.gmc : "",
    1000
  );

  const {
    trigger: checkGMC,
    data: gmcCheckData,
    loading: gmcCheckLoading,
    error: gmcCheckError,
  } = useAPI<StatusCheckResponse | null, { gmc: string }>({
    method: "GET",
    fieldName: "checkGMC",
    manual: true,
  });

  // Ref so we don't validate the fetchDoctor response before it's even completed
  const fetchDoctorCompleteRef = useRef<boolean>(false);
  const {
    trigger: fetchDoctorInfo,
    data: fetchDoctorInfoData,
    loading: fetchDoctorInfoLoading,
    error: fetchDoctorInfoError,
  } = useAPI<StatusCheckResponse | null, { gmc: string }>({
    method: "GET",
    fieldName: "checkGMC",
    args: {
      gmc: debouncedGMC,
    },
    onCompleted: () => {
      fetchDoctorCompleteRef.current = true;
    },
    manual: true,
  });

  const { trigger: fetchPhoneInfo, error: fetchPhoneInfoError } = useAPI<
    {
      exists: boolean;
    },
    {
      filter: PhoneNumberExistsFilterInput;
    }
  >({
    method: "GET",
    fieldName: "checkPhoneNumberExists",
    manual: true,
  });

  const {
    trigger: validatePostcode,
    loading: postcodeLoading,
    error: postcodeError,
  } = useAPI<
    EventResponse | null,
    {
      findExactMatch?: boolean;
      term?: string;
      postcode?: string;
      addressId?: string;
    }
  >({
    method: "GET",
    fieldName: "searchByPostcode",
    manual: true,
    onCompleted: (searchByPostcode) => {
      if (searchByPostcode) {
        dispatch({
          type: DoctorActionTypes.SET_LOCATION,
          payload: {
            location: {
              lat: searchByPostcode.location?.lat ?? 0.0,
              lon: searchByPostcode.location?.lon ?? 0.0,
            },
          },
        });
        dispatch({
          type: DoctorActionTypes.SET_LOCATION_NAME,
          payload: {
            locationName: {
              city: searchByPostcode.locationName?.city,
              addressNotes: searchByPostcode.locationName?.addressNotes,
              address: searchByPostcode.locationName?.address ?? "unspecified",
              postcode:
                searchByPostcode.locationName?.postcode ?? state.postcode,
            },
          },
        });
        setPostcodeValidated(1);
      }

      if (!searchByPostcode) {
        setPostcodeValidated(-1);
        dispatch({
          type: DoctorActionTypes.SET_LOCATION,
          payload: {
            location: {
              lat: 0.0,
              lon: 0.0,
            },
          },
        });
        dispatch({
          type: DoctorActionTypes.SET_LOCATION_NAME,
          payload: {
            locationName: {
              address: "unspecified",
              postcode: state.postcode,
            },
          },
        });
      }
    },
    onError: () => {
      setPostcodeValidated(-2);
      dispatch({
        type: DoctorActionTypes.SET_LOCATION,
        payload: {
          location: {
            lat: 0.0,
            lon: 0.0,
          },
        },
      });
      dispatch({
        type: DoctorActionTypes.SET_LOCATION_NAME,
        payload: {
          locationName: {
            address: "unspecified",
            postcode: state.postcode,
          },
        },
      });
    },
  });

  const phoneSchemaRefined = DoctorFormSchema.pick({ phone: true }).refine(
    async (data) => {
      if (isValidPhone(data.phone)) {
        const result = await fetchPhoneInfo({
          filter: {
            ...(user && user.id && { id: user.id }),
            phone: data.phone,
          },
        });

        if (result.data && result.data) {
          return !result.data.exists;
        }
      }
      return true;
    },
    { message: MESSAGES.DUPLICATE_PHONE_ERROR_MESSAGE, path: ["phone"] }
  );

  const additionalPhoneSchemaRefined = DoctorFormSchema.pick({
    phone: true,
    phone2: true,
  }).refine(
    (data) => {
      if (
        data.phone2 &&
        data.phone2.trim().length > 0 &&
        data.phone === data.phone2
      ) {
        return false;
      }
      return true;
    },
    {
      message: MESSAGES.PHONE_NUMBER_SAME,
      path: ["phone2"],
    }
  );

  const DoctorFormSchemaRefined = DoctorFormSchema.refine(
    async (data) => {
      if (isValidPhone(data.phone.trim())) {
        const result = await fetchPhoneInfo({
          filter: {
            ...(user && user.id && { id: user.id }),
            phone: data.phone.trim(),
          },
        });

        if (result.data) {
          return !result.data.exists;
        }
      }
      return true;
    },
    { message: MESSAGES.DUPLICATE_PHONE_ERROR_MESSAGE, path: ["phone"] }
  ).refine(
    (data) => {
      if (
        data.phone2 &&
        data.phone2.trim().length > 0 &&
        data.phone === data.phone2
      ) {
        return false;
      }
      return true;
    },
    {
      message: MESSAGES.PHONE_NUMBER_SAME,
      path: ["phone2"],
    }
  );

  /**
   * 1 = Postcode validation success
   * 2 = Postcode validating
   * 0 = Postcode not validated and typing
   * -2 = GraphQL error on postcode validating
   * -1 = Other request errors postcode validating
   * ? IS: `setPostcodeValidated` is now updated via onBlur of the postcode input, so when a user is loaded we assume (if the postcode hasn't changed)
   * that the postcode is valid
   */
  const [postcodeValidated, setPostcodeValidated] = useState<
    2 | 1 | 0 | -1 | -2
  >(!!user ? 1 : 0);

  // Validate Postcode
  const handleValidatePostcode = useCallback(() => {
    if (state.postcode.length > 0 && isValidPostCode(state.postcode)) {
      validatePostcode({
        postcode: state.postcode,
      });
    }
  }, [state.postcode, validatePostcode]);

  // Mutations
  const {
    trigger: createS12Doctor,
    loading: createLoading,
    error: createError,
  } = useAPI<S12Doctor | null, { input: AdminCreateS12DoctorInput }>({
    method: "POST",
    fieldName: "createS12Doctor",
    manual: true,
  });

  const {
    trigger: updateS12Doctor,
    loading: updateLoading,
    error: updateError,
  } = useAPI<S12Doctor | null, { input: AdminUpdateS12DoctorInput }>({
    method: "POST",
    fieldName: "updateS12Doctor",
    manual: true,
  });

  const {
    trigger: deleteUser,
    loading: deleteLoading,
    error: deleteError,
  } = useAPI<User | null, { input: DeleteUserInput }>({
    method: "PUT",
    fieldName: "deleteUser",
    manual: true,
  });

  const {
    trigger: disableUser,
    loading: disableLoading,
    error: disableError,
  } = useAPI<User | null, { input: DisableUserInput }>({
    method: "PUT",
    fieldName: "disableUser",
    manual: true,
  });

  const {
    trigger: reenableUser,
    loading: reenableLoading,
    error: reenableError,
  } = useAPI<User | null, { input: ReenableUserInput }>({
    method: "PUT",
    fieldName: "reenableUser",
    manual: true,
  });

  // Use Effects
  useEffect(() => {
    if (
      createError ||
      updateError ||
      deleteError ||
      disableError ||
      reenableError ||
      gmcCheckError ||
      fetchDoctorInfoError ||
      fetchPhoneInfoError ||
      postcodeError
    ) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    createError,
    fetchDoctorInfoError,
    deleteError,
    disableError,
    gmcCheckError,
    handleBannerMessage,
    reenableError,
    updateError,
    fetchPhoneInfoError,
    postcodeError,
  ]);

  // This function resets the form to the original values
  const resetForm = useCallback(() => {
    setPostcodeValidated(0);
    dispatch({ type: DoctorActionTypes.RESET, payload: constantState.current });
  }, []);

  const getFormData = useCallback(
    () => ({
      gmc: state.gmc,
      name: state.name,
      legalName: state.legalName,
      phone: state.phone,
      phone2: state.phone2,
      email: state.email,
      location: state.location,
      locationName: state.locationName,
      employer: state.employer,
      s12Expiry: gmcCheckData?.section12ExpiryDate ?? undefined,
      specialties: state.specialties?.map((val) => val.value),
      languages: state.languages?.map((val) => val.value),
      mhts: state.mhts?.map((val) => val.key),
      ...(state.gender !== "" && {
        gender: (state.gender === "Male"
          ? "M"
          : state.gender === "Female"
          ? "F"
          : "U") as Gender,
      }),
      notes: state.notes,
      sso: state.sso,
      mfa: state.mfa,
      hideFromSearch: state.hideFromSearch,
      isOutsideEngland: state.isOutsideEngland,
      siteName: state.siteName,
    }),
    [gmcCheckData, state]
  );

  // This function is the common add doctor function
  const addDoctor = useCallback(() => {
    const formData = getFormData();

    createS12Doctor({
      input: formData,
    })
      .then((value) => {
        if (
          value &&
          value.data &&
          value.data &&
          value.data.id === "alreadyExists"
        ) {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.EXISTS_ERROR.replace("{name}", "User")
          );
        } else if (
          value &&
          value.data &&
          value.data &&
          value.data.id === "phoneAlreadyExists"
        ) {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.EXISTS_ERROR.replace("{name}", "Phone Number")
          );
        } else if (
          value &&
          value.data &&
          value.data &&
          value.data.id &&
          value.data.name
        ) {
          resetForm();
          handleBannerMessage(
            COMMON.TYPE_SUCCESS,
            MESSAGES.ADDED_SUCCESS.replace(
              "{name}",
              `${MESSAGES.DOCTOR_MESSAGE}${COMMON.EMPTY_SPACE}${value.data.name}`
            )
          );
          closeSnackbar();
        } else {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        }
      })
      .catch(() => {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.UNEXPECTED_ERROR_MESSAGE
        );
      });
  }, [
    closeSnackbar,
    createS12Doctor,
    getFormData,
    handleBannerMessage,
    resetForm,
  ]);

  const handleAddS12DoctorAfterPostcodeValidation = useCallback(() => {
    if (!state.isOutsideEngland) {
      checkGMC({
        gmc: state.gmc,
      })
        .then((val) => {
          if (!val.data) {
            handleConfirmation(MESSAGES.GMC_ERROR_CONFIRM_ADDING_DOCTOR, () =>
              addDoctor()
            );
          } else if (
            val.data &&
            !val.data.approved &&
            !val.data.section12ExpiryDate
          ) {
            if (process.env.NODE_ENV !== "production") {
              addDoctor();
            } else {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.INVALID_GMC_ERROR_MESSAGE_2
              );
            }
          } else if (
            val.data &&
            val.data.approved &&
            val.data.section12ExpiryDate
          ) {
            addDoctor();
          } else if (
            val.data &&
            val.data &&
            !!val.data.section12ExpiryDate &&
            dayjs(val.data.section12ExpiryDate).isBefore(dayjs().toISOString())
          ) {
            handleConfirmation(
              `${MESSAGES.EXPIRED_GMC_ERROR_PROMPT_MESSAGE_1}${dayjs(
                val.data.section12ExpiryDate
              ).format(COMMON.DATE_FORMAT_WITHOUT_TIME)}${
                MESSAGES.EXPIRED_GMC_ERROR_PROMPT_MESSAGE_2
              }`,
              () => addDoctor()
            );
          } else {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          }
        })
        .catch(() => {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        });
    } else {
      addDoctor();
    }
  }, [
    addDoctor,
    checkGMC,
    handleBannerMessage,
    handleConfirmation,
    state.gmc,
    state.isOutsideEngland,
  ]);

  const handleUpdateS12DoctorAfterPostcodeValidation = useCallback(() => {
    if (!user) {
      return;
    }
    updateS12Doctor({
      input: {
        id: user.id,
        gmc: state.gmc,
        name: state.name,
        legalName: state.legalName,
        phone: state.phone,
        phone2: state.phone2,
        email: state.email,
        location: state.location,
        locationName: _.omit(state.locationName, "__typename"), // this can cause an error
        employer: state.employer,
        specialties: state.specialties?.map((val) => val.value),
        languages: state.languages?.map((val) => val.value),
        mhts: state.mhts?.map((val) => val.key),
        ...(state.gender !== "" && {
          gender:
            state.gender === "Male"
              ? "M"
              : state.gender === "Female"
              ? "F"
              : "U",
        }),
        notes: state.notes,
        sso: state.sso,
        mfa: state.mfa,
        hideFromSearch: state.hideFromSearch,
        isOutsideEngland: state.isOutsideEngland,
        siteName: state.siteName,
      },
    })
      .then((value) => {
        if (value && value.data && value.data.id === "phoneAlreadyExists") {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.EXISTS_ERROR.replace("{name}", "Phone Number")
          );
        } else if (value && value.data && value.data.id && value.data.name) {
          constantState.current = state;
          handleBannerMessage(
            COMMON.TYPE_SUCCESS,
            MESSAGES.UPDATED_SUCCESS.replace(
              "{name}",
              `${MESSAGES.DOCTOR_MESSAGE}${COMMON.EMPTY_SPACE}${state.legalName}`
            )
          );
        } else {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        }
      })
      .catch(() => {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.UNEXPECTED_ERROR_MESSAGE
        );
      });
  }, [handleBannerMessage, state, updateS12Doctor, user]);

  // This Function deals with the submit operation of add/update S12 Doctor
  const handleAddUpdateS12Doctor = useCallback(() => {
    const formData = { ...getFormData(), postcode: state.postcode }; // postcode isn't sent in the form data request but it is needed for validation

    handleValidatePostcode();

    validateFormDataAsync(
      DoctorFormSchemaRefined,
      formData,
      () => {
        if (!user) {
          if (postcodeValidated !== 1) {
            handleConfirmation(
              MESSAGES.POSTCODE_ERROR_CONFIRM_ADDING_DOCTOR.replace(
                "{type}",
                "add"
              ).replace("{typeContinuous}", "adding"),
              () => handleAddS12DoctorAfterPostcodeValidation()
            );
          } else {
            handleAddS12DoctorAfterPostcodeValidation();
          }
        } else {
          if (isEqual(constantState.current, state)) {
            handleBannerMessage(COMMON.TYPE_INFO, MESSAGES.NO_CHANGES);
            return;
          }
          if (postcodeValidated !== 1) {
            handleConfirmation(
              MESSAGES.POSTCODE_ERROR_CONFIRM_ADDING_DOCTOR.replace(
                "{type}",
                "update"
              ).replace("{typeContinuous}", "updating"),
              () => handleUpdateS12DoctorAfterPostcodeValidation()
            );
          } else {
            handleUpdateS12DoctorAfterPostcodeValidation();
          }
        }
      },
      setFormErrors
    );
  }, [
    DoctorFormSchemaRefined,
    getFormData,
    handleAddS12DoctorAfterPostcodeValidation,
    handleBannerMessage,
    handleConfirmation,
    handleUpdateS12DoctorAfterPostcodeValidation,
    handleValidatePostcode,
    postcodeValidated,
    state,
    user,
  ]);

  useEffect(() => {
    if (
      debouncedGMC.length > 0 &&
      isValidGMC(debouncedGMC) &&
      !state.isOutsideEngland &&
      !fetchDoctorCompleteRef.current
    ) {
      fetchDoctorInfo({
        gmc: debouncedGMC,
      });
    }
  }, [debouncedGMC,]);

  // This function deals with the delete operation of S12 Doctor
  const handleDelete = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.INVALID_USER_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.CONFIRM_DELETED.replace("this item", `${state.legalName}`),
      () => {
        deleteUser({
          input: {
            id: user.id,
            type: "DOCTOR",
            gmc: user.gmc,
          },
        })
          .then((value) => {
            if (!value || !value.data || !!value.error) {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            } else {
              handleBannerMessage(
                COMMON.TYPE_SUCCESS,
                MESSAGES.DELETED_SUCCESS.replace(
                  "{name}",
                  `${MESSAGES.DOCTOR_MESSAGE}${COMMON.EMPTY_SPACE}${user.name}`
                )
              );
              resetForm();
              handleCancel();
            }
          })
          .catch(() => {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          });
      },
      "Warning!"
    );
  }, [
    user,
    handleConfirmation,
    state.legalName,
    handleBannerMessage,
    deleteUser,
    resetForm,
    handleCancel,
  ]);

  // This function deals with the disable operation of S12 Doctor
  const handleDisable = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.INVALID_USER_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.CONFIRM_DISABLE.replace("{name}", `${state.legalName}`),
      () => {
        disableUser({
          input: {
            id: user.id,
            type: "DOCTOR",
            gmc: user.gmc,
          },
        })
          .then((value) => {
            if (!value || !value.data || !!value.error) {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            } else {
              handleBannerMessage(
                COMMON.TYPE_SUCCESS,
                MESSAGES.DISABLED_SUCCESS.replace(
                  "{name}",
                  `${MESSAGES.DOCTOR_MESSAGE}${COMMON.EMPTY_SPACE}${user.name}`
                )
              );
              resetForm();
              dispatch({
                type: DoctorActionTypes.SET_DISABLED,
                payload: {
                  disabled: true,
                },
              });
            }
          })
          .catch(() => {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          });
      },
      "Warning!"
    );
  }, [
    disableUser,
    handleConfirmation,
    handleBannerMessage,
    resetForm,
    state.legalName,
    user,
  ]);

  const handleReenable = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.INVALID_USER_MESSAGE);
      return;
    }
    handleConfirmation(MESSAGES.CONFIRMATION_MESSAGE, () => {
      reenableUser({
        input: {
          id: user.id,
          type: "DOCTOR",
          gmc: user.gmc,
        },
      })
        .then((value) => {
          if (!value || !value.data || !!value.error) {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          } else {
            handleBannerMessage(
              COMMON.TYPE_SUCCESS,
              MESSAGES.REENABLED_SUCCESS.replace(
                "{name}",
                `${MESSAGES.DOCTOR_MESSAGE}${COMMON.EMPTY_SPACE}${user.name}`
              )
            );
            resetForm();
            dispatch({
              type: DoctorActionTypes.SET_DISABLED,
              payload: {
                disabled: false,
              },
            });
          }
        })
        .catch(() => {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        });
    });
  }, [handleConfirmation, handleBannerMessage, reenableUser, resetForm, user]);

  const postcodeValidationError =
    !postcodeLoading && (postcodeValidated === -1 || postcodeValidated === -2);

  useEffect(() => {
    if (isEqual(state, constantState.current)) {
      setPristine();
    } else {
      setDirty();
    }
  }, [setDirty, setPristine, state]);

  useEffect(() => {
    if (
      state.disabled &&
      debouncedGMC.length > 0 &&
      isValidGMC(debouncedGMC) &&
      !state.isOutsideEngland &&
      fetchDoctorInfoData &&
      (!fetchDoctorInfoData.approved ||
        (fetchDoctorInfoData.approved &&
          dayjs(fetchDoctorInfoData.section12ExpiryDate).isBefore(
            dayjs().toISOString()
          )))
    ) {
      handleSnackbar(
        COMMON.TYPE_ERROR,
        MESSAGES.INVALID_OR_EXPIRED_GMC_MESSAGE_AND_DOCTOR_DISABLED,
        -1,
        true
      );
    }
    if (
      state.disabled &&
      !(
        debouncedGMC.length > 0 &&
        isValidGMC(debouncedGMC) &&
        !state.isOutsideEngland &&
        fetchDoctorInfoData &&
        (!fetchDoctorInfoData.approved ||
          (fetchDoctorInfoData.approved &&
            dayjs(fetchDoctorInfoData.section12ExpiryDate).isBefore(
              dayjs().toISOString()
            )))
      )
    ) {
      handleSnackbar(COMMON.TYPE_ERROR, MESSAGES.DOCTOR_DISABLED, -1, true);
    }
    if (
      !state.disabled &&
      debouncedGMC.length > 0 &&
      isValidGMC(debouncedGMC) &&
      !state.isOutsideEngland &&
      fetchDoctorInfoData &&
      (!fetchDoctorInfoData.approved ||
        (fetchDoctorInfoData.approved &&
          dayjs(fetchDoctorInfoData.section12ExpiryDate).isBefore(
            dayjs().toISOString()
          )))
    ) {
      handleSnackbar(
        COMMON.TYPE_ERROR,
        MESSAGES.INVALID_OR_EXPIRED_GMC_MESSAGE,
        -1,
        true
      );
    }

    if (
      debouncedGMC.length > 0 &&
      isValidGMC(debouncedGMC) &&
      !state.isOutsideEngland &&
      !fetchDoctorInfoData &&
      fetchDoctorCompleteRef.current
    ) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }

    return () => {
      closeSnackbar();
      fetchDoctorCompleteRef.current = false;
    };
  }, [
    closeSnackbar,
    debouncedGMC,
    fetchDoctorInfoData,
    handleBannerMessage,
    handleSnackbar,
    state.disabled,
    state.isOutsideEngland,
  ]);

  useEffect(() => {
    if (!!initialState) {
      constantState.current = initialState;
    }
  }, [initialState]);

  return (
    <>
      {Prompt}
      <ActivityDialog
        data={dialogData?.getUserActivities ?? []}
        open={dialogOpen}
        loading={dialogLoading}
        setDialogOpen={setDialogOpen}
        error={dialogError}
      />
      <Grid
        xs={12}
        m={1}
        p={2}
        component={Card}
        sx={{
          borderRadius: 1,
          minHeight: window.innerHeight - 595,
        }}
      >
        <Grid container spacing={3} item xs={12}>
          <Grid item>
            <Typography variant="h1">{title}</Typography>
          </Grid>
          {state.disabled && (
            <Grid item>
              <Chip
                style={{ borderRadius: ".3rem" }}
                label="Disabled"
                color="error"
              />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3} xs={12} mt={1}>
          <Grid item xs={12}>
            <Typography variant="h3">Required Fields</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={12}>
          <Grid
            container
            item
            spacing={3}
            xs={6}
            mt={1}
            sx={{
              position: "relative",
              overflow: "auto",
            }}
          >
            {!user && (
              <Grid item xs={12}>
                <StyledInput
                  id="gmcNumber"
                  label="GMC Number"
                  value={state.gmc}
                  readOnly={!!user}
                  onChange={(event) => {
                    const { value } = event.target;

                    dispatch({
                      type: DoctorActionTypes.SET_GMC,
                      payload: {
                        gmc: value,
                      },
                    });

                    resetInput("gmc", setFormErrors);
                  }}
                  onBlur={(event) => {
                    const { value } = event.target;

                    validateInput(
                      DoctorFormSchema,
                      "gmc",
                      { gmc: value },
                      setFormErrors
                    );
                  }}
                  error={!readOnly && !!formErrors?.gmc}
                  errorText={formErrors?.gmc}
                  maxLength={255}
                  required={true}
                  autoFocus
                  disabled={readOnly}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <StyledInput
                id="legalName"
                label="Legal Name"
                value={state.legalName}
                onChange={(event) => {
                  const { value } = event.target;

                  dispatch({
                    type: DoctorActionTypes.SET_LEGAL_NAME,
                    payload: {
                      legalName: value,
                    },
                  });

                  resetInput("legalName", setFormErrors);
                }}
                onBlur={(event) => {
                  const { value } = event.target;

                  validateInput(
                    DoctorFormSchema,
                    "legalName",
                    { legalName: value },
                    setFormErrors
                  );
                }}
                error={!readOnly && !!formErrors?.legalName}
                errorText={formErrors?.legalName}
                maxLength={255}
                required={true}
                endAdornment={
                  user && (
                    <InputAdornment position="end">
                      <CopyToClipboardButton
                        id="legalName"
                        value={state.legalName}
                      />
                    </InputAdornment>
                  )
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledInput
                id={FIELDS.ID_NAME}
                label="Known as name"
                value={state.name}
                onChange={(event) => {
                  const { value } = event.target;

                  dispatch({
                    type: DoctorActionTypes.SET_NAME,
                    payload: {
                      name: value,
                    },
                  });

                  resetInput("name", setFormErrors);
                }}
                onBlur={(event) => {
                  const { value } = event.target;

                  validateInput(
                    DoctorFormSchema,
                    "name",
                    { name: value },
                    setFormErrors
                  );
                }}
                error={!readOnly && !!formErrors?.name}
                errorText={formErrors?.name}
                maxLength={255}
                required
                endAdornment={
                  user && (
                    <InputAdornment position="end">
                      <CopyToClipboardButton
                        id={FIELDS.ID_NAME}
                        value={state.name}
                      />
                    </InputAdornment>
                  )
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledInput
                id={FIELDS.ID_EMAIL}
                label={FIELDS.LABEL_EMAIL}
                value={state.email}
                onChange={(event) => {
                  const { value } = event.target;

                  dispatch({
                    type: DoctorActionTypes.SET_EMAIL,
                    payload: {
                      email: value,
                    },
                  });

                  resetInput("email", setFormErrors);
                }}
                onBlur={(event) => {
                  const { value } = event.target;

                  validateInput(
                    DoctorFormSchema,
                    "email",
                    { email: value },
                    setFormErrors
                  );
                }}
                error={!readOnly && !!formErrors?.email}
                errorText={formErrors?.email}
                maxLength={255}
                required={true}
                endAdornment={
                  user && (
                    <InputAdornment position="end">
                      <CopyToClipboardButton
                        id={FIELDS.ID_EMAIL}
                        value={state.email}
                      />
                    </InputAdornment>
                  )
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledInput
                id={FIELDS.ID_PHONE}
                label={FIELDS.LABEL_PHONE}
                value={state.phone}
                onChange={(event) => {
                  const { value } = event.target;

                  dispatch({
                    type: DoctorActionTypes.SET_PHONE,
                    payload: {
                      phone: value,
                    },
                  });
                  resetInput("phone", setFormErrors);
                }}
                onBlur={(event) => {
                  const { value } = event.target;
                  validateInputForZodEffectAsync(
                    phoneSchemaRefined,
                    "phone",
                    { phone: value },
                    setFormErrors
                  );
                }}
                error={!readOnly && !!formErrors?.phone}
                errorText={formErrors?.phone}
                maxLength={255}
                required
                helperText={MESSAGES.PHONE_NUMBER_FORMAT_HELPER_MESSAGE}
                endAdornment={
                  user && (
                    <InputAdornment position="end">
                      <CopyToClipboardButton
                        id={FIELDS.ID_PHONE}
                        value={state.phone}
                      />
                    </InputAdornment>
                  )
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledInput
                id="postcode"
                label="Postcode"
                value={state.postcode}
                onChange={(event) => {
                  const { value } = event.target;

                  setPostcodeValidated(0);
                  dispatch({
                    type: DoctorActionTypes.SET_POSTCODE,
                    payload: {
                      postcode: value,
                    },
                  });

                  resetInput("postcode", setFormErrors);
                }}
                onBlur={(event) => {
                  const { value } = event.target;

                  validateInput(
                    DoctorFormSchema,
                    "postcode",
                    { postcode: value },
                    setFormErrors
                  );

                  handleValidatePostcode();
                }}
                error={
                  (!readOnly && !!formErrors?.postcode) ||
                  postcodeValidationError
                }
                errorText={
                  formErrors?.postcode ||
                  (postcodeValidated === -1
                    ? "Incorrect postal code"
                    : postcodeValidated === -2
                    ? "Error occurred when validating postal code"
                    : postcodeValidationError
                    ? MESSAGES.INVALID_POSTCODE_ERROR_MESSAGE
                    : "")
                }
                maxLength={255}
                required={true}
                endAdornment={
                  user && (
                    <InputAdornment position="end">
                      <CopyToClipboardButton
                        id="postcode"
                        value={state.postcode}
                      />
                    </InputAdornment>
                  )
                }
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledSelect
                id="siteName"
                value={state.siteName}
                label="Site Name"
                onChange={(event: SelectChangeEvent<unknown>) => {
                  const { value } = event.target;

                  dispatch({
                    type: DoctorActionTypes.SET_SITE,
                    payload: { siteName: String(value) },
                  });

                  resetInput("siteName", setFormErrors);
                }}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  const { value } = event.target;

                  validateInput(
                    DoctorFormSchema,
                    "siteName",
                    { siteName: value },
                    setFormErrors
                  );
                }}
                menuItems={
                  siteDataLoading
                    ? ([
                        {
                          key: "##",
                          value: "Loading",
                        },
                      ] as { key: string; value: string }[])
                    : siteDataLoadError
                    ? ([
                        {
                          key: "##",
                          value: "Error occurred when loading site names",
                        },
                      ] as { key: string; value: string }[])
                    : (siteData
                        ?.map((val) => {
                          return {
                            key: val.id,
                            value: val.name,
                          };
                        })
                        .sort((a, b) => a.value.localeCompare(b.value)) as {
                        key: string;
                        value: string;
                      }[])
                }
                required={true}
                error={!readOnly && !!formErrors?.siteName}
                errorText={formErrors?.siteName}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12} mb={3}>
              <StyledSelect
                id={FIELDS.ID_SSO}
                value={state.sso}
                required
                label={FIELDS.LABEL_SSO}
                onChange={(event: SelectChangeEvent<unknown>) => {
                  const { value } = event.target;

                  dispatch({
                    type: DoctorActionTypes.SET_SSO,
                    payload: { sso: String(value) },
                  });

                  resetInput("sso", setFormErrors);
                }}
                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                  const { value } = event.target;

                  validateInput(
                    DoctorFormSchema,
                    "sso",
                    { sso: value },
                    setFormErrors
                  );
                }}
                disabled={
                  readOnly ||
                  (!!user && constantState.current.sso === "Microsoft")
                }
                menuItems={SSO_OPTION_LIST}
                helperText={
                  state.sso === SSO_LIST.Microsoft
                    ? MESSAGES.SSO_SELECTED_HELPER_MESSAGE
                    : COMMON.EMPTY_STRING
                }
                error={!readOnly && !!formErrors?.sso}
                errorText={formErrors?.sso}
              />
            </Grid>
          </Grid>
          <Grid
            item
            container
            xs={6}
            display="flex"
            flexDirection="column"
            spacing={2}
            mt={2}
          >
            {!!user && (
              <>
                <Grid item ml={8}>
                  <LoadingButton
                    id=""
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={async () => {
                      setDialogOpen(true);

                      refetch({
                        username: user.id,
                      });
                    }}
                    style={{ marginRight: 10 }}
                  >
                    Login Activity
                  </LoadingButton>
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={() => setAudit(true)}
                  >
                    Audit
                  </LoadingButton>
                </Grid>
                <Grid item ml={10}>
                  <Stack direction="row">
                    <FormControlLabel
                      tabIndex={-1}
                      control={
                        <Typography variant="h3">GMC :&nbsp;</Typography>
                      }
                      label={state.gmc}
                    />

                    <CopyToClipboardButton id="gmc" value={state.gmc} />
                  </Stack>
                </Grid>
                <Grid item ml={10}>
                  <Stack direction="row">
                    <FormControlLabel
                      tabIndex={-1}
                      control={
                        <Typography variant="h3">GMC Expiry :&nbsp;</Typography>
                      }
                      label={
                        !!fetchDoctorInfoData &&
                        !!fetchDoctorInfoData.section12ExpiryDate
                          ? dayjs(
                              fetchDoctorInfoData.section12ExpiryDate
                            ).format(COMMON.DATE_FORMAT_WITHOUT_TIME)
                          : fetchDoctorInfoLoading
                          ? `Loading...`
                          : `N/A`
                      }
                    />
                    <CopyToClipboardButton
                      id="gmc_expiry"
                      value={
                        !!fetchDoctorInfoData &&
                        !!fetchDoctorInfoData.section12ExpiryDate
                          ? dayjs(
                              fetchDoctorInfoData.section12ExpiryDate
                            ).format(COMMON.DATE_FORMAT_WITHOUT_TIME)
                          : fetchDoctorInfoLoading
                          ? `Loading...`
                          : `N/A`
                      }
                    />
                  </Stack>
                </Grid>
              </>
            )}
            <Grid item ml={8}>
              <StyledCheckBox
                id={FIELDS.ID_MFA}
                label={FIELDS.LABEL_FORCE_MFA}
                checked={state.mfa}
                handleChange={() =>
                  dispatch({
                    type: DoctorActionTypes.SET_MFA,
                    payload: { mfa: !state.mfa },
                  })
                }
                disabled={readOnly || (!!user && constantState.current.mfa)}
              />

              <StyledCheckBox
                id="outsideEngland"
                label="Doctor is outside England"
                checked={state.isOutsideEngland}
                handleChange={() =>
                  dispatch({
                    type: DoctorActionTypes.SET_IS_DOCTOR_OUTSIDE_ENGLAND,
                    payload: { isOutsideEngland: !state.isOutsideEngland },
                  })
                }
                disabled={readOnly}
              />

              <StyledCheckBox
                id="hideSearch"
                label="Hide doctor from search"
                checked={state.hideFromSearch}
                handleChange={() =>
                  dispatch({
                    type: DoctorActionTypes.SET_HIDE_DOCTOR_FROM_SEARCH,
                    payload: { hideFromSearch: !state.hideFromSearch },
                  })
                }
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        m={1}
        mt={2}
        p={2}
        component={Card}
        sx={{
          justifyContent: "space-between",
          borderRadius: 1,
          minHeight: window.innerHeight - 595,
        }}
      >
        <Grid container spacing={3} xs={12}>
          <Grid item xs={12}>
            <Typography variant="h3">Additional Fields</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={6} mb={3} mt={1}>
          <Grid item xs={12}>
            <StyledInput
              id="phone2"
              label="Additional Phone"
              endAdornment={
                user && (
                  <InputAdornment position="end">
                    <CopyToClipboardButton id="phone2" value={state.phone2} />
                  </InputAdornment>
                )
              }
              value={state.phone2}
              onChange={(event) => {
                const { value } = event.target;

                dispatch({
                  type: DoctorActionTypes.SET_ADDITIONAL_PHONE,
                  payload: {
                    phone2: value,
                  },
                });

                resetInput("phone2", setFormErrors);
              }}
              onBlur={(event) => {
                const { value } = event.target;

                validateInputForZodEffect(
                  additionalPhoneSchemaRefined,
                  "phone2",
                  { phone2: value, phone: state.phone },
                  setFormErrors
                );
              }}
              error={!readOnly && !!formErrors?.phone2}
              errorText={formErrors?.phone2}
              maxLength={255}
              /*placeholder="Only numbers and plus sign are allowed"*/
              helperText={MESSAGES.PHONE_NUMBER_FORMAT_HELPER_MESSAGE}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledSelect
              id="gender"
              value={state.gender}
              label="Gender"
              onChange={(event: SelectChangeEvent<unknown>) =>
                dispatch({
                  type: DoctorActionTypes.SET_GENDER,
                  payload: { gender: String(event.target.value) },
                })
              }
              menuItems={GENDER_OPTION_LIST}
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              id="employer"
              label="Employer"
              value={state.employer}
              onChange={(event) =>
                dispatch({
                  type: DoctorActionTypes.SET_EMPLOYER,
                  payload: {
                    employer: event.target.value,
                  },
                })
              }
              maxLength={255}
              endAdornment={
                user && (
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id="employer"
                      value={state.employer}
                    />
                  </InputAdornment>
                )
              }
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledMultipleSelect
              id="mhts"
              handlingState={state.mhts}
              handlingFunction={(_event, value) =>
                dispatch({
                  type: DoctorActionTypes.SET_MHTS,
                  payload: { mhts: value },
                })
              }
              loading={mhtLoading}
              error={!readOnly && !!mhtError}
              errorText="Error occurred when loading site names"
              options={
                (mhtData
                  ?.map((val) => {
                    return {
                      key: val.id,
                      value: val.abbreviation,
                    };
                  })
                  .sort((a, b) => a.value.localeCompare(b.value)) as {
                  key: string;
                  value: string;
                }[]) || []
              }
              label="MHTs"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledMultipleSelect
              id="specialties"
              options={
                specialties
                  ?.map((val) => {
                    return { key: val.toString(), value: val.toString() };
                  })
                  .sort((a, b) => a.value.localeCompare(b.value)) as readonly {
                  key: string;
                  value: string;
                }[]
              }
              loading={false}
              handlingState={state.specialties}
              handlingFunction={(_event, value) =>
                dispatch({
                  type: DoctorActionTypes.SET_SPECIALTIES,
                  payload: { specialties: value },
                })
              }
              label="Specialties"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledMultipleSelect
              id="languages"
              options={
                languages
                  ?.map((val) => {
                    return { key: val.toString(), value: val.toString() };
                  })
                  .sort((a, b) => a.value.localeCompare(b.value)) as readonly {
                  key: string;
                  value: string;
                }[]
              }
              loading={false}
              handlingState={state.languages}
              handlingFunction={(_event, value) =>
                dispatch({
                  type: DoctorActionTypes.SET_LANGUAGES,
                  payload: { languages: value },
                })
              }
              label="Languages"
              disabled={readOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledInput
              id="notes"
              multiline
              maxRows={10}
              minRows={4}
              label="Notes"
              value={state.notes}
              onChange={(event) =>
                dispatch({
                  type: DoctorActionTypes.SET_NOTES,
                  payload: {
                    notes: event.target.value,
                  },
                })
              }
              maxLength={255}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={12}>
          <Grid item>
            <LoadingButton
              id={FIELDS.ID_ADD_UPDATE}
              loading={!user ? gmcCheckLoading || createLoading : updateLoading}
              variant="contained"
              color="primary"
              size="large"
              onClick={handleAddUpdateS12Doctor}
              disabled={readOnly}
            >
              {!!user ? COMMON.TEXT_UPDATE : COMMON.TEXT_ADD}
              {COMMON.EMPTY_SPACE}Doctor
            </LoadingButton>
          </Grid>
          {!!user ? (
            <>
              {state.disabled ? (
                <Grid item>
                  <LoadingButton
                    id="reenable"
                    variant="outlined"
                    color="secondary"
                    size="large"
                    loading={reenableLoading}
                    onClick={handleReenable}
                    disabled={readOnly}
                  >
                    Reenable Doctor
                  </LoadingButton>
                </Grid>
              ) : (
                <Grid item>
                  <LoadingButton
                    id="disable"
                    variant="outlined"
                    loading={disableLoading}
                    color="error"
                    size="large"
                    onClick={handleDisable}
                    disabled={readOnly}
                  >
                    Disable Doctor
                  </LoadingButton>
                </Grid>
              )}

              <Grid item>
                <LoadingButton
                  id={FIELDS.ID_DELETE}
                  variant="contained"
                  loading={deleteLoading}
                  color="error"
                  size="large"
                  onClick={handleDelete}
                  disabled={readOnly}
                >
                  Delete Doctor
                </LoadingButton>
              </Grid>
              <Grid item>
                <Button
                  id={FIELDS.ID_CANCEL}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => {
                    handleConfirmation(
                      MESSAGES.CONFIRM_CANCEL,
                      () => {
                        handleCancel();
                      },
                      "Confirm"
                    );
                  }}
                  disabled={readOnly}
                >
                  Cancel
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Button
                id={FIELDS.ID_RESET}
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => {
                  handleConfirmation(
                    MESSAGES.CONFIRM_CLEAR,
                    () => {
                      resetForm();
                    },
                    "Warning!"
                  );
                }}
                disabled={readOnly}
              >
                Clear
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item spacing={3} xs={12}>
          <Box height={50} />
        </Grid>
        {state.gmc && (
          <Modal open={showAudit} onClose={() => setAudit(false)}>
            <EventModalContent
              filter={{
                fieldName: "getAuditLog",
                category: "doctor",
                id: state.gmc,
              }}
            />
          </Modal>
        )}
      </Grid>
    </>
  );
};

export default React.memo(DoctorForm);
