// AMHP Addition / Search / View and Update
export const ID_ADD_UPDATE = "addUpdate";
export const ID_AMHP_TYPE = "amhpType";
export const ID = "id";
export const ID_FORM_GROUPS = "formGroups";
export const ID_HOSPITAL_NAME = "hospitalName";
export const ID_NAME = "name";
export const ID_FULL_TEAM_NAME = "fullTeamName";
export const ID_GMC = "gmc";
export const ID_EMAIL = "email";
export const ID_PHONE = "phone";
export const ID_PHONE2 = "phone2";
export const ID_TEAMS = "teams";
export const ID_SSO = "sso";
export const ID_MFA = "mfa";
export const ID_IS_TRAINEE_AMHP = "isTraineeAmhp";
export const ID_CANCEL = "cancel";
export const ID_RESET = "reset";
export const ID_DELETE = "delete";
export const ID_DELETED = "deleted";
export const ID_DISABLED = "disabled";
export const ID_REACTIVATE = "reactivate";
export const ID_POSTCODE = "postcode";

export const LABEL_ID = "Id";
export const LABEL_AMHP_TYPE = "AMHP Type";
export const LABEL_USERNAME = "Username";
export const LABEL_FORM_GROUPS = "Form Groups";
export const LABEL_HOSPITAL_NAME = "Hospital Name";
export const LABEL_NAME = "Name";
export const LABEL_FULL_TEAM_NAME = "Full Team Name";
export const LABEL_GMC = "GMC";
export const LABEL_PHONE = "Phone";
export const LABEL_PHONE2 = "Secondary Phone";
export const LABEL_EMAIL = "Email";
export const LABEL_TEAMS = "Teams";
export const LABEL_ORGANISATIONS = "Organisations";
export const LABEL_CLAIM_APPROVER = "Claim Approver";
export const LABEL_CLAIM_PAYER = "Claim Payer";
export const LABEL_SSO = "SSO Provider";
export const LABEL_STATUS = "Status";
export const LABEL_FORCE_MFA = "Force MFA";
export const LABEL_IS_TRAINEE_AMHP = "Is Trainee Amhp";
export const LABEL_POSTCODE = "Postcode";
export const LABEL_DELETED = "Deleted";
