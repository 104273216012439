import React from "react";
import { Grid, FormControlLabel, Checkbox, IconButton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

interface StyledCheckBoxProps {
  label?: string;
  tooltip?: string;
  labelPlacement?: "end" | "top" | "bottom" | "start" | undefined;
  checkBoxColor?:
    | "default"
    | "error"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning";
  handleChange?: any;
  value?: string;
  checked?: boolean;
  infoIcon?: boolean;
  id?: string;
  tabIndex?: number;
  disabled?: boolean;
}

const StyledCheckBox: React.FC<StyledCheckBoxProps> = (
  {
    label,
    tooltip = "",
    labelPlacement = "end",
    checkBoxColor = "primary",
    handleChange,
    value,
    checked,
    infoIcon = false,
    id,
    tabIndex,
    disabled = false,
    ...props
  },
  { ...rest }
) => {
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f5",
      color: "rgba(0, 0, 0, 0.87)",
      fontSize: theme.typography.pxToRem(16),
      border: "1px solid #dadde9",
    },
  }));
  return (
    <Grid
      xs={12}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: 0.8,
      }}
    >
      <FormControlLabel
        id={id}
        value={value}
        tabIndex={-1}
        control={
          <Checkbox
            id={id}
            color={checkBoxColor}
            checked={checked}
            sx={{ padding: 0.6 }}
            disabled={disabled}
            
          />
        }
        label={label}
        labelPlacement={labelPlacement}
        onChange={handleChange}
        disabled={disabled}
      />
      {infoIcon && !disabled && (
        <HtmlTooltip title={tooltip} placement="top" arrow={true}>
          <IconButton sx={{ padding: 0.6, paddingRight: 2 }}>
            <InfoOutlinedIcon color="primary" />
          </IconButton>
        </HtmlTooltip>
      )}
    </Grid>
  );
};

export { StyledCheckBox };
