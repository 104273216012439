import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { midBaseGrey } from "common/constants/theme";
import React from "react";

const useStyles = makeStyles((theme) => ({
  circle: {
    backgroundColor: midBaseGrey,
    width: 30,
    height: 30,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  textColor: {
    color: theme.palette.primary.contrastText,
  },
}));

const HtmlBadge: React.FC<{ counter: number }> = ({ counter = 0 }) => {
  const classes = useStyles();
  return (
    <Box component="span" className={classes.circle}>
      <Typography className={classes.textColor}>{counter}</Typography>
    </Box>
  );
};

export default HtmlBadge;
