import { z } from "zod";
import { locationName, location } from "./common.zod";
import * as MESSAGES from "common/constants/messages";
import { isValidPostCode, isValidPhone, isValidGMC } from "common/validators";

export const DoctorFormSchema = z.object({
  // Required fields
  gmc: z
    .string()
    .length(7, MESSAGES.INVALID_GMC_ERROR_MESSAGE)
    .min(1, "GMC is required")
    .refine(isValidGMC, MESSAGES.INVALID_GMC_ERROR_MESSAGE),
  legalName: z.string().min(1, "Legal name is required"),
  name: z.string().min(1, "Name is required"),
  email: z
    .string()
    .email(MESSAGES.INVALID_EMAIL_ERROR_MESSAGE)
    .min(1, "Email is required"),
  phone: z
    .string()
    .min(1, "Phone is required")
    .refine(isValidPhone, "Phone number is invalid"),
  postcode: z
    .string()
    .min(1, "Postcode is required")
    .refine(isValidPostCode, "Postcode is invalid"),
  siteName: z.string().min(1, "Site name is required"),
  sso: z.string().min(1, "SSO is required"),
  // Non required
  gender: z.string().optional(),
  employer: z.string().optional(),
  phone2: z
    .string()
    .refine(
      (phone: string) => isValidPhone(phone.trim(), true),
      "Phone number is invalid"
    ),
  notes: z.string().optional(),
  mhts: z.array(z.string()),
  specialties: z.array(z.string()),
  languages: z.array(z.string()),
  mfa: z.boolean().optional(),
  isOutsideEngland: z.boolean().optional(),
  locationName,
  location,
  hideFromSearch: z.boolean().optional(),
  disabled: z.boolean().optional(),
});
