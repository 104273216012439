import { IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import ContentCopyOutlined from "@mui/icons-material/ContentCopyOutlined";
import theme from "theme";
import { HtmlTooltip } from "components/tooltip";
import { clippie } from "common/functions";

const CopyToClipboardButton: React.FC<{ id: string; value: string }> = ({
  id,
  value,
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
    clippie(value);
  };

  useEffect(() => {
    if (open) {
      let timeout = setTimeout(() => {
        setOpen(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [open]);

  return (
    <>
      <HtmlTooltip
        id={`${id}-tooltip-id`}
        data-test-id={`${id}-tooltip`}
        title="Copied to clipboard"
        placement="top"
        PopperProps={{
          disablePortal: true,
        }}
        onClose={() => setOpen(false)}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <IconButton
          onClick={handleClick}
          id={`${id}-clipboard-id`}
          data-test-id={`${id}-clipboard`}
        >
          <ContentCopyOutlined sx={{ color: theme.palette.primary.dark }} />
        </IconButton>
      </HtmlTooltip>
    </>
  );
};

export default CopyToClipboardButton;
