import { INVALID_EMAIL_ERROR_MESSAGE } from "common/constants/messages";
import { z } from "zod";

export const StatFormSchema = z.object({
  email: z.string().email(INVALID_EMAIL_ERROR_MESSAGE),
  name: z.string().min(1, "Name is required"),
  hospitalName: z.string(),
  formGroups: z
    .array(
      z.enum([
        "Admission",
        "CTO",
        "Guardianship",
        "Hospital",
        "MedRecs",
        "Misc",
        "Treatment",
      ])
    )
    .min(1, "At least one group should be selected"),
});

export const StatFormSchemaRefined = StatFormSchema.refine(
  (data) =>
    !(
      data.formGroups.includes("Hospital") &&
      data.hospitalName.trim().length === 0
    ),
  { message: "Hospital name is required", path: ["hospitalName"] }
);

export const StatFormHospitalRefined = StatFormSchema.pick({
  formGroups: true,
  hospitalName: true,
}).refine(
  (data) =>
    !(
      data.formGroups.includes("Hospital") &&
      data.hospitalName.trim().length === 0
    ),
  { message: "Hospital name is required", path: ["hospitalName"] }
);
