import { compact } from "lodash";

const emailExpression =
  // eslint-disable-next-line
  /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

const phoneExpression =
  // eslint-disable-next-line
  /^(((\+44\s?\d{4}?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;

export const PHONE_NUMBER_VALIDATION_REGULAR_EXPRESSION =
  // eslint-disable-next-line
  /^([^\+\d]?)|([^\+\d]?[^\d]+)$/g;

export const postcodeExpression =
  // eslint-disable-next-line
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})$/i;

// eslint-disable-next-line
export const gmcExpression = /^\d{7}$/i;

export function isValidMultipleEmail(
  emails: string | null | undefined
): boolean {
  if (emails === null || typeof emails === "undefined") {
    return false;
  }

  return compact(
    emails
      .split(",")
      .map((email) =>
        email.replaceAll(/\s/g, "").length > 0
          ? email.trim().toLowerCase()
          : null
      )
  ).every((email) => emailExpression.test(email));
}

export function convertMultipleEmailList(emails: string) {
  return compact(
    emails
      .split(",")
      .map((email) =>
        email.replaceAll(/\s/g, "").length > 0
          ? email.trim().toLowerCase()
          : null
      )
  ).join(", ");
}

export function isValidEmail(
  email: string | null | undefined,
  allowEmpty: boolean = false
): boolean {
  if (!allowEmpty && !email) {
    return false;
  } else if (allowEmpty && !Boolean(email)) {
    return true;
  }
  return emailExpression.test(String(email).toLowerCase());
}
// Validates uk phone numbers with vairous extentions
export function isValidPhone(
  phone: string | null | undefined,
  allowEmpty: boolean = false
): boolean {
  if (!allowEmpty && !phone) {
    return false;
  } else if (allowEmpty && !Boolean(phone)) {
    return true;
  }
  return phoneExpression.test(String(phone).toLowerCase());
}

export function isValidGMC(gmc: string | null | undefined): boolean {
  return (
    gmc !== null &&
    typeof gmc !== "undefined" &&
    gmcExpression.test(String(gmc).toLowerCase())
  );
}
export function isValidPostCode(postcode: string): boolean {
  const pCode = `${postcode.replace(" ", "").slice(0, -3)} ${postcode
    .replace(" ", "")
    .slice(-3)}`;

  return postcodeExpression.test(pCode);
}
