import React, { SyntheticEvent } from "react";
import {
  CircularProgress,
  Autocomplete,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AMHPTeamFeatureFlags } from "../../common/types/features";
import { OrgType } from "@s12solutions/types";

const useStyles = makeStyles((theme) => ({
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.error.dark,
    fontWeight: "bold",
  },
  helperText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
}));

export interface SelectListProps {
  key: string;
  value: string;
  // Custom Properties
  featureFlags?: AMHPTeamFeatureFlags;
  type?: OrgType;
}

interface StyledMultipleSelectProps {
  options: readonly SelectListProps[];
  loading: boolean;
  handlingState: SelectListProps[];
  handlingFunction: (
    event: SyntheticEvent<Element, Event>,
    value: SelectListProps[]
  ) => void;
  onBlurHandlingFunction?: (
    event: React.FocusEvent<HTMLDivElement, Element>
  ) => void;
  userData?: SelectListProps[] | null;
  error?: boolean;
  errorText?: string;
  label?: string;
  id?: string;
  required?: boolean;
  tabIndex?: number;
  helperText?: string;
  placeholder?: string;
  autoFocus?: boolean;
  disabled?: boolean;
}

const StyledMultipleSelect: React.FC<StyledMultipleSelectProps> = ({
  options,
  loading,
  handlingState,
  handlingFunction,
  onBlurHandlingFunction = (event) => {},
  userData,
  error,
  errorText,
  label,
  id,
  required = false,
  helperText,
  placeholder,
  autoFocus,
  disabled,
}) => {
  const classes = useStyles();
  return (
    <Autocomplete
      disabled={disabled}
      multiple
      id={id}
      options={options}
      getOptionLabel={(option) => option.value}
      filterSelectedOptions
      isOptionEqualToValue={(option, value) => option.key === value.key}
      loading={loading}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            tabIndex={-1}
            autoFocus={autoFocus}
            required={required}
            error={error}
            label={label}
            placeholder={placeholder}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
          {!error && helperText && (
            <Typography
              variant="body1"
              className={classes.helperText}
              fontSize={12}
            >
              {helperText}
            </Typography>
          )}
          {error && errorText && (
            <Typography
              variant="body1"
              className={classes.errorText}
              fontSize={12}
            >
              {errorText}
            </Typography>
          )}
        </>
      )}
      value={handlingState}
      onBlur={onBlurHandlingFunction}
      onChange={(event, value) => handlingFunction(event, value)}
      defaultValue={userData ? [...userData] : []}
    />
  );
};

export { StyledMultipleSelect };
