import { Dispatch, SetStateAction } from "react";
import { FormErrors } from "common/types/formErrors";
import { z } from "zod";
import { omit } from "lodash";

/**
 * validateInput
 * this should be triggered `onBlur`
 * @param {z.ZodObject<any>} schema - zod validation schema
 * @param {string} errorKey - key of the errored input
 * @param {Record<string, any>} value - values to validate, sometimes inputs can rely on other values for validation so you can include these here
 * and they will be validated together
 * @param {Dispatch<SetStateAction<FormErrors>>} setFormErrors setFormErrors
 */
export const validateInput = (
  schema: z.ZodObject<any>,
  errorKey: string,
  value: Record<string, any>,
  setFormErrors: Dispatch<SetStateAction<FormErrors>>,
  successCallbackFunction?: () => void,
  errorCallbackFunction?: () => void
) => {
  try {
    const pickObject: { [key: string]: true | undefined } = {};
    Object.keys(value).forEach((key) => (pickObject[key] = true));

    const newSchema = schema.pick(pickObject);
    newSchema.parse(value);

    setFormErrors(
      (prevState) => ({ ...omit(prevState, errorKey) } as FormErrors)
    );
    successCallbackFunction && successCallbackFunction();
  } catch (error: any) {
    if (error.issues && error.issues.length === 1) {
      setFormErrors((prevState) => ({
        ...prevState,
        [errorKey]: error.issues?.at(0).message,
      }));
    } else {
      const errorObject: FormErrors = {};
      JSON.parse(error).forEach((err: any) => {
        err.path.forEach((element: any) => {
          errorObject[element] ||= err.message;
        });
      });
      setFormErrors((prevState) => ({ ...prevState, ...errorObject }));
    }
    errorCallbackFunction && errorCallbackFunction();
  }
};

export const validateInputAsync = async (
  schema: z.ZodObject<any>,
  errorKey: string,
  value: Record<string, any>,
  setFormErrors: Dispatch<SetStateAction<FormErrors>>,
  successCallbackFunction?: () => void,
  errorCallbackFunction?: () => void
) => {
  try {
    const pickObject: { [key: string]: true | undefined } = {};
    Object.keys(value).forEach((key) => (pickObject[key] = true));

    const newSchema = schema.pick(pickObject);
    await newSchema.parseAsync(value);

    setFormErrors(
      (prevState) => ({ ...omit(prevState, errorKey) } as FormErrors)
    );
    successCallbackFunction && successCallbackFunction();
  } catch (error: any) {
    if (error.issues && error.issues.length === 1) {
      setFormErrors((prevState) => ({
        ...prevState,
        [errorKey]: error.issues?.at(0).message,
      }));
    } else {
      const errorObject: FormErrors = {};
      JSON.parse(error).forEach((err: any) => {
        err.path.forEach((element: any) => {
          errorObject[element] ||= err.message;
        });
      });
      setFormErrors((prevState) => ({ ...prevState,...errorObject }));
    }
    errorCallbackFunction && errorCallbackFunction();
  }
};

export const validateInputForZodEffect = (
  schema: z.ZodEffects<any, any, any>,
  errorKey: string,
  value: Record<string, any>,
  setFormErrors: Dispatch<SetStateAction<FormErrors>>,
  successCallbackFunction?: () => void,
  errorCallbackFunction?: () => void
) => {
  try {
    schema.parse(value);

    setFormErrors(
      (prevState) => ({ ...omit(prevState, errorKey) } as FormErrors)
    );
    successCallbackFunction && successCallbackFunction();
  } catch (error: any) {
    if (error.issues && error.issues.length === 1) {
      setFormErrors((prevState) => ({
        ...prevState,
        [errorKey]: error.issues?.at(0).message,
      }));
    } else {
      const errorObject: FormErrors = {};
      JSON.parse(error).forEach((err: any) => {
        err.path.forEach((element: any) => {
          errorObject[element] ||= err.message;
        });
      });
      setFormErrors((prevState) => ({ ...prevState,...errorObject }));
    }

    errorCallbackFunction && errorCallbackFunction();
  }
};

export const validateInputForZodEffectAsync = async (
  schema: z.ZodEffects<any, any, any>,
  errorKey: string,
  value: Record<string, any>,
  setFormErrors: Dispatch<SetStateAction<FormErrors>>,
  successCallbackFunction?: () => void,
  errorCallbackFunction?: () => void
) => {
  try {
    await schema.parseAsync(value);

    setFormErrors(
      (prevState) => ({ ...omit(prevState, errorKey) } as FormErrors)
    );
    successCallbackFunction && successCallbackFunction();
  } catch (error: any) {
    if (error.issues && error.issues.length === 1) {
      setFormErrors((prevState) => ({
        ...prevState,
        [errorKey]: error.issues?.at(0).message,
      }));
    } else {
      const errorObject: FormErrors = {};
      JSON.parse(error).forEach((err: any) => {
        err.path.forEach((element: any) => {
          errorObject[element] ||= err.message;
        });
      });
      setFormErrors((prevState) => ({ ...prevState, ...errorObject }));
    }
    errorCallbackFunction && errorCallbackFunction();
  }
};
