export const EMPTY_STRING = "";
export const EMPTY_SPACE = " ";
export const REQUIRED_MARK = "*";
export const HYPHEN = "-";

export const MINIMUM_NUMBER_OF_CHARACTERS = 3;
export const EMPTY_ARRAY_LENGTH = 0;
export const EVENTUAL_CONSISTENCY_TIMEOUT = 3000;

export const SNACKBAR_TYPE_ERROR = "error";
export const SNACKBAR_TYPE_SUCCESS = "success";
export const SNACKBAR_TYPE_INFO = "info";
export const SNACKBAR_TYPE_WARNING = "warning";

export const TYPE_ERROR = "error";
export const TYPE_SUCCESS = "success";
export const TYPE_INFO = "info";

export const TEXT_UPDATE = "Update";
export const TEXT_ADD = "Add";
export const TEXT_REACTIVATE = "Reactivate";

export const MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH = 2;
export const PLACEHOLDER_SEARCH = "Type to search...";
export const LABEL_SEARCH_AMHP = "Search AMHP";
export const LABEL_SEARCH_DOCTOR = "Search Doctor";
export const LABEL_SEARCH_CCG = "Search CCG";
export const LABEL_SEARCH_ORG = "Search Org User ";

export const LABEL_SEARCH_MHT = "Search MHT";
export const DATE_FORMAT_WITHOUT_TIME = "DD/MM/YYYY";
export const DATE_FORMAT_WITH_TIME = "DD/MM/YYYY   HH:mm";
export const DATE_FORMAT_ONLY_TIME = "HH:mm";
