import React from "react";
import { Grid, Typography as Text, Card } from "@mui/material";
import AMHPTeamForm from "pages/AMHPTeam";

export interface searchByProps {
  menuItems: { key: number | string; value: string }[];
}
const AddAMHPTeam: React.FC = () => {
  return (
    <Grid
      xs={12}
      m={1}
      p={2}
      component={Card}
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 1,
        minHeight: window.innerHeight - 85,
      }}
    >
      <Grid item xs={12}>
        <Text variant="h1">Add AMHP Team</Text>
        <AMHPTeamForm />
      </Grid>
    </Grid>
  );
};

export default React.memo(AddAMHPTeam);
