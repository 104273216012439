import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { StyledInput, StyledSelect } from "components/uiElements";
import { useState } from "react";

const FilterModalContent = ({
  category,
  filters = [],
  setFilters,
  onClose,
  listItems,
}: any) => {
  const [field, setField] = useState("");
  const [value, setValue] = useState("");

  const style = {
    box: {
      position: "absolute" as "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: 250,
      width: "80%",
      bgcolor: "background.paper",
      borderRadius: 1,
      boxShadow: 24,
      p: 4,
    },
  };
  return (
    <Box sx={style.box} id="filter-modal-wrapper">
      <StyledSelect
        id="filterFieldSelect"
        label="Filter field"
        value={field}
        onChange={(e: any) => {
          setField(e.target.value);
        }}
        menuItems={listItems}
      />
      <StyledInput
        id="filterValueInput"
        label="Filter value"
        value={value}
        onChange={(e: any) => {
          setValue(e.target.value);
        }}
      />
      <LoadingButton
        variant="contained"
        disabled={!field || !value}
        onClick={() => {
          setFilters([...filters, { field, value }]);
          onClose();
        }}
      >
        Add filter
      </LoadingButton>
    </Box>
  );
};

export default FilterModalContent;
