import React, { useCallback, useContext, useState } from "react";
import { AlertColor } from "@mui/material";

interface Confirmation {
  message: string;
  successCallback: () => any;
  title?: string;
}

interface Snackbar {
  type?: AlertColor;
  message: string;
  duration?: number;
  disableCloseWhenClickAway?: boolean;
}

interface Bannermessage {
  type?: AlertColor;
  message: string;
}

interface InitialState {
  confirmation: Confirmation;
  snackbar: Snackbar;
  bannermessage: Bannermessage;
}

interface UsePopups {
  confirmation: Confirmation;
  handleConfirmation: (
    message: string,
    successCallback: () => any,
    title?: string
  ) => void;
  closeConfirmation: () => void;
  snackbar: Snackbar;
  handleSnackbar: (
    type: AlertColor,
    message: string,
    duration?: number,
    disableCloseWhenClickAway?: boolean,
  ) => void;
  closeSnackbar: () => void;
  closeBannermessage: () => void;
  bannermessage: Bannermessage;
  handleBannerMessage: (type: AlertColor, message: string) => void;
}

const initialState: InitialState = {
  confirmation: {
    message: "",
    successCallback: () => {},
    title: "",
  },
  snackbar: {
    type: undefined,
    message: "",
  },
  bannermessage: {
    type: undefined,
    message: "",
  },
};

const PopupsContext = React.createContext(initialState);

/**
 * The popups provider wraps the whole application and allows global use of the
 * confirmation dialog (components/confirmation) and snackbar component (components/snackbar).
 * The values and functions in this provider are accessible via the usePopups hook defined at the bottom of this file
 */
export const PopupsProvider = ({ children }: { children: React.ReactNode }) => {
  const [confirmation, setConfirmation] = useState<Confirmation>(
    initialState.confirmation
  );
  const [snackbar, setSnackbar] = useState<Snackbar>(initialState.snackbar);
  const [bannermessage, setBannermessage] = useState<Bannermessage>(
    initialState.bannermessage
  );

  const closeConfirmation = useCallback(() => {
    setConfirmation(initialState.confirmation);
  }, []);

  /**
   * Handle Confirmation
   * create a confirmation dialog with a custom message and success callback function
   * @params message {string} The message you would like to display to the user in the confirmation dialog
   * @params successCallback {function} The function you would like to execute if the user clicks confirm, this will also close the dialog
   * @returns void
   */
  const handleConfirmation = useCallback(
    (message: string, successCallback: () => any, title: string) => {
      setConfirmation({
        message,
        successCallback: () => {
          successCallback();
          closeConfirmation();
        },
        title,
      });
    },
    [closeConfirmation]
  );

  /**
   * Handle Snackbar
   * create any variant of a Material UI snackbar with a custom message
   * @params type {AlertColor} The variant of the Material UI snackbar
   * @params message {string} The message you would like to display to the user
   * @returns void
   */
  const handleSnackbar = useCallback(
    (
      type: AlertColor,
      message: string,
      duration?: number,
      disableCloseWhenClickAway?: boolean,
    ) => {
      setSnackbar({
        type,
        message,
        duration,
        disableCloseWhenClickAway,
      });
    },
    []
  );

  const handleBannerMessage = useCallback(
    (type: AlertColor, message: string) => {
      setBannermessage({
        type,
        message,
      });
    },
    []
  );

  const closeSnackbar = useCallback(() => {
    setSnackbar(initialState.snackbar);
  }, []);

  const closeBannermessage = useCallback(() => {
    setBannermessage(initialState.bannermessage);
  }, []);

  const contextValue = {
    confirmation,
    handleConfirmation,
    closeConfirmation,
    snackbar,
    handleSnackbar,
    closeSnackbar,
    bannermessage,
    handleBannerMessage,
    closeBannermessage,
  };

  return (
    <PopupsContext.Provider value={contextValue}>
      {children}
    </PopupsContext.Provider>
  );
};

export const usePopups = () => useContext(PopupsContext) as UsePopups;
