import React from "react";

// Hooks

// Material UI Imports
import { Card, Grid, Typography } from "@mui/material";

// Custom Types
import Search from "components/search/Search";
import {
  LABEL_SEARCH_DOCTOR,
  PLACEHOLDER_SEARCH,
} from "common/constants/common";
import { User } from "@s12solutions/types";
import { useNavigate, useLocation } from "react-router-dom";

// Custom Types

interface DoctorSelectProps {
  setDoctorId: React.Dispatch<React.SetStateAction<string | null>>;
  title: string;
  headerData: any;
  tableError: any;
  tableLoading: boolean;
  searchString: string;
  tableData: User[];
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
}

const DoctorSelectForm: React.FC<DoctorSelectProps> = ({
  setDoctorId,
  title,
  headerData,
  tableError,
  tableLoading,
  searchString,
  setSearchString,
  tableData,
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
  }

  return (
    <>
      <Grid
        xs={12}
        m={1}
        p={2}
        component={Card}
        sx={{
          justifyContent: "space-between",
          borderRadius: 1,
          minHeight: window.innerHeight - 85,
        }}
      >
        <Grid container spacing={3} xs={12}>
          <Grid item xs={12}>
            <Typography variant="h1">{title}</Typography>
          </Grid>
          <Grid item xs={12} mt={1} paddingRight={0} mr={0}>
            <Search
              rowsPerPageOptions={[5, 10, 25, 100]}
              autoFocus={true}
              error={tableError}
              loading={tableLoading}
              searchString={searchString}
              handleSearch={setSearchString}
              searchLabel={LABEL_SEARCH_DOCTOR}
              headerData={headerData}
              rowData={tableData}
              placeholder={PLACEHOLDER_SEARCH}
              onClickRowData={(event: any) => {
                setDoctorId(event.id);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export { DoctorSelectForm };
