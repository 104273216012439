import { Button, Grid, Typography } from "@mui/material";
import { S12Error } from "./Error";
import WarningIcon from "@mui/icons-material/Warning";
import { ROOT } from "layout/routes/RouteConstants";

interface ErrorBoundaryMessagePropTypes {
  error: S12Error;
}

// TODO Need To handle errors appropriately
export const ErrorBoundaryMessage = (props: ErrorBoundaryMessagePropTypes) => {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "50vh" }}
    >
      <Grid item xs={3}>
        <WarningIcon color="warning" fontSize="large" />
        <Typography variant="h4">
          {props.error.displayText ??
            "There was an application error. Please contact our Support Team if the problem persists"}
        </Typography>
        {!!props.error.id && (
          <Typography variant="h3">{`Error Id: ${props.error.id}`}</Typography>
        )}
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => window.location.reload()}
        >
          Reload
        </Button>
        <Button
          variant="contained"
          sx={{
            ml: 5,
          }}
          color="secondary"
          size="large"
          onClick={() => window.location.replace(ROOT)}
        >
          Go to Home
        </Button>
      </Grid>
    </Grid>
  );
};
