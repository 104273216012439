import React, { useContext } from "react";

// Material UI Imports
import { Grid, Card, Typography as Text, Button } from "@mui/material";
import AppVersionContext from "store/appVersionContext";

import {
  AMHP_UPDATE,
  DOCTOR_ADD,
  DOCTOR_UPDATE,
  DOCTOR_MANAGE_AVAILABILITY_V3,
  GENERAL_RESET_USER_PASSWORD,
  STAT_FORM_ASSIGNED_FORMS,
  GENERAL_CHECK_GMC,
} from "layout/routes/RouteConstants";

import { useNavigate } from "react-router-dom";

const Homepage: React.FC = () => {
  const navigate = useNavigate();
  const { version } = useContext(AppVersionContext);

  return (
    <Grid
      xs={12}
      m={1}
      p={2}
      component={Card}
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 1,
        minHeight: window.innerHeight - 85,
      }}
    >
      <Grid container m={0}>
        <Grid container spacing={3} xs={12} sx={{ height: "fit-content" }}>
          <Grid item xs={12}>
            <Text variant="h1">Admin Panel</Text>
            <Text mt={3} sx={{ fontWeight: "bold" }}>
              Please navigate to a common page below, or use the side-menu for
              more options.
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(AMHP_UPDATE);
              }}
            >
              AMHP Update
            </Button>
          </Grid>
          <Grid
            item
            xs={12}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Grid>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(DOCTOR_ADD);
                }}
              >
                Doctor Addition
              </Button>
            </Grid>
            <Grid>
              <Button
                variant="outlined"
                sx={{
                  marginLeft: 3,
                }}
                onClick={() => {
                  navigate(DOCTOR_UPDATE);
                }}
              >
                Doctor Search
              </Button>
            </Grid>
            {version === "1" && (
              <Grid>
                <Button
                  variant="outlined"
                  sx={{
                    marginLeft: 3,
                  }}
                  onClick={() => {
                    navigate(DOCTOR_MANAGE_AVAILABILITY_V3);
                  }}
                >
                  Doctor Availability V3
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(STAT_FORM_ASSIGNED_FORMS);
              }}
            >
              Stat Form Users
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(GENERAL_RESET_USER_PASSWORD);
              }}
            >
              Reset User Password
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(GENERAL_CHECK_GMC);
              }}
            >
              Check GMC
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(Homepage);
