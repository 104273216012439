import React, { useState, useCallback, useEffect } from "react";

// Hooks
import { useAPI, usePopups } from "hooks";
import useUnsavedChangesWarning from "hooks/useUnsavedChangesWarning";

// Material UI Imports
import { Card, Grid, Typography as Text, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Custom Imports
import { StyledInput } from "components/uiElements";

// Custom Types
import { Site, CreateSiteInput } from "@s12solutions/types";
import * as COMMON from "common/constants/common";
import * as MESSAGES from "common/constants/messages";
import { z } from "zod";
import { FormErrors } from "common/types/formErrors";
import {
  resetInput,
  validateFormDataAsync,
  validateInputAsync,
} from "utils/zod";

const AddSite: React.FC = () => {
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [name, setName] = useState<string>("");
  const { handleConfirmation, handleBannerMessage } = usePopups();
  const {
    trigger: createSite,
    loading,
    error,
  } = useAPI<Site | null, { input: CreateSiteInput }>({
    method: "POST",
    fieldName: "createSite",
    manual: true,
  });

  const siteSchema = z.object({
    site: z
      .string()
      .min(1, "Site name is required")
      .refine(async (site) => {
        let result = await listSites();

        if (result && result.data) {
          return !result.data.some(
            (val) => val.name.trim().toLowerCase() === site.trim().toLowerCase()
          );
        }
        return true;
      }, "Duplicate site name"),
  });

  const { trigger: listSites, error: siteDataLoadError } = useAPI<
    Site[] | null
  >({
    method: "GET",
    fieldName: "listSites",
    manual: true,
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const handleAddSite = useCallback(async () => {
    try {
      validateFormDataAsync(
        siteSchema,
        { site: name },
        async () => {
          let result = await createSite({
            input: {
              name: name.trim(),
            },
          });

          if (result && result.data) {
            setName("");
            handleBannerMessage(
              COMMON.TYPE_SUCCESS,
              MESSAGES.ADDED_SUCCESS.replace("{name}", "Site")
            );
          } else {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          }
        },
        setFormErrors
      );
    } catch {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [siteSchema, name, createSite, handleBannerMessage]);

  const resetData = () => {
    setName("");
    setFormErrors({});
  };

  useEffect(() => {
    if (name.length === 0) {
      setPristine();
    } else {
      setDirty();
    }
  }, [setDirty, setPristine, name]);

  useEffect(() => {
    if (error || siteDataLoadError) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [error, handleBannerMessage, siteDataLoadError]);

  return (
    <Grid
      xs={12}
      m={1}
      p={2}
      component={Card}
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 1,
        minHeight: window.innerHeight - 85,
      }}
    >
      <Grid container m={0}>
        {Prompt}
        <Grid container spacing={3} xs={6} sx={{ height: "fit-content" }}>
          <Grid item xs={12}>
            <Text variant="h1">Add Site</Text>
          </Grid>
          <Grid item mt={1} xs={12}>
            <StyledInput
              id="name"
              name="name"
              label="Name"
              required
              value={name}
              error={!!formErrors.site}
              errorText={formErrors.site}
              onBlur={(event) => {
                validateInputAsync(
                  siteSchema,
                  "site",
                  { site: event.target.value },
                  setFormErrors
                );
              }}
              onChange={(e: any) => {
                resetInput("site", setFormErrors);
                setName(e.target.value);
              }}
            />
          </Grid>
          <Grid item mt={1} xs={12}>
            <LoadingButton
              loading={loading}
              sx={{
                marginRight: 1,
              }}
              variant="contained"
              onClick={handleAddSite}
            >
              Add Site
            </LoadingButton>
            <Button
              sx={{
                marginLeft: 1,
              }}
              variant="outlined"
              onClick={() => {
                handleConfirmation(
                  MESSAGES.CONFIRM_CLEAR,
                  () => {
                    resetData();
                  },
                  "Warning!"
                );
              }}
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(AddSite);
