import { LocationNameInput, LocationInput } from "@s12solutions/types";
import { AMHPFormSchema } from "utils/zod/schemas/AMHPFormSchema";
import { z } from "zod";
import { AMHPTeamFormSchema } from "utils/zod/schemas/AMHPTeamFormSchema";
import { PartialDeep } from "type-fest";

export enum AmhpActionTypes {
  SET_NAME = "SET_NAME",
  SET_EMAIL = "SET_EMAIL",
  SET_PHONE = "SET_PHONE",
  SET_TEAMS = "SET_TEAMS",
  SET_SSO = "SET_SSO",
  SET_TYPE = "SET_TYPE",
  SET_MFA = "SET_MFA",
  SET_IS_TRAINEE_AMHP = "SET_IS_TRAINEE_AMHP",
  RESET = "RESET",
}

export interface AmhpAction {
  type: AmhpActionTypes;
  payload: Partial<AMHPState>;
}

export type AMHPState = z.infer<typeof AMHPFormSchema>;

export enum AmhpTeamActionTypes {
  SET_SITE = "SET_SITE",
  SET_TEAM_NAME = "SET_TEAM_NAME",
  SET_LOCAL_AUTHORITY = "SET_LOCAL_AUTHORITY",
  SET_FULL_TEAM_NAME = "SET_FULL_TEAM_NAME",
  ADD_COMMON_ADDRESS = "ADD_COMMON_ADDRESS",
  REMOVE_COMMON_ADDRESS = "REMOVE_COMMON_ADDRESS",
  ADD_TEAM_PHONE_NUMBER = "ADD_TEAM_PHONE_NUMBER",
  REMOVE_TEAM_PHONE_NUMBER = "REMOVE_TEAM_PHONE_NUMBER",
  ADD_TEAM_EMAIL = "ADD_TEAM_EMAIL",
  REMOVE_TEAM_EMAIL = "REMOVE_TEAM_EMAIL",
  TOGGLE_FEATURE_FLAGS = "TOGGLE_FEATURE_FLAGS",
  ADD_HOSPITAL_EMAIL = "ADD_HOSPITAL_EMAIL",
  REMOVE_HOSPITAL_EMAIL = "REMOVE_HOSPITAL_EMAIL",
  ADD_APPROVED_RECIPIENT = "ADD_APPROVED_RECIPIENT",
  REMOVE_APPROVED_RECIPIENT = "REMOVE_APPROVED_RECIPIENT",
  SET_MHA_INBOX_INSTRUCTION = "SET_MHA_INBOX_INSTRUCTION",
  RESET = "RESET",
}

export interface AmhpTeamAction {
  type: AmhpTeamActionTypes;
  payload: PartialDeep<AMHPTeamState> & { deleteId?: string };
}

export type AMHPTeamState = z.infer<typeof AMHPTeamFormSchema>;

export enum DoctorActionTypes {
  SET_GMC = "SET_GMC",
  SET_DISABLED = "SET_DISABLED",
  SET_LEGAL_NAME = "SET_LEGAL_NAME",
  SET_NAME = "SET_NAME",
  SET_EMAIL = "SET_EMAIL",
  SET_PHONE = "SET_PHONE",
  SET_ADDITIONAL_PHONE = "SET_ADDITIONAL_PHONE",
  SET_POSTCODE = "SET_POSTCODE",
  SET_LOCATION = "SET_LOCATION",
  SET_LOCATION_NAME = "SET_LOCATION_NAME",
  SET_GENDER = "SET_GENDER",
  SET_EMPLOYER = "SET_EMPLOYER",
  SET_SITE = "SET_SITE",
  SET_MHTS = "SET_MHTS",
  SET_SPECIALTIES = "SET_SPECIALTIES",
  SET_LANGUAGES = "SET_LANGUAGES",
  SET_NOTES = "SET_NOTES",
  SET_SSO = "SET_SSO",
  SET_MFA = "SET_MFA",
  SET_IS_DOCTOR_OUTSIDE_ENGLAND = "SET_IS_DOCTOR_OUTSIDE_ENGLAND",
  SET_HIDE_DOCTOR_FROM_SEARCH = "SET_HIDE_DOCTOR_FROM_SEARCH",
  RESET = "RESET",
}

export interface DoctorAction {
  type: DoctorActionTypes;
  payload: Partial<DoctorState>;
}

export interface DoctorState {
  gmc: string;
  legalName: string;
  name: string;
  email: string;
  phone: string;
  phone2: string;
  postcode: string;
  gender: string;
  employer: string;
  siteName: string;
  notes: string;
  mhts: { key: string; value: string }[];
  specialties: { key: string; value: string }[];
  languages: { key: string; value: string }[];
  sso: string;
  mfa: boolean;
  isOutsideEngland: boolean;
  locationName: LocationNameInput;
  location: LocationInput;
  hideFromSearch: boolean;
  disabled: boolean;
}
