import React from "react";
import { Grid, Typography as Text } from "@mui/material";
import * as constants from "common/constants/theme";

type SuccessMessageProps = {
  message: string;
  type?: "error" | "success";
};

const SuccessMessage: React.FC<SuccessMessageProps> = (props) => {
  const { message, type } = props;

  return (
    <Grid
      xs={12}
      sx={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Grid
        item
        xs={12}
        data-test-id="testSuccessMessage"
        sx={{
          backgroundColor:
            type === "error" ? constants.lightRed : constants.primaryLight,
          borderWidth: 2,
          borderColor: type === "error" ? constants.red : constants.primaryMain,
          borderStyle: "solid",
          justifyContent: "center",
          display: "flex",
          padding: 1,
          marginBottom: 3,
          borderRadius: 1,
          flex: 1,
        }}
      >
        <Text sx={{ color: constants.black, fontWeight: "bold", fontSize: 20 }}>
          {message}
        </Text>
      </Grid>
    </Grid>
  );
};

export default React.memo(SuccessMessage);
