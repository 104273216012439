import React, { useState, useCallback } from "react";
import * as Sentry from "@sentry/react";

// Hooks
import { useAPI, usePopups } from "hooks";

// Material UI Imports
import { Card, Grid, Typography as Text } from "@mui/material";

// Custom Imports
import UserSelect from "components/userSelect";
import ResetUserPasswordForm from "./ResetUserPasswordForm";

// Custom Types
import { ResetUserPasswordInput, User } from "@s12solutions/types";
import * as COMMON from "common/constants/common";
import * as MESSAGES from "common/constants/messages";
import { useNavigate, useLocation } from "react-router-dom";
import { validateFormData } from "utils/zod";
import { FormErrors } from "common/types/formErrors";
import { ResetPasswordSchemaRefined } from "utils/zod/schemas/ResetPasswordSchema";

const ResetUserPassword: React.FC = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
  }
  const { handleConfirmation, handleBannerMessage } = usePopups();

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [user, setUser] = useState<User | null>(null);
  const [temporaryPassword, setTemporaryPassword] = useState<string>("");
  const [confirmTemporaryPassword, setConfirmTemporaryPassword] =
    useState<string>("");

  const {
    trigger: resetUserPassword,
    loading,
    error,
  } = useAPI<User | null, { input: ResetUserPasswordInput }>({
    method: "POST",
    fieldName: "resetUserPassword",
    manual: true,
  });

  const handleReset = useCallback(() => {
    setUser(null);
    setTemporaryPassword("");
    setConfirmTemporaryPassword("");
  }, [setUser, setTemporaryPassword, setConfirmTemporaryPassword]);

  const handleSubmit = useCallback(() => {
    validateFormData(
      ResetPasswordSchemaRefined,
      {
        confirmTemporaryPassword: confirmTemporaryPassword,
        temporaryPassword: temporaryPassword,
      },
      async () => {
        try {
          if (!user) {
            handleReset();
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
            return;
          }

          const result = await resetUserPassword({
            input: {
              userName: user.id,
              password: temporaryPassword,
            },
          });

          if (!result.data || !result.data || result.error || error) {
            handleReset();
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          } else {
            handleReset();
            handleBannerMessage(
              COMMON.TYPE_SUCCESS,
              MESSAGES.RESET_SUCCESS.replace("{name}", "Password")
            );
          }
        } catch (error: any) {
          handleReset();
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
          Sentry.captureException("Error resetting a users password.", error);
        }
      },
      setFormErrors
    );
  }, [
    handleBannerMessage,
    user,
    temporaryPassword,
    confirmTemporaryPassword,
    resetUserPassword,
    error,
    handleReset,
  ]);

  return (
    <Grid
      xs={12}
      m={1}
      p={2}
      component={Card}
      direction="column"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 1,
        minHeight: window.innerHeight - 85,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Text variant="h1">Reset User Password</Text>
        </Grid>
        {!!user ? (
          <Grid item xs={12}>
            <ResetUserPasswordForm
              temporaryPassword={temporaryPassword}
              confirmTemporaryPassword={confirmTemporaryPassword}
              setTemporaryPassword={setTemporaryPassword}
              setConfirmTemporaryPassword={setConfirmTemporaryPassword}
              handleCancel={() => {
                handleConfirmation(
                  MESSAGES.CONFIRM_CANCEL,
                  () => {
                    setUser(null);
                    navigate(location.pathname);
                    handleReset();
                  },
                  "Confirm"
                );
              }}
              handleSubmit={handleSubmit}
              user={user}
              loading={loading}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
            />
          </Grid>
        ) : (
          <Grid item xs={12} mt={1}>
            <UserSelect userType="All" setUser={setUser} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default React.memo(ResetUserPassword);
