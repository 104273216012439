import {
  PASSWORD_CHARACTERS,
  PASSWORD_MISMATCH,
} from "common/constants/messages";
import { z } from "zod";

export const ResetPasswordSchema = z.object({
  temporaryPassword: z.string().min(8, {message: PASSWORD_CHARACTERS}),
  confirmTemporaryPassword: z.string().min(1, "Confirm password is required"),
});

export const ResetPasswordSchemaRefined = ResetPasswordSchema.refine(
  (data) => data.temporaryPassword === data.confirmTemporaryPassword,
  {
    message: PASSWORD_MISMATCH,
    path: ["confirmTemporaryPassword"],
  }
);
