import { Dispatch, SetStateAction } from "react";
import { FormErrors } from "common/types/formErrors";
import { z } from "zod";

export const validateFormData = (
  schema: z.ZodObject<any> | z.ZodEffects<any, any, any>,
  formData: any,
  callbackFunction: () => void,
  setFormErrors: Dispatch<SetStateAction<FormErrors>>,
  errorCallback?: () => void
) => {
  try {
    schema.parse(formData);
    setFormErrors(() => ({}));
    callbackFunction();
  } catch (error: any) {
    const errorObject: FormErrors = {};
    JSON.parse(error).forEach((err: any) => {
      err.path.forEach((element: any) => {
        errorObject[element] ||= err.message;
      });
    });
    setFormErrors((prevState) => ({ ...prevState, ...errorObject }));
    errorCallback && errorCallback();
  }
};

export const validateFormDataAsync = async (
  schema: z.ZodObject<any> | z.ZodEffects<any, any, any>,
  formData: any,
  callbackFunction: () => void,
  setFormErrors: Dispatch<SetStateAction<FormErrors>>,
  errorCallback?: () => void
) => {
  try {
    await schema.parseAsync(formData);
    setFormErrors(() => ({}));
    callbackFunction();
  } catch (error: any) {
    const errorObject: FormErrors = {};
    JSON.parse(error).forEach((err: any) => {
      err.path.forEach((element: any) => {
        errorObject[element] ||= err.message;
      });
    });
    setFormErrors((prevState) => ({ ...prevState, ...errorObject }));
    errorCallback && errorCallback();
  }
};
