// Definition and load of all routes for the system
import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import AppVersionContext from "store/appVersionContext";

// Redux
import { useAuth } from "hooks";

// Custom
import PrivateRoute from "./PrivateRoute";

// Pages
import Homepage from "pages/Homepage";
import ResetUserPassword from "pages/General/ResetUserPassword";
import ResendWelcomeEmail from "pages/General/ResendWelcomeEmail";
import CheckGMC from "pages/General/CheckGMC";
import AddSite from "pages/Site/AddSite";
import GlobalSettings from "pages/General/GlobalSettings";
import CCGAndICBLookup from "pages/General/CCGAndICBLookup";
//import LinkSite from "pages/Site/LinkSite";
import AddAMHP from "pages/AMHP/AddAMHP";
import UpdateAMHP from "pages/AMHP/UpdateAMHP";
import AddAMHPTeam from "pages/AMHPTeam/AddAMHPTeam";
import UpdateAMHPTeam from "pages/AMHPTeam/UpdateAMHPTeam";
import UpdateCCG from "pages/CCG/UpdateCCG";
import UpdateOrgUser from "pages/CCG/UpdateOrgUser";
import AddDoctor from "pages/Doctor/AddDoctor";
import UpdateDoctor from "pages/Doctor/UpdateDoctor";
import ManageDoctorAvailabilityV3 from "pages/Doctor/ManageDoctorAvalabilityV3";
//import AddMHT from "pages/MHT/Add MHT"; // 2022-11-02, temporary disable this page for initial release
import UpdateMHT from "pages/MHT/UpdateMHT";
import {
  ROOT,
  HOME,
  GENERAL,
  GENERAL_RESET_USER_PASSWORD,
  GENERAL_RESEND_WELCOME_EMAIL,
  GENERAL_CHECK_GMC,
  GENERAL_GLOBAL_SETTINGS,
  GENERAL_CCG_AND_ICB_LOOKUP,
  SITES,
  SITES_ADD,
  AMHP,
  AMHP_ADD,
  AMHP_UPDATE,
  AMHP_TEAM,
  AMHP_TEAM_ADD,
  AMHP_TEAM_UPDATE,
  CCG,
  CCG_UPDATE,
  CCG_UPDATE_ORG_USER,
  DOCTOR,
  DOCTOR_ADD,
  DOCTOR_UPDATE,
  DOCTOR_MANAGE_AVAILABILITY_V3,
  DOCTOR_MANAGE_TIME_AWAY,
  MHT,
  MHT_UPDATE,
  STAT_FORMS,
  STAT_FORM_ASSIGNED_FORMS,
  STAT_FORM_USERS,
  CCG_ADD_ORG_USER,
  SIFT,
  //SITES_LINK,
} from "./RouteConstants";
import EditStatUser from "pages/StatForms/EditStatFormUser";
import AddStatUser from "pages/StatForms/AddStatFormUser";
import AddOrgUser from "pages/CCG/AddOrgUser/AddOrgUser";
import SIFTPage from "pages/SIFT";

const ApplicationRoutes: React.FC = () => {
  const { user } = useAuth();
  const isAuth = !!user; // convert user object to boolean as private route expects a boolean prop
  const readOnly = user?.attributes["custom:limit_admin"];
  const { version } = useContext(AppVersionContext);

  return (
    <div>
      <Routes>
        {/* Authenticated routes */}
        <Route path={ROOT} element={<PrivateRoute access={isAuth} />}>
          <Route path={HOME} element={<Homepage />} />
          {/* General */}
          <Route path={GENERAL}>
            <Route
              path={GENERAL_RESET_USER_PASSWORD}
              element={<ResetUserPassword />}
            />
            <Route
              path={GENERAL_RESEND_WELCOME_EMAIL}
              element={<ResendWelcomeEmail />}
            />
            <Route path={GENERAL_CHECK_GMC} element={<CheckGMC />} />
            <Route
              path={GENERAL_GLOBAL_SETTINGS}
              element={<GlobalSettings />}
            />
            <Route
              path={GENERAL_CCG_AND_ICB_LOOKUP}
              element={<CCGAndICBLookup />}
            />
          </Route>
          {/* Sites */}
          {!readOnly && (
            <Route path={SITES}>
              <Route path={SITES_ADD} element={<AddSite />} />
              {/* <Route path={SITES_LINK} element={<LinkSite />} /> */}
            </Route>
          )}
          {/* SIFT */}

          <Route path={SIFT} element={<SIFTPage />} />

          {/* AMHP */}
          <Route path={AMHP}>
            {!readOnly && <Route path={AMHP_ADD} element={<AddAMHP />} />}
            <Route path={AMHP_UPDATE} element={<UpdateAMHP />} />
          </Route>
          {/* AMHP Team */}
          <Route path={AMHP_TEAM}>
            {!readOnly && (
              <Route path={AMHP_TEAM_ADD} element={<AddAMHPTeam />} />
            )}
            <Route path={AMHP_TEAM_UPDATE} element={<UpdateAMHPTeam />} />
          </Route>
          {/* CCG */}
          <Route path={CCG}>
            <Route path={CCG_UPDATE} element={<UpdateCCG />} />
            <Route path={CCG_ADD_ORG_USER} element={<AddOrgUser />} />
            <Route path={CCG_UPDATE_ORG_USER} element={<UpdateOrgUser />} />
          </Route>
          {/* Doctor */}
          <Route path={DOCTOR}>
            {!readOnly && <Route path={DOCTOR_ADD} element={<AddDoctor />} />}
            <Route path={DOCTOR_UPDATE} element={<UpdateDoctor />} />
            <Route
              path={DOCTOR_MANAGE_AVAILABILITY_V3}
              element={<ManageDoctorAvailabilityV3 />}
            />
          </Route>
          {/* MHT */}
          <Route path={MHT}>
            {/* <Route path={MHT_ADD} element={<AddMHT />} /> */}
            <Route path={MHT_UPDATE} element={<UpdateMHT />} />
          </Route>
          {/* Stat Forms */}
          <Route path={STAT_FORMS}>
            <Route path={STAT_FORM_ASSIGNED_FORMS} element={<EditStatUser />} />
            <Route path={STAT_FORM_USERS} element={<AddStatUser />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
};

export default React.memo(ApplicationRoutes);
