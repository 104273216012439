import React, { useEffect, useState } from "react";
import { Grid, Typography as Text, Card } from "@mui/material";
import { AMHPTeamSelectForm } from "./AMHPTeamSelectForm";
import {
  AmhpTeamResponseItem,
  ListAmhpTeamsFilterInput,
} from "@s12solutions/types";
import AMHPTeamForm from "pages/AMHPTeam";
import { useLocation, useNavigate } from "react-router-dom";
import { useDebounce } from "use-debounce";
import {
  EVENTUAL_CONSISTENCY_TIMEOUT,
  MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH,
  TYPE_ERROR,
} from "common/constants/common";
import { UNEXPECTED_ERROR_MESSAGE } from "common/constants/messages";
import { useAPI, usePopups } from "hooks";
import Progress from "components/cirularProgress/Progress";

const UpdateAMHPTeam: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [amhpTeamId, setAmhpTeamId] = useState<string | null>(null);
  const [tableData, setTableData] = useState<AmhpTeamResponseItem[]>([]);
  const [amhpTeam, setAmhpTeam] = useState<AmhpTeamResponseItem | null>(null);
  const [pageLoading, setPageLoading] = useState(false);
  const { handleBannerMessage } = usePopups();
  const [searchString, setSearchString] = useState("");

  const [debouncedSearchString] = useDebounce(
    searchString.trim().length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
      ? searchString.trim()
      : "",
    1000
  );

  const {
    trigger: listAmhpTeams,
    data: amhpTeamData,
    error: amhpTeamError,
    loading: amhpTeamLoading,
  } = useAPI<
    AmhpTeamResponseItem[] | null,
    {
      filter: ListAmhpTeamsFilterInput;
    }
  >({
    method: "GET",
    fieldName: "listAmhpTeams",
    args: {
      filter: {
        searchNameString: debouncedSearchString,
      },
    },
    manual: true,
  });

  const {
    trigger: listSingleAmhpTeam,
    data: amhpTeamSingleData,
    error: amhpTeamSingleError,
    loading: amhpTeamSingleLoading,
  } = useAPI<
    AmhpTeamResponseItem[] | null,
    {
      filter: ListAmhpTeamsFilterInput;
    }
  >({
    method: "GET",
    fieldName: "listAmhpTeams",
    args: {
      filter: {
        id: amhpTeamId || "",
      },
    },
    manual: true,
  });

  useEffect(() => {
    if (location?.search.length > 0) {
      setTimeout(() => {
        navigate(location.pathname);
      }, 8000);
      setAmhpTeamId(decodeURIComponent(location?.search.substring(1)));
    }
  }, [location.pathname, location?.search, navigate, setAmhpTeamId]);

  useEffect(() => {
    if (amhpTeamId) {
      listSingleAmhpTeam({
        filter: {
          id: amhpTeamId,
        },
      });
    }
  }, [amhpTeamId, listSingleAmhpTeam]);

  useEffect(() => {
    if (debouncedSearchString.length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH) {
      listAmhpTeams();
    }
  }, [debouncedSearchString, listAmhpTeams]);

  useEffect(() => {
    if (amhpTeamError || amhpTeamSingleError) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [amhpTeamError, amhpTeamSingleError, handleBannerMessage]);

  useEffect(() => {
    if (!amhpTeamLoading && amhpTeamData && !amhpTeamError && !pageLoading) {
      setTableData(amhpTeamData);
    } else {
      setTableData([]);
    }
  }, [amhpTeamData, amhpTeamError, amhpTeamLoading, pageLoading]);

  useEffect(() => {
    if (amhpTeamSingleData && !amhpTeamSingleError && !amhpTeamSingleLoading) {
      setAmhpTeam(amhpTeamSingleData[0]);
    } else {
      setAmhpTeam(null);
    }
  }, [amhpTeamSingleData, amhpTeamSingleError, amhpTeamSingleLoading]);

  useEffect(() => {
    return () => {
      setAmhpTeamId(null);
      setAmhpTeam(null);
      setTableData([]);
    };
  }, []);

  return (
    <>
      <Progress loading={amhpTeamSingleLoading}>
        <Grid
          xs={12}
          m={1}
          p={2}
          component={Card}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid item xs>
            <Text variant="h1">Update AMHP Team</Text>
            {!!amhpTeam ? (
              <AMHPTeamForm
                handleCancel={() => {
                  if (location.state) setPageLoading(true);
                  navigate(location.pathname);
                  setAmhpTeamId(null);
                  setAmhpTeam(null);
                  setTimeout(() => {
                    if (
                      debouncedSearchString.trim().length >
                      MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
                    ) {
                      listAmhpTeams();
                    }
                    setPageLoading(false);
                  }, EVENTUAL_CONSISTENCY_TIMEOUT);
                }}
                amhpTeam={amhpTeam}
              />
            ) : (
              <AMHPTeamSelectForm
                setAmhpTeamId={setAmhpTeamId}
                searchString={searchString}
                setSearchString={setSearchString}
                tableData={tableData}
                tableLoading={amhpTeamLoading || pageLoading}
                tableError={amhpTeamError}
              />
            )}
          </Grid>
        </Grid>
      </Progress>
    </>
  );
};

export default React.memo(UpdateAMHPTeam);
