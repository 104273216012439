import React from "react";

// Material UI Imports
import { Grid, Typography as Text } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// Custom Imports
import { StyledInput } from "components/uiElements";

// Custom Types
import { User } from "@s12solutions/types";
// Hooks
import useUnsavedChangesWarning from "hooks/useUnsavedChangesWarning";
import { useNavigate, useLocation } from "react-router-dom";
import { FormErrors } from "common/types/formErrors";
import { resetInput, validateInput } from "utils/zod";
import {
  ResetPasswordSchema,
  ResetPasswordSchemaRefined,
} from "utils/zod/schemas/ResetPasswordSchema";
import { validateInputForZodEffect } from "utils/zod/validateInput";

interface ResetUserPasswordFormProps {
  temporaryPassword: string;
  confirmTemporaryPassword: string;
  setTemporaryPassword: React.Dispatch<React.SetStateAction<string>>;
  setConfirmTemporaryPassword: React.Dispatch<React.SetStateAction<string>>;
  handleCancel: () => void;
  handleSubmit: () => void;
  user: User | null;
  loading: boolean;
  formErrors: FormErrors;
  setFormErrors: React.Dispatch<React.SetStateAction<FormErrors>>;
}

const ResetUserPasswordForm: React.FC<ResetUserPasswordFormProps> = ({
  temporaryPassword,
  confirmTemporaryPassword,
  setTemporaryPassword,
  setConfirmTemporaryPassword,
  handleCancel,
  handleSubmit,
  user,
  loading,
  formErrors,
  setFormErrors,
}) => {
  const location: any = useLocation();
  const navigate = useNavigate();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
    handleCancel();
  }
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  if (!user) return <></>;

  return (
    <>
      {Prompt}
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Text mt={0} sx={{ fontWeight: "bold" }}>
            Resetting password for{" "}
            <strong>
              {user.name} ({user.email})
            </strong>
          </Text>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <InfoOutlinedIcon color="primary" />
          <Text variant="h5" sx={{ lineHeight: 1.5, marginLeft: 1 }}>
            Please ensure to use a random pass phrase for the new temporary
            password rather than common terms like "changeme" etc. When possible
            use{" "}
            <a href="https://randomwordgenerator.com/">
              randomwordgenerator.com
            </a>{" "}
            to generate three random words with each no more than 6 letters in
            length.
          </Text>
        </Grid>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <StyledInput
                id="temporaryPassword"
                label="Temporary Password"
                value={temporaryPassword}
                onChange={(e: any) => {
                  e.target.value.length > 0 ? setDirty() : setPristine();
                  resetInput("temporaryPassword", setFormErrors);
                  setTemporaryPassword(e.target.value);
                }}
                onBlur={(event) => {
                  validateInput(
                    ResetPasswordSchema,
                    "temporaryPassword",
                    { temporaryPassword: event.target.value },
                    setFormErrors
                  );
                }}
                error={!!formErrors.temporaryPassword}
                errorText={formErrors.temporaryPassword}
                required
                autoFocus
              />
            </Grid>
            <Grid item xs={12}>
              <StyledInput
                id="confirmTemporaryPassword"
                label="Confirm Temporary Password"
                value={confirmTemporaryPassword}
                onChange={(e: any) => {
                  e.target.value.length > 0 ? setDirty() : setPristine();
                  resetInput("confirmTemporaryPassword", setFormErrors);
                  setConfirmTemporaryPassword(e.target.value);
                }}
                onBlur={(event) => {
                  validateInputForZodEffect(
                    ResetPasswordSchemaRefined,
                    "confirmTemporaryPassword",
                    {
                      temporaryPassword: temporaryPassword,
                      confirmTemporaryPassword: event.target.value,
                    },
                    setFormErrors
                  );
                }}
                error={!!formErrors.confirmTemporaryPassword}
                errorText={formErrors.confirmTemporaryPassword}
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton
            variant="contained"
            color="primary"
            size="large"
            onClick={handleSubmit}
            loading={loading}
          >
            Reset Password
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleCancel}
            sx={{ ml: "1rem" }}
          >
            Cancel
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
};

export default ResetUserPasswordForm;
