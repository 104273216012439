import { ListUserFilterField } from "@s12solutions/types";
import {
  ID_NAME,
  LABEL_NAME,
  ID_EMAIL,
  LABEL_EMAIL,
  LABEL_PHONE,
  ID_PHONE,
  ID_GMC,
  LABEL_GMC,
} from "./fields";

export const SEARCH_BY_LIST: { key: ListUserFilterField; value: string }[] = [
  {
    key: ID_NAME,
    value: LABEL_NAME,
  },
  {
    key: ID_EMAIL,
    value: LABEL_EMAIL,
  },
  {
    key: ID_PHONE,
    value: LABEL_PHONE,
  },
];

export const SEARCH_BY_LIST_FOR_DOCTOR: {
  key: ListUserFilterField;
  value: string;
}[] = [
  {
    key: ID_GMC,
    value: LABEL_GMC,
  },
  {
    key: ID_NAME,
    value: LABEL_NAME,
  },
  {
    key: ID_EMAIL,
    value: LABEL_EMAIL,
  },
  {
    key: ID_PHONE,
    value: LABEL_PHONE,
  },
];

export const SEARCH_BY_LIST_2: { key: ListUserFilterField; value: string }[] = [
  {
    key: ID_NAME,
    value: LABEL_NAME,
  },
  {
    key: ID_EMAIL,
    value: LABEL_EMAIL,
  },
];

export const SSO_LIST = {
  Microsoft: "Microsoft",
  None: "None",
};

export const SSO_OPTION_LIST = [
  { key: "Microsoft", value: "Microsoft" },
  { key: "None", value: "None" },
];

export const AMHP_TYPE_LIST = [
  { key: "normal", value: "normal" },
  { key: "trainee", value: "trainee" },
  { key: "support", value: "support" },
  { key: "lead", value: "lead" },
];

export type AMHPTeamFeatures =
  | "AMHP_PROVIDE_NHS_NUMBER"
  | "AMHP_TEAM_HAS_VIDEO"
  | "AMHP_MHA_FORMS"
  | "AMHP_MHA_EXTERNAL_DOCTORS"
  | "AMHP_TEAM_HAS_HOSPITAL_EMAILS"
  | "AMHP_TEAM_USES_JOINT_MED_RECS"
  | "AMHP_MHA_FORM_INBOX"
  | "AMHP_TEAM_CAN_DOWNLOAD_FORMS"
  | "AMHP_TEAM_CAN_EMAIL_FORMS"
  | "AMHP_TEAM_APPROVED_RECIPIENT_LIST"
  | "AMHP_TEAM_HAS_CONVEYANCE_FORMS"
  | "DOC_INFORMAL_MED_RECS"
  | "REQUIRES_MFA"
  | "REQUIRES_SSO";

export const GENDER_LIST = {
  Female: "Female",
  Male: "Male",
  Undefined: "Prefer not to say",
};

export const GENDER_OPTION_LIST = [
  { key: "Female", value: "Female" },
  { key: "Male", value: "Male" },
  { key: "Prefer not to say", value: "Prefer not to say" },
];
