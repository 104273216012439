import { useState, useEffect } from "react";
import { useAPI } from "hooks";

type Filter = {
  field: string;
  value: string;
};

const useCategoryAndFilters = (initialCategory: string) => {
  const [category, setCategory] = useState(initialCategory);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [listCategories, setListCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    data: listData,
    error: apiError,
    trigger: listCategory,
  } = useAPI({
    method: "GET",
    fieldName: "getAuditLogCategories",
    manual: true,
  });

  useEffect(() => {
    setIsLoading(true);
    listCategory().finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (apiError) {
      setError(apiError);
    } else if (listData && listData.length > 0) {
      setListCategories(listData);
    }
    setIsLoading(false);
  }, [listData, apiError]);

  useEffect(() => {
    const params = new URL(window.location.href).searchParams;
    const urlFilters: Filter[] = [];
    for (const [field, value] of params.entries()) {
      if (field === "category") {
        setCategory(value);
      } else {
        urlFilters.push({ field, value });
      }
    }
    setFilters(urlFilters);
  }, []);

  return {
    category,
    setCategory,
    filters,
    setFilters,
    listCategories,
    isLoading,
    setIsLoading,
    error,
  };
};

export default useCategoryAndFilters;
