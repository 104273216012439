import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import {
  Grid,
  Button,
  InputAdornment,
  Dialog,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Tab,
  DialogActions,
  Modal,
} from "@mui/material";
import { StyledInput } from "components/uiElements";
import { useAPI, useAuth, useOnMount, usePopups } from "hooks";
import TeamUsers from "components/teamUsers";
import TeamFeatures from "components/teamFeatures";
import {
  Organisation,
  UpdateOrganisationInput,
  OrganisationUser,
  FindOrgUsersInput,
  EventResponse,
} from "@s12solutions/types";
import * as COMMON from "common/constants/common";
import * as MESSAGES from "common/constants/messages";
import useUnsavedChangesWarning from "hooks/useUnsavedChangesWarning";
import { useNavigate, useLocation } from "react-router-dom";
import { CCG_UPDATE_ORG_USER } from "layout/routes/RouteConstants";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import CopyToClipboardButton from "components/clipBoard";
import { FormErrors } from "common/types/formErrors";
import { resetInput, validateFormData, validateInput } from "utils/zod";
import { CCGFormSchema } from "utils/zod/schemas/common.zod";
import { isEqual } from "lodash";
import { getFeatureFlagObjectCCG } from "utils/featureFlags";
import { Add, Close, KeyboardArrowRight } from "@mui/icons-material";
import SuccessMessage from "components/successMessage";
import Search from "components/search";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useDebounce } from "use-debounce";
import { ManualAddressFormSchema } from "utils/zod/schemas/AMHPTeamFormSchema";
import { compact } from "lodash";
import EventModalContent from "components/EventModal/EventModal";
interface CCGFormProps {
  handleCancel?: any;
  isEditing: boolean;
  org?: Organisation | null;
}

const CCGForm: React.FC<CCGFormProps> = ({ handleCancel, org, isEditing }) => {
  const readOnly = useAuth().user?.attributes["custom:limit_admin"];
  const location: any = useLocation();
  const navigate = useNavigate();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
    handleCancel();
  }

  const { handleConfirmation, handleBannerMessage } = usePopups();
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [initialState, setInitialState] = useState<
    Organisation | null | undefined
  >(org);
  const [isUpdate, setIsUpdate] = useState(false);
  const name = useMemo(() => initialState?.name ?? "", [initialState?.name]);
  const initialLocationId = useMemo(
    () =>
      compact([
        initialState?.locationName?.address,
        initialState?.locationName?.city,
        initialState?.locationName?.postcode,
      ]).join(", "),
    [initialState?.locationName]
  );
  const initialLocationName = useMemo(
    () => ({
      address: initialState?.locationName?.address ?? "",
      addressNotes: initialState?.locationName?.addressNotes,
      city: initialState?.locationName?.city,
      postcode: initialState?.locationName?.postcode?.trim()!,
    }),
    [initialState?.locationName]
  );
  const constantLocationId = useRef(initialLocationId);
  const constantAddressId = useRef("");
  const [locationName, setLocationName] = useState(initialLocationName);
  const [contactPhone, setContactPhone] = useState(initialState?.phone);
  const [contactEmail, setContactEmail] = useState(initialState?.email);
  const [invoiceEmail, setInvoiceEmail] = useState(initialState?.invoiceEmail);
  const [expensesInstruction, setExpensesInstruction] = useState(
    initialState?.expensesInstruction
  );
  const [declarationInstruction, setDeclarationInstruction] = useState(
    initialState?.declarationText
  );
  const [additionalNotes, setAdditionalNotes] = useState(
    initialState?.additionalNotesText
  );
  const [featureFlags, setFeatureFlags] = useState(
    getFeatureFlagObjectCCG(initialState?.featureFlags)
  );

  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [usersList, setUsersList] = useState<any[]>([]);
  const [dialog, setDialog] = React.useState(false);

  const [addressTableData, setAddressTableData] = useState<EventResponse[]>([]);
  const [addressTableLoading, setAddressTableLoading] = useState(false);

  const [value, setValue] = React.useState("1");

  const [showAudit, setAudit] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [fullAddresses, setFullAddresses] = useState({
    line1: "",
    line2: "",
    code: "",
  });
  const [searchString, setSearchString] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [debouncedSearchString] = useDebounce(
    searchString.trim().length > COMMON.MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
      ? searchString.trim()
      : "",
    1000
  );
  const [addressText, setAddressText] = useState(
    compact([
      locationName.address?.trim(),
      locationName.city?.trim(),
      locationName.postcode.trim(),
    ]).join(", ")
  );

  const [manualAddressFormErrors, setManualAddressFormErrors] =
    useState<FormErrors>({});

  const {
    trigger: findAddresses,
    data: addressData,
    loading: addressLoading,
    error: addressError,
  } = useAPI<
    EventResponse[] | null,
    {
      findExactMatch?: boolean;
      term?: string;
      postcode?: string;
      addressId?: string;
    }
  >({
    method: "GET",
    fieldName: "addressLookup",
    manual: true,
  });

  const {
    trigger: getAddress,
    loading: foundAddressLoading,
    error: foundAddressError,
  } = useAPI<
    EventResponse[] | null,
    {
      findExactMatch?: boolean;
      term?: string;
      postcode?: string;
      addressId?: string;
    }
  >({
    method: "GET",
    fieldName: "addressLookup",
    manual: true,
  });

  const TEAM_FEATURES = [
    {
      value: "claims",
      label: "CLAIMS",
      infoIcon: true,
      checked: featureFlags.claims,
      tooltip: "CCG is live with Claims in platform",
    },
    {
      value: "mileage",
      label: "MILEAGE",
      infoIcon: true,
      checked: featureFlags.mileage,
      tooltip: "CCG pays mileage and Doctor can claim mileage expenses",
    },
    {
      value: "additionalExpenses",
      label: "TRAVEL EXPENSES",
      infoIcon: true,
      checked: featureFlags.additionalExpenses,
      tooltip:
        "CCG Pays Additional Travel Expenses; Doctor can claim receipted expenses e.g. parking or train tickets",
    },
    {
      value: "fullPatientName",
      label: "FULL PATIENT NAME",
      infoIcon: true,
      checked: featureFlags.fullPatientName,
      tooltip:
        "CCG Records the full patient name in the CCG model and displays to CCG. (For GDPR purposes full patient name is hidden. Some CCGs require full patient name to be able to verify the claim)",
    },
    {
      value: "ccgRequiresBillingInformation",
      label: "REQUIRES BILLING INFORMATION",
      infoIcon: true,
      checked: featureFlags.ccgRequiresBillingInformation,
      tooltip:
        "CCG enforces that billing information is supplied with claim. CCG uses this information to identify they are paying the correct doctor - Bank account name isn't necessarily a match to the name of the doctor in the platform",
    },
    {
      value: "confirmAssessmentTookPlace",
      label: "AMHP CONFIRM VISIT TOOK PLACE",
      infoIcon: true,
      checked: featureFlags.confirmAssessmentTookPlace,
      tooltip:
        "CCG requires the amhp to note if the visit took place. CCGs will sometimes pay a partial fee or just mileage if the doctor attends to assessment location but doesn't actually assess the patient (medicated/can't gain access etc)",
    },
    {
      value: "provideVehicleInformation",
      label: "DOCTOR PROVIDE VEHICLE INFORMATION",
      infoIcon: true,
      checked: featureFlags.provideVehicleInformation,
      tooltip:
        "CCG requires fuel type/engine size to determine the reimbursement rate for any mileage claimed",
    },
    {
      value: "ccgRequiresNhsNumber",
      label: "REQUIRES NHS NUMBER",
      infoIcon: true,
      checked: featureFlags.ccgRequiresNhsNumber,
      tooltip:
        "CCG uses patient NHS number to verify the information in order to pay the claim",
    },
    {
      value: "ccgRequiresDoctorToInvoice",
      label: "REQUIRES DOCTOR TO INVOICE",
      infoIcon: true,
      checked: featureFlags.ccgRequiresDoctorToInvoice,
      tooltip:
        "Doctors have to produce invoices and send them through to the CCG via either SBS or directly to the CCG for uploading into their finance system",
    },
    {
      value: "ccgRequiresLineManager",
      label: "REQUIRES LINE MANAGER",
      infoIcon: true,
      checked: featureFlags.ccgRequiresLineManager,
      tooltip:
        "CCG has typically outsourced payment of the claims to the trust and the line manager has to approve the claim before the doctor is paid",
    },
    {
      value: "ccgRequiresGmcNumber",
      label: "REQUIRES GMC NUMBER",
      infoIcon: true,
      checked: featureFlags.ccgRequiresGmcNumber,
      tooltip:
        "CCG uses GMC number to identify a doctor in their payment system",
    },
    {
      value: "ccgDoesNotAcceptHospitalAssessments",
      label: "DOES NOT ACCEPT HOSPITAL ASSESSMENTS",
      infoIcon: true,
      checked: featureFlags.ccgDoesNotAcceptHospitalAssessments,
      tooltip:
        "If an assessment is for an in-patient, the claim will not be paid. AMHP will not be able to submit a claim to the doctor, process stops",
    },
    {
      value: "ccgRequiresAmhpTeamName",
      label: "REQUIRES AMHP TEAM NAME",
      infoIcon: true,
      checked: featureFlags.ccgRequiresAmhpTeamName,
      tooltip:
        "Created when a number of CCGs merged into one and finance teams need to allocate claim payments to the correct budget codes",
    },
    {
      value: "ccgRequiresDoctorMhtAssociations",
      label: "REQUIRES DOCTOR MHTS ASSOCIATION",
      infoIcon: true,
      checked: featureFlags.ccgRequiresDoctorMhtAssociations,
      tooltip:
        "CCG uses MHT information to determine who pays or who approves a claim",
    },
    {
      value: "ccgDisplayClaimLocation",
      label: "DISPLAY CLAIM LOCATION",
      infoIcon: true,
      checked: featureFlags.ccgDisplayClaimLocation,
      tooltip:
        "CCG requires claim location to show the partial postcode used to determine the CCG",
    },
    {
      value: "ccgRequiresDoctorAdditionalConfirmation",
      label: "REQUIRES DOCTOR ADDITIONAL CONFIRMATION",
      infoIcon: true,
      checked: featureFlags.ccgRequiresDoctorAdditionalConfirmation,
      tooltip:
        "Devon CCG requires choice of: employed and working hours, employed and outside working hours, independent",
    },
    {
      value: "doctorFeesMileageWithoutInvoice",
      label: "DOCTOR FEES MILEAGE WITHOUT INVOICE",
      infoIcon: true,
      checked: featureFlags.doctorFeesMileageWithoutInvoice,
      tooltip:
        "CCG requires export to include assessment fee and mileage claim value to save processors from having to do the same",
    },
    {
      value: "mhtAndLineManager",
      label: "MHT AND LINE MANAGER",
      infoIcon: true,
      checked: featureFlags.mhtAndLineManager,
      tooltip:
        "CCG has typically outsourced payment of the claims to the trust and the line manager has to approve the claim before the doctor is paid",
    },
    {
      value: "ccgRequiresDoctorEmployedStatus",
      label: "DOCTOR SHOULD CONFIRM PAYROLL STATUS",
      infoIcon: true,
      checked: featureFlags.ccgRequiresDoctorEmployedStatus,
      tooltip:
        "Used to identify whether a doctor should be paid via payroll or via their expenses system",
    },
  ];

  const {
    trigger: updateCCG,
    loading: updateLoading,
    error: updateError,
  } = useAPI<Organisation | null, { input: UpdateOrganisationInput }>({
    method: "PUT",
    fieldName: "updateOrganisation",
    manual: true,
  });

  const ccgUpdate = useCallback(() => {
    validateFormData(
      CCGFormSchema,
      {
        contactEmail: contactEmail,
        contactPhone: contactPhone,
        invoiceEmail: invoiceEmail,
        locationName: locationName,
      },
      () => {
        if (!initialState) {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
          return;
        }
        if (isUpdate) {
          updateCCG({
            input: {
              id: initialState.id,
              name: name,
              locationName: locationName,
              email: contactEmail,
              phone: contactPhone,
              invoiceEmail: invoiceEmail,
              expensesInstruction: expensesInstruction,
              declarationText: declarationInstruction,
              additionalNotesText: additionalNotes,
              features: initialState.features,
              featureFlags: JSON.stringify(featureFlags),
              type: initialState.type,
              updatedBy: initialState.updatedBy,
            },
          })
            .then((value) => {
              if (value && value.data && !updateError) {
                setIsUpdate(false);
                setPristine();
                setInitialState({
                  ...initialState,
                  name: name,
                  locationName: locationName,
                  email: contactEmail,
                  phone: contactPhone,
                  invoiceEmail: invoiceEmail,
                  expensesInstruction: expensesInstruction,
                  declarationText: declarationInstruction,
                  additionalNotesText: additionalNotes,
                  featureFlags: JSON.stringify(featureFlags),
                });
                handleBannerMessage(
                  COMMON.TYPE_SUCCESS,
                  MESSAGES.UPDATED_SUCCESS.replace("{name}", "CCG")
                );
              } else {
                handleBannerMessage(
                  COMMON.TYPE_ERROR,
                  MESSAGES.UNEXPECTED_ERROR_MESSAGE
                );
              }
            })
            .catch(() => {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            });
        } else {
          handleBannerMessage(COMMON.TYPE_INFO, MESSAGES.NO_CHANGES);
        }
      },
      setFormErrors,
      () => {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.UNEXPECTED_ERROR_MESSAGE
        );
      }
    );
  }, [
    additionalNotes,
    locationName,
    contactEmail,
    contactPhone,
    declarationInstruction,
    expensesInstruction,
    featureFlags,
    handleBannerMessage,
    initialState,
    invoiceEmail,
    isUpdate,
    name,
    setPristine,
    updateCCG,
    updateError,
  ]);

  const addCCG = () => {
    //This function should develop when addccg feature available
  };
  //Reset Add CCG data
  const resetData = () => {
    //This function should develop when addccg feature available
  };

  const {
    trigger: userList,
    loading: userLoading,
    error: userError,
  } = useAPI<
    OrganisationUser[] | null,
    {
      filter: FindOrgUsersInput;
    }
  >({
    method: "GET",
    fieldName: "listOrganisationUsers",
    manual: true,
  });

  const findUsers = useCallback(() => {
    if (!initialState) {
      return;
    }
    userList({
      filter: {
        organisationUserOrganisationId: initialState.id,
      },
    }).then((value) => {
      if (value && value.data && !userError) {
        const listOfUsers = value.data?.map((item) => {
          return {
            id: item.user.id,
            name: item.user.name,
            email: item.user.email,
            phone: item.user.phone,
          };
        });
        // set user list
        setUsersList(listOfUsers ?? []);
      } else {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.UNEXPECTED_ERROR_MESSAGE
        );
      }
    });
  }, [handleBannerMessage, initialState, userError, userList]);

  useOnMount(() => {
    findUsers();
  });

  useEffect(() => {
    if (!!org) {
      setInitialState(org);
    }
  }, [org]);

  useEffect(() => {
    setAddressText(
      compact([
        locationName.address.trim(),
        locationName.city?.trim(),
        locationName.postcode.trim(),
      ]).join(", ")
    );
  }, [locationName]);

  //check page whether edit or not
  useEffect(() => {
    if (
      !isEqual(contactPhone, initialState?.phone) ||
      !isEqual(contactEmail, initialState?.email) ||
      !isEqual(invoiceEmail, initialState?.invoiceEmail) ||
      !isEqual(locationName, initialLocationName) ||
      !isEqual(expensesInstruction, initialState?.expensesInstruction) ||
      !isEqual(declarationInstruction, initialState?.declarationText) ||
      !isEqual(additionalNotes, initialState?.additionalNotesText) ||
      !isEqual(
        featureFlags,
        getFeatureFlagObjectCCG(initialState?.featureFlags)
      )
    ) {
      setIsUpdate(true);
      setDirty();
    } else {
      setIsUpdate(false);
      setPristine();
    }
  }, [
    contactPhone,
    contactEmail,
    invoiceEmail,
    expensesInstruction,
    declarationInstruction,
    additionalNotes,
    featureFlags,
    initialState,
    setDirty,
    setPristine,
    locationName,
    initialLocationName,
  ]);

  // Handle Search
  useEffect(() => {
    if (
      debouncedSearchString.length >
      COMMON.MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
    ) {
      findAddresses({
        term: debouncedSearchString,
        addressId: undefined,
      });
    }
  }, [debouncedSearchString, findAddresses]);

  const saveAddress = useCallback(() => {
    validateFormData(
      ManualAddressFormSchema,
      fullAddresses,
      () => {
        const finalAddresses = compact([
          fullAddresses.line1.trim(),
          fullAddresses.line2.trim(),
        ]).join(", ");

        const updatingLocationName = {
          address: finalAddresses,
          postcode: fullAddresses.code,
          addressNotes: "",
          city: "",
        };
        const updatingLocationId = compact([
          updatingLocationName.address,
          updatingLocationName.city,
          updatingLocationName.postcode,
        ]).join(", ");

        if (isEqual(constantLocationId.current, updatingLocationId)) {
          setShowErrorMessage(true);
          setFormErrors({ locationName: MESSAGES.ADDRESS_ALREADY_UPDATED });
          return;
        }
        validateInput(
          CCGFormSchema,
          "locationName",
          {
            locationName: updatingLocationName,
          },
          setFormErrors,
          () => {
            setLocationName(updatingLocationName);
            constantLocationId.current = updatingLocationId;
            constantAddressId.current = "";
            setFullAddresses({
              line1: "",
              line2: "",
              code: "",
            });
            setDialog(false);
            setSearchString("");
          },
          () => {
            setShowErrorMessage(true);
          }
        );
      },
      setManualAddressFormErrors
    );
  }, [fullAddresses]);

  const updateCCGAddress = useCallback(
    async (params: any) => {
      try {
        if (!params.isExpandable) {
          // This checks if the address is already added with certain address id coming with event response object
          if (
            isEqual(constantAddressId.current, params.id) ||
            isEqual(constantLocationId.current, params.itemText)
          ) {
            setShowErrorMessage(true);
            setFormErrors({ locationName: MESSAGES.ADDRESS_ALREADY_UPDATED });
            return;
          }
          let result = await getAddress({
            term: undefined,
            addressId: params.id,
          });

          if (!result.data || result.error || foundAddressError) {
            setShowErrorMessage(true);
            return;
          }
          const data = result.data[0].locationName;
          const addressId = result.data[0].id;

          const address = data?.address;

          if (!data || !address) {
            setShowErrorMessage(true);
            return;
          }
          const updatingLocationName = {
            postcode: data.postcode,
            address: address,
            addressNotes: result.data[0].locationName?.addressNotes,
            city: result.data[0].locationName?.city,
          };
          const updatingLocationId = compact([
            updatingLocationName.address,
            updatingLocationName.city,
            updatingLocationName.postcode,
          ]).join(", ");

          // This checks if the address is already added with formatted address id (i.e address|city|postcode format) with event response object
          if (isEqual(constantLocationId.current, updatingLocationId)) {
            setShowErrorMessage(true);
            setFormErrors({ locationName: MESSAGES.ADDRESS_ALREADY_UPDATED });
            return;
          }
          validateInput(
            CCGFormSchema,
            "locationName",
            {
              locationName: updatingLocationName,
            },
            setFormErrors,
            () => {
              setLocationName(updatingLocationName);
              constantAddressId.current = addressId;
              constantLocationId.current = updatingLocationId;
              setSearchString("");
              setFullAddresses({
                line1: "",
                line2: "",
                code: "",
              });
              setDialog(false);
            },
            () => {
              setShowErrorMessage(true);
            }
          );
        } else {
          findAddresses({
            term: undefined,
            addressId: params.id,
          });
        }
      } catch {
        setShowErrorMessage(true);
      }
    },
    [findAddresses, foundAddressError, getAddress]
  );

  useEffect(() => {
    if (!foundAddressLoading && !addressLoading && !!addressData) {
      setAddressTableData(
        compact(
          addressData?.map((val) => {
            return {
              ...val,
              addresses: `${val.itemText?.replaceAll(",", ", ")}`,
            };
          })
        )
      );
      setAddressTableLoading(false);
    } else if (foundAddressLoading || addressLoading) {
      setAddressTableData([]);
      setAddressTableLoading(true);
    } else {
      setAddressTableData([]);
    }

    return () => {
      setAddressTableData([]);
    };
  }, [addressData, addressLoading, foundAddressLoading]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowErrorMessage(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [showErrorMessage]);

  const handleDialogClose = () => {
    setFullAddresses({
      line1: "",
      line2: "",
      code: "",
    });
    setSearchString("");
    setDialog(false);
    setValue("1");
  };

  return (
    <>
      <Dialog
        open={dialog}
        maxWidth="sm"
        fullWidth={false}
        PaperProps={{
          style: {
            borderRadius: 8,
            height: 500,
            width: 750,
          },
        }}
      >
        <DialogTitle>
          <Typography>Update CCG Address</Typography>
          <IconButton
            aria-label="close"
            onClick={handleDialogClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {showErrorMessage && (
            <SuccessMessage
              message={formErrors.locationName ?? "Unexpected error occurred"}
              type="error"
            />
          )}

          <TabContext value={value}>
            <TabList onChange={handleChange} aria-label="Update CCG Address">
              <Tab value="1" label="Location Search" />
              <Tab value="2" label="Manual Address" />
            </TabList>

            <TabPanel value="1">
              <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
                <Search
                  searchString={searchString}
                  handleSearch={setSearchString}
                  searchLabel="Search Location"
                  placeholder={COMMON.PLACEHOLDER_SEARCH}
                  headerData={[
                    {
                      headerName: "Addresses",
                      field: "addresses",
                      minWidth: 200,
                      flex: 1,
                    },
                    {
                      field: "actions",
                      type: "actions",
                      width: 100,
                      getActions: (params: any) => [
                        <GridActionsCellItem
                          icon={
                            !!params.row.isExpandable ? (
                              <KeyboardArrowRight color="primary" />
                            ) : (
                              <Add color="primary" />
                            )
                          }
                          label="Add"
                          onClick={() => updateCCGAddress(params.row)}
                        />,
                      ],
                    },
                  ]}
                  loading={addressTableLoading}
                  error={addressError}
                  rowData={addressTableData}
                  autoFocus={true}
                  onClickRowData={updateCCGAddress}
                />
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
                <StyledInput
                  id="address_line_1"
                  name="Address Line 1"
                  label="Address Line 1"
                  value={fullAddresses.line1}
                  error={!!manualAddressFormErrors.line1}
                  errorText={manualAddressFormErrors.line1}
                  onBlur={(event) => {
                    validateInput(
                      ManualAddressFormSchema,
                      "line1",
                      {
                        line1: event.target.value,
                      },
                      setManualAddressFormErrors
                    );
                  }}
                  onChange={(e: any) => {
                    resetInput("line1", setManualAddressFormErrors);
                    setFullAddresses((val) => {
                      return {
                        ...val,
                        line1: e.target.value,
                      };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
                <StyledInput
                  id="address_line_2"
                  name="Address Line 2"
                  label="Address Line 2"
                  error={!!manualAddressFormErrors.line2}
                  errorText={manualAddressFormErrors.line2}
                  onBlur={(event) => {
                    validateInput(
                      ManualAddressFormSchema,
                      "line2",
                      {
                        line2: event.target.value,
                      },
                      setManualAddressFormErrors
                    );
                  }}
                  value={fullAddresses.line2}
                  onChange={(e: any) => {
                    resetInput("line2", setManualAddressFormErrors);
                    setFullAddresses((val) => {
                      return {
                        ...val,
                        line2: e.target.value,
                      };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: 1, paddingTop: 1 }}>
                <StyledInput
                  id="postcode"
                  name="Postcode"
                  label="Postcode"
                  value={fullAddresses.code}
                  required
                  error={!!manualAddressFormErrors.code}
                  errorText={manualAddressFormErrors.code}
                  onBlur={(event) => {
                    validateInput(
                      ManualAddressFormSchema,
                      "code",
                      {
                        code: event.target.value,
                      },
                      setManualAddressFormErrors
                    );
                  }}
                  maxLength={255}
                  onChange={(e: any) => {
                    resetInput("code", setManualAddressFormErrors);
                    setFullAddresses((val) => {
                      return {
                        ...val,
                        code: e.target.value,
                      };
                    });
                  }}
                />
              </Grid>
            </TabPanel>
          </TabContext>
        </DialogContent>
        {value.includes("2") && (
          <DialogActions>
            <Button
              sx={{ marginRight: 1 }}
              disabled={false}
              variant="outlined"
              onClick={handleDialogClose}
            >
              Close
            </Button>
            <Button
              sx={{ marginRight: 2 }}
              variant="contained"
              onClick={() => saveAddress()}
            >
              Update Address
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <Grid
        xs={12}
        direction="row"
        mt={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderRadius: 1,
          minHeight: window.innerHeight - 85,
        }}
      >
        {Prompt}
        <Grid container m={0} xs={6}>
          <Grid container spacing={3} xs={12} sx={{ height: "fit-content" }}>
            <Grid item mt={1} xs={12}>
              <StyledInput
                id="mht_name"
                name="Name"
                label="Name"
                value={name}
                disabled={readOnly || isEditing ? true : false}
                endAdornment={
                  <InputAdornment position="end">
                    <CopyToClipboardButton id="mht_name" value={name} />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item mt={1} xs={12}>
              <StyledInput
                id="address"
                name="Address"
                label="Address"
                value={addressText}
                required
                disabled={readOnly}
                onChange={() => {
                  setAddressText(
                    compact([
                      locationName.address?.trim(),
                      locationName.city?.trim(),
                      locationName.postcode.trim(),
                    ]).join(", ")
                  );
                  setDialog(true);
                }}
                onClick={(event: any) => {
                  if (event.target.id === "address") {
                    setDialog(true);
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <CopyToClipboardButton id="address" value={addressText} />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item mt={1} xs={12}>
              <StyledInput
                disabled={readOnly}
                id="contact_phone"
                name="Contact Phone"
                label="Contact Phone"
                value={contactPhone}
                helperText="Please use international format without spaces, example: +447123453623"
                error={!readOnly && !!formErrors.contactPhone}
                errorText={formErrors.contactPhone}
                maxLength={255}
                onBlur={(event) => {
                  validateInput(
                    CCGFormSchema,
                    "contactPhone",
                    { contactPhone: event.target.value },
                    setFormErrors
                  );
                }}
                onChange={(e: any) => {
                  setContactPhone(e.target.value);
                  resetInput("contactPhone", setFormErrors);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id="contact_phone"
                      value={contactPhone ?? ""}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item mt={1} xs={12}>
              <StyledInput
                disabled={readOnly}
                id="contact_email"
                name="Contact Email"
                label="Contact Email"
                value={contactEmail}
                error={!readOnly && !!formErrors.contactEmail}
                errorText={formErrors.contactEmail}
                maxLength={255}
                onBlur={(event) => {
                  validateInput(
                    CCGFormSchema,
                    "contactEmail",
                    { contactEmail: event.target.value },
                    setFormErrors
                  );
                }}
                onChange={(e: any) => {
                  setContactEmail(e.target.value);
                  resetInput("contactEmail", setFormErrors);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id="contact_email"
                      value={contactEmail ?? ""}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item mt={1} xs={12}>
              <StyledInput
                disabled={readOnly}
                id="invoice_email"
                name="Invoice Email"
                label="Invoice Email"
                value={invoiceEmail}
                error={!readOnly && !!formErrors.invoiceEmail}
                errorText={formErrors.invoiceEmail}
                maxLength={255}
                onBlur={(event) => {
                  validateInput(
                    CCGFormSchema,
                    "invoiceEmail",
                    { invoiceEmail: event.target.value },
                    setFormErrors
                  );
                }}
                onChange={(e: any) => {
                  setInvoiceEmail(e.target.value);
                  resetInput("invoiceEmail", setFormErrors);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id="invoice_email"
                      value={invoiceEmail ?? ""}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item mt={1} xs={12}>
              <StyledInput
                disabled={readOnly}
                id="ccg_expenses_instruction"
                name="Expenses Instruction"
                label="Expenses Instruction"
                value={expensesInstruction}
                maxLength={255}
                onChange={(e: any) => {
                  setExpensesInstruction(e.target.value);
                }}
              />
            </Grid>
            <Grid item mt={1} xs={12}>
              <StyledInput
                disabled={readOnly}
                id="mht_declaration_instruction"
                name="Declaration Instruction"
                label="Declaration Instruction"
                value={declarationInstruction}
                multiline
                maxRows={10}
                minRows={4}
                onChange={(e: any) => {
                  setDeclarationInstruction(e.target.value);
                }}
              />
            </Grid>
            <Grid item mt={1} xs={12}>
              <StyledInput
                disabled={readOnly}
                id="ccg_additional_notes_text"
                name="Additional Notes Text"
                label="Additional Notes Text"
                value={additionalNotes}
                multiline
                maxRows={10}
                minRows={4}
                onChange={(e: any) => {
                  setAdditionalNotes(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} mt={1}>
              <LoadingButton
                loading={updateLoading}
                sx={{
                  marginRight: 1,
                }}
                variant="contained"
                onClick={() => (isEditing ? ccgUpdate() : addCCG())}
                disabled={readOnly}
              >
                {isEditing ? "Update CCG" : "Add CCG"}
              </LoadingButton>
              <Button
                sx={{
                  marginLeft: 1,
                }}
                variant="outlined"
                onClick={
                  isEditing
                    ? () => {
                        handleConfirmation(
                          MESSAGES.CONFIRM_CANCEL,
                          () => {
                            handleCancel();
                          },
                          "Confirm"
                        );
                      }
                    : resetData
                }
                disabled={readOnly}
              >
                {isEditing ? "Cancel" : "Clear"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container m={0} spacing={3} xs={6}>
          <Grid spacing={3} xs={12} ml={8}>
            <Grid item mb={2}>
              <LoadingButton
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => setAudit(true)}
              >
                Audit
              </LoadingButton>
            </Grid>
            <TeamFeatures
              teamFeatures={TEAM_FEATURES}
              header="Team Features"
              handleFeature={(event: any) => {
                setFeatureFlags((featureFlags: any) => ({
                  ...featureFlags,
                  [event.target.value]: event.target.checked,
                }));
              }}
              disabled={readOnly}
            />
            <TeamUsers
              userNames={usersList}
              header="Team Users"
              handleUser={(i: any) => {
                navigate(CCG_UPDATE_ORG_USER, {
                  state: {
                    fromUserList: true,
                    userId: i.id,
                  },
                });
              }}
              isLoading={userLoading}
            />
          </Grid>
        </Grid>
        {initialState?.id && (
          <Modal open={showAudit} onClose={() => setAudit(false)}>
            <EventModalContent
              filter={{
                fieldName: "getAuditLog",
                category: "ccg",
                id: initialState.id,
              }}
            />
          </Modal>
        )}
      </Grid>
    </>
  );
};

export default React.memo(CCGForm);
