import React, { useState } from "react";

// Auth
import Auth from "@s12solutions/auth/Auth";
import { AuthContext } from "store/auth/authContext";

// Material UI
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

// Custom Imports
import { usePopups } from "hooks";
import Appbar from "./appbar";
import SideMenu from "./sidemenu";
import ApplicationRoutes from "./routes/ApplicationRoutes";

import { DRAWER_WIDTH } from "common/constants/ui";
import BannerMessage from "components/bannerMessage";

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  paddingLeft: theme.spacing(1),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(3),
  paddingRight: open ? theme.spacing(1.1) : theme.spacing(1),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: DRAWER_WIDTH,
  ...(!open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  height: "100vh",
}));

const Layout: React.FC = () => {
  const classes = useStyles();
  const { handleSnackbar } = usePopups();
  const [drawerOpen, setDrawerOpen] = useState(true); // By default the drawer is open

  // Title bar to environment
  switch (process.env.REACT_APP_ENV) {
    case "dev":
      document.title = "S12 Admin - Development";
      break;
    case "preprod":
      document.title = "S12 Admin - Preprod";
      break;
    case "sandbox":
      document.title = "S12 Admin - Sandbox";
      break;
    default:
      document.title = "S12 Admin";
      break;
  }

  return (
    <Auth
      context={AuthContext}
      handleSnackbar={handleSnackbar}
      enabledUserGroups={["S12Admin"]}
    >
      <>
        <Appbar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        <SideMenu drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        <Main open={drawerOpen}>
          <div className={classes.appBarSpacer} />
          <BannerMessage />
          <ApplicationRoutes />
        </Main>
      </>
    </Auth>
  );
};

export default React.memo(Layout);
