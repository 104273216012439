import DoctorForm from "pages/Doctor";
import React from "react";

const AddDoctor: React.FC = () => {
  return (
    <>
      <DoctorForm title="Add Doctor" />
    </>
  );
};

export default React.memo(AddDoctor);
