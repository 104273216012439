/**
 * The main function that does the heavy lifting in the file
 * Bitwise operation of the target feature, against all of the features
 * https://www.w3schools.com/js/js_bitwise.asp
 */
const hasTargetFeatureFlag = (
  targetFeature: number | string,
  features: number | string
) => {
  if (typeof features === "string" && typeof targetFeature === "string") {
    // If we are a string, we are using the *new* system.
    const flags = JSON.parse(features);
    return !!flags[targetFeature];
  } else if (
    typeof features === "object" &&
    typeof targetFeature === "string"
  ) {
    return !!features[targetFeature];
  } else if (
    typeof features === "number" &&
    typeof targetFeature === "number"
  ) {
    // If we are a number, we are using the old system.
    return !!(targetFeature & features);
  } else {
    throw new Error(
      `Unexpected type combination: ${typeof features}, ${typeof targetFeature}`
    );
  }
};

/**
 * updateFeatureFlag:
 * - constructs new updated feature flag field for insertion into DB
 * @param featureFlags - existing featureFlags attribute string
 * @param updateObj - new flags as an object
 * @returns - updated featureFlags attribute string to be inserted into database
 */
//! Commented as this function is not using at the moment...
//const updateFeatureFlag = (featureFlags: string, updateObj: any) =>
//  JSON.stringify({ ...JSON.parse(featureFlags), ...updateObj });

// CCG CHECKS
export const hasClaims = (features: number | string) =>
  hasTargetFeatureFlag("claims", features);
export const hasMileage = (features: number | string) =>
  hasTargetFeatureFlag("mileage", features);
export const hasAdditionalExpenses = (features: number | string) =>
  hasTargetFeatureFlag("additionalExpenses", features);
export const hasFullPatientName = (features: number | string) =>
  hasTargetFeatureFlag("fullPatientName", features);
export const shouldConfirmAssessmentTookPlace = (features: number | string) =>
  hasTargetFeatureFlag("confirmAssessmentTookPlace", features);
export const shouldProvideVehicleInformation = (features: number | string) =>
  hasTargetFeatureFlag("provideVehicleInformation", features);
export const ccgRequiresNhsNumber = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresNhsNumber", features);
export const ccgRequiresDoctorToInvoice = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresDoctorToInvoice", features);
export const ccgRequiresBillingInformation = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresBillingInformation", features);
export const ccgRequiresLineManager = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresLineManager", features);
export const ccgRequiresGmcNumber = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresGmcNumber", features);
export const ccgDoesNotAcceptHospitalAssessments = (
  features: number | string
) => hasTargetFeatureFlag("ccgDoesNotAcceptHospitalAssessments", features);
export const ccgRequiresAmhpTeamName = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresAmhpTeamName", features);
export const ccgRequiresDoctorMhtAssociations = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresDoctorMhtAssociations", features);
export const ccgDisplayClaimLocation = (features: number | string) =>
  hasTargetFeatureFlag("ccgDisplayClaimLocation", features);
export const ccgRequiresDoctorAdditionalConfirmation = (
  features: number | string
) => hasTargetFeatureFlag("ccgRequiresDoctorAdditionalConfirmation", features);
export const doctorFeesMileageWithoutInvoice = (features: number | string) =>
  hasTargetFeatureFlag("doctorFeesMileageWithoutInvoice", features);
export const mhtAndLineManager = (features: number | string) =>
  hasTargetFeatureFlag("mhtAndLineManager", features);
export const ccgRequiresDoctorEmployedStatus = (features: number | string) =>
  hasTargetFeatureFlag("ccgRequiresDoctorEmployedStatus", features);
export const docInformalMedRecs = (features: number | string) =>
  hasTargetFeatureFlag("docInformalMedRecs", features);

// AMHP TEAM CHECKS
export const amhpShouldProvideNhsNumber = (features: number | string) =>
  hasTargetFeatureFlag("amhpShouldProvideNhsNumber", features);
export const amhpTeamVideoMeetings = (features: number | string) =>
  hasTargetFeatureFlag("amhpTeamVideoMeetings", features);
export const amhpTeamMHAExternalDoctors = (features: number | string) =>
  hasTargetFeatureFlag("amhpTeamMHAExternalDoctors", features);
export const amhpTeamMHAForms = (features: number | string) =>
  hasTargetFeatureFlag("amhpTeamMHAForms", features);
export const amhpTeamMhaHospitalEmails = (features: number | string) =>
  hasTargetFeatureFlag("amhpTeamMhaHospitalEmails", features);
export const amhpTeamEmailFormsToSelf = (features: number | string) =>
  hasTargetFeatureFlag("amhpTeamEmailFormsToSelf", features);
export const amhpTeamEmailFormsToApprovedLists = (features: number | string) =>
  hasTargetFeatureFlag("amhpTeamEmailFormsToApprovedLists", features);
export const amhpTeamDownloadForms = (features: number | string) =>
  hasTargetFeatureFlag("amhpTeamDownloadForms", features);
export const amhpTeamUsesJointMedRecs = (features: number | string) =>
  hasTargetFeatureFlag("amhpTeamUsesJointMedRecs", features);
export const amhpTeamInbox = (features: number | string) =>
  hasTargetFeatureFlag("amhpTeamInbox", features);
export const amhpTeamUsesConveyanceForms = (features: number | string) =>
  hasTargetFeatureFlag("amhpTeamUsesConveyanceForms", features);
export const requiresMFA = (features: number | string) =>
  hasTargetFeatureFlag("requiresMFA", features);
export const requiresSSO = (features: number | string) =>
  hasTargetFeatureFlag("requiresSSO", features);

export const getFeatureFlagObjectAMHPTeam = (
  featureFlags: string | null | undefined
) => {
  if (!featureFlags) {
    return {
      amhpShouldProvideNhsNumber: false,
      amhpTeamVideoMeetings: false,
      amhpTeamMHAExternalDoctors: false,
      amhpTeamMHAForms: false,
      amhpTeamMhaHospitalEmails: false,
      amhpTeamEmailFormsToSelf: false,
      amhpTeamEmailFormsToApprovedLists: false,
      amhpTeamDownloadForms: false,
      amhpTeamUsesJointMedRecs: false,
      amhpTeamInbox: false,
      amhpTeamUsesConveyanceForms: false,
      docInformalMedRecs: false,
      requiresSSO: false,
      requiresMFA: false,
    };
  }

  return {
    amhpShouldProvideNhsNumber: amhpShouldProvideNhsNumber(featureFlags),
    amhpTeamVideoMeetings: amhpTeamVideoMeetings(featureFlags),
    amhpTeamMHAExternalDoctors: amhpTeamMHAExternalDoctors(featureFlags),
    amhpTeamMHAForms: amhpTeamMHAForms(featureFlags),
    amhpTeamMhaHospitalEmails: amhpTeamMhaHospitalEmails(featureFlags),
    amhpTeamEmailFormsToSelf: amhpTeamEmailFormsToSelf(featureFlags),
    amhpTeamEmailFormsToApprovedLists:
      amhpTeamEmailFormsToApprovedLists(featureFlags),
    amhpTeamDownloadForms: amhpTeamDownloadForms(featureFlags),
    amhpTeamUsesJointMedRecs: amhpTeamUsesJointMedRecs(featureFlags),
    amhpTeamInbox: amhpTeamInbox(featureFlags),
    amhpTeamUsesConveyanceForms: amhpTeamUsesConveyanceForms(featureFlags),
    docInformalMedRecs: docInformalMedRecs(featureFlags),
    requiresSSO: requiresSSO(featureFlags),
    requiresMFA: requiresMFA(featureFlags),
  };
};

export const getFeatureFlagObjectCCG = (
  featureFlags: string | null | undefined
) => {
  if (!featureFlags) {
    return {
      claims: false,
      mileage: false,
      additionalExpenses: false,
      fullPatientName: false,
      confirmAssessmentTookPlace: false,
      provideVehicleInformation: false,
      ccgRequiresNhsNumber: false,
      ccgRequiresDoctorToInvoice: false,
      ccgRequiresBillingInformation: false,
      ccgRequiresLineManager: false,
      ccgRequiresGmcNumber: false,
      ccgDoesNotAcceptHospitalAssessments: false,
      ccgRequiresAmhpTeamName: false,
      ccgRequiresDoctorMhtAssociations: false,
      ccgDisplayClaimLocation: false,
      ccgRequiresDoctorAdditionalConfirmation: false,
      doctorFeesMileageWithoutInvoice: false,
      mhtAndLineManager: false,
      ccgRequiresDoctorEmployedStatus: false,
      docInformalMedRecs: false,
    };
  }

  return {
    claims: hasClaims(featureFlags),
    mileage: hasMileage(featureFlags),
    additionalExpenses: hasAdditionalExpenses(featureFlags),
    fullPatientName: hasFullPatientName(featureFlags),
    confirmAssessmentTookPlace: shouldConfirmAssessmentTookPlace(featureFlags),
    provideVehicleInformation: shouldProvideVehicleInformation(featureFlags),
    ccgRequiresNhsNumber: ccgRequiresNhsNumber(featureFlags),
    ccgRequiresDoctorToInvoice: ccgRequiresDoctorToInvoice(featureFlags),
    ccgRequiresBillingInformation: ccgRequiresBillingInformation(featureFlags),
    ccgRequiresLineManager: ccgRequiresLineManager(featureFlags),
    ccgRequiresGmcNumber: ccgRequiresGmcNumber(featureFlags),
    ccgDoesNotAcceptHospitalAssessments:
      ccgDoesNotAcceptHospitalAssessments(featureFlags),
    ccgRequiresAmhpTeamName: ccgRequiresAmhpTeamName(featureFlags),
    ccgRequiresDoctorMhtAssociations:
      ccgRequiresDoctorMhtAssociations(featureFlags),
    ccgDisplayClaimLocation: ccgDisplayClaimLocation(featureFlags),
    ccgRequiresDoctorAdditionalConfirmation:
      ccgRequiresDoctorAdditionalConfirmation(featureFlags),
    doctorFeesMileageWithoutInvoice:
      doctorFeesMileageWithoutInvoice(featureFlags),
    mhtAndLineManager: mhtAndLineManager(featureFlags),
    ccgRequiresDoctorEmployedStatus:
      ccgRequiresDoctorEmployedStatus(featureFlags),
    docInformalMedRecs: docInformalMedRecs(featureFlags),
  };
};
