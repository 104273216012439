import {
  INVALID_EMAIL_ERROR_MESSAGE,
  INVALID_PHONE_ERROR_MESSAGE,
  SELECT_AT_LEAST_ONE_TEAM_MESSAGE,
} from "common/constants/messages";
import { isValidPhone } from "common/validators";
import { z } from "zod";

// For Inputs Validation Other than phone
export const AMHPFormSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z
    .string()
    .min(1, "Email is required")
    .email(INVALID_EMAIL_ERROR_MESSAGE),
  phone: z
    .string()
    .refine(
      (phone) => isValidPhone(phone.trim(), true),
      INVALID_PHONE_ERROR_MESSAGE
    ),
  teams: z
    .array(
      z.object({
        key: z.string(),
        value: z.string(),
        featureFlags: z.object({
          amhpShouldProvideNhsNumber: z.boolean(),
          amhpTeamVideoMeetings: z.boolean(),
          amhpTeamMHAExternalDoctors: z.boolean(),
          amhpTeamMHAForms: z.boolean(),
          amhpTeamMhaHospitalEmails: z.boolean(),
          amhpTeamEmailFormsToSelf: z.boolean(),
          amhpTeamEmailFormsToApprovedLists: z.boolean(),
          amhpTeamDownloadForms: z.boolean(),
          amhpTeamUsesJointMedRecs: z.boolean(),
          amhpTeamInbox: z.boolean(),
          amhpTeamUsesConveyanceForms: z.boolean(),
          docInformalMedRecs: z.boolean(),
          requiresSSO: z.boolean(),
          requiresMFA: z.boolean(),
        }),
      })
    )
    .min(1, SELECT_AT_LEAST_ONE_TEAM_MESSAGE),
  sso: z.string().min(1, "SSO is required"),
  mfa: z.boolean(),
  type: z.string().min(1, "Type is required"),
  isTraineeAmhp: z.boolean(),
});
