import { Dialog, DialogTitle, Typography } from "@mui/material";
import React from "react";
import DialogContent from "@mui/material/DialogContent";
import ProTable from "components/proTable/ProTable";
import dayjs from "dayjs";
import { DATE_FORMAT_WITH_TIME, HYPHEN } from "common/constants/common";
import { GridOverlay } from "@mui/x-data-grid-pro";

interface ActivityDialogProps {
  open: boolean;
  data: any[] | undefined;
  loading?: boolean;
  error?: any;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ActivityDialog: React.FC<ActivityDialogProps> = ({
  data,
  loading,
  error,
  open,
  setDialogOpen,
}) => {
  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 8,
          minHeight: 220,
          // width: window.innerWidth / 3,
        },
      }}
      open={open}
      onClose={() => setDialogOpen(false)}
      fullWidth
    >
      <DialogTitle>
        <Typography>User Login Activity History</Typography>
      </DialogTitle>
      <DialogContent>
        <ProTable
          rows={data ?? []}
          loading={loading}
          tableError={error}
          noResultsOverlay={() => (
            <GridOverlay>
              <Typography>- No activity -</Typography>
            </GridOverlay>
          )}
          columns={[
            {
              field: "id",
              hide: true,
              flex: 0,
            },
            {
              field: "sign_in_time",
              headerName: "Sign in time",
              valueGetter: (params: any) =>
                dayjs(params.row.sign_in_time).format(DATE_FORMAT_WITH_TIME) ??
                HYPHEN,
              flex: 3,
            },
            { field: "status", headerName: "Status", flex: 0 },
            {
              field: "city",
              headerName: "City",
              flex: 2,
              valueGetter: (params: any) => params.row.city ?? HYPHEN,
            },
            { field: "country", headerName: "Country", flex: 2 },
          ]}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ActivityDialog;
