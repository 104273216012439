import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Grid,
  Typography as Text,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Button,
  TextField,
  RadioGroup,
  Radio,
  InputAdornment,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import dayjs, { Dayjs } from "dayjs";
import engb from "dayjs/locale/en-gb";
import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import {
  StyledInput,
  StyledCheckBox,
  StyledSelect,
} from "components/uiElements";
import SuccessMessage from "components/successMessage";
import {
  DoctorAvailabilityObj,
  ManageAvailabilityType,
  convertInputDataToMutationInputV4,
} from "common/functions";
import {
  EventResponse,
  S12DoctorListItem,
  CreateDoctorAvailabilityInput,
  UpdateDoctorAvailabilityInput,
  DoctorAvailability,
  AvailabilityType,
} from "@s12solutions/types";
import { isValidPostCode } from "common/validators";
import {
  AVAILABILITY_ADDED,
  AVAILABILITY_UPDATED,
  DayOfTheWeek,
  END_DATE_BEFORE_START_DATE,
  END_DATE_IN_PAST,
  END_TIME_NEED_TO_BE_WITHIN_A_DAY,
  END_TIME_SHOULD_AN_HOUR_AHEAD_START_TIME,
  INCORRECT_POSTAL_CODE,
  INCORRECT_POSTCODE,
  INVALID_DATE,
  INVALID_POSTCODE_ERROR_MESSAGE,
  NOTHING_TO_UPDATE,
  PLEASE_ENTER_END_DATE,
  REQUIRED_MANDATORY_FIELDS,
  START_TIME_MUST_BE_BEFORE_END_TIME,
} from "common/constants/messages";
import { LoadingButton } from "@mui/lab";
import {
  INVALID_TIME,
  UNEXPECTED_ERROR_MESSAGE,
  INVALID_DATA,
  AVAILABILITY_ALREADY_EXIST,
} from "common/constants/messages";
import {
  DATE_FORMAT_ONLY_TIME,
  DATE_FORMAT_WITHOUT_TIME,
  EMPTY_STRING,
  EVENTUAL_CONSISTENCY_TIMEOUT,
  HYPHEN,
  TYPE_ERROR,
  TYPE_SUCCESS,
} from "common/constants/common";
import CopyToClipboardButton from "components/clipBoard";
import { isEqual } from "lodash";
import { useAPI } from "hooks";
import { dayOfTheWeekFn } from "common/functions";
import { RepeatWeeklyOccurrenceOption } from "../../../common/functions/index";

type DoctorAvailabilityItemPopupV3Props = {
  open: boolean;
  close: () => void;
  doctorAvailabilityObj: DoctorAvailabilityObj | null;
  isEditing: boolean;
  isDuplicate: boolean | null;
  user: S12DoctorListItem;
  refetch: () => void;
};

const AvailabilityItemPopupV3: React.FC<DoctorAvailabilityItemPopupV3Props> = (
  props
) => {
  const {
    open,
    close,
    doctorAvailabilityObj,
    refetch,
    user,
    isEditing,
    isDuplicate,
  } = props;

  const initialState = useMemo(() => {
    if (doctorAvailabilityObj) {
      return {
        id: doctorAvailabilityObj.id,
        availabilityDoctorId: doctorAvailabilityObj.availabilityDoctorId || "",
        createdAt: doctorAvailabilityObj.createdAt
          ? doctorAvailabilityObj.createdAt
          : "",
        endDateTime: doctorAvailabilityObj.endDateTime,
        startDateTime: doctorAvailabilityObj.startDateTime,
        startDate: dayjs(doctorAvailabilityObj.startDateTime).format(
          "MMMM DD, YYYY"
        ),
        startTime: dayjs(doctorAvailabilityObj.startDateTime).format("HH:mm"),
        endDate: doctorAvailabilityObj.hasEndDate
          ? dayjs(doctorAvailabilityObj.endDateTime).format("MMMM DD, YYYY")
          : null,
        endTime: dayjs(doctorAvailabilityObj.endDateTime).format("HH:mm"),
        location: doctorAvailabilityObj.location
          ? doctorAvailabilityObj.location
          : { lat: 0, lon: 0 },
        locationName: doctorAvailabilityObj.locationName
          ? doctorAvailabilityObj.locationName
          : { postcode: "" },
        notes: doctorAvailabilityObj.notes,
        oncall: doctorAvailabilityObj.oncall
          ? doctorAvailabilityObj.oncall
          : false,
        rota: doctorAvailabilityObj.rota,
        rotaId: doctorAvailabilityObj.rotaId,
        rotaName: doctorAvailabilityObj.rotaName,
        seriesId: doctorAvailabilityObj.seriesId,
        type: doctorAvailabilityObj.type ? doctorAvailabilityObj.type : "",
        mht: doctorAvailabilityObj.mht,
        postcode: doctorAvailabilityObj.postcode
          ? doctorAvailabilityObj.postcode
          : null,
        repeat: doctorAvailabilityObj?.repeatEvent,
        repeatWeekdays: doctorAvailabilityObj.repeatWeekdays,
        RepeatWeeklyOccurrenceOption:
          doctorAvailabilityObj.repeatWeeklyOccurrences,
        repeatWeeks: doctorAvailabilityObj.repeatWeeklyFrequency,
        repeatWeeklyFrequency: doctorAvailabilityObj.repeatWeeklyFrequency,
        repeatType: doctorAvailabilityObj.repeatWeeklyOccurrences,
      };
    } else {
      return {
        startDate: dayjs(),
        startTime: dayjs()
          .set("minute", 0)
          .set("millisecond", 0)
          .set("seconds", 0)
          .add(1, "hour"),
        endDate: dayjs(),
        endTime: dayjs()
          .set("minute", 0)
          .set("millisecond", 0)
          .set("seconds", 0)
          .add(2, "hour"),
        notes: user.notes || EMPTY_STRING,
        postcode: user.locationName?.postcode || EMPTY_STRING,
        rota: HYPHEN,
        mht: HYPHEN,
        oncall: false,
        repeat: false,
        repeatWeeks: 1,
        repeatType: RepeatWeeklyOccurrenceOption.Once,
        repeatWeekdays: [dayOfTheWeekFn(dayjs())],
      };
    }
  }, [doctorAvailabilityObj, user]);

  const constantState = useRef(initialState);
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs(constantState.current.startDateTime)
  );
  const [startTime, setStartTime] = useState<Dayjs | null>(
    dayjs(constantState.current.startDateTime)
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    dayjs(constantState.current.endDateTime)
  );
  const [endTime, setEndTime] = useState<Dayjs | null>(
    dayjs(constantState.current.endDateTime)
  );
  const [notes, setNotes] = useState<string>(constantState.current.notes);
  const [postcode, setPostcode] = useState<string>(
    constantState.current.postcode ? constantState.current.postcode : ""
  );
  const [mht, setMht] = useState<string>(constantState.current.mht);
  const [rota, setRota] = useState<string>(constantState.current.rota || "");
  const [repeat, setRepeat] = useState<boolean>(
    constantState.current.repeat ? constantState.current.repeat : false
  );
  const [oncall, setOncall] = useState<boolean>(
    constantState.current.oncall ? constantState.current.oncall : false
  );
  const [repeatWeekdays, setRepeatWeekdays] = useState<string[]>(
    constantState.current.repeatWeekdays
      ? constantState.current.repeatWeekdays
      : [dayOfTheWeekFn(dayjs())]
  );
  const [repeatType, setRepeatType] = useState<RepeatWeeklyOccurrenceOption>(
    constantState.current.repeatType
      ? constantState.current.repeatType
      : RepeatWeeklyOccurrenceOption.Once
  );
  const [repeatWeeks, setRepeatWeeks] = useState(
    constantState.current.repeatWeeks
  );
  const [postcodeValidated, setPostcodeValidated] = useState(true);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState(EMPTY_STRING);
  const [messageType, setMessageType] = useState<
    "error" | "success" | undefined
  >(undefined);
  const [startTimeError, setStartTimeError] = useState(false);
  const [endTimeError, setEndTimeError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [endDateErrorText, setEndDateErrorText] = useState(EMPTY_STRING);
  const [endTimeErrorText, setEndTimeErrorText] = useState(EMPTY_STRING);
  const [timeError, setTimeError] = useState(EMPTY_STRING);
  const [repeatWeeksError, setRepeatWeeksError] = useState<boolean>(false);
  const [repeatWeeksErrorText, setRepeatWeeksErrorText] =
    useState<string>(EMPTY_STRING);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isMultiDay, setIsMultiDay] = useState(false);

  const { trigger: validatePostcode, loading: postcodeLoading } = useAPI<
    EventResponse | null,
    {
      findExactMatch?: boolean;
      term?: string;
      postcode?: string;
      addressId?: string;
    }
  >({
    method: "GET",
    fieldName: "searchByPostcode",
    manual: true,
  });

  const { trigger: createDoctorAvailability, loading: createLoadingV3 } =
    useAPI<
      CreateDoctorAvailabilityInput[] | string | null,
      { input: CreateDoctorAvailabilityInput[] }
    >({
      method: "POST",
      fieldName: "createDoctorAvailability",
      manual: true,
    });

  const { trigger: updateDoctorAvailability, loading: updateLoadingV3 } =
    useAPI<
      UpdateDoctorAvailabilityInput[] | string | null,
      { input: UpdateDoctorAvailabilityInput[] }
    >({
      method: "PUT",
      fieldName: "updateDoctorAvailability",
      manual: true,
    });

  useEffect(() => {
    if (!!initialState) {
      constantState.current = initialState;
    }
  }, [initialState]);

  useEffect(() => {
    const newTimeError =
      startDate &&
      endDate &&
      (dayjs(startDate).isBefore(dayjs(endDate)) ||
        (dayjs(startDate).isSame(dayjs(endDate)) &&
          dayjs(startTime).isBefore(dayjs(endTime))))
        ? EMPTY_STRING
        : START_TIME_MUST_BE_BEFORE_END_TIME;

    if (newTimeError !== timeError) {
      setTimeError(newTimeError);
    }
  }, [startTime, endTime, startDate, endDate, timeError]);

  useEffect(() => {
    setStartDate(
      constantState.current.startDate
        ? dayjs(constantState.current.startDate)
        : dayjs()
    );
    setStartTime(
      constantState.current.startTime
        ? dayjs(constantState.current.startTime, "HH:mm")
        : dayjs()
            .set("minute", 0)
            .set("millisecond", 0)
            .set("seconds", 0)
            .add(1, "hour")
    );
    setEndDate(
      constantState.current.endDate
        ? dayjs(constantState.current.endDate)
        : dayjs(constantState.current.startDate)
    );
    setEndTime(
      constantState.current.endTime
        ? dayjs(constantState.current.endTime, "HH:mm")
        : dayjs()
            .set("minute", 0)
            .set("millisecond", 0)
            .set("seconds", 0)
            .add(2, "hour")
    );
    setNotes(constantState.current.notes);
    setPostcode(
      constantState.current.postcode ? constantState.current.postcode : ""
    );
    setMht(constantState.current.mht);
    setRota(constantState.current.rota || "");
    setRepeat(
      constantState.current.repeat ? constantState.current.repeat : false
    );
    setRepeatType(
      constantState.current.repeatType || RepeatWeeklyOccurrenceOption.Once
    );
    setRepeatWeekdays(
      constantState.current.repeatWeekdays || [dayOfTheWeekFn(dayjs())]
    );
    setRepeatWeeks(constantState.current.repeatWeeks);
    setOncall(constantState.current.oncall);
  }, [doctorAvailabilityObj]);

  const resetStateToInitial = () => {
    setStartDate(dayjs());
    setStartTime(
      dayjs()
        .set("minute", 0)
        .set("millisecond", 0)
        .set("seconds", 0)
        .add(1, "hour")
    );
    setEndDate(dayjs());
    setEndTime(
      dayjs()
        .set("minute", 0)
        .set("millisecond", 0)
        .set("seconds", 0)
        .add(2, "hour")
    );
    setNotes(EMPTY_STRING);
    setPostcode(user.locationName?.postcode || EMPTY_STRING);
    setMht(HYPHEN);
    setRota(HYPHEN);
    setRepeat(false);
    setRepeatType(RepeatWeeklyOccurrenceOption.Once);
    setRepeatWeekdays([dayOfTheWeekFn(dayjs())]);
    setRepeatWeeks(1);
    setOncall(false);
  };

  const handleClear = () => {
    if (!doctorAvailabilityObj) {
      resetStateToInitial();
    } else {
      setStartDate(dayjs(constantState.current.startDateTime));
      setStartTime(dayjs(constantState.current.startDateTime, "HH:mm"));
      setEndDate(dayjs(constantState.current.endDateTime));
      setEndTime(dayjs(constantState.current.endDateTime, "HH:mm"));
      setNotes(constantState.current.notes || "");
      setPostcode(constantState.current.postcode || "");
      setMht(constantState.current.mht);
      setRota(constantState.current.rota || "");
      setRepeat(constantState.current.repeat || false);
      setRepeatType(
        constantState.current.repeatType || RepeatWeeklyOccurrenceOption.Once
      );
      setRepeatWeekdays(
        constantState.current.repeatWeekdays || [dayOfTheWeekFn(dayjs())]
      );
      setRepeatWeeks(constantState.current.repeatWeeks);
      setOncall(constantState.current.oncall);
    }
  };

  const getTypeFromMht = (mht: string): AvailabilityType | null => {
    if (mht === "-") {
      return "independent";
    }
    return "trust";
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [showMessage]);

  useEffect(() => {
    if (repeatType === RepeatWeeklyOccurrenceOption.Multiple) {
      const proposedEndDate = dayjs(startDate).add(1, "week");
      if (!endDate || dayjs(endDate).isBefore(dayjs(startDate))) {
        setEndDate(proposedEndDate);
      }
    }
  }, [repeatType, startDate, endDate]);

  const handleRepeatWeeksChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    if (value < 1 || value > 12) {
      setRepeatWeeksError(true);
      setRepeatWeeksErrorText("Please enter a value between 1 and 12.");
      setIsButtonDisabled(true);
    } else {
      setRepeatWeeksError(false);
      setRepeatWeeksErrorText(EMPTY_STRING);
      setIsButtonDisabled(false);
    }
    setRepeatWeeks(value);
  };

  useEffect(() => {
    const hasErrors =
      !isValidPostCode(postcode) ||
      startDateError ||
      endDateError ||
      startTimeError ||
      endTimeError ||
      repeatWeeksError;

    setIsButtonDisabled(hasErrors);
  }, [
    postcode,
    startDateError,
    endDateError,
    startTimeError,
    endTimeError,
    repeatWeeksError,
  ]);

  useEffect(() => {
    if (startDate && startTime && endDate && endTime) {
      const startDateTime = dayjs(startDate)
        .set("hour", startTime.hour())
        .set("minute", startTime.minute());
      const endDateTime = dayjs(endDate)
        .set("hour", endTime.hour())
        .set("minute", endTime.minute());

      const duration = endDateTime.diff(startDateTime, "hours");
      setIsMultiDay(duration > 24);
    }
  }, [startDate, startTime, endDate, endTime]);

  const handleRepeatWeekdays = (event: any, weekDay: string) => {
    if (event.target.checked) {
      setRepeatWeekdays((v) => [...v, weekDay]);
    } else {
      setRepeatWeekdays((v) => [...v.filter((val) => !val.includes(weekDay))]);
    }
  };

  const mhtList = useMemo(() => {
    let arr =
      user.mhtEmployers?.items?.map((val) => {
        if (val) {
          return {
            key: val?.mht.id,
            value: val?.mht.organisation.name,
          };
        } else return {} as { key: string | number; value: string };
      }) || ([] as { key: string | number; value: string }[]);

    arr.push({ key: HYPHEN, value: "Independent" });
    return arr.sort((a, b) => a.value.localeCompare(b.value));
  }, [user.mhtEmployers?.items]);

  const rotaList = useMemo(() => {
    let arr =
      user.mhtEmployers?.items
        ?.filter((val) => val?.mht.id === mht)[0]
        ?.mht.organisation.rotas?.map((val) => {
          if (val) {
            return {
              key: val.id,
              value: val.name,
            };
          } else return {} as { key: string; value: string };
        }) || ([] as { key: string; value: string }[]);

    if (arr.length > 0) arr.push({ key: HYPHEN, value: "None" });
    return arr.sort((a, b) => a.value.localeCompare(b.value));
  }, [mht, user.mhtEmployers?.items]);

  const handlePopupBannerMessage = (
    message: string,
    type: "success" | "error"
  ) => {
    setMessageType(type);
    setMessage(message);
    setShowMessage(true);
  };

  const handleAddUpdate = useCallback(async () => {
    try {
      if (
        !startDate ||
        !startTime ||
        !endDate ||
        !endTime ||
        !isValidPostCode(postcode)
      ) {
        handlePopupBannerMessage(REQUIRED_MANDATORY_FIELDS, TYPE_SUCCESS);
        return;
      }

      if (
        startDateError ||
        endDateError ||
        endTimeError ||
        startTimeError ||
        !isValidPostCode(postcode)
      ) {
        handlePopupBannerMessage(
          `${INVALID_DATA} (${startDateError} ${endDateError} ${endTimeError} ${startTimeError} ${!isValidPostCode(
            postcode
          )})`,
          TYPE_ERROR
        );
        return;
      }

      let result = await validatePostcode({
        postcode: postcode,
      });

      if (
        !result ||
        !result.data ||
        !result.data.location ||
        !result.data.locationName
      ) {
        setPostcodeValidated(false);
        handlePopupBannerMessage(INCORRECT_POSTCODE, TYPE_ERROR);
        return;
      }

      let inputV4: DoctorAvailability[] = convertInputDataToMutationInputV4(
        {
          ...((isEditing || isDuplicate) && doctorAvailabilityObj
            ? { id: doctorAvailabilityObj.id }
            : {}),
          postcode: postcode,
          mht: mht,
          oncall: oncall,
          rotaId: rota !== HYPHEN ? rota : undefined,
          rotaName:
            rota !== HYPHEN
              ? rotaList.find(
                  (val: { key: string; value: string }) => val.key === rota
                )?.value
              : undefined,
          notes: notes,
          startDate: startDate.toDate(),
          startTime: startTime.format(DATE_FORMAT_ONLY_TIME),
          endDate: endDate.toDate(),
          endTime: endTime.format(DATE_FORMAT_ONLY_TIME),
          repeat: repeat,
          repeatWeeklyFrequency: repeatWeeks ? repeatWeeks : 1,
          repeatWeekdays: repeat ? repeatWeekdays : [dayOfTheWeekFn(startDate)],
          repeatWeeklyOccurrences: repeatType,
          hasEndDate: !!endDate,
          seriesId: doctorAvailabilityObj?.seriesId
            ? doctorAvailabilityObj?.seriesId
            : undefined,
          repeatType: repeatType,
          availabilityMhtId: mht ? mht : HYPHEN,
        },
        isEditing
          ? ManageAvailabilityType.UPDATE
          : ManageAvailabilityType.CREATE,
        user.id
      );

      let inputV5 = inputV4.map((val) => {
        return {
          ...val,
          type: getTypeFromMht(mht ? mht : HYPHEN),
          location: result.data?.location
            ? {
                lat: result.data.location.lat,
                lon: result.data.location.lon,
              }
            : null,
          locationName: result.data?.locationName
            ? {
                postcode: result.data.locationName.postcode,
                city: result.data.locationName.city,
                address: result.data.locationName.address,
                addressNotes: result.data.locationName.addressNotes,
              }
            : null,
          updatedAt: new Date().toISOString(),
          updatedBy: user.id,
        };
      });
      if (!!isEditing || !!isDuplicate) {
        if (
          !!doctorAvailabilityObj &&
          dayjs(startDate).format(DATE_FORMAT_WITHOUT_TIME) ===
            dayjs(constantState.current.startDateTime).format(
              DATE_FORMAT_WITHOUT_TIME
            ) &&
          dayjs(endDate).format(DATE_FORMAT_WITHOUT_TIME) ===
            dayjs(constantState.current.endDateTime).format(
              DATE_FORMAT_WITHOUT_TIME
            ) &&
          dayjs(endTime).format(DATE_FORMAT_ONLY_TIME) ===
            dayjs(constantState.current.endTime).format(
              DATE_FORMAT_ONLY_TIME
            ) &&
          dayjs(startTime).format(DATE_FORMAT_ONLY_TIME) ===
            dayjs(constantState.current.startTime).format(
              DATE_FORMAT_ONLY_TIME
            ) &&
          constantState.current.mht === mht &&
          constantState.current.oncall === oncall &&
          constantState.current.postcode === postcode &&
          constantState.current.repeat === repeat &&
          constantState.current.repeatType === repeatType &&
          isEqual(constantState.current.repeatWeekdays, repeatWeekdays) &&
          constantState.current.repeatWeeks === repeatWeeks &&
          constantState.current.rota === rota &&
          constantState.current.notes.trim().valueOf() ===
            notes.trim().valueOf()
        ) {
          handlePopupBannerMessage(NOTHING_TO_UPDATE, TYPE_SUCCESS);
          return;
        }

        let output = await updateDoctorAvailability({
          input: inputV5,
        });
        if (
          output.data &&
          typeof output.data === "string" &&
          output.data.includes("Overlapping")
        ) {
          handlePopupBannerMessage(AVAILABILITY_ALREADY_EXIST, TYPE_ERROR);
          return;
        }
        setTimeout(refetch, EVENTUAL_CONSISTENCY_TIMEOUT);
        handlePopupBannerMessage(AVAILABILITY_UPDATED, TYPE_SUCCESS);
      } else {
        let output = await createDoctorAvailability({
          input: inputV5,
        });

        if (
          output.data &&
          typeof output.data === "string" &&
          output.data.includes("Overlapping")
        ) {
          handlePopupBannerMessage(AVAILABILITY_ALREADY_EXIST, TYPE_ERROR);
          return;
        }

        setTimeout(refetch, EVENTUAL_CONSISTENCY_TIMEOUT);
        handlePopupBannerMessage(AVAILABILITY_ADDED, TYPE_SUCCESS);
        setStartDate(dayjs());
        setStartTime(
          dayjs()
            .set("minute", 0)
            .set("millisecond", 0)
            .set("seconds", 0)
            .add(1, "hour")
        );
        setEndDate(dayjs());
        setEndTime(
          dayjs()
            .set("minute", 0)
            .set("millisecond", 0)
            .set("seconds", 0)
            .add(2, "hour")
        );
        setNotes(user.notes || EMPTY_STRING);
        setPostcode(user.locationName?.postcode || EMPTY_STRING);
        setMht(HYPHEN);
        setRota(HYPHEN);
        setRepeat(false);
        setRepeatType(RepeatWeeklyOccurrenceOption.Once);
        setRepeatWeekdays([dayOfTheWeekFn(dayjs())]);
        setRepeatWeeks(repeatWeeks);
        setOncall(false);
      }
      close();
    } catch (e) {
      handlePopupBannerMessage(UNEXPECTED_ERROR_MESSAGE, TYPE_ERROR);
    }
  }, [
    isEditing,
    isDuplicate,
    doctorAvailabilityObj,
    createDoctorAvailability,
    endDate,
    endDateError,
    endTime,
    endTimeError,
    mht,
    notes,
    oncall,
    postcode,
    refetch,
    repeat,
    repeatType,
    repeatWeekdays,
    repeatWeeks,
    rota,
    rotaList,
    startDate,
    startDateError,
    startTime,
    startTimeError,
    updateDoctorAvailability,
    user,
    validatePostcode,
    close,
  ]);

  useEffect(() => {
    if (showMessage) {
      let timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [close, showMessage]);
  return (
    <Dialog
      open={open}
      maxWidth="lg"
      fullWidth={false}
      PaperProps={{
        style: {
          borderRadius: 8,
          width: 800,
        },
      }}
    >
      <Box
        component="form"
        action="/"
        method="POST"
        onSubmit={(e: any) => {
          e.preventDefault();
          handleAddUpdate();
        }}
        noValidate
      >
        <DialogTitle>
          <Text>
            {!isEditing ? (
              <>Add Availability V3</>
            ) : (
              <>Update Availability V3</>
            )}
          </Text>
          <IconButton
            aria-label="close"
            onClick={close}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 3 }}>
          {showMessage && (
            <SuccessMessage message={message} type={messageType} />
          )}
          <Grid
            item
            mb={3}
            direction={"row"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <StyledInput
              id="postcode"
              data-test-id="availability_postcode"
              name="postcode"
              label="Postcode"
              autoFill="undefined"
              required
              value={postcode}
              error={!isValidPostCode(postcode) || !postcodeValidated}
              errorText={
                !isValidPostCode(postcode)
                  ? INVALID_POSTCODE_ERROR_MESSAGE
                  : !postcodeValidated
                  ? INCORRECT_POSTAL_CODE
                  : EMPTY_STRING
              }
              onChange={(e: any) => {
                setPostcodeValidated(true);
                setPostcode(e.target.value);
              }}
              maxLength={255}
              endAdornment={
                <InputAdornment position="end">
                  <CopyToClipboardButton id="postcode" value={postcode} />
                </InputAdornment>
              }
            />
            <Box sx={{ width: "40%" }}>
              <StyledSelect
                id="availability-mht"
                data-test-id="availability-mht"
                label="MHT Association"
                value={mht}
                menuItems={mhtList}
                onChange={(event: any) => {
                  setMht(event.target.value);
                }}
                getKeyFromValue={true}
              />
            </Box>
          </Grid>
          {mht !== HYPHEN && (
            <Grid
              item
              mb={3}
              direction={"row"}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <StyledCheckBox
                id="availability-oncall"
                data-test-id="availability-oncall"
                label="On call"
                checked={oncall}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setOncall(event.target.checked);
                }}
              />

              {oncall && mht !== HYPHEN && rotaList.length > 0 && (
                <Box sx={{ width: "50%" }}>
                  <StyledSelect
                    id="availability-rota"
                    data-test-id="availability-rota"
                    label="Rota"
                    value={rota}
                    menuItems={rotaList}
                    onChange={(event: any) => {
                      setRota(event.target.value);
                    }}
                    getKeyFromValue={true}
                  />
                </Box>
              )}
            </Grid>
          )}
          <Grid item mb={3} sx={{}}>
            <StyledInput
              id="availability_note"
              data-test-id="availability_note"
              name="Availability Note"
              label="Notes"
              value={notes}
              multiline
              maxRows={10}
              minRows={2}
              onChange={(e: any) => {
                setNotes(e.target.value);
              }}
              maxLength={255}
            />
          </Grid>
          <Grid
            item
            mb={2}
            direction={"row"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box mr={2}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={{ ...engb, weekStart: 1 }}
              >
                <DesktopDatePicker
                  data-test-id="availability-start-date"
                  label="Start Date"
                  inputFormat={DATE_FORMAT_WITHOUT_TIME}
                  openTo="day"
                  views={["year", "month", "day"]}
                  value={startDate}
                  minDate={dayjs()}
                  maxDate={dayjs().add(12, "week")}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  onError={(reason) => {
                    if (reason === "invalidDate") {
                      setStartDateError(true);
                    } else {
                      setStartDateError(false);
                    }
                  }}
                  onAccept={() => {
                    setStartDateError(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="availability-start-date-input"
                      data-test-id="availability-start-date-input"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={startDateError || !startDate}
                      helperText={
                        startDateError || !startDate
                          ? INVALID_DATE
                          : EMPTY_STRING
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box mr={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  label="Start Time"
                  data-test-id="availability-start-time"
                  value={startTime}
                  onChange={(newValue) => {
                    setStartTime(newValue);
                  }}
                  onError={(reason) => {
                    if (reason === "invalidDate") {
                      setStartTimeError(true);
                    } else {
                      setStartTimeError(false);
                    }
                  }}
                  onAccept={() => {
                    setStartTimeError(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="availability-start-time-input"
                      data-test-id="availability-start-time-input"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={startTimeError || !startTime || !!timeError}
                      helperText={
                        timeError ||
                        (startTimeError || !startTime
                          ? INVALID_TIME
                          : EMPTY_STRING)
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          <Grid
            item
            mb={2}
            direction={"row"}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box mr={2}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={{ ...engb, weekStart: 1 }}
              >
                <DesktopDatePicker
                  data-test-id="availability-end-date"
                  label="End Date"
                  inputFormat={DATE_FORMAT_WITHOUT_TIME}
                  openTo="day"
                  views={["year", "month", "day"]}
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  minDate={startDate}
                  maxDate={startDate ? startDate.add(12, "week") : null}
                  onError={(reason) => {
                    if (reason === "invalidDate") {
                      setEndDateError(true);
                      setEndDateErrorText(INVALID_DATE);
                    } else if (reason === "minDate") {
                      setEndDateError(true);
                      setEndDateErrorText(END_DATE_BEFORE_START_DATE);
                    } else if (reason === "disablePast") {
                      setEndDateError(true);
                      setEndDateErrorText(END_DATE_IN_PAST);
                    } else {
                      setEndDateError(false);
                      setEndDateErrorText(EMPTY_STRING);
                    }
                  }}
                  onAccept={() => {
                    setEndDateError(false);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="availability-end-date-input"
                      data-test-id="availability-end-date-input"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!endDate || endDateError}
                      helperText={
                        !endDate ? PLEASE_ENTER_END_DATE : endDateErrorText
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
            <Box mr={2}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                  data-test-id="availability-end-time"
                  label={"End Time:"}
                  value={endTime}
                  onChange={(newValue) => {
                    setEndTime(newValue);
                  }}
                  onError={(reason) => {
                    switch (reason) {
                      case "invalidDate":
                        setEndTimeError(true);
                        setEndTimeErrorText(INVALID_TIME);
                        break;
                      case "maxTime":
                        setEndTimeError(true);
                        setEndTimeErrorText(END_TIME_NEED_TO_BE_WITHIN_A_DAY);
                        break;
                      case "minTime":
                        setEndTimeError(true);
                        setEndTimeErrorText(
                          END_TIME_SHOULD_AN_HOUR_AHEAD_START_TIME
                        );
                        break;
                      default:
                        setEndTimeError(false);
                        setEndTimeErrorText(EMPTY_STRING);
                        break;
                    }
                  }}
                  onAccept={() => {
                    setEndTimeError(false);
                    setEndTimeErrorText(EMPTY_STRING);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="availability-end-time-input"
                      data-test-id="availability-end-time-input"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={endTimeError || !endTime || !!timeError}
                      helperText={
                        timeError ||
                        (endTimeError || !endTime ? INVALID_TIME : EMPTY_STRING)
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
          {!isMultiDay && (
            <Grid
              item
              mb={2}
              direction={"row"}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "self-start",
              }}
            >
              <StyledCheckBox
                label="Repeat Event"
                id="availability-repeat"
                data-test-id="availability-repeat"
                checked={repeat}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const checked = event.target.checked;
                  setRepeat(checked);

                  if (!checked) {
                    if (!doctorAvailabilityObj) {
                      setRepeatType(RepeatWeeklyOccurrenceOption.Once);
                      setRepeatWeekdays([dayOfTheWeekFn(dayjs())]);
                      setRepeatWeeks(1);
                    } else {
                      setRepeatType(
                        constantState.current.repeatType ||
                          RepeatWeeklyOccurrenceOption.Once
                      );
                      setRepeatWeekdays(
                        constantState.current.repeatWeekdays || [
                          dayOfTheWeekFn(dayjs()),
                        ]
                      );
                      setRepeatWeeks(constantState.current.repeatWeeks);
                      setEndDate(
                        constantState.current.endDateTime
                          ? dayjs(constantState.current.endDateTime)
                          : null
                      );
                    }
                  }
                }}
              />
            </Grid>
          )}
          {repeat && (
            <>
              <Grid item mb={1}>
                <Text sx={{ fontWeight: "bold", fontSize: 18 }}>Repeat</Text>
                <Grid
                  item
                  mb={1}
                  mt={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "self-start",
                  }}
                >
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.MONDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.MONDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.MONDAY)}
                    label={DayOfTheWeek.MONDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.MONDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.TUESDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.TUESDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.TUESDAY)}
                    label={DayOfTheWeek.TUESDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.TUESDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.WEDNESDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.WEDNESDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.WEDNESDAY)}
                    label={DayOfTheWeek.WEDNESDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.WEDNESDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.THURSDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.THURSDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.THURSDAY)}
                    label={DayOfTheWeek.THURSDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.THURSDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.FRIDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.FRIDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.FRIDAY)}
                    label={DayOfTheWeek.FRIDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.FRIDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.SATURDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.SATURDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.SATURDAY)}
                    label={DayOfTheWeek.SATURDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.SATURDAY)
                    }
                  />
                  <StyledCheckBox
                    id={"availability".concat(DayOfTheWeek.SUNDAY)}
                    data-test-id={"availability".concat(DayOfTheWeek.SUNDAY)}
                    checked={repeatWeekdays.includes(DayOfTheWeek.SUNDAY)}
                    label={DayOfTheWeek.SUNDAY}
                    handleChange={(e: any) =>
                      handleRepeatWeekdays(e, DayOfTheWeek.SUNDAY)
                    }
                  />
                </Grid>
              </Grid>
              <Grid item mb={1}>
                <Grid
                  item
                  container
                  mb={1}
                  mt={1}
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  xs={12}
                  spacing={1}
                >
                  <Grid item xs={2}>
                    <Text>Repeat for</Text>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      id="availability-weeks"
                      data-test-id="availability-weeks"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        inputProps: {
                          min: 1,
                          max: 12,
                        },
                      }}
                      value={repeatWeeks}
                      onChange={handleRepeatWeeksChange}
                      error={repeatWeeksError}
                      helperText={repeatWeeksErrorText}
                    />
                  </Grid>
                  <Grid item xs>
                    <Text>week(s)</Text>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <DialogActions>
            <Button
              id="availability-clear"
              data-test-id="availability-clear"
              sx={{ marginRight: 1 }}
              disabled={false}
              variant="outlined"
              onClick={handleClear}
            >
              Clear
            </Button>
            <LoadingButton
              id="availability-submit"
              data-test-id="availability-submit"
              loading={
                (!isEditing ? createLoadingV3 : updateLoadingV3) ||
                postcodeLoading
              }
              sx={{ marginRight: 2 }}
              variant="contained"
              type="submit"
              disabled={isButtonDisabled}
            >
              {!isEditing ? (
                <>Add Availability V3</>
              ) : (
                <>Update Availability V3</>
              )}
            </LoadingButton>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default React.memo(AvailabilityItemPopupV3);
