import React from "react";

// Material UI
import { DatePicker } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { TextField, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  input: {
    width: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  inputDisabled: {
    width: "100%",
    backgroundColor: theme.palette.grey[100],
    borderRadius: ".5rem",
  },
  errorText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.error.dark,
    fontWeight: "bold",
  },
  helperText: {
    marginTop: ".5rem",
    marginLeft: "1rem",
    color: theme.palette.text.primary,
    fontWeight: "bold",
  },
  dateTime: {
    backgroundColor: theme.palette.common.white,
    borderRadius: ".5rem",
  },
  datePicker: {
    "& MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important",
    },
  },
}));

interface StyledDatepickerProps {
  testId?: string;
  disabled?: boolean;
  value: number | string | null;
  label: string;
  onChange: any;
  errorText?: string;
  error?: boolean | undefined;
}

const StyledDatePicker: React.FC<StyledDatepickerProps> = (
  { testId, disabled, value, label, onChange, error, errorText },
  { ...rest }
) => {
  const classes = useStyles();
  return (
    <div>
      <DatePicker
        label={label}
        value={value}
        inputFormat="DD-MM-YYYY"
        disabled={disabled}
        onChange={onChange}
        className={classes.datePicker}
        renderInput={(params: any) => (
          <TextField
            {...params}
            data-test-id={testId}
            className={classes.dateTime}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />

      {error && errorText && (
        <Typography variant="body1" className={classes.errorText} fontSize={12}>
          {errorText}
        </Typography>
      )}
    </div>
  );
};

export { StyledDatePicker };
