import { Dispatch, SetStateAction } from "react";
import { FormErrors } from "common/types/formErrors";
import _ from "lodash";

/**
 * resetInput
 * We want to reset the input `onChange` and we validate `onBlur` and upon submission
 * @param {string} errorKey - key of the input
 * @param {Dispatch<SetStateAction<FormErrors>>} setFormErrors setFormErrors
 */
export const resetInput = (
  errorKey: string,
  setFormErrors: Dispatch<SetStateAction<FormErrors>>
) => {
  setFormErrors(
    (prevState) => ({ ..._.omit(prevState, errorKey) } as FormErrors)
  );
};
