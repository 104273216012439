import React, { useEffect, useState } from "react";

// Hooks
import { useDebounce } from "use-debounce";

// Material UI Imports
import { Grid, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { GridActionsCellItem } from "@mui/x-data-grid";

// Custom Types
import {
  ListUserFilterField,
  ListUsersFilterInput,
  ListUsersUserType,
  User,
} from "@s12solutions/types";

// All Custom Queries
import {
  HYPHEN,
  MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH,
  PLACEHOLDER_SEARCH,
} from "common/constants/common";
import {
  LABEL_NAME,
  ID_NAME,
  LABEL_EMAIL,
  ID_EMAIL,
  LABEL_PHONE,
  ID_PHONE,
  ID,
  LABEL_USERNAME,
} from "common/constants/fields";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Search from "components/search";
import { useNavigate, useLocation } from "react-router-dom";
import { useAPI } from "hooks";

// Custom Types
type UserCallbackFunction = (user: User) => void;

interface UserSelectProps {
  userType?: ListUsersUserType;
  setUser?: React.Dispatch<React.SetStateAction<User | null>>;
  customActions?: {
    icon: JSX.Element;
    label: string;
    onClick: UserCallbackFunction;
  }[];
  customOnClickRowData?: UserCallbackFunction;
}

const UserSelect: React.FC<UserSelectProps> = ({
  userType,
  setUser,
  customActions,
  customOnClickRowData,
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [searchString, setSearchString] = useState<string>("");
  const [searchBy, setSearchBy] = useState<string>("name");

  const [debouncedSearchString] = useDebounce(
    searchString.trim().length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
      ? searchString.trim()
      : "",
    1000
  );

  // Queries
  const {
    trigger: getUsers,
    data: userData,
    loading: userLoading,
    error: userError,
  } = useAPI<User[], { filter: ListUsersFilterInput }>({
    method: "GET",
    fieldName: "listUsers",
    args: {
      filter: {
        searchString: debouncedSearchString,
        filterField: searchBy as ListUserFilterField,
        userType,
      },
    },
    manual: true,
  });

  const data = userData && !userLoading ? userData : [];
  const loading = userLoading;

  // Handle Search
  useEffect(() => {
    async function handleSearch() {
      getUsers();
    }
    if (debouncedSearchString.length > 2) {
      handleSearch();
    }
  }, [debouncedSearchString, getUsers]);

  useEffect(() => {
    if (location?.search.length > 0) {
      const strArray = decodeURIComponent(location?.search.substring(1)).split(
        "??"
      );
      setSearchString(strArray[1]);
      setSearchBy(strArray[2]);
      setTimeout(() => {
        getUsers();
      }, 300);
    }
  }, [getUsers, location?.search]);

  useEffect(() => {
    if (location?.search.length > 0) {
      setTimeout(() => {
        navigate(location.pathname);
      }, 10000);
      setUser?.(
        userData?.filter(
          (item) =>
            item.id ===
            decodeURIComponent(location?.search.substring(1)).split("??")[0]
        )[0] as User
      );
    }
  }, [userData, location?.search, location.pathname, setUser, navigate]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Search
          rowsPerPageOptions={[5, 10, 25, 100]}
          autoFocus={true}
          error={userError}
          loading={loading}
          searchString={searchString}
          handleSearch={setSearchString}
          searchLabel="Search User"
          headerData={[
            {
              field: "actions",
              type: "actions",
              width: 80,
              getActions: (params: any) => [
                ...(setUser
                  ? [
                      <GridActionsCellItem
                        icon={
                          <Tooltip title="Edit" placement="right">
                            <EditIcon color="primary" />
                          </Tooltip>
                        }
                        label="Edit"
                        onClick={() => {
                          setUser?.(params.row as User);
                        }}
                      />,
                      <GridActionsCellItem
                        icon={
                          <Tooltip title="Open in new tab" placement="right">
                            <OpenInNewIcon color="primary" />
                          </Tooltip>
                        }
                        label="New Tab"
                        onClick={() => {
                          const queryString = encodeURIComponent(params.row.id);
                          window.open(
                            window.location.href +
                              "?" +
                              queryString +
                              "??" +
                              debouncedSearchString +
                              "??" +
                              searchBy,
                            "_blank"
                          );
                        }}
                      />,
                    ]
                  : []),
                ...(customActions
                  ? customActions.map(({ icon: Icon, label, onClick }) => (
                      <GridActionsCellItem
                        icon={
                          <Tooltip title={label} placement="right">
                            {Icon}
                          </Tooltip>
                        }
                        label={label}
                        onClick={() => onClick(params.row as User)}
                      />
                    ))
                  : []),
              ],
            },
            {
              headerName: LABEL_USERNAME,
              field: ID,
              minWidth: 200,
              flex: 1,
            },
            {
              headerName: LABEL_NAME,
              field: ID_NAME,
              minWidth: 200,
              flex: 1,
            },
            {
              headerName: LABEL_EMAIL,
              field: ID_EMAIL,
              minWidth: 200,
              flex: 1,
            },
            {
              headerName: LABEL_PHONE,
              field: ID_PHONE,
              minWidth: 200,
              flex: 1,
              valueGetter: (params: any) => params.row.phone || HYPHEN,
            },
          ]}
          rowData={data}
          placeholder={PLACEHOLDER_SEARCH}
          onClickRowData={(params: any) => {
            if (customOnClickRowData) {
              customOnClickRowData(params as User);

              return;
            }

            if (setUser) {
              setUser(params as User);

              return;
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(UserSelect);
