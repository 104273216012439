import React from "react";

// Hooks

// Material UI Imports
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Divider, Grid } from "@mui/material";

// Custom Types
import Search from "components/search/Search";
import { LABEL_SEARCH_MHT, PLACEHOLDER_SEARCH } from "common/constants/common";
import { ID_NAME, LABEL_NAME } from "common/constants/fields";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { Organisation } from "@s12solutions/types";
import { useNavigate, useLocation } from "react-router-dom";
import AlphabetSearch from "components/alphabetSearch";

interface MHTSelectProps {
  tableError: any;
  tableLoading: boolean;
  searchString: string;
  tableData: Organisation[];
  allData: Organisation[];
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  setOrgId: React.Dispatch<React.SetStateAction<string | null>>;
}

const MHTSelectForm: React.FC<MHTSelectProps> = ({
  setOrgId,
  tableError,
  tableLoading,
  searchString,
  setSearchString,
  tableData,
  allData,
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
  }

  return (
    <Grid container spacing={3} mt={1}>
      <Grid item sm={12}>
        <Search
          rowsPerPageOptions={[5, 10, 25, 100]}
          autoFocus={true}
          error={tableError}
          loading={tableLoading}
          handleSearch={setSearchString}
          searchString={searchString}
          searchLabel={LABEL_SEARCH_MHT}
          headerData={[
            {
              field: "actions",
              type: "actions",
              width: 80,
              getActions: (params: any) => [
                <GridActionsCellItem
                  icon={<EditIcon color="primary" />}
                  label="Edit"
                  onClick={() => {
                    setOrgId(params.row.id);
                  }}
                />,
                <GridActionsCellItem
                  icon={<OpenInNewIcon color="primary" />}
                  label="New Tab"
                  onClick={() => {
                    const queryString = encodeURIComponent(params.row.id);
                    window.open(
                      window.location.href + "?" + queryString,
                      "_blank"
                    );
                  }}
                />,
              ],
            },
            {
              headerName: LABEL_NAME,
              field: ID_NAME,
              minWidth: 200,
              flex: 1,
            },
          ]}
          rowData={tableData}
          placeholder={PLACEHOLDER_SEARCH}
          onClickRowData={(event: any) => {
            setOrgId(event.id);
          }}
        />
      </Grid>
      {searchString.length < 3 && (
        <Grid item xs={12} mr={3}>
          <Divider />
          <AlphabetSearch
            header={"MHT List"}
            allData={allData}
            handleClick={(val: any, isNewWindow: boolean) => {
              if (isNewWindow) {
                const queryString = encodeURIComponent(val.id);
                window.open(window.location.href + "?" + queryString, "_blank");
              } else {
                setOrgId(val.id);
              }
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export { MHTSelectForm };
