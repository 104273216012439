export default [
  "General Practitioner",
  "Psychiatrist",
  "Forensic",
  "Children and Adolescent Mental Health Services",
  "Learning Disability / Intellectual Disability",
  "General Adult",
  "Older Adults",
  "Anorexia",
  "Acquired Brain Injury",
  "Addictions (Drug and Alcohol Misuse)",
  "Attention Deficit Hyperactivity Disorder",
  "Autism Spectrum Disorders (includes Autism and Asperger's)",
  "Children in Care",
  "Chronic Treatment Resistant Psychiatric Disorder",
  "Dementia",
  "Deprivation of Liberty Safeguards",
  "Early Intervention",
  "Eating Disorders",
  "Hearing Impaired",
  "Homelessness",
  "Huntington's Disease",
  "Perinatal",
  "Personality Disorders",
  "Veteran's Mental Health",
];
