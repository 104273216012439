import { Grid, Typography as Text, Card } from "@mui/material";
import React from "react";
import OrgUserForm from "../OrgUserForm";

const AddCCG: React.FC = () => {
  return (
    <Grid
      xs={12}
      m={1}
      p={2}
      component={Card}
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 1,
        minHeight: window.innerHeight - 85,
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Text variant="h1">Add Org User</Text>
          <OrgUserForm isEditing={false} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(AddCCG);
