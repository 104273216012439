import { INVALID_EMAIL_ERROR_MESSAGE, INVALID_PHONE_ERROR_MESSAGE } from "common/constants/messages";
import { isValidPhone } from "common/validators";
import { z } from "zod";


export const OrgUserFormSchema = z.object({
    name: z.string().min(1, "Name is required"),
    email: z
      .string()
      .min(1, "Email is required")
      .email(INVALID_EMAIL_ERROR_MESSAGE),
    phone: z
      .string()
      .refine(
        (phone) => isValidPhone(phone.trim(), true),
        INVALID_PHONE_ERROR_MESSAGE
      ),
    sso: z.string().min(1, "SSO is required"),
    mfa: z.boolean(),
  }).passthrough();