import React, { useState } from "react";
import { Grid, Box, Button, ButtonProps, IconButton } from "@mui/material";
import {
  DataGridPro,
  GridRenderCellParams,
  useGridApiContext,
  GridColumns,
  GridRowsProp,
  DataGridProProps,
  useGridSelector,
  gridFilteredDescendantCountLookupSelector,
} from "@mui/x-data-grid-pro";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import * as constants from "common/constants/theme";

export const isNavigationKey = (key: string) =>
  key === "Home" ||
  key === "End" ||
  key.indexOf("Arrow") === 0 ||
  key.indexOf("Page") === 0 ||
  key === " ";

interface StyledTreeDataGridProProps {
  groupName?: string;
  rows: GridRowsProp;
  columns: GridColumns;
  height?: any;
  width?: any;
  initialState?: any;
  disableSelectionOnClick?: boolean;
  checkboxSelection?: boolean;
  getRowId?: any;
  showFilteredDescendantCount?: boolean; // whether or not to display the count of each group
}

const StyledTreeDataGridPro: React.FC<StyledTreeDataGridProProps> = ({
  rows,
  columns,
  initialState,
  height,
  width = "100%",
  disableSelectionOnClick = true,
  checkboxSelection = false,
  getRowId,
  groupName = "Group",
  showFilteredDescendantCount,
  ...props
}) => {
  const CustomGridTreeDataGroupingCell = (props: GridRenderCellParams) => {
    const { id, field, rowNode } = props;

    const [arrow, setArrow] = useState(false);

    const apiRef = useGridApiContext();
    const filteredDescendantCountLookup = useGridSelector(
      apiRef,
      gridFilteredDescendantCountLookupSelector
    );
    const filteredDescendantCount = filteredDescendantCountLookup[rowNode.id] ?? 0;

    const handleKeyDown: ButtonProps["onKeyDown"] = (event) => {
      if (event.key === " ") {
        event.stopPropagation();
      }
      if (isNavigationKey(event.key) && !event.shiftKey) {
        apiRef.current.publishEvent("cellNavigationKeyDown", props, event);
      }
    };

    const handleClick: ButtonProps["onClick"] = (event) => {
      setArrow(!arrow);
      apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
      apiRef.current.setCellFocus(id, field);
      event.stopPropagation();
    };

    return (
      <Box sx={{ ml: rowNode.depth * 4 }}>
        <div>
          {filteredDescendantCount > 0 ? (
            <Button
              onClick={handleClick}
              onKeyDown={handleKeyDown}
              tabIndex={-1}
              size="small"
            >
              {rowNode.groupingKey}{`${showFilteredDescendantCount ? ` (${filteredDescendantCount})` : ``}`}
              <IconButton>
                {arrow ? (
                  <KeyboardArrowRightOutlinedIcon />
                ) : (
                  <KeyboardArrowDownOutlinedIcon />
                )}
              </IconButton>
            </Button>
          ) : (
            <span />
          )}
          {!rowNode.children && rowNode.depth === 0 && (
            <Button tabIndex={-1} size="small">
              {rowNode.groupingKey}
            </Button>
          )}
        </div>
      </Box>
    );
  };

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) =>
    row.hierarchy;

  const groupingColDef: DataGridProProps["groupingColDef"] = {
    headerName: groupName,
    width: 160,
    renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
  };

  return (
    <Box sx={{ height: height, width: width }}>
      <DataGridPro
        {...props}
        treeData
        disableSelectionOnClick={disableSelectionOnClick}
        checkboxSelection={checkboxSelection}
        rows={rows}
        pageSize={10}
        rowsPerPageOptions={[10]}
        columns={columns}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={groupingColDef}
        initialState={initialState}
        getRowId={getRowId}
        defaultGroupingExpansionDepth={-1}
        autoHeight={true}
        density="compact"
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-row": {
            ":hover": {
              backgroundColor: constants.lightBaseGrey,
            },
          },
          "& .super-app-theme--even": {
            backgroundColor: constants.lightGrey,
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        getRowClassName={(params) =>
          `super-app-theme--${params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }`
        }
      />
    </Box>
  );
};

export { StyledTreeDataGridPro };
