import React, { useEffect, useState } from "react";

// Material UI Imports
import { Card, Grid, Typography as Text } from "@mui/material";

// Custom Imports

// Custom Types

import {
  ListUsersFilterInput,
  ListUsersHasFormGroupsResponse,
  User,
} from "@s12solutions/types";
import StatFormUser from "pages/StatForms";
import { SelectStatFormUser } from "./SelectStatFormUser";
import { useNavigate, useLocation } from "react-router-dom";
import {
  EVENTUAL_CONSISTENCY_TIMEOUT,
  MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH,
  TYPE_ERROR,
} from "common/constants/common";
import { UNEXPECTED_ERROR_MESSAGE } from "common/constants/messages";
import { useAPI, usePopups } from "hooks";
import { useDebounce } from "use-debounce";
import Progress from "components/cirularProgress/Progress";

const AssignedForms: React.FC = () => {
  const [userId, setUserId] = useState<string | null>(null);
  const [statUser, setStatUser] =
    useState<ListUsersHasFormGroupsResponse | null>(null);
  const [tableData, setTableData] = useState<User[]>([]);
  const navigate = useNavigate();
  const location: any = useLocation();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
  }
  const [searchString, setSearchString] = useState("");
  const { handleBannerMessage } = usePopups();
  const [pageLoading, setPageLoading] = useState(false);

  const [debouncedSearchString] = useDebounce(
    searchString.trim().length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
      ? searchString.trim()
      : "",
    1000
  );

  // Queries
  const {
    trigger: getStatUsers,
    data: listStatUsersData,
    loading: listStatUsersLoading,
    error: listStatUsersError,
  } = useAPI<
    User[] | null,
    {
      filter: ListUsersFilterInput;
    }
  >({
    method: "GET",
    fieldName: "listUsers",
    args: {
      filter: {
        searchString: debouncedSearchString,
        userType: "StatForms",
      },
    },
    manual: true,
  });

  const {
    trigger: getSingleStatUser,
    data: getStatUsersData,
    loading: getStatUsersLoading,
    error: getStatUsersError,
  } = useAPI<
    ListUsersHasFormGroupsResponse | null,
    {
      id: string;
    }
  >({
    method: "GET",
    fieldName: "getUserHasFormGroups",
    manual: true,
  });

  useEffect(() => {
    if (listStatUsersData && !listStatUsersLoading && !pageLoading) {
      setTableData(listStatUsersData);
    } else {
      setTableData([]);
    }
  }, [listStatUsersData, listStatUsersLoading, pageLoading]);

  // Handle Search
  useEffect(() => {
    function handleSearch() {
      getStatUsers();
    }
    if (
      debouncedSearchString.trim().length >
      MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
    ) {
      handleSearch();
    }
  }, [debouncedSearchString, getStatUsers]);

  useEffect(() => {
    if (listStatUsersError || getStatUsersError) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [listStatUsersError, getStatUsersError, handleBannerMessage]);

  useEffect(() => {
    if (location?.search.length > 0) {
      setTimeout(() => {
        navigate(location.pathname);
      }, 8000);
      setUserId(decodeURIComponent(location?.search.substring(1)));
    }
  }, [getSingleStatUser, location.pathname, location?.search, navigate]);

  useEffect(() => {
    if (userId) {
      getSingleStatUser({
        id: userId,
      });
    }
  }, [getSingleStatUser, userId]);

  useEffect(() => {
    if (getStatUsersData && !getStatUsersLoading && !getStatUsersError) {
      setStatUser(getStatUsersData);
    } else {
      setStatUser(null);
    }
  }, [getStatUsersData, setStatUser, getStatUsersLoading, getStatUsersError]);

  useEffect(() => {
    return () => {
      setUserId(null);
      setStatUser(null);
      setTableData([]);
    };
  }, []);

  return (
    <>
      <Progress loading={getStatUsersLoading}>
        <Grid
          xs={12}
          m={1}
          p={2}
          component={Card}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid item xs>
            <Text variant="h1">Update Stat Form User</Text>
            {!!statUser ? (
              <StatFormUser
                handleCancel={() => {
                  setPageLoading(true);
                  setUserId(null);
                  setStatUser(null);
                  navigate(location.pathname);
                  setTimeout(() => {
                    if (
                      debouncedSearchString.trim().length >
                      MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
                    ) {
                      getStatUsers();
                    }
                    setPageLoading(false);
                  }, EVENTUAL_CONSISTENCY_TIMEOUT);
                }}
                user={statUser}
              />
            ) : (
              <SelectStatFormUser
                setUserId={setUserId}
                searchString={searchString}
                setSearchString={setSearchString}
                tableData={tableData}
                tableError={listStatUsersError}
                tableLoading={listStatUsersLoading || pageLoading}
              />
            )}
          </Grid>
        </Grid>
      </Progress>
    </>
  );
};

export default React.memo(AssignedForms);
