import { LoadingButton } from "@mui/lab";
import { Grid, Card, Typography, Stack, FormControlLabel } from "@mui/material";
import { ID_POSTCODE, LABEL_POSTCODE } from "common/constants/fields";
import { StyledInput, StyledSelect } from "components/uiElements";
import { useCallback, useEffect, useState } from "react";
import { z } from "zod";
import { resetInput, validateFormData, validateInput } from "utils/zod";
import { FormErrors } from "common/types/formErrors";
import axios from "axios";
import urlJoin from "url-join";
import { isValidPostCode } from "common/validators";
import { INVALID_POSTCODE_ERROR_MESSAGE } from "common/constants/messages";
import CopyToClipboardButton from "components/clipBoard";
import React from "react";
import { useAPI } from "hooks/useAPI";
import { useAuth } from "hooks";

interface icbOrCCGResponseDataObject {
  icb_code: string;
  ccg_code: string;
  icb_name_en: string;
  organisation_name: string;
}

interface icbApiResponse {
  icb_code: string;
  icb_name: string;
}
interface ccgApiResponse {
  ccg_code: string;
  ccg_name: string;
}

const CCGAndICBLookup: React.FC = () => {
  const [postcode, setPostcode] = useState<string>("");
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [ccgLoading, setCCGLoading] = useState(false);
  const [selectedCCG, setSelectedCCG] = useState({ ccgName: "", ccgCode: "" });
  const [selectedICB, setSelectedICB] = useState({ icbName: "", icbCode: "" });
  const [icbDataFromCCGResult, setIcbDataFromCCGResult] = useState<
    any[] | null
  >(null);
  const [ccgDataFromICBResult, setCcgDataFromICBResult] = useState<
    any[] | null
  >(null);
  const [ccgResultState, setCCGresultState] = useState<any | null>(null);

  const readOnly = useAuth().user?.attributes["custom:limit_admin"];

  const CheckPostcodeSchema = z.object({
    postcode: z
      .string()
      .min(1, "Postcode is required")
      .refine(isValidPostCode, INVALID_POSTCODE_ERROR_MESSAGE),
  });

  // Get Request for all CCGs
  const {
    data: allCCGsData,
    loading: allCCGsLoading,
    error: allCCGsError,
  } = useAPI<ccgApiResponse[] | null>({
    method: "GET",
    fieldName: "getAllCCGs",
  });

  // Get Request for all ICBs
  const {
    data: allICBsData,
    loading: allICBsLoading,
    error: allICBsError,
  } = useAPI<icbApiResponse[] | null>({
    method: "GET",
    fieldName: "getAllICBs",
  });

  // Get Request to get ccg data from postcode
  const callCCGDataFromPostcodeEndpoint = useCallback(async (postcodeArg) => {
    setCCGLoading(true);
    try {
      const ccgResult = await axios.get<any | null>(
        urlJoin(process.env["REACT_APP_S12_API_EP"]!, "/ccgDataFromPostcode"),
        {
          method: "GET",
          params: {
            postcode: postcodeArg.replaceAll(/\s+/g, "").toUpperCase(),
          },
        }
      );
      setCCGLoading(false);
      setCCGresultState(ccgResult.data);
    } catch (error) {
      setCCGLoading(false);
      console.error("Error during CCG and ICB lookup:", error);
    }
  }, []);

  // Handler to call ccg data from postcode endpoint
  const checkGMC = useCallback(() => {
    validateFormData(
      CheckPostcodeSchema,
      { postcode: postcode },
      () => {
        callCCGDataFromPostcodeEndpoint(postcode);
      },
      setFormErrors
    );
  }, [CheckPostcodeSchema, callCCGDataFromPostcodeEndpoint, postcode]);

  // use effect to get ICB data from CCG code which is called when selectedCCG state is updated
  useEffect(() => {
    const fetchData = async () => {
      try {
        const icbResult = await axios.get<icbOrCCGResponseDataObject[] | null>(
          urlJoin(process.env["REACT_APP_S12_API_EP"]!, "/icbDataFromCCGCode"),
          {
            method: "GET",
            params: {
              ccgCode: selectedCCG.ccgCode,
            },
          }
        );
        setIcbDataFromCCGResult(icbResult.data);
      } catch (error) {
      }
    };

    if (selectedCCG && selectedCCG.ccgCode) {
      fetchData();
    }
  }, [selectedCCG]);

  // use effect to get CCG data from ICB code which is called when icbCode state is updated
  useEffect(() => {
    const fetchData = async () => {
      try {
        const ccgResult = await axios.get<icbOrCCGResponseDataObject[] | null>(
          urlJoin(process.env["REACT_APP_S12_API_EP"]!, "/ccgDataFromICBCode"),
          {
            method: "GET",
            params: {
              icbCode: selectedICB.icbCode,
            },
          }
        );
        setCcgDataFromICBResult(ccgResult.data);
      } catch (error) {
      }
    };

    if (selectedICB && selectedICB.icbCode) {
      fetchData();
    }
  }, [selectedICB]);

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        m={1}
        p={2}
        component={Card}
      >
        <Grid item xs={12} marginBottom={2}>
          <Typography variant="h1">CCG/ICB Lookup</Typography>
        </Grid>

        <Grid container direction="row" spacing={3}>
        {/* Left Content Panel - Postcode Lookup */}
        <Grid container spacing={3} item xs={6}>
          <Grid item xs={12} margin={1}>
            <Typography variant="h2">Postcode Lookup</Typography>
          </Grid>
          <Grid
            container
            xs={12}
            direction="row"
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            mt={1}
            spacing={3}
            marginLeft={1}
          >
            <Grid item xs={9}>
              <StyledInput
                id={ID_POSTCODE}
                label={LABEL_POSTCODE}
                value={postcode}
                onBlur={(event) => {
                  validateInput(
                    CheckPostcodeSchema,
                    "postcode",
                    { postcode: event.target.value },
                    setFormErrors
                  );
                }}
                onChange={(e) => {
                  setPostcode(e.target.value);
                  resetInput("postcode", setFormErrors);
                }}
                error={!!formErrors.postcode}
                errorText={formErrors.postcode}
                maxLength={255}
                required={true}
                autoFocus
                fullWidth={false}
              />
            </Grid>
            <Grid item xs={3} mt={1}>
              <LoadingButton
                id="CCG/ICBLookupButton"
                variant="contained"
                loading={ccgLoading}
                color="primary"
                size="large"
                onClick={checkGMC}
              >
                Search
              </LoadingButton>
            </Grid>
            <Grid item xs={12} ml={2}>
              <></>
            </Grid>
            <Grid item xs={12} ml={2}>
              <Stack direction="row">
                <FormControlLabel
                  tabIndex={-1}
                  control={<Typography variant="h3">ICB :&nbsp;</Typography>}
                  label={
                    !!ccgResultState && !!ccgResultState.icb && !ccgLoading
                      ? ccgResultState.icb
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />

                <CopyToClipboardButton
                  id="gmc"
                  value={
                    !!ccgResultState && !!ccgResultState.icb && !ccgLoading
                      ? ccgResultState.icb
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12} ml={2}>
              <Stack direction="row">
                <FormControlLabel
                  tabIndex={-1}
                  control={
                    <Typography variant="h3">ICB Code :&nbsp;</Typography>
                  }
                  label={
                    !!ccgResultState &&
                      !!ccgResultState.icbCode &&
                      !ccgLoading
                      ? ccgResultState.icbCode
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />
                <CopyToClipboardButton
                  id="gmc_expiry"
                  value={
                    !!ccgResultState &&
                      !!ccgResultState.icbCode &&
                      !ccgLoading
                      ? ccgResultState.icbCode
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12} ml={2}>
              <Stack direction="row">
                <FormControlLabel
                  tabIndex={-1}
                  control={<Typography variant="h3">CCG :&nbsp;</Typography>}
                  label={
                    !!ccgResultState && !!ccgResultState.ccg && !ccgLoading
                      ? ccgResultState.ccg
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />

                <CopyToClipboardButton
                  id="surname"
                  value={
                    !!ccgResultState && !!ccgResultState.ccg && !ccgLoading
                      ? ccgResultState.ccg
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12} ml={2}>
              <Stack direction="row">
                <FormControlLabel
                  tabIndex={-1}
                  control={
                    <Typography variant="h3">CCG Code :&nbsp;</Typography>
                  }
                  label={
                    !!ccgResultState && !!ccgResultState.ccgId && !ccgLoading
                      ? ccgResultState.ccgId
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />

                <CopyToClipboardButton
                  id="surname"
                  value={
                    !!ccgResultState && !!ccgResultState.ccgId && !ccgLoading
                      ? ccgResultState.ccgId
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12} ml={2}>
              <Stack direction="row">
                <FormControlLabel
                  tabIndex={-1}
                  control={
                    <Typography variant="h3">
                      Primary Care Trust :&nbsp;
                    </Typography>
                  }
                  label={
                    !!ccgResultState &&
                      !!ccgResultState.primaryCareTrust &&
                      !ccgLoading
                      ? ccgResultState.primaryCareTrust
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />

                <CopyToClipboardButton
                  id="surname"
                  value={
                    !!ccgResultState &&
                      !!ccgResultState.primaryCareTrust &&
                      !ccgLoading
                      ? ccgResultState.primaryCareTrust
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12} ml={2}>
              <Stack direction="row">
                <FormControlLabel
                  tabIndex={-1}
                  control={
                    <Typography variant="h3">
                      Police Authority :&nbsp;
                    </Typography>
                  }
                  label={
                    !!ccgResultState &&
                      !!ccgResultState.policeAuthority &&
                      !ccgLoading
                      ? ccgResultState.policeAuthority
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />

                <CopyToClipboardButton
                  id="surname"
                  value={
                    !!ccgResultState &&
                      !!ccgResultState.policeAuthority &&
                      !ccgLoading
                      ? ccgResultState.policeAuthority
                      : ccgLoading
                        ? `Loading...`
                        : `N/A`
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        {/* Right Content Panel - CG/ICB Relartionships */}
        <Grid
          container
          display="flex"
          flexDirection="column"
          item
          xs={6}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} margin={1}>
              <Typography variant="h2">CCG/ICB relationships</Typography>
            </Grid>
            <Grid item xs={12} margin={1} >
              {/* dropdown for ccgs */}
              <Grid item xs={12} marginBottom={3} marginTop={1}>
                <StyledSelect
                  id="CCGs"
                  value={selectedCCG.ccgName}
                  label="CCGs"
                  onChange={(event) => {
                    const ccgNameValue = event.target?.value as string;
                    const ccgCodeValue = allCCGsData?.find(
                      (ccg) => ccg.ccg_name === ccgNameValue
                    )?.ccg_code as string;
                    setSelectedCCG({
                      ccgName: ccgNameValue,
                      ccgCode: ccgCodeValue,
                    });
                    // Reset other dropdown so only one set of results are displayed at a time
                    setCcgDataFromICBResult(null);
                    setSelectedICB({ icbCode: "", icbName: "" });
                    resetInput("ccgName", setFormErrors);
                  }}
                  menuItems={
                    allCCGsLoading
                      ? ([
                        {
                          key: "##",
                          value: "Loading",
                        },
                      ] as { key: string; value: string }[])
                      : allCCGsError
                        ? ([
                          {
                            key: "##",
                            value: "Error occurred when loading site names",
                          },
                        ] as { key: string; value: string }[])
                        : (allCCGsData
                          ?.map((val) => {
                            return {
                              key: val.ccg_code,
                              value: val.ccg_name,
                            };
                          })
                          .sort((a, b) => a.value.localeCompare(b.value)) as {
                            key: string;
                            value: string;
                          }[])
                  }
                  error={!readOnly && !!formErrors?.ccgName}
                  errorText={formErrors?.ccgName}
                  disabled={readOnly}
                />
              </Grid>

              {/* dropdown for icbs */}
              <Grid item mt={1} xs={12} marginBottom={3}>
                <StyledSelect
                  id="ICBs"
                  value={selectedICB.icbName}
                  label="ICBs"
                  onChange={(event) => {
                    const icbNameValue = event.target?.value as string;
                    const icbCodeValue = allICBsData?.find(
                      (icb) => icb.icb_name === icbNameValue
                    )?.icb_code as string;
                    setSelectedICB({
                      icbName: icbNameValue,
                      icbCode: icbCodeValue,
                    });
                    setIcbDataFromCCGResult(null);
                    setSelectedCCG({ ccgCode: "", ccgName: "" });
                    resetInput("icbName", setFormErrors);
                  }}
                  onBlur={() => { }}
                  menuItems={
                    allICBsLoading
                      ? ([
                        {
                          key: "##",
                          value: "Loading",
                        },
                      ] as { key: string; value: string }[])
                      : allICBsError
                        ? ([
                          {
                            key: "##",
                            value: "Error occurred when loading site names",
                          },
                        ] as { key: string; value: string }[])
                        : (allICBsData
                          ?.map((val) => {
                            return {
                              key: val.icb_code,
                              value: val.icb_name,
                            };
                          })
                          .sort((a, b) => a.value.localeCompare(b.value)) as {
                            key: string;
                            value: string;
                          }[])
                  }
                  error={!readOnly && !!formErrors?.icbName}
                  errorText={formErrors?.icbName}
                  disabled={readOnly}
                />
              </Grid>
              {!!icbDataFromCCGResult && icbDataFromCCGResult.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h3" marginBottom={3}>
                    - ICB Name: {icbDataFromCCGResult[0]?.icb_name_en}{" "}
                  </Typography>
                  <Typography variant="h3">
                    - ICB Code: {icbDataFromCCGResult[0]?.icb_code}{" "}
                  </Typography>
                </Grid>
              )}
              {!!icbDataFromCCGResult &&
                icbDataFromCCGResult.length === 0 && (
                  <Grid item xs={12} >
                    <Typography variant="h3">
                      No ICB found for this CCG
                    </Typography>
                  </Grid>
                )}
              {!!ccgDataFromICBResult && ccgDataFromICBResult.length > 0 && (
                <Grid item xs={12} >
                  {ccgDataFromICBResult.map((ccg) => {
                    return (
                      <Typography variant="h3" marginBottom={3}>
                        - CCG Name: {ccg?.organisation_name}{" "}
                      </Typography>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default React.memo(CCGAndICBLookup);
