import React from "react";

// Hooks
import { usePopups } from "hooks";

// Material UI
import MuiSnackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { AlertColor } from "@mui/material";
import theme from "theme";

interface SnackbarProps {
  open: boolean;
  close: () => void;
  type?: AlertColor;
  message: string;
  duration?: number;
  disableCloseWhenClickAway?: boolean;
}

// Extracted for ease of testing
export const SnackbarContent: React.FC<SnackbarProps> = ({
  open,
  close,
  type,
  message,
  duration,
  disableCloseWhenClickAway = false,
}) => (
  <MuiSnackbar
    data-test-id="snackbar-content"
    open={open}
    autoHideDuration={
      duration && duration === -1 ? null : duration ? duration : 6000
    }
    onClose={(event, reason) => {
      if (reason && reason === "clickaway" && disableCloseWhenClickAway) return;
      else close();
    }}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
  >
    <Alert
      onClose={close}
      severity={type}
      elevation={6}
      variant="filled"
      sx={{
        width: window.innerWidth - 10,
        backgroundColor:
          type === "success" || type === "info"
            ? theme.palette.secondary.main
            : type === "warning"
            ? theme.palette.warning.main
            : theme.palette.error.main,
      }}
    >
      {message}
    </Alert>
  </MuiSnackbar>
);

const Snackbar: React.FC = () => {
  const {
    snackbar: { type, message, duration, disableCloseWhenClickAway },
    closeSnackbar,
  } = usePopups();
  const isOpen = type && message ? true : false;

  if (!isOpen) return <></>;

  return (
    <SnackbarContent
      open={isOpen}
      close={closeSnackbar}
      duration={duration}
      type={type}
      message={message}
      disableCloseWhenClickAway={disableCloseWhenClickAway}
    />
  );
};

export default React.memo(Snackbar);
