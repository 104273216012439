// Main entry point for the app
// Sets up state store and router parents
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license-pro";
import * as Sentry from "@sentry/react";

import "./index.css";
import App from "./App";
import { AuthProvider } from "store/auth/authProvider";

import * as serviceWorker from "./serviceWorker";
import "@fontsource/roboto";
import ErrorBoundary from "components/errorBoundary/ErrorBoundary";

// MUIX License
const muixProLicenseKey = process.env.REACT_APP_MUIX_PRO_LICENSE_KEY!;
LicenseInfo.setLicenseKey(muixProLicenseKey);

// Sentry error logging
if (process.env.REACT_APP_SENTRY_KEY) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["localhost", /^https:\/\/admin\.my\.s12solutions\.com/],
      }),
    ],
  });
}

// Title bar to environment
switch (process.env.NODE_ENV) {
  case "production":
    document.title = "S12 Admin";
    break;
  case "test":
    document.title = "S12 Admin - Preprod";
    break;
  default:
    document.title = "S12 Admin - Development";
    break;
}

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <AuthProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AuthProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
