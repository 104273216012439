import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { authService } from "store/auth/authService";

const ENV = process.env;

function stackMapToEndpoint(stackName: string) {
  if (stackName === "S12AppStack") return "REACT_APP_S12_API_EP";
  else if (stackName === "AdminPanelStack") return "REACT_APP_ADMIN_API_EP";
  else if (stackName === "AdminPanelV3Stack")
    return "REACT_APP_ADMIN_API_EP_V3";
  else {
    throw new Error(`$$$ Invalid stack name: ${stackName}`);
  }
}

function getOrigin() {
  return ENV["REACT_APP_ORIGIN"] || window.origin;
}

// For cors the request method needs to be set for each method
class API {
  private instance: AxiosInstance;

  async injectToken(config: AxiosRequestConfig) {
    try {
      const session = await authService.currentSession();
      const token = session
        ? `Bearer ${session.getIdToken().getJwtToken()}`
        : "";
      if (token) {
        config.headers ||= {};
        config.headers.Authorization = token;
      }
    } catch (err) {
    } finally {
      return config;
    }
  }

  constructor(stack: string) {
    const instance = axios.create({
      baseURL: ENV[stackMapToEndpoint(stack)],
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Access-Control-Allow-Origin": getOrigin(),
      },
    });

    // Add a request interceptor to inject token
    instance.interceptors.request.use(this.injectToken, (error) => {
      console.error("Error with interceptor ", error);
      Promise.reject(error);
    });

    this.instance = instance;
  }

  get<TData = any>(config?: AxiosRequestConfig): Promise<AxiosResponse<TData>> {
    return this.instance.get<TData, AxiosResponse<TData>>("", config);
  }

  post<TData = any, TFormData = any>(
    data?: TFormData,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TData>> {
    return this.instance.post<TData, AxiosResponse<TData>>("", data, config);
  }
  put<TData = any, TFormData = any>(
    data?: TFormData,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TData>> {
    return this.instance.put<TData, AxiosResponse<TData>>("", data, config);
  }
  delete<TData = any>(
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TData>> {
    return this.instance.delete<TData, AxiosResponse<TData>>("", config);
  }
}
export default API;
