import React, { useState, useEffect, useRef } from "react";
// Material UI Imports
import {
  Grid,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  ListItemButton,
  List,
  ListSubheader,
  IconButton,
  Stack,
} from "@mui/material";
import * as constants from "common/constants/theme";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  OrganisationProps,
  sortOrganisationsIgnoringStandardTitles,
} from "../../utils/validationHelpers";

interface SearhProps {
  allData?: any;
  header?: any;
  handleClick?: any;
}

const AlphabetSearch: React.FC<SearhProps> = ({
  allData = [],
  header,
  handleClick,
}) => {
  const [letterValue, setLetterValue] = useState<string | null>(null);
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const refs: any = {
    A: useRef<HTMLDivElement>(null),
    B: useRef<HTMLDivElement>(null),
    C: useRef<HTMLDivElement>(null),
    D: useRef<HTMLDivElement>(null),
    E: useRef<HTMLDivElement>(null),
    F: useRef<HTMLDivElement>(null),
    G: useRef<HTMLDivElement>(null),
    H: useRef<HTMLDivElement>(null),
    I: useRef<HTMLDivElement>(null),
    J: useRef<HTMLDivElement>(null),
    K: useRef<HTMLDivElement>(null),
    L: useRef<HTMLDivElement>(null),
    M: useRef<HTMLDivElement>(null),
    N: useRef<HTMLDivElement>(null),
    O: useRef<HTMLDivElement>(null),
    P: useRef<HTMLDivElement>(null),
    Q: useRef<HTMLDivElement>(null),
    R: useRef<HTMLDivElement>(null),
    S: useRef<HTMLDivElement>(null),
    T: useRef<HTMLDivElement>(null),
    U: useRef<HTMLDivElement>(null),
    V: useRef<HTMLDivElement>(null),
    W: useRef<HTMLDivElement>(null),
    X: useRef<HTMLDivElement>(null),
    Y: useRef<HTMLDivElement>(null),
    Z: useRef<HTMLDivElement>(null),
  };
  const [sortedOrganisations, setSortedOrganisations] = useState<{
    [key: string]: OrganisationProps[];
  }>({});
  const listRef = React.useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (allData.length > 0) {
      const organisations = allData
        .map((item: any) => {
          return { id: item.id, name: item.name };
        })
        .sort(sortOrganisationsIgnoringStandardTitles)
        .reduce((acc: any, OrgType: OrganisationProps) => {
          const strippedUnimportantStrings = OrgType.name
            .replace(/(NHS|CCG)/g, "")
            .trim();
          const firstLetter = strippedUnimportantStrings.split(" ")[0][0];
          const [group] = firstLetter;
          if (!acc[group]) {
            acc[group] = [OrgType];
          } else {
            acc[group].push(OrgType);
          }
          return acc;
        }, {});
      setSortedOrganisations(organisations);
    }
  }, [allData]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    setLetterValue(value);
    if (value === null) {
      scrollToRef(refs["A"], "A");
    } else {
      scrollToRef(refs[value], value);
    }
  };

  const scrollToRef = (ref: any, letter: string) => {
    listRef?.current?.scrollTo({
      top: ref.current.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Grid item xs={12}>
      <Grid item xs={12} mb={3} mt={3}>
        <Typography variant="h3">{header}</Typography>
      </Grid>
      <ToggleButtonGroup
        fullWidth={true}
        size="small"
        color="primary"
        value={letterValue}
        exclusive
        onChange={handleChange}
        aria-label="text alignment"
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(5px, 1fr))",
          marginBottom: 3,
        }}
      >
        {alphabet.map((letter) => {
          return (
            <ToggleButton
              value={letter}
              key={letter}
              aria-label="centered"
              disabled={!Object.keys(sortedOrganisations).includes(letter)}
              selected={letter === letterValue}
              onClick={() => {
                scrollToRef(refs[letter], letter);
              }}
              sx={{
                fontStyle: {
                  color: constants.primaryMain,
                  fontWeight: "bold",
                },
              }}
            >
              {letter}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>
      <Grid item xs={12}>
        <List
          sx={{
            width: "100%",
            position: "relative",
            overflow: "auto",
            maxHeight: window.innerHeight - 430,
            "& ul": { padding: 0 },
          }}
          subheader={<li />}
          ref={listRef}
        >
          {Object.entries(sortedOrganisations).map((key: any) => {
            return (
              <div key={key[0]} ref={refs[key[0]]}>
                <li key={key[0]}>
                  <ul>
                    <ListSubheader>
                      <Typography variant="h3">{key[0]}</Typography>
                    </ListSubheader>
                    {key[1].map((org: OrganisationProps, idx: number) => {
                      return (
                        <Stack direction={"row"} key={idx}>
                          <IconButton
                            sx={{ marginLeft: 0.5 }}
                            onClick={() => {
                              handleClick(
                                allData.filter(
                                  (item: any) => item.id === org.id
                                )[0],
                                true
                              );
                            }}
                          >
                            <OpenInNewIcon color="primary" />
                          </IconButton>
                          <ListItemButton
                            sx={{ paddingLeft: 0 }}
                            onClick={() => {
                              handleClick(
                                allData.filter(
                                  (item: any) => item.id === org.id
                                )[0],
                                false
                              );
                            }}
                          >
                            {org.name}
                          </ListItemButton>
                        </Stack>
                      );
                    })}
                  </ul>
                </li>
              </div>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

export default React.memo(AlphabetSearch);
