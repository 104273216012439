import React, { useEffect, useState } from "react";

// Material UI Imports
import { Card, Chip, Grid, Typography as Text } from "@mui/material";

// Custom Imports
import AMHPForm from "pages/AMHP";

// Custom Types
import { AmhpProfile, ListUsersFilterInput, User } from "@s12solutions/types";
import { AMHPSelectForm } from "../AMHPSelectForm";
import { useLocation, useNavigate } from "react-router-dom";
import { AMHP_TEAM_UPDATE } from "layout/routes/RouteConstants";
import { useDebounce } from "use-debounce";
import {
  EVENTUAL_CONSISTENCY_TIMEOUT,
  MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH,
  TYPE_ERROR,
} from "common/constants/common";
import { useAPI, usePopups } from "hooks";
import { UNEXPECTED_ERROR_MESSAGE } from "common/constants/messages";
import Progress from "components/cirularProgress/Progress";

const UpdateAMHP: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const locationState = location.state as { amhpID: any };

  const [amhpId, setAmhpId] = useState<string | null>(null);
  const [searchString, setSearchString] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [data, setData] = useState<User[]>([]);
  const [amhpUser, setAmhpUser] = useState<AmhpProfile | null>(null);
  const { handleBannerMessage } = usePopups();

  const [debouncedSearchString] = useDebounce(
    searchString.trim().length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
      ? searchString.trim()
      : "",
    1000
  );
  const {
    trigger: getAmhp,
    data: amhpData,
    loading: amhpLoading,
    error: amhpError,
  } = useAPI<
    AmhpProfile | null,
    {
      id: string;
    }
  >({
    method: "GET",
    fieldName: "getAmhpProfile",
    args: {
      id: amhpId || "",
    },
    manual: true,
  });

  // Queries
  const {
    trigger: listAmhps,
    data: listAmhpsData,
    loading: listAmhpsLoading,
    error: listAmhpsError,
  } = useAPI<
    User[] | null,
    {
      filter: ListUsersFilterInput;
    }
  >({
    method: "GET",
    fieldName: "listUsers",
    args: {
      filter: {
        searchString: debouncedSearchString,
        userType: "AMHP",
      },
    },
    manual: true,
  });

  useEffect(() => {
    if (amhpId) {
      getAmhp({
        id: amhpId,
      });
    }
  }, [amhpId, getAmhp]);

  // Handle Search
  useEffect(() => {
    if (
      debouncedSearchString.trim().length >
      MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
    ) {
      listAmhps();
    }
  }, [debouncedSearchString, listAmhps]);

  useEffect(() => {
    if (location?.search.length > 0) {
      setTimeout(() => {
        navigate(location.pathname);
      }, 8000);
      setAmhpId(decodeURIComponent(location?.search.substring(1)));
    }
  }, [location.pathname, location?.search, navigate, setAmhpId]);

  useEffect(() => {
    if (typeof locationState?.amhpID === "string") {
      setAmhpId(locationState?.amhpID);
    }
  }, [locationState?.amhpID, setAmhpId]);

  useEffect(() => {
    if (amhpError || listAmhpsError) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [amhpError, listAmhpsError, handleBannerMessage]);

  useEffect(() => {
    if (listAmhpsData && !listAmhpsLoading && !pageLoading && !listAmhpsError) {
      setData(listAmhpsData);
    } else {
      setData([]);
    }
  }, [listAmhpsData, listAmhpsError, listAmhpsLoading, pageLoading]);

  useEffect(() => {
    if (amhpData && !amhpLoading && !amhpError) {
      setAmhpUser(amhpData);
    } else {
      setAmhpUser(null);
    }
  }, [amhpData, amhpError, amhpLoading]);

  useEffect(() => {
    return () => {
      setAmhpId(null);
      setAmhpUser(null);
      setData([]);
    };
  }, []);

  return (
    <>
      <Progress loading={amhpLoading}>
        <Grid
          m={1}
          p={2}
          component={Card}
          container
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid item xs>
            <Grid display={"flex"}>
              <Text variant="h1">Update AMHP</Text>
              {!!amhpUser?.deleted && (
                <Chip
                  style={{ borderRadius: ".3rem", marginLeft: "10px" }}
                  label="Deleted"
                  color="error"
                />
              )}
            </Grid>
            {amhpUser ? (
              <AMHPForm
                handleCancel={() => {
                  if (locationState?.amhpID) {
                    navigate(AMHP_TEAM_UPDATE, {
                      state: {
                        isRefetch: true,
                      },
                    });
                  } else {
                    setPageLoading(true);
                    setAmhpId(null);
                    setAmhpUser(null);
                    navigate(location.pathname);
                    setTimeout(() => {
                      if (
                        debouncedSearchString.trim().length >
                        MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
                      ) {
                        listAmhps();
                      }
                      setPageLoading(false);
                    }, EVENTUAL_CONSISTENCY_TIMEOUT);
                  }
                }}
                user={amhpUser}
              />
            ) : (
              <AMHPSelectForm
                setAmhpId={setAmhpId}
                searchString={searchString}
                setSearchString={setSearchString}
                amhpsData={data}
                amhpsLoading={listAmhpsLoading || pageLoading}
                amhpsError={listAmhpsError}
              />
            )}
          </Grid>
        </Grid>
      </Progress>
    </>
  );
};

export default React.memo(UpdateAMHP);
