import React from "react";

// Hooks

// Material UI Imports
import EditIcon from "@mui/icons-material/Edit";
import { Grid } from "@mui/material";

// Custom Types
import { User } from "@s12solutions/types";
import Search from "components/search/Search";
import { PLACEHOLDER_SEARCH } from "common/constants/common";
import {
  ID_EMAIL,
  ID_NAME,
  LABEL_NAME,
  LABEL_EMAIL,
  ID_FORM_GROUPS,
  LABEL_FORM_GROUPS,
  LABEL_HOSPITAL_NAME,
  ID_HOSPITAL_NAME,
} from "common/constants/fields";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

// Custom Types

interface SelectStatUserProps {
  tableLoading: boolean;
  tableError: any;
  searchString: string;
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  tableData: User[];
  setUserId: React.Dispatch<React.SetStateAction<string | null>>;
}

const SelectStatFormUser: React.FC<SelectStatUserProps> = ({
  setUserId,
  tableData,
  searchString,
  setSearchString,
  tableError,
  tableLoading,
}) => {
  return (
    <Grid container spacing={3} mt={1}>
      <Grid item sm={12}>
        <Search
          rowsPerPageOptions={[5, 10, 25, 100]}
          autoFocus={true}
          error={tableError}
          loading={tableLoading}
          searchString={searchString}
          handleSearch={setSearchString}
          searchLabel="Search Stat Form User"
          headerData={[
            {
              field: "actions",
              type: "actions",
              width: 80,
              getActions: (params: any) => [
                <GridActionsCellItem
                  icon={<EditIcon color="primary" />}
                  label="Edit"
                  onClick={() => {
                    setUserId(params.row.id);
                  }}
                />,
                <GridActionsCellItem
                  icon={<OpenInNewIcon color="primary" />}
                  label="New Tab"
                  onClick={() => {
                    const queryString = encodeURIComponent(params.row.id);
                    window.open(
                      window.location.href + "?" + queryString,
                      "_blank"
                    );
                  }}
                />,
              ],
            },
            {
              headerName: LABEL_NAME,
              field: ID_NAME,
              minWidth: 200,
              flex: 1,
            },
            {
              headerName: LABEL_EMAIL,
              field: ID_EMAIL,
              minWidth: 200,
              flex: 1,
            },
            {
              headerName: LABEL_HOSPITAL_NAME,
              field: ID_HOSPITAL_NAME,
              minWidth: 180,
              flex: 1,
            },
            {
              headerName: LABEL_FORM_GROUPS,
              field: ID_FORM_GROUPS,
              minWidth: 200,
              flex: 1,
            },
          ]}
          rowData={tableData}
          placeholder={PLACEHOLDER_SEARCH}
          onClickRowData={(event: any) => {
            setUserId(event.id);
          }}
        />
      </Grid>
    </Grid>
  );
};

export { SelectStatFormUser };
