import React from "react";

// Hooks
// Material UI Imports
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Grid } from "@mui/material";

// Custom Types
import Search from "components/search/Search";
import { PLACEHOLDER_SEARCH } from "common/constants/common";
import { ID_FULL_TEAM_NAME, ID_NAME, LABEL_FULL_TEAM_NAME, LABEL_NAME } from "common/constants/fields";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { AmhpTeamResponseItem } from "@s12solutions/types";

import { useNavigate, useLocation } from "react-router-dom";
// Custom Types

interface AmhpTeamSelectProps {
  tableError: any;
  tableLoading: boolean;
  searchString: string;
  tableData: AmhpTeamResponseItem[];
  setSearchString: React.Dispatch<React.SetStateAction<string>>;
  setAmhpTeamId: React.Dispatch<React.SetStateAction<string | null>>;
}

const AMHPTeamSelectForm: React.FC<AmhpTeamSelectProps> = ({
  setAmhpTeamId,
  tableError,
  tableLoading,
  searchString,
  setSearchString,
  tableData,
}) => {
  const navigate = useNavigate();
  const location: any = useLocation();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
  }

  return (
    <Grid container spacing={3} mt={1}>
      <Grid item sm={12}>
        <Search
          rowsPerPageOptions={[5, 10, 25, 100]}
          autoFocus={true}
          error={tableError}
          loading={tableLoading}
          searchString={searchString}
          handleSearch={setSearchString}
          searchLabel="Search AMHP Teams"
          headerData={[
            {
              field: "actions",
              type: "actions",
              width: 80,
              getActions: (params: any) => [
                <GridActionsCellItem
                  icon={<EditIcon color="primary" />}
                  label="Edit"
                  onClick={() => {
                    setAmhpTeamId(params.row.id);
                  }}
                />,
                <GridActionsCellItem
                  icon={<OpenInNewIcon color="primary" />}
                  label="New Tab"
                  onClick={() => {
                    const queryString = encodeURIComponent(params.row.id);
                    window.open(
                      window.location.href + "?" + queryString,
                      "_blank"
                    );
                  }}
                />,
              ],
            },
            {
              headerName: LABEL_NAME,
              field: ID_NAME,
              minWidth: 200,
              flex: 1,
            },
            {
              headerName: LABEL_FULL_TEAM_NAME,
              field: ID_FULL_TEAM_NAME,
              minWidth: 200,
              flex: 1,
            }
          ]}
          rowData={tableData}
          placeholder={PLACEHOLDER_SEARCH}
          onClickRowData={(event: any) => {
            setAmhpTeamId(event.id);
          }}
        />
      </Grid>
    </Grid>
  );
};

export { AMHPTeamSelectForm };
