import React, { useCallback, useState, useEffect, useContext } from "react";
import { History, Transition } from "history";
import { Navigator } from "react-router";
import { UNSAFE_NavigationContext as NavigationContext } from "react-router-dom";
import { usePopups } from "hooks";
import { UNSAVED_WARNING } from "common/constants/messages";

type ExtendNavigator = Navigator & Pick<History, "block">;
const useBlocker = (blocker: (tx: Transition) => void, when = true) => {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;
    const unblock = (navigator as ExtendNavigator).block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
};

const usePrompt = (message: string, when = true) => {
  const { handleConfirmation } = usePopups();
  const blocker = useCallback(
    (tx: Transition) => {
      handleConfirmation(
        message,
        () => {
          tx.retry();
        },
        "Warning!"
      );
    },
    [handleConfirmation, message]
  );

  useBlocker(blocker, when);
};

const useUnsavedChangesWarning = (): [any, any, any] => {
  const [isDirty, setDirty] = useState(false);
  const routerPrompt = usePrompt(UNSAVED_WARNING, isDirty);
  return [routerPrompt, () => setDirty(true), () => setDirty(false)];
};

export default useUnsavedChangesWarning;
