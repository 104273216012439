import React, { useEffect, useMemo, useState } from "react";

// Material UI Imports
import { Card, Grid, Typography as Text } from "@mui/material";

// Custom Imports
import CCGForm from "../CCGForm";

// Custom Types
import {
  OrgType,
  Organisation,
  OrganisationFilterInput,
} from "@s12solutions/types";
import { CCGSelectForm } from "../CCGSelectForm";
import { useLocation, useNavigate } from "react-router-dom";
import {
  EVENTUAL_CONSISTENCY_TIMEOUT,
  MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH,
  TYPE_ERROR,
} from "common/constants/common";
import { UNEXPECTED_ERROR_MESSAGE } from "common/constants/messages";
import { useAPI, usePopups } from "hooks";
import { useDebounce } from "use-debounce";
import Progress from "components/cirularProgress/Progress";

const UpdateCCG: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [org, setOrg] = useState<Organisation | null>(null);
  const [orgId, setOrgId] = useState<string | null>(null);
  const [tableData, setTableData] = useState<Organisation[]>([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [searchString, setSearchString] = useState<string>("");
  const [orgType] = useState<OrgType>("CCG");
  const { handleBannerMessage } = usePopups();
  const [debouncedSearchString] = useDebounce(
    searchString.trim().length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
      ? searchString.trim()
      : "",
    1000
  );

  // Queries search string
  const {
    trigger: getOrgs,
    data: orgsData,
    loading: orgsLoading,
    error: orgsError,
  } = useAPI<
    Organisation[] | null,
    {
      filter: OrganisationFilterInput;
    }
  >({
    method: "GET",
    fieldName: "listOrganisations",
    args: {
      filter: {
        name: debouncedSearchString,
        type: orgType,
      },
    },
    manual: true,
  });
  // Queries for all data
  const {
    trigger: getAllOrgs,
    data: orgAllData,
    loading: orgAllLoading,
    error: orgAllError,
  } = useAPI<
    Organisation[] | null,
    {
      filter: OrganisationFilterInput;
    }
  >({
    method: "GET",
    fieldName: "listOrganisations",
    args: {
      filter: {
        type: orgType,
      },
    },
    manual: true,
  });

  //Query single data
  const {
    trigger: getOrg,
    data: orgData,
    loading: orgLoading,
    error: orgError,
  } = useAPI<
    Organisation[] | null,
    {
      filter: OrganisationFilterInput;
    }
  >({
    method: "GET",
    fieldName: "listOrganisations",
    manual: true,
  });

  useEffect(() => {
    if (orgsData && !orgsLoading && !pageLoading) {
      setTableData(orgsData);
    } else {
      setTableData([]);
    }
  }, [orgsLoading, orgsData, pageLoading]);

  const allData = useMemo(() => {
    return orgAllData && orgAllData ? orgAllData : [];
  }, [orgAllData]);

  // Handle Search
  useEffect(() => {
    function handleSearch() {
      getOrgs();
    }
    if (debouncedSearchString.length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH) {
      handleSearch();
    }
  }, [debouncedSearchString, getOrgs]);

  useEffect(() => {
    if (orgsError || orgAllError || orgError) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [orgsError, handleBannerMessage, orgAllError, orgError]);

  useEffect(() => {
    getAllOrgs();
  }, [getAllOrgs]);

  useEffect(() => {
    if (location?.search.length > 0) {
      setTimeout(() => {
        navigate(location.pathname);
      }, 8000);
      setOrgId(decodeURIComponent(location?.search.substring(1)));
    }
  }, [location.pathname, location?.search, navigate, setOrg]);

  useEffect(() => {
    if (!!orgId) {
      getOrg({
        filter: {
          type: orgType,
          id: orgId,
        },
      });
    }
  }, [getOrg, orgId, orgType]);

  useEffect(() => {
    if (orgData && !orgLoading && !orgError) {
      setOrg(orgData[0]);
    } else {
      setOrg(null);
    }
  }, [orgData, orgError, orgLoading]);

  useEffect(() => {
    return () => {
      setOrg(null);
      setOrgId(null);
      setTableData([]);
    };
  }, []);

  return (
    <>
      <Progress loading={orgLoading || orgAllLoading}>
        <Grid
          xs={12}
          m={1}
          p={2}
          component={Card}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Text variant="h1">Update CCG</Text>
              {!!org ? (
                <CCGForm
                  handleCancel={() => {
                    setOrg(null);
                    setOrgId(null);
                    navigate(location.pathname);
                    setPageLoading(true);
                    setTimeout(() => {
                      if (
                        debouncedSearchString.trim().length >
                        MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
                      ) {
                        getOrgs();
                      }
                      setPageLoading(false);
                    }, EVENTUAL_CONSISTENCY_TIMEOUT);
                  }}
                  org={org}
                  isEditing={true}
                />
              ) : (
                <CCGSelectForm
                  setOrgId={setOrgId}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  tableData={tableData}
                  tableLoading={orgsLoading || pageLoading}
                  tableError={orgsError}
                  allData={allData}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Progress>
    </>
  );
};

export default React.memo(UpdateCCG);
