import { Grid, CircularProgress } from "@mui/material";
import React from "react";

interface ProgressProps {
  loading: boolean;
  children: React.ReactNode;
}

const Progress: React.FC<ProgressProps> = ({ loading, children }) => {
  return (
    <>
      {loading ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "50vh" }}
        >
          <Grid item xs={3}>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        children
      )}
    </>
  );
};

export default Progress;
