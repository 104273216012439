import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useReducer,
} from "react";
import {
  Grid,
  Typography as Text,
  InputAdornment,
  IconButton,
  Chip,
  Button,
  Box,
  Collapse,
  Stack,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
} from "@mui/material";
import {
  StyledInput,
  StyledSelect,
  StyledSingleSelect,
  StyledTreeDataGridPro,
} from "components/uiElements";
import AddBoxIcon from "@mui/icons-material/AddBox";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from "@mui/icons-material/Close";
import Search from "components/search";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ProTable from "components/proTable";
import TeamUsers from "components/teamUsers";
import TeamFeatures from "components/teamFeatures";
import {
  PHONE_NUMBER_VALIDATION_REGULAR_EXPRESSION,
  convertMultipleEmailList,
} from "common/validators";
import AddIcon from "@mui/icons-material/Add";
import {
  EMPTY_SPACE,
  EMPTY_STRING,
  MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH,
  PLACEHOLDER_SEARCH,
} from "common/constants/common";
import {
  CONFIRM_DELETED,
  SUCCESS_EMAIL_ADDED,
  CONFIRM_CLEAR,
  CONFIRM_CANCEL,
  PHONE_NUMBER_FORMAT_HELPER_MESSAGE,
  SUCCESS_ADDRESS_ADDED,
  SUCCESS_RECIPIENT_ADDED,
} from "common/constants/messages";

//Custom hooks
import useUnsavedChangesWarning from "hooks/useUnsavedChangesWarning";
import { useAPI, useAuth, usePopups } from "hooks";
import { AMHPTeamFeatures } from "common/constants/options";
import {
  CreateAmhpTeamInput,
  EventResponse,
  AmhpTeamResponseItem,
  UpdateAmhpTeamInput,
  Site,
  OrganisationFilterInput,
  Organisation,
} from "@s12solutions/types";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import { makeStyles } from "@mui/styles";
import { useDebounce } from "use-debounce";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { useNavigate, useLocation } from "react-router";
import { AMHP_UPDATE } from "layout/routes/RouteConstants";
import SuccessMessage from "components/successMessage";
import { red, midBaseGrey } from "common/constants/theme";
import * as COMMON from "common/constants/common";
import * as MESSAGES from "common/constants/messages";
import { compact, isEqual, omit, uniqueId } from "lodash";
import {
  AMHPTeamFormSchema,
  AMHPTeamFormSchemaWithRefinements,
  ApprovedRecipientFormSchema,
  ApprovedRecipientsSchemaRefinement,
  CCGDetailsFormSchema,
  HospitalEmailsSchemaRefinement,
  MHAInboxSchemaRefinement,
  ManualAddressFormSchema,
} from "utils/zod/schemas/AMHPTeamFormSchema";
import { HospitalWardFormSchema } from "utils/zod/schemas/common.zod";
import { AMHPTeamState, AmhpTeamActionTypes } from "common/types/actions";
import { amhpTeamFormReducer } from "common/functions/reducers";
import { FormErrors } from "common/types/formErrors";
import { resetInput, validateFormData, validateInput } from "utils/zod";
import { validateInputForZodEffect } from "utils/zod/validateInput";
import { getFeatureFlagObjectAMHPTeam } from "utils/featureFlags";
import CopyToClipboardButton from "components/clipBoard";
import EventModalContent from "components/EventModal/EventModal";
import axios from "axios";
import urlJoin from "url-join";
interface UpdatedEventResponse extends EventResponse {
  locationName: {
    address?: string;
    addressNotes?: string | null;
    city?: string | null;
    postcode: string;
  };
}

interface ChipArray {
  key: string;
  label: string;
}

const useStyles = makeStyles((theme) => ({
  circle: {
    backgroundColor: midBaseGrey,
    width: 30,
    height: 30,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  textColor: {
    color: theme.palette.primary.contrastText,
  },
}));

interface AMHPTeamFormProps {
  handleCancel?: any;
  amhpTeam?: AmhpTeamResponseItem | null;
}

const AMHPTeamForm: React.FC<AMHPTeamFormProps> = ({
  handleCancel,
  amhpTeam,
}) => {
  const readOnly = useAuth().user?.attributes["custom:limit_admin"];

  const navigate = useNavigate();
  const location: any = useLocation();
  if (location?.state?.isSameMenu && handleCancel) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
    handleCancel();
  }

  let initialValues: AMHPTeamState = useMemo(() => {
    return {
      commonAddresses:
        amhpTeam && amhpTeam.commonAddresses
          ? compact(
              amhpTeam.commonAddresses.map((val) => {
                if (!val) {
                  return null;
                }
                return {
                  ...val,
                  itemText:
                    val?.locationName.address !== null
                      ? val?.locationName.address
                      : undefined,
                  ccgId: val?.ccgId ?? "",
                };
              })
            )
          : [],
      siteAmhpTeamsId: amhpTeam?.siteAmhpTeamsId || "",
      featureFlags: getFeatureFlagObjectAMHPTeam(amhpTeam?.featureFlags),
      name: amhpTeam?.name || "",
      fullTeamName: amhpTeam?.fullTeamName || "",
      hospitalEmails:
        amhpTeam?.hospitalEmails?.flatMap((val) => {
          return val.wards.map((v) => {
            return {
              hierarchy: [val.name, v.name],
              hospital: val.name,
              wardName: v.name,
              wardEmail: v.email,
              id: `${val.name}_${v?.name}_${v?.email}`,
            };
          });
        }) || [],
      localAuthority: amhpTeam?.localAuthority || "",
      mhaInboxInstructions: amhpTeam?.mhaInboxInstructions || "",
      emails:
        amhpTeam && amhpTeam.emails
          ? amhpTeam.emails.map((val) => {
              return {
                key: val,
                label: val,
              };
            })
          : [],
      mhaEmailAddresses:
        compact(
          amhpTeam?.mhaEmailAddresses?.flatMap((val) => {
            if (!val) {
              return null;
            }
            return compact(
              val?.recipients?.map((v) => {
                if (!v || !v.name || !v.email || !val || !val.name) {
                  return null;
                }
                return {
                  hierarchy: [val?.name, v?.email],
                  groupName: val?.name,
                  recipientName: v?.name,
                  recipientEmail: v?.email,
                  id: `${val.name}_${v?.name}_${v?.email}`,
                };
              })
            );
          })
        ) || [],
      phoneNumbers:
        amhpTeam && amhpTeam.phoneNumbers
          ? amhpTeam.phoneNumbers.map((val) => {
              return {
                key: val,
                label: val,
              };
            })
          : [],
      users: compact(amhpTeam?.users) || [],
    };
  }, [amhpTeam]);

  const constantState = useRef(initialValues);

  const [state, dispatch] = useReducer(
    amhpTeamFormReducer,
    constantState.current
  );

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [ccgDetailsFormErrors, setCCGDetailsFormErrors] = useState<FormErrors>(
    {}
  );

  const [manualAddressFormErrors, setManualAddressFormErrors] =
    useState<FormErrors>({});

  const [approvedRecipientFormErrors, setApprovedRecipientFormErrors] =
    useState<FormErrors>({});

  const [hospitalEmailFormErrors, setHospitalEmailFormErrors] =
    useState<FormErrors>({});

  const classes = useStyles();
  const { handleConfirmation, handleBannerMessage } = usePopups();
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [searchString, setSearchString] = useState("");
  const [showAudit, setAudit] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showCCGForm, setShowCCGForm] = useState(false);
  const [tempAddress, setTempAddress] = useState<UpdatedEventResponse | null>(
    null
  );
  const [debouncedSearchString] = useDebounce(
    searchString.trim().length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
      ? searchString.trim()
      : "",
    1000
  );

  const [phoneAdded, setPhoneAdded] = useState(false);
  const [emailAdded, setEmailAdded] = useState(false);
  const [teamPhoneNumbers, setTeamPhoneNumbers] = useState<string>("");
  const [teamEmails, setTeamEmails] = useState<string>("");
  const [addresses, setAddresses] = useState<string>("");
  const [fullAddresses, setFullAddresses] = useState({
    line1: "",
    line2: "",
    city: "",
    code: "",
  });
  const [ccgDetails, setCCGDetails] = useState<{
    ccgId: string;
    ccgName: string;
  }>({
    ccgId: "",
    ccgName: "",
  });
  const [hospitalEmailsData, setHospitalEmailsData] = useState({
    hospital: "",
    ward: "",
    email: "",
  });
  const [recipientData, setRecipientData] = useState({
    groupName: "",
    name: "",
    email: "",
  });
  const [recipientList, setRecipientList] = useState<string>();
  const [hospitalEmails, setHospitalEmails] = useState<string>();
  //data table
  const [open, setOpen] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openReci, setOpenReci] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [hospitalDialog, setHospitalDialog] = useState(false);
  const [approvedDialog, setApprovedDialog] = useState(false);
  const [addressTableData, setAddressTableData] = useState<EventResponse[]>([]);
  const [addressTableLoading, setAddressTableLoading] = useState(false);
  const [ccgLoading, setCCGLoading] = useState(false);

  //Tab panel
  const [value, setValue] = useState("1");

  // Queries for all data
  const {
    trigger: getAllOrgs,
    data: orgAllData,
    loading: orgAllLoading,
    error: orgAllError,
  } = useAPI<
    Organisation[] | null,
    {
      filter: OrganisationFilterInput;
    }
  >({
    method: "GET",
    fieldName: "listOrganisations",
    args: {
      filter: {
        type: "CCG",
      },
    },
    manual: true,
  });

  const {
    trigger: createAmhpTeam,
    loading: createLoading,
    error: createError,
  } = useAPI<AmhpTeamResponseItem | null, { input: CreateAmhpTeamInput }>({
    method: "POST",
    fieldName: "createAmhpTeam",
    manual: true,
  });

  const {
    trigger: updateAmhpTeam,
    loading: updateLoading,
    error: updateError,
  } = useAPI<AmhpTeamResponseItem | null, { input: UpdateAmhpTeamInput }>({
    method: "PUT",
    fieldName: "updateAmhpTeam",
    manual: true,
  });

  const {
    data: siteData,
    error: siteDataLoadError,
    loading: siteDataLoading,
  } = useAPI<Site[] | null>({
    method: "GET",
    fieldName: "listSites",
  });

  const {
    trigger: findAddresses,
    data: addressData,
    loading: addressLoading,
    error: addressError,
  } = useAPI<
    EventResponse[] | null,
    {
      findExactMatch?: boolean;
      term?: string;
      postcode?: string;
      addressId?: string;
    }
  >({
    method: "GET",
    fieldName: "addressLookup",
    manual: true,
  });

  const {
    trigger: getAddress,
    loading: foundAddressLoading,
    error: foundAddressError,
  } = useAPI<
    EventResponse[] | null,
    {
      findExactMatch?: boolean;
      term?: string;
      postcode?: string;
      addressId?: string;
    }
  >({
    method: "GET",
    fieldName: "addressLookup",
    manual: true,
  });

  const {
    trigger: getLocationDataForManualEntry,
    loading: getLocationDataLoading,
    error: getLocationDataError,
  } = useAPI<
    EventResponse | EventResponse[] | null,
    {
      findExactMatch?: boolean;
      term?: string;
      postcode?: string;
      addressId?: string;
    }
  >({
    method: "GET",
    fieldName: "addressLookup",
    manual: true,
  });

  const menuItems = useMemo(
    () =>
      siteData
        ? siteData
            .map((val) => {
              return { key: val.id, value: val.name };
            })
            .sort((a, b) => a.value.localeCompare(b.value))
        : [],
    [siteData]
  );

  const userNames = compact(
    constantState.current.users?.map((val) => {
      if (!val || !val.amhp) {
        return null;
      }
      return {
        name: val?.amhp.name,
        id: val?.amhp.id,
      };
    })
  ).sort((a, b) => a.name.localeCompare(b.name));

  const securityOptions = useMemo<
    {
      tooltip: string;
      value: AMHPTeamFeatures;
      label: string;
      infoIcon: boolean;
      checked?: boolean;
    }[]
  >(() => {
    return [
      {
        value: "REQUIRES_MFA",
        label: "Requires MFA",
        infoIcon: true,
        tooltip: "Enable multi-factor authentication for all members of this AMHP Team",
        checked: state.featureFlags.requiresMFA,
      },
      {
        value: "REQUIRES_SSO",
        label: "Requires SSO",
        infoIcon: true,
        tooltip: "Enable single sign-on for all members of this AMHP Team",
        checked: state.featureFlags.requiresSSO,
      },
    ];
  }, [state.featureFlags.requiresMFA, state.featureFlags.requiresSSO]);

  const teamFeatures = useMemo<
    {
      tooltip: string;
      value: AMHPTeamFeatures;
      label: string;
      infoIcon: boolean;
      checked?: boolean;
    }[]
  >(() => {
    return [
      {
        tooltip: "Requires the amhp to provide NHS # when creating a claim",
        value: "AMHP_PROVIDE_NHS_NUMBER",
        label: "PROVIDE NHS NUMBER",
        infoIcon: true,
        checked: state.featureFlags.amhpShouldProvideNhsNumber,
      },
      {
        value: "AMHP_TEAM_HAS_VIDEO",
        label: "HAS VIDEO",
        infoIcon: true,
        tooltip: "Enable video meetings for AMHP teams",
        checked: state.featureFlags.amhpTeamVideoMeetings,
      },
      {
        value: "AMHP_MHA_FORMS",
        label: "MHA FORMS",
        infoIcon: true,
        tooltip: "AMHP team uses MHA forms",
        checked: state.featureFlags.amhpTeamMHAForms,
      },
      {
        value: "AMHP_MHA_EXTERNAL_DOCTORS",
        label: "MHA EXTERNAL DOCTORS",
        infoIcon: true,
        tooltip: "AMHP team has external doctors",
        checked: state.featureFlags.amhpTeamMHAExternalDoctors,
      },
      {
        value: "AMHP_TEAM_HAS_HOSPITAL_EMAILS",
        label: "HAS HOSPITAL EMAILS",
        infoIcon: true,
        tooltip: "Show hospitals/wards for amhps to submit MHA forms to",
        checked: state.featureFlags.amhpTeamMhaHospitalEmails,
      },
      {
        value: "AMHP_TEAM_USES_JOINT_MED_RECS",
        label: "USES JOINT MED RECS",
        infoIcon: true,
        tooltip: "Enables joint medical recommendations",
        checked: state.featureFlags.amhpTeamUsesJointMedRecs,
      },
      {
        value: "AMHP_MHA_FORM_INBOX",
        label: "MHA FORM INBOX",
        infoIcon: true,
        tooltip: "AMHP team inbox for unattached mha forms",
        checked: state.featureFlags.amhpTeamInbox,
      },
      {
        value: "AMHP_TEAM_CAN_DOWNLOAD_FORMS",
        label: "CAN DOWNLOAD FORMS",
        infoIcon: true,
        tooltip: "AMHP team can download signed mha forms",
        checked: state.featureFlags.amhpTeamDownloadForms,
      },
      {
        value: "AMHP_TEAM_CAN_EMAIL_FORMS",
        label: "CAN EMAIL FORMS",
        infoIcon: true,
        tooltip: "AMHP team can email forms to themselves",
        checked: state.featureFlags.amhpTeamEmailFormsToSelf,
      },
      {
        value: "AMHP_TEAM_APPROVED_RECIPIENT_LIST",
        label: "APPROVED RECIPIENT LIST",
        infoIcon: true,
        tooltip: "AMHP team can send bulk emails to approved recipient lists",
        checked: state.featureFlags.amhpTeamEmailFormsToApprovedLists,
      },
      {
        value: "AMHP_TEAM_HAS_CONVEYANCE_FORMS",
        label: "HAS CONVEYANCE FORMS",
        infoIcon: true,
        tooltip: "Team uses blue conveyance forms",
        checked: state.featureFlags.amhpTeamUsesConveyanceForms,
      },
      {
        value: "DOC_INFORMAL_MED_RECS",
        label: "HAS STATEMENT OF REASON FORM",
        infoIcon: true,
        tooltip:
          'Assessments set up by team allow for informal "Statement of Reason" forms',
        checked: state.featureFlags.docInformalMedRecs,
      },
    ];
  }, [state.featureFlags]);

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const addPhoneChips = useCallback(() => {
    validateInput(
      AMHPTeamFormSchema,
      "phoneNumbers",
      {
        phoneNumbers: [
          ...state.phoneNumbers,
          {
            key: teamPhoneNumbers,
            label: teamPhoneNumbers,
          },
        ],
      },
      setFormErrors,
      () => {
        dispatch({
          type: AmhpTeamActionTypes.ADD_TEAM_PHONE_NUMBER,
          payload: {
            phoneNumbers: [
              {
                key: teamPhoneNumbers,
                label: teamPhoneNumbers,
              },
            ],
          },
        });
        setTeamPhoneNumbers("");
      },
      () => {
        setTimeout(() => {
          setFormErrors(
            (prevState) =>
              ({ ...omit(prevState, ["phoneNumbers"]) } as FormErrors)
          );
        }, 3000);
      }
    );
  }, [state.phoneNumbers, teamPhoneNumbers]);
  const deletePhoneChips = (chipToDelete: ChipArray) => () => {
    dispatch({
      type: AmhpTeamActionTypes.REMOVE_TEAM_PHONE_NUMBER,
      payload: {
        deleteId: chipToDelete.key,
      },
    });
  };
  const addEmailChips = useCallback(() => {
    validateInput(
      AMHPTeamFormSchema,
      "emails",
      {
        emails: [
          ...state.emails,
          {
            key: teamEmails,
            label: teamEmails,
          },
        ],
      },
      setFormErrors,
      () => {
        dispatch({
          type: AmhpTeamActionTypes.ADD_TEAM_EMAIL,
          payload: {
            emails: [
              {
                key: teamEmails,
                label: teamEmails,
              },
            ],
          },
        });
        setTeamEmails("");
      },
      () => {
        setTimeout(() => {
          setFormErrors(
            (prevState) => ({ ...omit(prevState, ["emails"]) } as FormErrors)
          );
        }, 3000);
      }
    );
  }, [state.emails, teamEmails]);

  const deleteEmailChips = (chipToDelete: ChipArray) => () => {
    dispatch({
      type: AmhpTeamActionTypes.REMOVE_TEAM_EMAIL,
      payload: {
        deleteId: chipToDelete.key,
      },
    });
  };

  // Common Addresses data table
  const commonAddressesColumns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      width: 20,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <IconButton
            sx={{ color: red }}
            onClick={() => {
              handleConfirmation(CONFIRM_DELETED, () => {
                dispatch({
                  type: AmhpTeamActionTypes.REMOVE_COMMON_ADDRESS,
                  payload: {
                    deleteId: params.id,
                  },
                });
              });
            }}
            disabled={readOnly}
          >
            <HighlightOffIcon />
          </IconButton>
        );
      },
    },
    {
      field: "addresses",
      headerName: "Address",
      minWidth: 50,
      flex: 10,
      disableReorder: true,
    },
  ];

  const validateCommonAddressAndSaveAddress = useCallback(
    async (result: UpdatedEventResponse) => {
      let updatedResult = result;
      if (!updatedResult.ccgId) {
        setCCGLoading(true);

        try {
          // Proper type formatting added for type safety of the API response
          type ccgResponse = {
            ccgId?: string;
            ccgName?: string;
          };
          const ccgResult = await axios.get<ccgResponse | ccgResponse[] | null>(
            urlJoin(
              process.env["REACT_APP_S12_API_EP"]!,
              "/ccgDataFromPostcode"
            ),
            {
              method: "GET",
              params: {
                postcode: updatedResult.locationName.postcode
                  .replaceAll(/\s+/g, "")
                  .toUpperCase(),
              },
            }
          );
          setCCGLoading(false);

          // `Array.isArray()` is added since we got both array and object responses for this API function `ccgDataFromPostcode`
          updatedResult = {
            ...updatedResult,
            ccgId: ccgResult.data
              ? Array.isArray(ccgResult.data)
                ? ccgResult.data[0].ccgId
                : ccgResult.data.ccgId
              : null,
            ccgName: ccgResult.data
              ? Array.isArray(ccgResult.data)
                ? ccgResult.data[0].ccgName
                : ccgResult.data.ccgName
              : null,
          };
        } catch {
          setCCGLoading(false);
        }
      }

      if (!updatedResult.ccgId) {
        setShowCCGForm(true);
        !orgAllData && getAllOrgs();
        setTempAddress(updatedResult);
        return;
      }
      validateInput(
        AMHPTeamFormSchema,
        "commonAddresses",
        {
          commonAddresses: [
            ...(state.commonAddresses ?? []),

            {
              ...updatedResult,
            },
          ],
        },
        setFormErrors,
        () => {
          if (!updatedResult.ccgId) {
            !orgAllData && getAllOrgs();
            setShowCCGForm(true);
            setTempAddress(updatedResult);
            return;
          }
          dispatch({
            type: AmhpTeamActionTypes.ADD_COMMON_ADDRESS,
            payload: {
              commonAddresses: [
                {
                  ...updatedResult,
                  ccgId: updatedResult.ccgId,
                },
              ],
            },
          });
          setShowMessage(true);
          handleCommonAddressFormClear();
        },
        () => {
          setShowErrorMessage(true);
        }
      );
    },
    [getAllOrgs, orgAllData, state.commonAddresses]
  );

  const saveAddress = useCallback(() => {
    validateFormData(
      ManualAddressFormSchema,
      fullAddresses,
      async () => {
        const finalAddresses =
          fullAddresses.line1 +
          (fullAddresses.line1.length > 0 ? ", " : "") +
          fullAddresses.line2;

        let eventResponse: UpdatedEventResponse = {
          location: {
            lat: 0,
            lon: 0,
          },
          ccgId: "",
          locationName: {
            address: finalAddresses,
            postcode: fullAddresses.code.trim().toUpperCase(),
            addressNotes: undefined,
            city: fullAddresses.city.trim().toUpperCase(),
          },
          itemText: finalAddresses,
          // Had to make the id to replace all spaces, commas and then converted to lowercase
          // to handle avoiding user entering same data with different case or format
          id: finalAddresses.trim().replaceAll(/[\s,]/g, "").toLowerCase(),
        };

        let result = await getLocationDataForManualEntry({
          postcode: fullAddresses.code.trim(),
        });

        if (
          result.error ||
          !result.data ||
          (result.data &&
            Array.isArray(result.data) &&
            result.data.length > 0 &&
            !result.data[0].location) ||
          (result.data &&
            Array.isArray(result.data) &&
            result.data.length > 0 &&
            result.data[0].location &&
            !result.data[0].location.lat) ||
          (result.data &&
            Array.isArray(result.data) &&
            result.data.length > 0 &&
            result.data[0].location &&
            !result.data[0].location.lon) ||
          (result.data &&
            !Array.isArray(result.data) &&
            !result.data.location) ||
          (result.data &&
            !Array.isArray(result.data) &&
            result.data.location &&
            !result.data.location.lat) ||
          (result.data &&
            !Array.isArray(result.data) &&
            result.data.location &&
            !result.data.location.lon)
        ) {
          setShowErrorMessage(true);
          return;
        }

        // Setting location (lat/lon) data as we should not set them to 0,0
        // and also ccgId to avoid using too many requests
        eventResponse = {
          ...eventResponse,
          location: Array.isArray(result.data)
            ? result.data[0].location
            : result.data.location,
          ccgId: Array.isArray(result.data)
            ? result.data[0].ccgId
            : result.data.ccgId,
          ccgName: result.data
            ? Array.isArray(result.data)
              ? result.data[0].ccgName
              : result.data.ccgName
            : null,
        };

        validateInput(
          AMHPTeamFormSchema,
          "commonAddresses",
          {
            commonAddresses: [
              ...(state.commonAddresses ?? []),

              {
                ...eventResponse,
              },
            ],
          },
          setFormErrors,
          () => {
            validateCommonAddressAndSaveAddress(eventResponse);
          },
          () => {
            setShowErrorMessage(true);
          }
        );
      },
      setManualAddressFormErrors
    );
  }, [
    fullAddresses,
    getLocationDataForManualEntry,
    state.commonAddresses,
    validateCommonAddressAndSaveAddress,
  ]);

  const proColumns = [
    {
      field: "wardName",
      headerName: "Ward Name",
      minWidth: 50,
      flex: 4,
      disableReorder: true,
    },
    {
      field: "wardEmail",
      headerName: "Ward Emails",
      minWidth: 50,
      flex: 6,
      disableReorder: true,
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      width: 20,
      flex: 1,
      renderCell: (params: any) => {
        if (params.rowNode.depth !== 0) {
          return (
            <IconButton
              sx={{ color: red }}
              onClick={() => {
                handleConfirmation(CONFIRM_DELETED, () => {
                  dispatch({
                    type: AmhpTeamActionTypes.REMOVE_HOSPITAL_EMAIL,
                    payload: {
                      deleteId: params.id,
                    },
                  });
                });
              }}
              disabled={readOnly}
            >
              <HighlightOffIcon />
            </IconButton>
          );
        } else return null;
      },
    },
  ];
  const addHospitalEmail = useCallback(() => {
    validateFormData(
      HospitalWardFormSchema,
      {
        hospitalName: hospitalEmailsData.hospital,
        wardName: hospitalEmailsData.ward,
        wardEmail: hospitalEmailsData.email,
      },
      () => {
        let data = {
          hierarchy: [hospitalEmailsData.hospital, hospitalEmailsData.ward],
          hospital: hospitalEmailsData.hospital,
          wardName: hospitalEmailsData.ward,
          wardEmail: convertMultipleEmailList(hospitalEmailsData.email),
          id: hospitalEmailsData.hospital + "_" + hospitalEmailsData.ward,
        };

        validateInput(
          AMHPTeamFormSchema,
          "hospitalEmails",
          {
            hospitalEmails: [...state.hospitalEmails, data],
          },
          setFormErrors,
          () => {
            setShowMessage(true);
            dispatch({
              type: AmhpTeamActionTypes.ADD_HOSPITAL_EMAIL,
              payload: {
                hospitalEmails: [data],
              },
            });

            setHospitalEmailsData((val) => {
              return {
                hospital: val.hospital,
                ward: "",
                email: "",
              };
            });
          },
          () => {
            setShowErrorMessage(true);
          }
        );
      },
      setHospitalEmailFormErrors
    );
  }, [hospitalEmailsData, state.hospitalEmails]);

  const recipientColumns = [
    {
      field: "recipientName",
      headerName: "Recipient Name",
      minWidth: 50,
      flex: 6,
      disableReorder: true,
    },
    {
      field: "recipientEmail",
      headerName: "Recipient Email",
      minWidth: 50,
      flex: 6,
      disableReorder: true,
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      width: 20,
      flex: 1,
      renderCell: (params: any) => {
        if (params.rowNode.depth !== 0) {
          return (
            <IconButton
              sx={{ color: red }}
              onClick={() => {
                handleConfirmation(CONFIRM_DELETED, () => {
                  dispatch({
                    type: AmhpTeamActionTypes.REMOVE_APPROVED_RECIPIENT,
                    payload: {
                      deleteId: params.id,
                    },
                  });
                });
              }}
              disabled={readOnly}
            >
              <HighlightOffIcon />
            </IconButton>
          );
        } else return null;
      },
    },
  ];
  const addRecipient = useCallback(() => {
    validateFormData(
      ApprovedRecipientFormSchema,
      {
        groupName: recipientData.groupName,
        recipientName: recipientData.name,
        recipientEmail: recipientData.email,
      },
      () => {
        let data = {
          hierarchy: [recipientData.groupName, recipientData.email],
          groupName: recipientData.groupName,
          recipientName: recipientData.name,
          recipientEmail: recipientData.email,
          id: `${recipientData.groupName}_${recipientData.name}_${recipientData.email}`,
        };

        validateInput(
          AMHPTeamFormSchema,
          "mhaEmailAddresses",
          {
            mhaEmailAddresses: [...state.mhaEmailAddresses, data],
          },
          setFormErrors,
          () => {
            setRecipientData((val) => {
              return {
                groupName: val.groupName,
                name: "",
                email: "",
              };
            });
            setShowMessage(true);
            dispatch({
              type: AmhpTeamActionTypes.ADD_APPROVED_RECIPIENT,
              payload: {
                mhaEmailAddresses: [data],
              },
            });
          },
          () => {
            setShowErrorMessage(true);
          }
        );
      },
      setApprovedRecipientFormErrors
    );
  }, [recipientData, state.mhaEmailAddresses]);
  // Handle Search
  useEffect(() => {
    if (debouncedSearchString.length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH) {
      findAddresses({
        term: debouncedSearchString,
        addressId: undefined,
      });
    }
  }, [debouncedSearchString, findAddresses]);

  //handle team feature
  const handleFeature = (event: any) => {
    switch (event.target.value as AMHPTeamFeatures) {
      case "AMHP_TEAM_HAS_HOSPITAL_EMAILS":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              amhpTeamMhaHospitalEmails: true,
            },
          },
        });
        break;
      case "AMHP_MHA_FORMS":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              amhpTeamMHAForms: true,
            },
          },
        });
        break;
      case "AMHP_MHA_FORM_INBOX":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              amhpTeamInbox: true,
            },
          },
        });
        break;
      case "AMHP_TEAM_APPROVED_RECIPIENT_LIST":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              amhpTeamEmailFormsToApprovedLists: true,
            },
          },
        });
        break;
      case "AMHP_MHA_EXTERNAL_DOCTORS":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              amhpTeamMHAExternalDoctors: true,
            },
          },
        });
        break;
      case "AMHP_TEAM_CAN_DOWNLOAD_FORMS":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              amhpTeamDownloadForms: true,
            },
          },
        });
        break;
      case "AMHP_PROVIDE_NHS_NUMBER":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              amhpShouldProvideNhsNumber: true,
            },
          },
        });
        break;
      case "AMHP_TEAM_CAN_EMAIL_FORMS":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              amhpTeamEmailFormsToSelf: true,
            },
          },
        });
        break;
      case "AMHP_TEAM_HAS_VIDEO":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              amhpTeamVideoMeetings: true,
            },
          },
        });
        break;
      case "AMHP_TEAM_HAS_CONVEYANCE_FORMS":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              amhpTeamUsesConveyanceForms: true,
            },
          },
        });
        break;
      case "REQUIRES_MFA":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              requiresMFA: true,
            },
          },
        });
        break;
      case "REQUIRES_SSO":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              requiresSSO: true,
            },
          },
        });
        break;
      case "AMHP_TEAM_USES_JOINT_MED_RECS":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              amhpTeamUsesJointMedRecs: true,
            },
          },
        });
        break;
      case "DOC_INFORMAL_MED_RECS":
        dispatch({
          type: AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS,
          payload: {
            featureFlags: {
              docInformalMedRecs: true,
            },
          },
        });
        break;
      default:
        break;
    }
  };

  // reset all data
  const resetData = useCallback(() => {
    dispatch({
      type: AmhpTeamActionTypes.RESET,
      payload: constantState.current,
    });
    setFormErrors({});
    setApprovedRecipientFormErrors({});
    setHospitalEmailFormErrors({});
    setManualAddressFormErrors({});
    setTeamEmails("");
    setTeamPhoneNumbers("");
    setRecipientData({
      groupName: "",
      name: "",
      email: "",
    });
    setHospitalEmailsData({
      hospital: "",
      ward: "",
      email: "",
    });
    handleCommonAddressFormClear();
  }, []);

  // Use Effects
  useEffect(() => {
    if (
      createError ||
      updateError ||
      addressError ||
      siteDataLoadError ||
      foundAddressError ||
      getLocationDataError
    ) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    addressError,
    createError,
    foundAddressError,
    getLocationDataError,
    handleBannerMessage,
    siteDataLoadError,
    updateError,
  ]);

  const convertTableDataToMutableData = useCallback(() => {
    let finalRecipientEmailList: {
      name: string;
      recipients: {
        name: string;
        email: string;
      }[];
    }[] = [];

    if (state.featureFlags.amhpTeamEmailFormsToApprovedLists) {
      state.mhaEmailAddresses.forEach((val) => {
        let arr = state.mhaEmailAddresses.filter(
          (v) => v.groupName === val.groupName
        );

        if (finalRecipientEmailList.some((v) => v.name === val.groupName)) {
          finalRecipientEmailList = finalRecipientEmailList.filter(
            (v) => v.name !== val.groupName
          );
        }
        finalRecipientEmailList.push({
          name: val.groupName,
          recipients: arr.map((val) => {
            return {
              name: val.recipientName,
              email: val.recipientEmail,
            };
          }),
        });
      });
    }

    let finalHospitalWardsEmailList: {
      name: string;
      wards: {
        name: string;
        email: string;
      }[];
    }[] = [];

    if (state.featureFlags.amhpTeamMhaHospitalEmails) {
      state.hospitalEmails.forEach((val) => {
        let arr = state.hospitalEmails.filter(
          (v) => v.hospital === val.hospital
        );

        if (finalHospitalWardsEmailList.some((v) => v.name === val.hospital)) {
          finalHospitalWardsEmailList = finalHospitalWardsEmailList.filter(
            (v) => v.name !== val.hospital
          );
        }
        finalHospitalWardsEmailList.push({
          name: val.hospital,
          wards: arr.map((val) => {
            return {
              name: val.wardName,
              email: val.wardEmail,
            };
          }),
        });
      });
    }
    return {
      mhaEmailAddresses: state.featureFlags.amhpTeamEmailFormsToApprovedLists
        ? finalRecipientEmailList
        : undefined,
      hospitalEmails: state.featureFlags.amhpTeamMhaHospitalEmails
        ? finalHospitalWardsEmailList
        : undefined,
    };
  }, [
    state.featureFlags.amhpTeamEmailFormsToApprovedLists,
    state.featureFlags.amhpTeamMhaHospitalEmails,
    state.hospitalEmails,
    state.mhaEmailAddresses,
  ]);

  const submitData = useCallback(() => {
    validateFormData(
      AMHPTeamFormSchemaWithRefinements,
      state,
      async () => {
        try {
          const { mhaEmailAddresses, hospitalEmails } =
            convertTableDataToMutableData();

          if (!!amhpTeam) {
            if (isEqual(state, constantState.current)) {
              handleBannerMessage(COMMON.TYPE_INFO, MESSAGES.NO_CHANGES);
              return;
            }
            let val = await updateAmhpTeam({
              input: {
                id: amhpTeam.id,
                commonAddresses: compact(
                  state.commonAddresses.map((val) => {
                    if (!val.locationName?.postcode || !val.location) {
                      return null;
                    }
                    return {
                      location: {
                        lat: val.location?.lat,
                        lon: val.location?.lon,
                      },
                      locationName: {
                        address: val.locationName?.address,
                        addressNotes: val.locationName?.addressNotes,
                        postcode: val.locationName?.postcode,
                        city: val.locationName?.city,
                      },
                      ccgId: val.ccgId,
                    };
                  })
                ),
                siteAmhpTeamsId: state.siteAmhpTeamsId,
                featureFlags: JSON.stringify(state.featureFlags),
                name: state.name,
                fullTeamName: state.fullTeamName,
                hospitalEmails: hospitalEmails,
                localAuthority: state.localAuthority,
                mhaInboxInstructions: state.featureFlags.amhpTeamInbox
                  ? state.mhaInboxInstructions
                  : undefined,
                emails: state.emails?.map((val) => val.label),
                mhaEmailAddresses: mhaEmailAddresses,
                phoneNumbers: state.phoneNumbers?.map((val) => val.label),
              },
            });

            if (val.data) {
              constantState.current = {
                ...constantState.current,
                ...state,
              };
              handleBannerMessage(
                COMMON.TYPE_SUCCESS,
                MESSAGES.UPDATED_SUCCESS.replace(
                  "{name}",
                  `AMHP Team ${val.data.name}`
                )
              );
              setPristine();
            } else {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          } else {
            const val = await createAmhpTeam({
              input: {
                commonAddresses: compact(
                  state.commonAddresses.map((val) => {
                    if (!val.locationName?.postcode || !val.location) {
                      return null;
                    }
                    return {
                      location: {
                        lat: val.location?.lat,
                        lon: val.location?.lon,
                      },
                      locationName: {
                        address: val.locationName?.address,
                        addressNotes: val.locationName?.addressNotes,
                        postcode: val.locationName?.postcode,
                        city: val.locationName?.city,
                      },
                      ccgId: val.ccgId,
                    };
                  })
                ),
                siteAmhpTeamsId: state.siteAmhpTeamsId,
                featureFlags: JSON.stringify(state.featureFlags),
                name: state.name,
                fullTeamName: state.fullTeamName,
                hospitalEmails: hospitalEmails,
                localAuthority: state.localAuthority,
                mhaInboxInstructions: state.featureFlags.amhpTeamInbox
                  ? state.mhaInboxInstructions
                  : undefined,
                emails: state.emails?.map((val) => val.label),
                mhaEmailAddresses: mhaEmailAddresses,
                phoneNumbers: state.phoneNumbers?.map((val) => val.label),
              },
            });

            if (val.data) {
              if (val.data.id === "alreadyExists") {
                handleBannerMessage(
                  COMMON.TYPE_ERROR,
                  MESSAGES.EXISTS_ERROR.replace("{name}", "Team")
                );
              } else {
                handleBannerMessage(
                  COMMON.TYPE_SUCCESS,
                  MESSAGES.ADDED_SUCCESS.replace(
                    "{name}",
                    `AMHP Team ${val.data.name}`
                  )
                );
                resetData();
              }
            } else {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          }
        } catch {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        }
      },
      setFormErrors
    );
  }, [
    convertTableDataToMutableData,
    amhpTeam,
    state,
    updateAmhpTeam,
    handleBannerMessage,
    setPristine,
    createAmhpTeam,
    resetData,
  ]);

  const updateCommonAddress = useCallback(
    (params: any) => {
      try {
        if (!params.isExpandable) {
          validateInput(
            AMHPTeamFormSchema,
            "commonAddresses",
            {
              commonAddresses: [
                ...(state.commonAddresses ?? []),

                {
                  id: params.id,
                  ccgId: "",
                },
              ],
            },
            setFormErrors,
            async () => {
              let result = await getAddress({
                term: undefined,
                addressId: params.id,
              });

              if (
                !result.data ||
                result.error ||
                !result.data[0].locationName ||
                !result.data[0].locationName.address
              ) {
                setShowErrorMessage(true);
                return;
              }

              let eventResponse: UpdatedEventResponse = {
                ...result.data[0],
                locationName: {
                  ...result.data[0].locationName,
                  address: result.data[0].locationName.address,
                },
              };

              validateCommonAddressAndSaveAddress(eventResponse);
            },
            () => {
              setShowErrorMessage(true);
            }
          );
        } else {
          findAddresses({
            term: undefined,
            addressId: params.id,
          });
        }
      } catch {
        setShowErrorMessage(true);
      }
    },
    [
      findAddresses,
      getAddress,
      state.commonAddresses,
      validateCommonAddressAndSaveAddress,
    ]
  );

  useEffect(() => {
    if (!foundAddressLoading && !addressLoading && !!addressData) {
      setAddressTableData(
        compact(
          addressData?.map((val) => {
            return {
              ...val,
              addresses: `${val.itemText?.replaceAll(",", ", ")}`,
            };
          })
        )
      );
      setAddressTableLoading(false);
    } else if (foundAddressLoading || addressLoading) {
      setAddressTableData([]);
      setAddressTableLoading(true);
    } else {
      setAddressTableData([]);
    }

    return () => {
      setAddressTableData([]);
    };
  }, [addressData, addressLoading, foundAddressLoading]);

  //check page whether edit or not
  useEffect(() => {
    if (isEqual(state, constantState.current)) {
      setPristine();
    } else {
      setDirty();
    }
  }, [setDirty, setPristine, state]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowMessage(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [showMessage]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowErrorMessage(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [showErrorMessage]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setEmailAdded(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [emailAdded]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setPhoneAdded(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [phoneAdded]);

  const callbackRef = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  useEffect(() => {
    if (!!initialValues) {
      constantState.current = initialValues;
    }
  }, [initialValues]);

  const handleCommonAddressFormClear = () => {
    setShowCCGForm(false);
    setTempAddress(null);
    setSearchString("");
    setFullAddresses({
      line1: "",
      line2: "",
      city: "",
      code: "",
    });
    setCCGDetails({ ccgId: "", ccgName: "" });
    setManualAddressFormErrors({});
    setCCGDetailsFormErrors({});
  };

  const handleCommonAddressFormClose = () => {
    handleCommonAddressFormClear();
    setValue("1");
    setDialog(false);
  };

  const handleHospitalEmailFormClose = () => {
    setHospitalEmailsData({
      hospital: "",
      ward: "",
      email: "",
    });
    setHospitalDialog(false);
    setHospitalEmailFormErrors({});
  };

  const handleApprovedRecipientEmailFormClose = () => {
    setRecipientData({
      groupName: "",
      name: "",
      email: "",
    });
    setApprovedDialog(false);
    setApprovedRecipientFormErrors({});
  };

  return (
    <Grid container mt={3}>
      {Prompt}
      <Grid container spacing={2} ml={0} mt={0} xs={8}>
        <Grid container spacing={2} xs={11} sx={{ height: "fit-content" }}>
          {/* this dialog for add common addresses */}
          <Dialog
            open={dialog}
            maxWidth="md"
            fullWidth={false}
            PaperProps={{
              style: {
                borderRadius: 8,
                height: 655,
                width: 850,
              },
            }}
          >
            <DialogTitle>
              <Text>Add Common Addresses</Text>
              <IconButton
                aria-label="close"
                onClick={handleCommonAddressFormClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              {showMessage && (
                <SuccessMessage message={SUCCESS_ADDRESS_ADDED} />
              )}
              {showErrorMessage && (
                <SuccessMessage
                  message={
                    formErrors.commonAddresses ?? "Unexpected error occurred"
                  }
                  type="error"
                />
              )}
              {showCCGForm && tempAddress && (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      paddingBottom: 3,
                      paddingTop: 1,
                      paddingLeft: 3,
                      paddingRight: 3,
                    }}
                  >
                    <Text variant="h4">
                      CCG is required to complete adding the address
                    </Text>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      paddingBottom: 3,
                      paddingTop: 1,
                      paddingLeft: 3,
                      paddingRight: 3,
                    }}
                  >
                    <StyledSingleSelect
                      disabled={readOnly}
                      id="ccg"
                      options={
                        orgAllLoading
                          ? [{ key: "##", value: "Loading" }]
                          : orgAllData
                          ? orgAllData.map((val) => ({
                              key: val.id,
                              value: val.name,
                            }))
                          : orgAllError
                          ? [
                              {
                                key: "##",
                                value: "Error loading ccg list",
                              },
                            ]
                          : [
                              {
                                key: "##",
                                value: "ccg list not available",
                              },
                            ]
                      }
                      loading={orgAllLoading}
                      handlingState={{
                        key: ccgDetails.ccgId,
                        value: ccgDetails.ccgName,
                      }}
                      error={!!ccgDetailsFormErrors.ccgId}
                      errorText={ccgDetailsFormErrors.ccgId}
                      onBlurHandlingFunction={(_event) => {
                        validateInput(
                          CCGDetailsFormSchema,
                          "ccgId",
                          {
                            ccgId: ccgDetails.ccgId,
                          },
                          setCCGDetailsFormErrors
                        );
                      }}
                      handlingFunction={(_event, value) => {
                        resetInput("ccgId", setCCGDetailsFormErrors);
                        setCCGDetails((val) => {
                          return {
                            ...val,
                            ccgId: value?.key ?? "",
                            ccgName: value?.value ?? "",
                          };
                        });
                      }}
                      label="CCG"
                      required={true}
                    />
                  </Grid>
                </>
              )}

              <TabContext value={value}>
                <TabList
                  onChange={handleChange}
                  aria-label="Add Common Addresses"
                >
                  <Tab value="1" label="Location Search" />
                  <Tab value="2" label="Manual Address" />
                </TabList>

                <TabPanel value="1">
                  <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
                    <Search
                      searchString={searchString}
                      handleSearch={setSearchString}
                      searchLabel="Search Location"
                      placeholder={PLACEHOLDER_SEARCH}
                      disabled={!!showCCGForm && !!tempAddress}
                      headerData={[
                        {
                          headerName: "Addresses",
                          field: "addresses",
                          minWidth: 200,
                          flex: 1,
                        },
                        {
                          field: "actions",
                          type: "actions",
                          width: 100,
                          getActions: (params: any) => [
                            <GridActionsCellItem
                              disabled={!!showCCGForm && !!tempAddress}
                              icon={
                                !!params.row.isExpandable ? (
                                  <KeyboardArrowRightIcon color="primary" />
                                ) : (
                                  <AddIcon color="primary" />
                                )
                              }
                              label="Add"
                              onClick={() => updateCommonAddress(params.row)}
                            />,
                          ],
                        },
                      ]}
                      loading={addressTableLoading || ccgLoading}
                      error={addressError}
                      rowData={addressTableData}
                      onClickRowData={updateCommonAddress}
                    />
                  </Grid>
                </TabPanel>
                <TabPanel value="2">
                  <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
                    <StyledInput
                      id="address_line_1"
                      name="Address Line 1"
                      label="Address Line 1"
                      value={fullAddresses.line1}
                      disabled={!!showCCGForm && !!tempAddress}
                      error={!!manualAddressFormErrors.line1}
                      errorText={manualAddressFormErrors.line1}
                      onBlur={(event) => {
                        validateInput(
                          ManualAddressFormSchema,
                          "line1",
                          {
                            line1: event.target.value,
                          },
                          setManualAddressFormErrors
                        );
                      }}
                      onChange={(e: any) => {
                        resetInput("line1", setManualAddressFormErrors);
                        setFullAddresses((val) => {
                          return {
                            ...val,
                            line1: e.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
                    <StyledInput
                      id="address_line_2"
                      name="Address Line 2"
                      label="Address Line 2"
                      disabled={!!showCCGForm && !!tempAddress}
                      error={!!manualAddressFormErrors.line2}
                      errorText={manualAddressFormErrors.line2}
                      onBlur={(event) => {
                        validateInput(
                          ManualAddressFormSchema,
                          "line2",
                          {
                            line2: event.target.value,
                          },
                          setManualAddressFormErrors
                        );
                      }}
                      value={fullAddresses.line2}
                      onChange={(e: any) => {
                        resetInput("line2", setManualAddressFormErrors);
                        setFullAddresses((val) => {
                          return {
                            ...val,
                            line2: e.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
                    <StyledInput
                      id="city"
                      name="City"
                      label="Town / City"
                      disabled={!!showCCGForm && !!tempAddress}
                      required
                      error={!!manualAddressFormErrors.city}
                      errorText={manualAddressFormErrors.city}
                      onBlur={(event) => {
                        validateInput(
                          ManualAddressFormSchema,
                          "city",
                          {
                            city: event.target.value,
                          },
                          setManualAddressFormErrors
                        );
                      }}
                      value={fullAddresses.city}
                      onChange={(e) => {
                        resetInput("city", setManualAddressFormErrors);
                        setFullAddresses((val) => {
                          return {
                            ...val,
                            city: e.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ paddingBottom: 1, paddingTop: 1 }}>
                    <StyledInput
                      id="postcode"
                      name="Postcode"
                      label="Postcode"
                      disabled={!!showCCGForm && !!tempAddress}
                      value={fullAddresses.code}
                      required
                      error={!!manualAddressFormErrors.code}
                      errorText={manualAddressFormErrors.code}
                      onBlur={(event) => {
                        validateInput(
                          ManualAddressFormSchema,
                          "code",
                          {
                            code: event.target.value,
                          },
                          setManualAddressFormErrors
                        );
                      }}
                      maxLength={255}
                      onChange={(e: any) => {
                        resetInput("code", setManualAddressFormErrors);
                        setFullAddresses((val) => {
                          return {
                            ...val,
                            code: e.target.value,
                          };
                        });
                      }}
                    />
                  </Grid>
                </TabPanel>
              </TabContext>
            </DialogContent>
            {(value.includes("2") || (tempAddress && showCCGForm)) && (
              <DialogActions>
                <Button
                  sx={{ marginRight: 1 }}
                  disabled={false}
                  variant="outlined"
                  onClick={handleCommonAddressFormClose}
                >
                  Close
                </Button>
                <Button
                  sx={{ marginRight: 1 }}
                  disabled={false}
                  variant="outlined"
                  onClick={handleCommonAddressFormClear}
                >
                  Clear
                </Button>
                <LoadingButton
                  sx={{ marginRight: 2 }}
                  variant="contained"
                  loading={ccgLoading || getLocationDataLoading}
                  onClick={() => {
                    if (tempAddress && showCCGForm) {
                      validateFormData(
                        CCGDetailsFormSchema,
                        {
                          ccgId: ccgDetails.ccgId,
                        },
                        () => {
                          validateCommonAddressAndSaveAddress({
                            ...tempAddress,
                            ccgId: ccgDetails.ccgId,
                          });
                        },
                        setCCGDetailsFormErrors
                      );
                    } else {
                      saveAddress();
                    }
                  }}
                >
                  {tempAddress && showCCGForm ? `Confirm Addition` : `Add`}
                </LoadingButton>
              </DialogActions>
            )}
          </Dialog>

          {/* this Dialog for add hospital emails  */}
          <Dialog
            open={hospitalDialog}
            maxWidth="sm"
            fullWidth={false}
            PaperProps={{
              style: {
                borderRadius: 8,
                width: 750,
              },
            }}
          >
            <DialogTitle>
              <Text>Add Hospital Email</Text>
              <IconButton
                aria-label="close"
                onClick={handleHospitalEmailFormClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ padding: 3 }}>
              {showMessage && <SuccessMessage message={SUCCESS_EMAIL_ADDED} />}
              {showErrorMessage && (
                <SuccessMessage
                  message={formErrors.hospitalEmails}
                  type="error"
                />
              )}
              <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
                <StyledInput
                  id="hospital_name"
                  name="Hospital Name"
                  label="Hospital Name"
                  value={hospitalEmailsData.hospital}
                  error={!!hospitalEmailFormErrors.hospitalName}
                  errorText={hospitalEmailFormErrors.hospitalName}
                  maxLength={255}
                  required
                  onBlur={(event) => {
                    validateInput(
                      HospitalWardFormSchema,
                      "hospitalName",
                      {
                        hospitalName: event.target.value,
                      },
                      setHospitalEmailFormErrors
                    );
                  }}
                  onChange={(e: any) => {
                    resetInput("hospitalName", setHospitalEmailFormErrors);
                    setHospitalEmailsData((val) => {
                      return {
                        ...val,
                        hospital: e.target.value,
                      };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
                <StyledInput
                  id="ward_name"
                  name="Ward Name"
                  label="Ward Name"
                  value={hospitalEmailsData.ward}
                  error={!!hospitalEmailFormErrors.wardName}
                  errorText={hospitalEmailFormErrors.wardName}
                  maxLength={255}
                  required
                  onBlur={(event) => {
                    validateInput(
                      HospitalWardFormSchema,
                      "wardName",
                      {
                        wardName: event.target.value,
                      },
                      setHospitalEmailFormErrors
                    );
                  }}
                  onChange={(e: any) => {
                    resetInput("wardName", setHospitalEmailFormErrors);
                    setHospitalEmailsData((val) => {
                      return {
                        ...val,
                        ward: e.target.value,
                      };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: 1, paddingTop: 1 }}>
                <StyledInput
                  id="ward_email"
                  name="Ward Email"
                  label="Ward Emails"
                  multiline={true}
                  value={hospitalEmailsData.email}
                  error={!!hospitalEmailFormErrors.wardEmail}
                  errorText={hospitalEmailFormErrors.wardEmail}
                  maxLength={255}
                  required
                  onBlur={(event) => {
                    validateInput(
                      HospitalWardFormSchema,
                      "wardEmail",
                      {
                        wardEmail: event.target.value,
                      },
                      setHospitalEmailFormErrors
                    );
                  }}
                  onChange={(e: any) => {
                    resetInput("wardEmail", setHospitalEmailFormErrors);
                    setHospitalEmailsData((val) => {
                      return {
                        ...val,
                        email: e.target.value,
                      };
                    });
                  }}
                />
              </Grid>
            </DialogContent>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <DialogActions>
                <Button
                  sx={{ marginRight: 1 }}
                  disabled={false}
                  variant="outlined"
                  onClick={handleHospitalEmailFormClose}
                >
                  Close
                </Button>
                <Button
                  sx={{ marginRight: 2 }}
                  variant="contained"
                  onClick={() => addHospitalEmail()}
                >
                  Add
                </Button>
              </DialogActions>
            </Box>
          </Dialog>

          {/* this dialog for add  approved recipient list*/}
          <Dialog
            open={approvedDialog}
            maxWidth="sm"
            fullWidth={false}
            PaperProps={{
              style: {
                borderRadius: 8,
                width: 750,
              },
            }}
          >
            <DialogTitle>
              <Text>Add Approved Recipient Group</Text>
              <IconButton
                aria-label="close"
                onClick={handleApprovedRecipientEmailFormClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ padding: 3 }}>
              {showMessage && (
                <SuccessMessage message={SUCCESS_RECIPIENT_ADDED} />
              )}
              {showErrorMessage && (
                <SuccessMessage
                  message={formErrors.mhaEmailAddresses}
                  type="error"
                />
              )}
              <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
                <StyledInput
                  id="group_name"
                  name="group-name"
                  label="Group Name"
                  value={recipientData.groupName}
                  required
                  error={!!approvedRecipientFormErrors.groupName}
                  errorText={approvedRecipientFormErrors.groupName}
                  maxLength={255}
                  onChange={(e: any) => {
                    resetInput("groupName", setApprovedRecipientFormErrors);
                    setRecipientData((val) => {
                      return {
                        ...val,
                        groupName: e.target.value,
                      };
                    });
                  }}
                  onBlur={(event) => {
                    validateInput(
                      ApprovedRecipientFormSchema,
                      "groupName",
                      {
                        groupName: event.target.value,
                      },
                      setApprovedRecipientFormErrors
                    );
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
                <StyledInput
                  id="recipient_name"
                  name="recipient-name"
                  label="Recipient Name"
                  value={recipientData.name}
                  required
                  error={!!approvedRecipientFormErrors.recipientName}
                  errorText={approvedRecipientFormErrors.recipientName}
                  maxLength={255}
                  onBlur={(event) => {
                    validateInput(
                      ApprovedRecipientFormSchema,
                      "recipientName",
                      {
                        recipientName: event.target.value,
                      },
                      setApprovedRecipientFormErrors
                    );
                  }}
                  onChange={(e: any) => {
                    resetInput("recipientName", setApprovedRecipientFormErrors);
                    setRecipientData((val) => {
                      return {
                        ...val,
                        name: e.target.value,
                      };
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ paddingBottom: 1, paddingTop: 1 }}>
                <StyledInput
                  id="recipient_email"
                  name="recipient-mail"
                  label="Recipient Email"
                  value={recipientData.email}
                  required
                  error={!!approvedRecipientFormErrors.recipientEmail}
                  errorText={approvedRecipientFormErrors.recipientEmail}
                  maxLength={255}
                  onBlur={(event) => {
                    validateInput(
                      ApprovedRecipientFormSchema,
                      "recipientEmail",
                      {
                        recipientEmail: event.target.value,
                      },
                      setApprovedRecipientFormErrors
                    );
                  }}
                  onChange={(e: any) => {
                    resetInput(
                      "recipientEmail",
                      setApprovedRecipientFormErrors
                    );
                    setRecipientData((val) => {
                      return {
                        ...val,
                        email: e.target.value,
                      };
                    });
                  }}
                />
              </Grid>
            </DialogContent>
            <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
              <DialogActions>
                <Button
                  sx={{ marginRight: 2 }}
                  disabled={false}
                  variant="outlined"
                  onClick={handleApprovedRecipientEmailFormClose}
                >
                  Close
                </Button>
                <Button
                  sx={{ marginRight: 2 }}
                  variant="contained"
                  onClick={() => addRecipient()}
                >
                  Add
                </Button>
              </DialogActions>
            </Box>
          </Dialog>

          {/* Main text field starting */}
          <Grid item spacing={3} mt={1} xs={12}>
            <StyledSelect
              disabled={readOnly}
              autoFocus={true}
              label="Site"
              value={state.siteAmhpTeamsId}
              required={true}
              menuItems={
                siteDataLoading
                  ? [{ key: "##", value: "Loading" }]
                  : siteDataLoadError
                  ? [
                      {
                        key: "##",
                        value: "Error loading state.siteAmhpTeamsId list",
                      },
                    ]
                  : menuItems
              }
              onBlur={(event) => {
                validateInput(
                  AMHPTeamFormSchema,
                  "siteAmhpTeamsId",
                  { siteAmhpTeamsId: event.target.value },
                  setFormErrors
                );
              }}
              error={!!formErrors.siteAmhpTeamsId}
              errorText={formErrors.siteAmhpTeamsId}
              onChange={(event: any) => {
                resetInput("siteAmhpTeamsId", setFormErrors);
                dispatch({
                  type: AmhpTeamActionTypes.SET_SITE,
                  payload: {
                    siteAmhpTeamsId: event.target.value,
                  },
                });
              }}
              getKeyFromValue={true}
            />
          </Grid>
          <Grid item mt={1} xs={12}>
            <StyledInput
              id="team_name"
              name="Team Name"
              label="Team Name"
              value={state.name}
              required
              error={!!formErrors.name}
              errorText={formErrors.name}
              onBlur={(event) => {
                validateInput(
                  AMHPTeamFormSchema,
                  "name",
                  { name: event.target.value },
                  setFormErrors
                );
              }}
              maxLength={255}
              onChange={(event: any) => {
                resetInput("name", setFormErrors);
                dispatch({
                  type: AmhpTeamActionTypes.SET_TEAM_NAME,
                  payload: {
                    name: event.target.value,
                  },
                });
              }}
              disabled={readOnly}
              endAdornment={
                amhpTeam && (
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id="team_name_copy"
                      value={state.name}
                    />
                  </InputAdornment>
                )
              }
            />
          </Grid>

          {/* This !!amhpTeam conditional can be removed in v3 and will work as expected */}
          {!!amhpTeam && (
          <Grid item mt={1} xs={12}>
            <StyledInput
              id="full_team_name"
              name="Full Team Name"
              label="Full Team Name"
              value={state.fullTeamName}
              error={!!formErrors.fullTeamName}
              errorText={formErrors.fullTeamName}
              onBlur={(event) => {
                validateInput(
                  AMHPTeamFormSchema,
                  "fullTeamName",
                  { fullTeamName: event.target.value },
                  setFormErrors
                );
              }}
              maxLength={255}
              onChange={(event: any) => {
                resetInput("fullTeamName", setFormErrors);
                dispatch({
                  type: AmhpTeamActionTypes.SET_FULL_TEAM_NAME,
                  payload: {
                    fullTeamName: event.target.value,
                  },
                });
              }}
              disabled={readOnly}
              endAdornment={
                amhpTeam && (
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id="full_team_name_copy"
                      value={state.fullTeamName||EMPTY_STRING}
                    />
                  </InputAdornment>
                )
              }
            />
          </Grid>
          )}
          <Grid item mt={1} xs={12}>
            <StyledInput
              id="local_authority"
              name="Local Authority"
              label="Local Authority"
              maxLength={255}
              value={state.localAuthority}
              onBlur={(event) => {
                validateInput(
                  AMHPTeamFormSchema,
                  "localAuthority",
                  { localAuthority: event.target.value },
                  setFormErrors
                );
              }}
              error={!!formErrors.localAuthority}
              errorText={formErrors.localAuthority}
              onChange={(event: any) => {
                resetInput("localAuthority", setFormErrors);
                dispatch({
                  type: AmhpTeamActionTypes.SET_LOCAL_AUTHORITY,
                  payload: {
                    localAuthority: event.target.value,
                  },
                });
              }}
              disabled={readOnly}
              endAdornment={
                amhpTeam && (
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id="local_authority_copy"
                      value={state.localAuthority}
                    />
                  </InputAdornment>
                )
              }
            />
          </Grid>

          {/* Common Addresses */}
          <Grid item mt={1} xs={12}>
            <StyledInput
              id="common_addresses"
              name="Common Addresses"
              label="Common Addresses"
              value={addresses}
              onChange={(_e: any) => {
                setAddresses("");
                setDialog(true);
              }}
              onClick={() => {
                setDialog(true);
              }}
              disabled={readOnly}
            />
            {state.commonAddresses && state.commonAddresses.length > 0 && (
              <>
                <Stack
                  direction="row"
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Box component="span" className={classes.circle}>
                    <Text className={classes.textColor}>
                      {state.commonAddresses.length}
                    </Text>
                  </Box>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? (
                      <KeyboardArrowUpIcon fontSize="large" />
                    ) : (
                      <KeyboardArrowDownIcon fontSize="large" />
                    )}
                  </IconButton>
                </Stack>

                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Grid xs={12}>
                    <ProTable
                      rows={compact(
                        state.commonAddresses.map((val) => {
                          if (val.locationName && val.locationName.city) {
                            return {
                              addresses: `${
                                !!val.locationName.address
                                  ? `${val.locationName.address}, `
                                  : ""
                              }${val.locationName.city.toUpperCase()}, ${
                                val.locationName.postcode.split(EMPTY_SPACE)[0]
                              }`,

                              id: val.id ?? uniqueId(),
                            };
                          } else if (val.itemText) {
                            return {
                              addresses: val.itemText,
                              id: val.id ?? uniqueId(),
                            };
                          } else {
                            return null;
                          }
                        })
                      )}
                      columns={commonAddressesColumns}
                    />
                  </Grid>
                </Collapse>
              </>
            )}
          </Grid>

          {/* Team Phone Numbers */}
          <Grid item mt={1} xs={12}>
            <StyledInput
              id="team_phone_numbers"
              name="Team Phone Numbers"
              label="Team Phone Numbers"
              value={teamPhoneNumbers}
              error={!!formErrors.phoneNumbers}
              errorText={formErrors.phoneNumbers}
              maxLength={255}
              helperText={PHONE_NUMBER_FORMAT_HELPER_MESSAGE}
              onChange={(e: any) => {
                setTeamPhoneNumbers(
                  e.target.value.replace(
                    PHONE_NUMBER_VALIDATION_REGULAR_EXPRESSION,
                    EMPTY_STRING
                  )
                );
              }}
              inputRef={phoneAdded ? callbackRef : () => {}}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    id="addPhone"
                    data-testId="addPhone"
                    edge="end"
                    color={
                      teamPhoneNumbers.length === 0 ? "default" : "primary"
                    }
                    onClick={() => {
                      addPhoneChips();
                      setPhoneAdded(true);
                    }}
                    disabled={readOnly}
                  >
                    <AddBoxIcon fontSize="large" />
                  </IconButton>
                </InputAdornment>
              }
              disabled={readOnly}
            />
            {state.phoneNumbers?.map((item: ChipArray) => {
              return (
                <Chip
                  key={item.key}
                  sx={{
                    marginTop: 1,
                    marginRight: 1,
                  }}
                  label={item.label}
                  variant="outlined"
                  onDelete={deletePhoneChips(item)}
                  disabled={readOnly}
                />
              );
            })}
          </Grid>

          {/* Team Email Addresses */}
          <Grid item mt={1} xs={12}>
            <StyledInput
              id="team_emails"
              name="Team Emails"
              label="Team Emails"
              value={teamEmails}
              error={!!formErrors.emails}
              errorText={formErrors.emails}
              onChange={(e: any) => {
                setTeamEmails(e.target.value);
              }}
              inputRef={emailAdded ? callbackRef : () => {}}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    id="addEmail"
                    data-testId="addEmail"
                    edge="end"
                    color={teamEmails.length === 0 ? "default" : "primary"}
                    onClick={() => {
                      addEmailChips();
                      setEmailAdded(true);
                    }}
                    disabled={readOnly}
                  >
                    <AddBoxIcon fontSize="large" />
                  </IconButton>
                </InputAdornment>
              }
              disabled={readOnly}
            />
            {state.emails?.map((item: ChipArray) => {
              return (
                <Chip
                  key={item.key}
                  sx={{
                    marginTop: 1,
                    marginRight: 1,
                  }}
                  label={item.label}
                  variant="outlined"
                  onDelete={deleteEmailChips(item)}
                  disabled={readOnly}
                />
              );
            })}
          </Grid>

          {/* Hospital Email List */}
          {state.featureFlags.amhpTeamMhaHospitalEmails && (
            <Grid item mt={1} xs={12}>
              <StyledInput
                id="amhp_team_has_hospital_emails"
                name="AMHP TEAM HAS HOSPITAL EMAILS"
                label="Hospital Emails"
                value={hospitalEmails}
                onBlur={(_event) => {
                  validateInputForZodEffect(
                    HospitalEmailsSchemaRefinement,
                    "hospitalEmails",
                    {
                      featureFlags: state.featureFlags,
                      hospitalEmails: state.hospitalEmails,
                    },
                    setFormErrors
                  );
                }}
                error={!!formErrors.hospitalEmails}
                errorText={formErrors.hospitalEmails}
                required
                onChange={(_e: any) => {
                  setHospitalEmails("");
                  resetInput("hospitalEmails", setFormErrors);
                  setHospitalDialog(true);
                }}
                onClick={() => {
                  setHospitalDialog(true);
                }}
                disabled={readOnly}
              />
              {state.hospitalEmails.length > 0 && (
                <>
                  <Stack
                    direction="row"
                    sx={{
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Box component="span" className={classes.circle}>
                      <Text className={classes.textColor}>
                        {state.hospitalEmails.length}
                      </Text>
                    </Box>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpenEmail(!openEmail)}
                    >
                      {openEmail ? (
                        <KeyboardArrowUpIcon fontSize="large" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="large" />
                      )}
                    </IconButton>
                  </Stack>
                  <Collapse in={openEmail} timeout="auto" unmountOnExit>
                    <StyledTreeDataGridPro
                      groupName="Hospital Name"
                      rows={state.hospitalEmails}
                      columns={proColumns}
                    />
                  </Collapse>
                </>
              )}
            </Grid>
          )}

          {/* Approved Recipient List */}
          {state.featureFlags.amhpTeamEmailFormsToApprovedLists && (
            <Grid item mt={1} xs={12}>
              <StyledInput
                id="amhp_team_approved_recipient_list"
                name="AMHP TEAM APPROVED RECIPIENT LIST"
                label="Approved Recipient List"
                value={recipientList}
                onBlur={(_event) => {
                  validateInputForZodEffect(
                    ApprovedRecipientsSchemaRefinement,
                    "mhaEmailAddresses",
                    {
                      featureFlags: state.featureFlags,
                      mhaEmailAddresses: state.mhaEmailAddresses,
                    },
                    setFormErrors
                  );
                }}
                error={!!formErrors.mhaEmailAddresses}
                errorText={formErrors.mhaEmailAddresses}
                onChange={(_e: any) => {
                  setRecipientList("");
                  resetInput("mhaEmailAddresses", setFormErrors);
                  setApprovedDialog(true);
                }}
                onClick={() => {
                  setApprovedDialog(true);
                }}
                required
                disabled={readOnly}
              />
              {state.mhaEmailAddresses.length > 0 && (
                <>
                  <Stack
                    direction="row"
                    sx={{
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Box component="span" className={classes.circle}>
                      <Text className={classes.textColor}>
                        {state.mhaEmailAddresses.length}
                      </Text>
                    </Box>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpenReci((val) => !val)}
                    >
                      {openReci ? (
                        <KeyboardArrowUpIcon fontSize="large" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="large" />
                      )}
                    </IconButton>
                  </Stack>
                  <Collapse in={openReci} timeout="auto" unmountOnExit>
                    <Grid xs={12}>
                      <StyledTreeDataGridPro
                        groupName="Group"
                        rows={state.mhaEmailAddresses}
                        columns={recipientColumns}
                      />
                    </Grid>
                  </Collapse>
                </>
              )}
            </Grid>
          )}

          {/* MHA Mailbox Instructions */}
          {state.featureFlags.amhpTeamInbox && (
            <Grid item mt={1} xs={12}>
              <StyledInput
                id="mha_inbox_instructions"
                name="MHA Inbox Instructions"
                label="MHA Inbox Instructions"
                value={state.mhaInboxInstructions}
                onBlur={(event) => {
                  validateInputForZodEffect(
                    MHAInboxSchemaRefinement,
                    "mhaInboxInstructions",
                    {
                      featureFlags: state.featureFlags,
                      mhaInboxInstructions: event.target.value,
                    },
                    setFormErrors
                  );
                }}
                error={!!formErrors.mhaInboxInstructions}
                errorText={formErrors.mhaInboxInstructions}
                required
                multiline
                maxRows={10}
                minRows={4}
                placeholder="Set instructions"
                onChange={(event: any) => {
                  resetInput("mhaInboxInstructions", setFormErrors);
                  dispatch({
                    type: AmhpTeamActionTypes.SET_MHA_INBOX_INSTRUCTION,
                    payload: {
                      mhaInboxInstructions: event.target.value,
                    },
                  });
                }}
                disabled={readOnly}
                endAdornment={
                  amhpTeam && (
                    <InputAdornment position="end">
                      <CopyToClipboardButton
                        id="mha_inbox_instructions_copy"
                        value={state.mhaInboxInstructions}
                      />
                    </InputAdornment>
                  )
                }
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* *RIGHT-HAND PANEL */}
      <Grid container spacing={2} mt={0} xs={4}>
        <Grid spacing={3} xs={11}>
          {amhpTeam?.name && (
            <Grid item mb={3}>
              <LoadingButton
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => setAudit(true)}
              >
                Audit
              </LoadingButton>
            </Grid>
          )}
          <TeamFeatures
            teamFeatures={securityOptions}
            header="Security Options"
            handleFeature={(event: any) => {
              handleFeature(event);
            }}
            disabled={readOnly}
          />
          <TeamFeatures
            teamFeatures={teamFeatures}
            header="Team Features"
            handleFeature={(event: any) => {
              handleFeature(event);
            }}
            disabled={readOnly}
          />
          {userNames.length > 0 && (
            <TeamUsers
              userNames={userNames}
              header="Team Users"
              handleUser={(i: any) => {
                navigate(AMHP_UPDATE, {
                  state: {
                    amhpID: i?.id,
                  },
                });
              }}
            />
          )}
        </Grid>
      </Grid>
      {/* ACTIONS */}
      <Grid item mt={3} xs={12}>
        <LoadingButton
          sx={{
            marginRight: 1,
          }}
          loading={!!amhpTeam ? updateLoading : createLoading}
          variant="contained"
          onClick={() => {
            submitData();
          }}
          disabled={readOnly}
        >
          {!!amhpTeam ? "Update AMHP Team" : "Add AMHP Team"}
        </LoadingButton>
        {!!amhpTeam ? (
          <Button
            sx={{
              marginLeft: 1,
            }}
            variant="outlined"
            onClick={() => {
              handleConfirmation(
                CONFIRM_CANCEL,
                () => {
                  handleCancel();
                },
                "Confirm"
              );
            }}
            disabled={readOnly}
          >
            Cancel
          </Button>
        ) : (
          <Button
            sx={{
              marginLeft: 1,
            }}
            variant="outlined"
            onClick={() => {
              handleConfirmation(
                CONFIRM_CLEAR,
                () => {
                  resetData();
                },
                "Warning!"
              );
            }}
            disabled={readOnly}
          >
            Clear
          </Button>
        )}
      </Grid>
      {amhpTeam?.name && (
        <Modal open={showAudit} onClose={() => setAudit(false)}>
          <EventModalContent
            filter={{
              fieldName: "getAuditLog",
              category: "amhpTeam",
              id: amhpTeam.id,
            }}
          />
        </Modal>
      )}
    </Grid>
  );
};

export default React.memo(AMHPTeamForm);
