import React, { useState } from "react";
// Material UI Imports
import {
  Grid,
  Typography as Text,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Box,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

interface TeamUsersProps {
  userNames: any;
  header: string;
  handleUser?: any;
  isLoading?: boolean;
}

const TeamUsers: React.FC<TeamUsersProps> = ({
  userNames,
  header,
  handleUser,
  isLoading = false,
}) => {
  return (
    <React.Fragment>
      <Grid item spacing={3} mt={2} mb={1} xs={12}>
        <Text variant="h2">{header}</Text>
      </Grid>
      <Grid item spacing={3} xs={12}>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <List
            sx={{
              position: "relative",
              overflow: "auto",
              maxHeight: window.innerHeight / 2.5,
            }}
          >
            {userNames.length === 0 ? (
              <Box sx={{ marginLeft: "6px" }}>
                <Text>No team users</Text>
              </Box>
            ) : (
              userNames?.map((item: any) => {
                return (
                  <ListItemButton
                    id={item.id}
                    sx={{ padding: 0.5, paddingLeft: 0 }}
                    onClick={() => {
                      //handle on item click
                      handleUser(item);
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: "28px" }}>
                      <PersonIcon color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                );
              })
            )}
          </List>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(TeamUsers);
