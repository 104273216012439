import React from "react";

// Material UI Imports
import { Card, Grid, Typography as Text } from "@mui/material";

// Custom Types
import StatFormUser from "pages/StatForms";

const AddStatUser: React.FC = () => {
  return (
    <Grid
      xs={12}
      m={1}
      p={2}
      component={Card}
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 1,
        minHeight: window.innerHeight - 85,
      }}
    >
      <Grid item xs>
        <Text variant="h1">Add Stat Form User</Text>
        <StatFormUser />
      </Grid>
    </Grid>
  );
};

export default React.memo(AddStatUser);
