import {
  INVALID_EMAIL_ERROR_MESSAGE,
  INVALID_PHONE_ERROR_MESSAGE,
} from "common/constants/messages";
import {
  isValidEmail,
  isValidMultipleEmail,
  isValidPhone,
} from "common/validators";
import { z } from "zod";

export const HospitalWardFormSchema = z.object({
  hospitalName: z.string().min(1, "Hospital name is required"),
  wardName: z.string().min(1, "Ward name is required"),
  wardEmail: z
    .string()
    .refine(
      (email) => isValidMultipleEmail(email),
      "Please enter valid emails with comma separated"
    ),
});

export const keyValueList = z.array(
  z.object({
    key: z.string(),
    value: z.string(),
  })
);

export const locationName = z
  .object({
    address: z.string().optional(),
    addressNotes: z.string().optional().nullable(),
    city: z.string().optional().nullable(),
    postcode: z.string().optional(),
  })
  .partial();

export const location = z.object({
  lat: z.number(),
  lon: z.number(),
});

export const CCGFormSchema = z.object({
  contactPhone: z
    .string()
    .refine((phone) => isValidPhone(phone, true), INVALID_PHONE_ERROR_MESSAGE)
    .nullable()
    .optional(),
  contactEmail: z
    .string()
    .refine((email) => isValidEmail(email, true), INVALID_EMAIL_ERROR_MESSAGE)
    .nullable()
    .optional(),
  invoiceEmail: z
    .string()
    .refine((email) => isValidEmail(email, true), INVALID_EMAIL_ERROR_MESSAGE)
    .nullable()
    .optional(),
  locationName: z
    .object({
      address: z.string(),
      addressNotes: z.string().optional().nullable(),
      city: z.string().optional().nullable(),
      postcode: z.string(),
    }),
});
