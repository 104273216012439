import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import { ListUsersFilterInput, User } from "@s12solutions/types";
import {
  HYPHEN,
  MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH,
  TYPE_ERROR,
} from "common/constants/common";
import {
  LABEL_NAME,
  LABEL_EMAIL,
  ID_EMAIL,
  LABEL_PHONE,
  ID_PHONE,
  ID_NAME,
  ID_PHONE2,
  LABEL_PHONE2,
} from "common/constants/fields";
import React, { useState, useEffect } from "react";
import { DoctorSelectForm } from "pages/Doctor/DoctorSelectForm";
import ScheduleIcon from "@mui/icons-material/Schedule";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LABEL_GMC } from "common/constants/fields";
import DoctorAvailabilitySelectFormV3 from "./DoctorAvailabilitySelectFormV3";
import { useNavigate, useLocation } from "react-router-dom";
import { ID_GMC } from "common/constants/fields";
import { useAPI, usePopups } from "hooks";
import { UNEXPECTED_ERROR_MESSAGE } from "common/constants/messages";
import { useDebounce } from "use-debounce";

const ManageDoctorAvailabilityV3: React.FC = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const { handleBannerMessage } = usePopups();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
  }
  const [doctorId, setDoctorId] = useState<string | null>(null);

  const [tableData, setTableData] = useState<User[]>([]);
  const [searchString, setSearchString] = useState("");

  const [debouncedSearchString] = useDebounce(
    searchString.trim().length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
      ? searchString.trim()
      : "",
    1000
  );

  // API Calls
  const {
    trigger: getS12Doctors,
    data: s12DoctorData,
    loading: s12DoctorLoading,
    error: s12DoctorError,
  } = useAPI<
    User[] | null,
    {
      filter: ListUsersFilterInput;
    }
  >({
    method: "GET",
    fieldName: "listUsers",
    args: {
      filter: {
        searchString: debouncedSearchString,
        userType: "Doctor",
      },
    },
  });

  useEffect(() => {
    if (s12DoctorData && !s12DoctorLoading) {
      setTableData(s12DoctorData);
    } else {
      setTableData([]);
    }

    return () => {
      setTableData([]);
    };
  }, [s12DoctorData, s12DoctorLoading]);

  // Handle Search
  useEffect(() => {
    function handleSearch() {
      getS12Doctors();
    }
    if (debouncedSearchString.length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH) {
      handleSearch();
    }
  }, [debouncedSearchString, getS12Doctors]);

  useEffect(() => {
    if (location?.search.length > 0) {
      setTimeout(() => {
        navigate(location.pathname);
      }, 10000);
      setDoctorId(decodeURIComponent(location?.search.substring(1)));
    }
  }, [location.pathname, location?.search, navigate]);

  useEffect(() => {
    if (s12DoctorError) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [handleBannerMessage, s12DoctorError]);

  return (
    <>
      {!!doctorId ? (
        <DoctorAvailabilitySelectFormV3
          doctorId={doctorId}
          handleCancel={() => {
            setDoctorId(null);
            navigate(location.pathname);
          }}
        />
      ) : (
        <DoctorSelectForm
          title="Manage Availability V3"
          setDoctorId={setDoctorId}
          searchString={searchString}
          setSearchString={setSearchString}
          tableData={tableData}
          tableLoading={s12DoctorLoading}
          tableError={s12DoctorError}
          headerData={[
            {
              field: "actions",
              type: "actions",
              width: 80,
              getActions: (params: any) => [
                <GridActionsCellItem
                  icon={<ScheduleIcon color="primary" />}
                  label="Schedule"
                  onClick={() => {
                    setDoctorId(params.row.id);
                  }}
                />,
                <GridActionsCellItem
                  icon={<OpenInNewIcon color="primary" />}
                  label="New Tab"
                  onClick={() => {
                    const queryString = encodeURIComponent(params.row.id);
                    window.open(
                      window.location.href + "?" + queryString,
                      "_blank"
                    );
                  }}
                />,
              ],
            },
            {
              headerName: LABEL_GMC,
              field: ID_GMC,
              minWidth: 200,
              flex: 1,
            },
            {
              headerName: LABEL_NAME,
              field: ID_NAME,
              minWidth: 200,
              flex: 1,
            },
            {
              headerName: LABEL_EMAIL,
              field: ID_EMAIL,
              minWidth: 200,
              flex: 1,
            },
            {
              headerName: LABEL_PHONE,
              field: ID_PHONE,
              minWidth: 200,
              flex: 1,
              valueGetter: (params: any) => params.row.phone || HYPHEN,
            },
            {
              headerName: LABEL_PHONE2,
              field: ID_PHONE2,
              minWidth: 200,
              flex: 1,
              valueGetter: (params: any) => params.row.phone2 || HYPHEN,
            },
          ]}
        />
      )}
    </>
  );
};

export default React.memo(ManageDoctorAvailabilityV3);
