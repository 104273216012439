/**
 * Helper functions
 * TODO - Add relevant descriptions and detail for each function below
 * TODO - Group or extract sets of functions into other helper files (such as RRule ones)
 */
import {
  Availability,
  AvailabilityType,
  DoctorAvailability,
  LocationName,
  Location,
} from "@s12solutions/types";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { RRule } from "rrule";
import RRuleParser from "./RRule";
import { DATE_FORMAT_WITHOUT_TIME } from "common/constants/common";
import { v4 as uuidv4 } from "uuid";
import { location, locationName } from "../../utils/zod/schemas/common.zod";
import { HYPHEN } from "common/constants/common";
import { DayOfTheWeek } from "common/constants/messages";
import weekOfYear from "dayjs/plugin/weekOfYear";

dayjs.extend(utc); // set dayjs to utc
dayjs.extend(weekOfYear);
type Weekday = "SU" | "MO" | "TU" | "WE" | "TH" | "FR" | "SA";

export const dayOfTheWeekFn = (date: Dayjs) => {
  switch (date.day()) {
    case 1:
      return DayOfTheWeek.MONDAY;
    case 2:
      return DayOfTheWeek.TUESDAY;
    case 3:
      return DayOfTheWeek.WEDNESDAY;
    case 4:
      return DayOfTheWeek.THURSDAY;
    case 5:
      return DayOfTheWeek.FRIDAY;
    case 6:
      return DayOfTheWeek.SATURDAY;
    default:
      return DayOfTheWeek.SUNDAY;
  }
};

// Took from here https://github.com/silverwind/clippie/blob/master/index.js
export async function clippie(data: any, { reject = false } = {}) {
  try {
    const contents = Array.isArray(data) ? data : [data];
    let numSuccess = 0;
    for (const content of contents) {
      try {
        await navigator.clipboard.writeText(String(content));
        numSuccess++;
      } catch {
        if (!document.execCommand) continue;
        const el = document.createElement("textarea");
        el.value = String(content);
        el.style.top = "0";
        el.style.left = "0";
        el.style.position = "fixed";
        el.style.clipPath = "inset(50%)";
        el.hidden = true;
        document.body.appendChild(el);
        try {
          el.select();
          const success = document.execCommand("copy");
          if (success) numSuccess++;
        } finally {
          document.body.removeChild(el);
        }
      }
    }
    return numSuccess === contents.length;
  } catch (err) {
    if (reject) throw err;
    return false;
  }
}

/**
 * To not return a key value pair for an object if the value is empty, so to remove the item
 * Used for Dynamo when we don't want { key: "" } ... but instead want they key removed
 * @param key The name of the key
 * @param value The value it should be, if empty we return nothing
 * @returns Either the completed object or null
 */
export const jsonNullify = (key: string, value: string | null) => {
  interface Obj {
    [key: string]: string;
  }

  if (value) {
    let object: Obj = {};
    object[key] = value;
    return object;
  }
  return null;
};

// -------------------
// TODO - below need defining and potentially moving

export function getStartTimeFromRRule(rrule: string) {
  return `${rrule.split("DTSTART:")[1].split("T")[1].slice(0, 2)}:${rrule
    .split("DTSTART:")[1]
    .split("T")[1]
    .slice(2, 4)}`;
}

const NUMBER_OF_ELEMENTS_IN_AN_HOUR_VALUE = 2;
const INDEX_TWO = 2;
const INDEX_ZERO = 0;
const NUMBER_OF_ELEMENTS_IN_A_MINUTE_VALUE = 2;
const LAST_TWO_DIGITS_ON_TIME_STRING = -2;
const OVERNIGHT_PREFIX = "99";

export function convertTimeToUpdatedableTime(time: number) {
  let hours = time
    .toString()
    .slice(
      time.toString().startsWith(OVERNIGHT_PREFIX) ? INDEX_TWO : INDEX_ZERO,
      time.toString().length - NUMBER_OF_ELEMENTS_IN_A_MINUTE_VALUE
    );
  let minutes = time.toString().slice(LAST_TWO_DIGITS_ON_TIME_STRING);
  // Add extra 0 digit for the hour if the hour is less than 10 (single digit)
  return hours.length < NUMBER_OF_ELEMENTS_IN_AN_HOUR_VALUE
    ? `0${hours}:${minutes}`
    : `${hours}:${minutes}`;
}

export function getStartDate(availability: { date?: Date; rrule: string }) {
  const rule = RRule.fromString(availability.rrule);
  const localDate = availability.date || rule.options.dtstart || dayjs();
  return localDate;
}

export function getEndDateFromRRule(rrule: string) {
  return rrule.includes("UNTIL=")
    ? convertRruleEndDate(rrule.split("UNTIL=")[1].split(";")[0])
    : "";
}

export const convertRruleEndDate = (date: string) => {
  if (!date) {
    return dayjs().utc().format();
  }
  const year: string = date.substring(0, 4);
  const month: string = date.substring(4, 4 + 2);
  const day: string = date.substring(6, 6 + 2);
  const hours: string = date.substring(9, 9 + 2);
  const minutes: string = date.substring(11, 11 + 2);

  const isNumeric = [year, month, day, hours, minutes].every(
    (el) => !isNaN(Number(el))
  );
  return isNumeric
    ? dayjs(`${year}-${month}-${day}T${Number(hours)}:${Number(minutes)}:00`)
        .utc()
        .format()
    : dayjs().utc().format();
};

const ucFirst = (string: string) => {
  return string
    ? string
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
        .trim()
    : "";
};

export const displayAvailabilityInfo = (type: AvailabilityType, mht: any) => {
  return `${ucFirst(type)}${type === "trust" ? " " + mht?.abbreviation : ""}`;
};

export const convertAvailabilityTime = (time: string) => {
  let isOvernightEvent = time.startsWith("99");
  if (isOvernightEvent) {
    time = time.substring(2);
  }
  const endTime = time.length === 3 ? `0${time}` : time;
  return `${endTime.slice(0, 2)}:${endTime.slice(2)}${
    isOvernightEvent ? " (overnight)" : ""
  }`;
};

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export default function notFalsy<TValue>(
  value: TValue | null | undefined | false | ""
): value is TValue {
  return (
    value !== null && value !== undefined && value !== false && value !== ""
  );
}

export const convertRRuleDateTimeToUTCDate = (rruleDate: Date): Date =>
  new Date(
    rruleDate.getUTCFullYear(),
    rruleDate.getUTCMonth(),
    rruleDate.getUTCDate(),
    rruleDate.getUTCHours(),
    rruleDate.getUTCMinutes(),
    1,
    0
  );
export function getRepeatWeeklyFrequencyFromRRule(rrule: string) {
  const interval = rrule.split("INTERVAL=")[1];
  return Number(interval?.split(";")[0] || 1);
}

export function getRepeatWeekdaysFromRRule(rrule: string) {
  return rrule.split("BYDAY=")[1]?.split(";")[0].split(",") || [];
}

export enum RepeatWeeklyOccurrenceOption {
  Once = "once",
  Multiple = "multiple",
}

export type DoctorAvailabilitySeries = {
  id: string;
  seriesId: string;
  startDateTime: string;
  endDateTime: string;
  endDateTimeForDisplay: string;
  daysOfTheWeek: string;
  numberOfWeeks: number;
  notes: string;
};

export interface AvailabilityObj {
  id: string;
  postcode: string;
  currentRRule: string;
  mht: string;
  oncall: boolean;
  rota: string;
  notes: string;
  startDate: Date;
  endDate: string;
  startTime: string;
  endTime: string;
  repeatEvent: boolean;
  repeatWeeklyFrequency: number;
  repeatWeeklyOccurrences: RepeatWeeklyOccurrenceOption;
  hasEndDate: boolean;
  repeatWeekdays: string[];
  isOvernightEvent: boolean;
}

export interface DoctorAvailabilityObj {
  availabilityDoctorId: string;
  createdAt: string;
  endDateTime: string;
  id: string;
  location?: Location | null;
  locationName?: LocationName | null;
  notes: string;
  oncall: boolean;
  rota?: string;
  rotaId: string;
  rotaName: string;
  seriesId: string;
  startDateTime: string;
  type: string;
  repeatEvent: boolean;
  repeatWeeklyFrequency: number;
  repeatWeeklyOccurrences: RepeatWeeklyOccurrenceOption;
  hasEndDate: boolean;
  repeatWeekdays: string[];
  postcode: string;
  mht: string;
  startDate?: Date;
  endDate?: string | null;
  startTime?: string;
  endTime?: string;
  repeatType?: string;
}

export function convertAvailabilityToAddUpdateData(
  availability: Availability
): AvailabilityObj {
  const startDate = getStartDate(availability);
  const endDate = getEndDateFromRRule(availability.rrule);
  const rrule = new RRuleParser(availability.rrule);

  return {
    id: availability.id,
    postcode: availability.locationName
      ? availability.locationName.postcode
      : "",
    currentRRule: availability.rrule,
    mht: availability.mht?.id || "-",
    oncall: !!availability.oncall,
    rota: !!availability.rotaId ? availability.rotaId : "-",
    notes: availability.notes || "",
    startDate: startDate,
    endDate: endDate,
    startTime: getStartTimeFromRRule(availability.rrule),
    endTime: convertTimeToUpdatedableTime(availability.endHour),
    repeatEvent: rrule.repeatEvent,
    repeatWeeklyFrequency: getRepeatWeeklyFrequencyFromRRule(
      availability.rrule
    ),
    repeatWeeklyOccurrences:
      dayjs(endDate).format(DATE_FORMAT_WITHOUT_TIME) ===
      dayjs(startDate)
        .subtract(1, "day")
        .endOf("week")
        .add(1, "day")
        .format(DATE_FORMAT_WITHOUT_TIME)
        ? RepeatWeeklyOccurrenceOption.Once
        : RepeatWeeklyOccurrenceOption.Multiple,
    hasEndDate: !!endDate,
    repeatWeekdays: getRepeatWeekdaysFromRRule(availability.rrule),
    isOvernightEvent: availability.endHour
      ? availability.endHour.toString().startsWith("99")
      : false,
  };
}

export function convertDoctorAvailabilityToAddUpdateData(
  doctorAvailability: DoctorAvailability,
  isEdit: boolean,
  isDuplicate: boolean
): DoctorAvailabilityObj {
  return {
    id: doctorAvailability.id,
    availabilityDoctorId: doctorAvailability.availabilityDoctorId || "",
    createdAt: doctorAvailability.createdAt ? doctorAvailability.createdAt : "",
    endDateTime: doctorAvailability.endDateTime,
    startDateTime: doctorAvailability.startDateTime,
    location: doctorAvailability.location
      ? doctorAvailability.location
      : { lat: 0, lon: 0 },
    locationName: doctorAvailability.locationName
      ? doctorAvailability.locationName
      : { postcode: "" },
    notes: doctorAvailability.notes ? doctorAvailability.notes : "",
    oncall: doctorAvailability.oncall ? doctorAvailability.oncall : false,
    rotaId: doctorAvailability.rotaId ? doctorAvailability.rotaId : "",
    rotaName: doctorAvailability.rotaName ? doctorAvailability.rotaName : "",
    seriesId: !isEdit ? uuidv4() : doctorAvailability.seriesId,
    type: doctorAvailability.type ? doctorAvailability.type : "",
    repeatEvent: false,
    repeatWeeklyFrequency: 0,
    repeatWeeklyOccurrences: RepeatWeeklyOccurrenceOption.Once,
    hasEndDate: !!doctorAvailability.endDateTime,
    repeatWeekdays: [],
    postcode: doctorAvailability.locationName
      ? doctorAvailability.locationName.postcode
      : "",
    mht: doctorAvailability.availabilityMhtId || "-",
    rota: !!doctorAvailability.rotaId ? doctorAvailability.rotaId : "-",
  };
}

/**
 * Converts a recurring doctor's availability series into a format suitable for editing.
 *
 * This function takes an individual doctor's availability object and its associated series of availabilities,
 * then converts it into an editable format (`DoctorAvailabilityObj`). The function is useful for preparing
 * the data needed when editing a recurring event in a user interface. It extracts relevant information from
 * the series, such as start and end times, repeat frequency, and weekdays, and aggregates it into a single object.
 *
 * @param {DoctorAvailability} doctorAvailability - The availability object that is being edited.
 * @param {DoctorAvailability[][]} currentSeriesV3 - The full series of availabilities associated with the doctor's availability.
 * @param {boolean | null} isEdit - Indicates whether the function is processing an existing event for editing (true),
 *                                  creating a new event based on an existing one (false), or undefined.
 *
 * @returns {DoctorAvailabilityObj} - An object containing the aggregated data from the series, formatted for easy editing.
 *                                    The returned object includes details such as the start date, end date, repeat frequency,
 *                                    and weekdays on which the availability recurs.
 *
 * The function performs the following steps:
 * 1. Finds the series of availabilities that match the provided `seriesId`.
 * 2. Sorts the series by start date-time.
 * 3. Extracts the first and last availability in the series to determine the overall range.
 * 4. Aggregates and formats relevant information, such as the days of the week on which the availability occurs,
 *    start and end times, and the repeat frequency.
 * 5. Prepares an object that is ready to be used in a form or interface for editing the availability.
 *
 * Note: If the function is called in "edit" mode (`isEdit` is true), it retains the `seriesId`; otherwise, it generates a new `seriesId`.
 */
export function convertRecurringDoctorAvailablityToEditableAvailability(
  doctorAvailability: DoctorAvailabilitySeries,
  currentSeriesV3: DoctorAvailability[][],
  isEdit: boolean | null
): DoctorAvailabilityObj {
  let manageAvailabilityState: DoctorAvailabilityObj = {
    id: "",
    postcode: "",
    mht: "",
    oncall: false,
    rotaId: "",
    rotaName: "",
    notes: "",
    startDate: dayjs().toDate(),
    endDate: null,
    startTime: "",
    endTime: "",
    repeatEvent: false,
    repeatWeeklyFrequency: 1,
    repeatWeekdays: [],
    repeatWeeklyOccurrences: RepeatWeeklyOccurrenceOption.Multiple,
    hasEndDate: false,
    seriesId: "",
    availabilityDoctorId: "",
    createdAt: "",
    endDateTime: "",
    startDateTime: "",
    type: "",
    rota: "",
  };

  const series = currentSeriesV3.find((s) =>
    s.some((av) => av.seriesId === doctorAvailability.seriesId)
  );

  if (series && series.length > 0) {
    // Sort series by startDateTime
    const sortedSeries = series.sort(
      (a: DoctorAvailability, b: DoctorAvailability) =>
        dayjs(a.startDateTime).valueOf() - dayjs(b.startDateTime).valueOf()
    );

    const firstAvailability = sortedSeries[0];
    const lastAvailability = sortedSeries[sortedSeries.length - 1];

    // Extracting days of the week from the series
    const repeatWeekdays = Array.from(
      new Set(sortedSeries.map((av) => dayOfTheWeekFn(dayjs(av.startDateTime))))
    );

    // Calculate unique weeks from the series
    const uniqueWeeks = new Set(
      sortedSeries.map(
        (av) =>
          `${dayjs(av.startDateTime).year()}-W${dayjs(av.startDateTime).week()}`
      )
    );

    // The repeatWeeklyFrequency should be the number of unique weeks
    const repeatWeeklyFrequency =
      doctorAvailability.numberOfWeeks / currentSeriesV3.length;

    // Aggregating data for ManageAvailabilityStateV3
    manageAvailabilityState = {
      id: uuidv4(),
      postcode: firstAvailability.locationName?.postcode || "",
      mht: firstAvailability.availabilityDoctorId || "-",
      oncall: firstAvailability.oncall || false,
      rotaId: firstAvailability.rotaId || "",
      rotaName: firstAvailability.rotaName || "",
      notes: firstAvailability.notes || "",
      startDate: dayjs(firstAvailability.startDateTime).toDate(),
      endDate: lastAvailability.endDateTime,
      startTime: dayjs(firstAvailability.startDateTime).format("HH:mm"),
      endTime: dayjs(lastAvailability.endDateTime).format("HH:mm"),
      repeatEvent: true,
      repeatWeeklyFrequency: repeatWeeklyFrequency,
      repeatWeekdays: repeatWeekdays,
      repeatWeeklyOccurrences: RepeatWeeklyOccurrenceOption.Multiple,
      hasEndDate: !!lastAvailability.endDateTime,
      seriesId: !isEdit ? uuidv4() : firstAvailability.seriesId,
      availabilityDoctorId: firstAvailability.availabilityDoctorId || "",
      createdAt: firstAvailability.createdAt ? firstAvailability.createdAt : "",
      endDateTime: firstAvailability.endDateTime,
      startDateTime: firstAvailability.startDateTime,
      type: firstAvailability.type ? firstAvailability.type : "",
    };
  }
  return manageAvailabilityState;
}

export enum ManageAvailabilityType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export function convertTimeToTimeObject(time: string) {
  const [hours, minutes] = time.split(":");
  return {
    hours: parseInt(hours.startsWith("99") ? hours.slice(2) : hours),
    minutes: parseInt(minutes),
  };
}

export const convertDateToRRuleFormat = (
  date: Dayjs,
  time: { hours: number; minutes: number; seconds?: number }
) =>
  `${dayjs(date).format("YYYYMMDD")}T${
    time.hours <= 9 ? "0" + time.hours : time.hours
  }${time.minutes <= 9 ? "0" + time.minutes : time.minutes}${
    time.seconds
      ? time.seconds <= 9
        ? "0" + time.seconds
        : time.seconds
      : "00"
  }Z`;

export const convertUserInputToRRule = (data: {
  startDate: Dayjs;
  startTime: { hours: number; minutes: number };
  endDate: Dayjs | null;
  repeatEvent: boolean;
  repeatWeeklyFrequency: number;
  repeatWeeklyOccurrences: "multiple" | "once";
  repeatWeekdays: string[];
  hasEndDate: boolean;
}) => {
  const startDate = `DTSTART:${convertDateToRRuleFormat(
    data.startDate,
    data.startTime
  )}`;

  if (!data.repeatEvent) {
    return `${startDate};RRULE:UNTIL=${convertDateToRRuleFormat(
      dayjs(data.startDate).add(1, "day"),
      {
        hours: 23,
        minutes: 59,
      }
    )}`;
  }
  return `${startDate};RRULE:FREQ=WEEKLY;WKST=MO;INTERVAL=${
    data.repeatWeeklyFrequency
  };BYDAY=${data.repeatWeekdays.join(",")};BYHOUR=${
    data.startTime.hours
  };BYMINUTE=${data.startTime.minutes};BYSECOND=1${
    !!data.endDate && data.hasEndDate ? ";UNTIL=" : ""
  }${
    !!data.endDate && data.hasEndDate
      ? convertDateToRRuleFormat(data.endDate, { hours: 23, minutes: 59 })
      : ""
  }`;
};

export interface ManageAvailabilityState {
  id?: string;
  postcode: string;
  mht: string;
  oncall: boolean;
  rotaId?: string;
  rotaName?: string;
  notes: string;
  startDate: Date;
  endDate: Date | null;
  startTime: string;
  endTime: string;
  repeatEvent: boolean;
  repeatWeeklyFrequency: number;
  repeatWeekdays: string[];
  isOvernightEvent: boolean;
  repeatWeeklyOccurrences: RepeatWeeklyOccurrenceOption;
  hasEndDate: boolean;
}

export interface ManageAvailabilityStateV3 {
  id?: string;
  postcode: string;
  mht: string;
  oncall: boolean;
  rotaId?: string;
  rotaName?: string;
  notes: string;
  startDate: Date;
  endDate: Date;
  startTime: string;
  endTime: string;
  repeat: boolean;
  repeatWeeklyFrequency: number;
  repeatWeekdays: string[];
  repeatWeeklyOccurrences: RepeatWeeklyOccurrenceOption;
  hasEndDate: boolean;
  seriesId: string | undefined;
  repeatType: string;
  availabilityMhtId: string;
}

export const countSeriesWithMultipleEntries = (
  series: DoctorAvailability[][]
) => {
  // Filter sub-arrays with more than one element
  const filteredSeries = series.filter((subArray) => subArray.length > 1);

  // Return the count of such sub-arrays
  return filteredSeries.length;
};

/**
 * Converts input data from the Manage Availability interface into a format suitable for mutation requests.
 *
 * This function generates the appropriate availability objects by processing the input data, including the start date,
 * repeat frequency, weekdays, and other relevant details. It creates an array of availability events based on the provided
 * schedule and handles both the creation and update cases, assigning unique IDs where necessary.
 *
 * @param {ManageAvailabilityStateV3} input - The input state representing the availability data.
 * @param {ManageAvailabilityType | null} manageAvailabilityType - The type of operation, either CREATE or UPDATE.
 * @param {string} userId - The ID of the user (doctor) whose availability is being managed.
 *
 * @returns {DoctorAvailability[]} - An array of availability objects formatted for mutation, each containing details
 * such as start and end times, on-call status, notes, and series identifiers.
 */

export const convertInputDataToMutationInputV4 = (
  input: ManageAvailabilityStateV3,
  manageAvailabilityType: ManageAvailabilityType | null,
  userId: string
) => {
  let finalId: string;

  const dateTimePairs = generateDatesForWeekdays(
    dayjs(input.startDate),
    input.repeatWeeklyFrequency,
    input.repeatWeekdays,
    input.startTime,
    input.endTime,
    input.hasEndDate,
    input.endDate
  );

  let rotaInput = {};
  let seriesId: string;

  if (manageAvailabilityType === ManageAvailabilityType.CREATE) {
    seriesId = uuidv4();
    rotaInput =
      input.oncall && input.rotaId && input.rotaId.length
        ? { rotaId: input.rotaId, rotaName: input.rotaName }
        : {};
  } else {
    seriesId = input.seriesId ? input.seriesId : uuidv4();
    finalId = input.id ? input.id : uuidv4();
    rotaInput = {
      rotaId:
        input.oncall && input.rotaId && input.rotaId.length
          ? input.rotaId
          : undefined,
      rotaName:
        input.oncall && input.rotaName && input.rotaName.length
          ? input.rotaName
          : undefined,
    };
  }

  const eventObjects: DoctorAvailability[] = dateTimePairs.map((pair) => {
    return {
      id:
        manageAvailabilityType !== ManageAvailabilityType.CREATE &&
        dateTimePairs.length === 1
          ? finalId
          : uuidv4(),
      availabilityDoctorId: userId,
      startDateTime: pair[0].toISOString(),
      endDateTime: pair[1].toISOString(),
      oncall: input.oncall,
      rotaId: input.rotaId,
      rotaName: input.rotaName,
      notes: input.notes ? input.notes : null,
      seriesId: seriesId,
      ...rotaInput,
      createdAt: new Date().toISOString(),
      availabilityMhtId: input.mht === HYPHEN ? HYPHEN : input.mht,
      mht: input.mht,
    };
  });
  return eventObjects;
};

/**
 * Converts input data to mutation input format for doctor availability.
 *
 * Determines the end date and constructs an RRule string based on user input,
 * then creates an object with relevant details, including IDs, timestamps, and rota information.
 *
 * @param {ManageAvailabilityState} input - The input state object.
 * @param {string | null} availabilityId - The availability ID (for updates).
 * @param {ManageAvailabilityType | null} manageAvailabilityType - The type of availability management (CREATE/UPDATE).
 * @param {string} userId - The user ID.
 * @returns {object} An object formatted for the availability mutation.
 */
export const convertInputDataToMutationInput = (
  input: ManageAvailabilityState,
  availabilityId: string | null,
  manageAvailabilityType: ManageAvailabilityType | null,
  userId: string
) => {
  let endDate: Dayjs | null = null;
  let hasEndDate = false;

  if (
    input.repeatEvent &&
    input.repeatWeeklyOccurrences === "multiple" &&
    input.hasEndDate &&
    input.endDate
  ) {
    endDate = dayjs(input.endDate);
    hasEndDate = true;
  } else if (
    input.repeatEvent &&
    input.repeatWeeklyOccurrences === "multiple" &&
    !input.hasEndDate
  ) {
    endDate = dayjs(new Date(3000, 0, 1));
    hasEndDate = false;
  } else if (input.repeatEvent && input.repeatWeeklyOccurrences === "once") {
    endDate = dayjs(input.startDate)
      .subtract(1, "day")
      .endOf("week")
      .add(1, "day");
    hasEndDate = true;
  } else if (!input.repeatEvent) {
    endDate = dayjs(input.startDate).add(1, "day");
    hasEndDate = true;
  } else {
    endDate = null;
    hasEndDate = false;
  }

  const rrule = convertUserInputToRRule({
    startDate: dayjs(input.startDate),
    startTime: convertTimeToTimeObject(input.startTime),
    endDate: endDate,
    repeatEvent: input.repeatEvent,
    repeatWeeklyFrequency: input.repeatWeeklyFrequency,
    repeatWeeklyOccurrences: input.repeatWeeklyOccurrences,
    repeatWeekdays: input.repeatWeekdays,
    hasEndDate: hasEndDate,
  });

  // Add rota details if present, or if not exclude from create mutation or nullify in update
  // Can't set to null on create due to secondary index, so need to exclude
  let rotaInput;
  if (manageAvailabilityType === ManageAvailabilityType.CREATE) {
    rotaInput =
      input.oncall && input.rotaId && input.rotaId.length
        ? {
            rotaId: input.rotaId,
            rotaName: input.rotaName,
          }
        : {};
  } else {
    rotaInput = {
      rotaId:
        input.oncall && input.rotaId && input.rotaId.length
          ? input.rotaId
          : undefined,
      rotaName:
        input.oncall && input.rotaName && input.rotaName.length
          ? input.rotaName
          : undefined,
    };
  }

  // if no postcode data returned it's not a valid postcode in which case we will return null, which will be handled outside of this fn
  return {
    ...(availabilityId ? { id: availabilityId } : {}),
    availabilityDoctorId: userId,
    type: (input.mht === "-" ? "independent" : "trust") as AvailabilityType,
    availabilityMhtId: input.mht === "-" ? null : input.mht,
    endHour: Number(
      `${input.isOvernightEvent ? "99" : ""}${input.endTime
        .split(":")
        .join("")}`
    ),

    endDate: endDate
      ? convertDateToRRuleFormat(endDate, {
          hours: 23,
          minutes: 59,
        })
      : "",

    rrule,
    contractList: null,
    oncall: input.oncall,
    ...rotaInput,
    notes: input.notes ? input.notes : null,
    s12DoctorAvailabilitiesId: userId,
  };
};

// Function to get an array of IDs to the Recurring Availabilities based on seriesId
export const getIdsToOneSeries = (
  targetAvailability: DoctorAvailability,
  allSeries: DoctorAvailability[][]
): string[] => {
  const seriesId = targetAvailability.seriesId;
  const idsToDelete = allSeries
    .flat() // Flatten the array of arrays
    .filter((availability) => availability.seriesId === seriesId)
    .map((availability) => availability.id);

  return idsToDelete;
};

/**
 * Generates an array of start and end date-time pairs based on the specified parameters.
 *
 * This function creates a schedule of events by generating date-time pairs for each selected weekday,
 * repeated over the specified number of weeks. If no weekdays are selected, it generates date-time pairs
 * for each day within the specified start and end date range. The function ensures that all generated dates
 * respect the provided time range and optionally considers an end date to limit the schedule.
 *
 * @param {Dayjs} startDate - The initial start date for generating the schedule, adjusted to the start of the day.
 * @param {number} nbrepeatWeek - The number of weeks over which the events should be repeated.
 * @param {string[]} weekdays - An array of weekday abbreviations (e.g., "MO", "TU") for which events should be generated.
 * @param {string} startTime - The start time of each event in "HH:mm" format.
 * @param {string} endTime - The end time of each event in "HH:mm" format.
 * @param {boolean} hasEndDate - Indicates whether an end date has been specified to limit the schedule.
 * @param {Date | null} endDate - The optional end date that limits the event generation. If null, events continue as scheduled.
 *
 * @returns {Dayjs[][]} - An array of date-time pairs, where each pair consists of a start date-time and an end date-time.
 *                        Each pair represents a single event occurring within the generated schedule.
 *
 * @throws {Error} - Throws an error if the startDate or endDate is invalid.
 *
 * The function operates in two modes:
 * 1. If specific weekdays are selected, it generates events for those days over the specified number of weeks.
 * 2. If no weekdays are selected, it generates events for all days within the start and end date range.
 */
const generateDatesForWeekdays = (
  startDate: Dayjs,
  nbrepeatWeek: number,
  weekdays: string[],
  startTime: string,
  endTime: string,
  hasEndDate: boolean,
  endDate: Date | null
): Dayjs[][] => {
  let dates: Dayjs[][] = [];

  if (startDate.isValid() && endDate && dayjs(endDate).isBefore(startDate)) {
    endDate = null;
  }

  const dayjsStartDate = startDate.startOf("day");
  const dayjsEndDate = dayjs(endDate).startOf("day");
  const dateDifference = dayjsEndDate.diff(dayjsStartDate, "days");

  if (
    !dayjsStartDate.isValid() ||
    (hasEndDate && dayjsEndDate && !dayjsEndDate.isValid())
  ) {
    throw new Error("Invalid startDate or endDate");
  }

  const totalWeeks = nbrepeatWeek === 1 ? 1 : nbrepeatWeek;

  const maxWeeks = totalWeeks > 12 ? 12 : totalWeeks;

  for (let week = 0; week < maxWeeks; week++) {
    if (nbrepeatWeek && weekdays.length < 2) {
      const dateStart = dayjsStartDate.clone().add(week * 7, "days");
      const dateEnd = dateStart.clone().add(dateDifference, "days");
      const [startHour, startMinute] = startTime.split(":").map(Number);
      const [endHour, endMinute] = endTime.split(":").map(Number);
      const startDateTime = dateStart
        .clone()
        .hour(startHour)
        .minute(startMinute);
      const endDateTime = dateEnd.clone().hour(endHour).minute(endMinute);
      dates.push([startDateTime, endDateTime]);
    } else {
      weekdays.forEach((weekday) => {
        let dayjsWeekday = convertWeekdayStringToDayjsFormat(weekday);
        if (dayjsWeekday === null) return;

        let date = dayjsStartDate.clone().add(week, "week").day(dayjsWeekday);
        if (
          nbrepeatWeek === 1 &&
          hasEndDate &&
          dayjsEndDate &&
          date.isAfter(dayjsEndDate)
        ) {
          return;
        }

        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);

        const startDateTime = date.clone().hour(startHour).minute(startMinute);
        let endDateTime = date.clone().hour(endHour).minute(endMinute);

        // Handle overnight events
        if (endDateTime.isBefore(startDateTime)) {
          endDateTime = endDateTime.add(1, "day");
        }

        dates.push([startDateTime, endDateTime]);
      });
    }
  }
  return dates;
};

/**
 * Converts a weekday string into its corresponding numeric representation for use with the Dayjs library.
 *
 * This function maps a two-letter weekday abbreviation (e.g., 'MO' for Monday) to a numeric value
 * that represents the day of the week in a Dayjs-compatible format, where Monday is 1 and Sunday is 7.
 * If the input string does not match a valid weekday abbreviation, the function returns null.
 *
 * @param {string} weekday - A two-letter abbreviation representing the day of the week (e.g., 'MO' for Monday).
 *
 * @returns {number | null} - The numeric representation of the day of the week (1 for Monday, 7 for Sunday),
 * or null if the input is not a valid weekday abbreviation.
 */

const convertWeekdayStringToDayjsFormat = (weekday: string): number | null => {
  const map: { [key: string]: number } = {
    MO: 1, // Monday
    TU: 2, // Tuesday
    WE: 3, // Wednesday
    TH: 4, // Thursday
    FR: 5, // Friday
    SA: 6, // Saturday
    SU: 7, // Sunday
  };
  return map[weekday.toUpperCase()] ?? null;
};
