import React from "react";
import { DataGridPro, GridColumns, GridRowsProp } from "@mui/x-data-grid-pro";
import { Box } from "@mui/material";
import * as constants from "common/constants/theme";

interface ProTableProps {
  rows: GridRowsProp;
  columns: GridColumns;
  initialState?: any;
  disableSelectionOnClick?: boolean;
  checkboxSelection?: boolean;
  pageSize?: number;
  rowsPerPageOptions?: any[];
  onCellEditCommit?: any;
  onClickRowData?: (event: any) => void;
  loading?: boolean;
  noResultsOverlay?: React.JSXElementConstructor<any>;
  tableError?: any;
}

const ProTable: React.FC<ProTableProps> = ({
  rows,
  columns,
  initialState,
  onCellEditCommit,
  disableSelectionOnClick = true,
  checkboxSelection = false,
  pageSize = 10,
  rowsPerPageOptions = [5, 10, 25, 100],
  loading = false,
  onClickRowData,
  noResultsOverlay,
  tableError,
  ...props
}) => {
  const [pageSizes, setPageSizes] = React.useState<number>(pageSize);
  return (
    <Box sx={{ width: "100%" }}>
      <DataGridPro
        {...props}
        loading={loading}
        disableColumnPinning={true}
        disableColumnReorder={true}
        disableSelectionOnClick={disableSelectionOnClick}
        checkboxSelection={checkboxSelection}
        onCellEditCommit={onCellEditCommit}
        columns={columns}
        error={tableError}
        rows={rows}
        pageSize={pageSizes}
        onPageSizeChange={(newPageSize) => setPageSizes(newPageSize)}
        rowsPerPageOptions={rowsPerPageOptions}
        initialState={initialState}
        pagination
        autoHeight={true}
        density="compact"
        getRowId={(row) => row.id}
        onRowClick={(event) => {
          onClickRowData && onClickRowData(event.row);
        }}
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
          "& .MuiDataGrid-row": {
            ":hover": {
              cursor: "pointer",
              backgroundColor: constants.lightBaseGrey,
            },
          },
          "& .super-app-theme--even": {
            backgroundColor: constants.lightGrey,
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
        }}
        getRowClassName={(params) =>
          `super-app-theme--${
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }`
        }
        components={{
          NoRowsOverlay: noResultsOverlay,
        }}
      />
    </Box>
  );
};

export default React.memo(ProTable);
