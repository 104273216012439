import React from "react";
// Material UI Imports
import { Grid, Typography as Text } from "@mui/material";
import { StyledCheckBox } from "components/uiElements";

interface TeamFeaturesProps {
  errorText?: string;
  header: string;
  teamFeatures: any;
  handleFeature: any;
  maxHeight?: any;
  disabled?: boolean;
}

const TeamFeatures: React.FC<TeamFeaturesProps> = ({
  errorText,
  header,
  teamFeatures,
  handleFeature,
  maxHeight = window.innerHeight / 2.5,
  disabled = false,
}) => {
  return (
    <React.Fragment>
      <Grid item xs={6}>
        <Text variant="h2">{header}</Text>
      </Grid>
      {errorText && (
        <Grid item xs={6}>
          <Text variant="caption" color="red">
            {errorText}
          </Text>
        </Grid>
      )}

      <Grid
        item
        mt={2}
        mb={2}
        xs={12}
        sx={{
          position: "relative",
          overflow: "auto",
          maxHeight: maxHeight,
        }}
      >
        {teamFeatures?.map((item: any) => {
          return (
            <StyledCheckBox
              key={item.id ?? item.value}
              id={item.id ?? item.value}
              value={item.value}
              label={item.label}
              infoIcon={item.infoIcon}
              tooltip={item.tooltip}
              checked={item.checked}
              handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleFeature(event);
              }}
              disabled={disabled}
            />
          );
        })}
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(TeamFeatures);
