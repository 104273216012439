import React, { useCallback, useState } from "react";
// Material UI Imports

import { Grid, Box, Typography, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { StyledInput } from "components/uiElements";
import * as constants from "common/constants/theme";
import * as MESSAGES from "common/constants/messages";

//data grid
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH } from "common/constants/common";
import { Close } from "@mui/icons-material";
//end data grid

interface SearhProps {
  handleSearch: any;
  searchString: string;
  searchLabel?: string;
  autoFocus?: boolean;
  headerData?: any;
  rowData?: any;
  onClickRowData?: any;
  placeholder?: string;
  loading?: boolean;
  error?: any;
  rowsPerPageOptions?: number[];
  disabled?: boolean;
}

const Search: React.FC<SearhProps> = ({
  handleSearch,
  searchString,
  searchLabel = "Search",
  headerData = [],
  rowData = [],
  onClickRowData,
  placeholder,
  autoFocus = false,
  loading,
  error,
  rowsPerPageOptions = [5, 10, 25, 100],
  disabled = false,
}) => {
  const [pageSize, setPageSize] = useState(10);
  const [cleared, setCleared] = useState(false);

  const callbackRef = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
      setCleared(false);
    }
  }, []);

  return (
    <Grid container xs={12} spacing={3}>
      <Grid item xs={12}>
        <StyledInput
          inputRef={cleared ? callbackRef : () => {}}
          id="searchString"
          name="searchString"
          label={searchLabel}
          value={searchString}
          autoFocus={autoFocus}
          placeholder={placeholder}
          onChange={(e: any) => {
            handleSearch(e.target.value);
          }}
          startAdornment={<SearchIcon />}
          disabled={disabled}
          maxLength={255}
          endAdornment={
            searchString &&
            searchString.length > 0 && (
              <IconButton
                onClick={() => {
                  handleSearch("");
                  setCleared(true);
                }}
                disabled={disabled}
              >
                <Close />
              </IconButton>
            )
          }
        />
      </Grid>
      {searchString.trim().length <= MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH ? (
        <Grid item xs={12}>
          <Typography>{MESSAGES.LEAST_THREE_CHARACTERS}</Typography>
        </Grid>
      ) : error ? (
        <Grid item xs={12}>
          <Typography>{MESSAGES.ERROR_OCURRED}</Typography>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <DataGridPro
              disableColumnPinning={true}
              disableColumnReorder={true}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageSizeChange={(newPageSize: number) =>
                setPageSize(newPageSize)
              }
              pagination
              pageSize={pageSize}
              error={error}
              loading={loading}
              autoHeight={true}
              density="compact"
              hideFooterSelectedRowCount={true}
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-row": {
                  ":hover": {
                    cursor: "pointer",
                    backgroundColor: constants.lightBaseGrey,
                  },
                },
                "& .super-app-theme--even": {
                  backgroundColor: constants.lightGrey,
                },
                "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
                  outline: "none",
                },
              }}
              getRowClassName={(params) =>
                `super-app-theme--${
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }`
              }
              getRowId={(row) => row.id}
              columns={headerData}
              rows={rowData ? rowData : []}
              onRowClick={(event) => {
                onClickRowData(event.row);
              }}
              components={{
                NoRowsOverlay: () => (
                  <GridOverlay>
                    <Typography>{MESSAGES.NO_MATCHING_RECORDS}</Typography>
                  </GridOverlay>
                ),
              }}
            />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(Search);
