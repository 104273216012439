import { Grid, Typography as Text, Card } from "@mui/material";
import React from "react";
import AMHPForm from "pages/AMHP";

const AddAMHP: React.FC = () => {
  return (
    <Grid
      m={1}
      p={2}
      component={Card}
      container
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        borderRadius: 1,
        minHeight: window.innerHeight - 85,
      }}
    >
      <Grid item xs={12}>
        <Text variant="h1">Add AMHP</Text>
        <AMHPForm />
      </Grid>
    </Grid>
  );
};

export default React.memo(AddAMHP);
