import {
  INVALID_EMAIL_ERROR_MESSAGE,
  INVALID_PHONE_ERROR_MESSAGE,
} from "common/constants/messages";
import { isValidPhone } from "common/validators";
import { z } from "zod";

export const GlobalSettingsSchema = z.object({
  id: z.string(),
  value: z.string().min(1, "Value is required"),
});
