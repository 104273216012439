import React, {
  useEffect,
  useCallback,
  useMemo,
  useReducer,
  useState,
  useRef,
} from "react";

// Material UI Imports
import { Grid, InputAdornment, Modal, SelectChangeEvent } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// Custom Types
import {
  AmhpProfile,
  Site,
  User,
  AdminUpdateAmhpInput,
  DeleteUserInput,
  AdminCreateAmhpInput,
  ReenableUserInput,
} from "@s12solutions/types";
import { StyledSelect } from "components/uiElements/StyledSelect";
import { StyledCheckBox, StyledInput } from "components/uiElements";
import { useAuth, usePopups, useAPI } from "hooks";
import {
  SelectListProps,
  StyledMultipleSelect,
} from "components/uiElements/StyledMultipleSelect";
import {
  PHONE_NUMBER_VALIDATION_REGULAR_EXPRESSION,
  isValidPhone,
} from "common/validators";
import * as FIELDS from "common/constants/fields";
import * as COMMON from "common/constants/common";
import * as MESSAGES from "common/constants/messages";
import { SSO_LIST, SSO_OPTION_LIST, AMHP_TYPE_LIST } from "common/constants/options";
import { AMHPState, AmhpActionTypes } from "common/types/actions";
import useUnsavedChangesWarning from "hooks/useUnsavedChangesWarning";
import { amhpFormReducer } from "common/functions/reducers";
import CopyToClipboardButton from "components/clipBoard";
import { useNavigate, useLocation } from "react-router-dom";
import { compact, isEqual } from "lodash";
import { FormErrors } from "common/types/formErrors";
import { resetInput, validateFormDataAsync, validateInput } from "utils/zod";
import { validateInputForZodEffectAsync } from "utils/zod/validateInput";
import { AMHPFormSchema } from "utils/zod/schemas/AMHPFormSchema";
import { getFeatureFlagObjectAMHPTeam } from "utils/featureFlags";
import ActivityDialog from "components/activityDialog/ActivityDialog";
import {
  GetUserActivitiesInput,
  GetUserActivitiesResponseItem,
} from "common/types/commonTypes";
import EventModalContent from "components/EventModal/EventModal";

// This reducer functions handles all the states in the AMHP form

interface AMHPFormProps {
  handleCancel?: any;
  user?: AmhpProfile | null;
}

const AMHPForm: React.FC<AMHPFormProps> = ({ handleCancel, user }) => {
  const loggedInUser = useAuth();
  const location: any = useLocation();
  const readOnly = loggedInUser.user?.attributes["custom:limit_admin"];

  const { trigger: fetchPhoneInfo, error: fetchPhoneInfoError } = useAPI<{
    checkPhoneNumberExists: {
      exists: boolean;
    } | null;
  }>({
    method: "POST",
    fieldName: "checkPhoneNumberExists",
    manual: true,
  });

  const {
    trigger: reenableUser,
    loading: reenableLoading,
    error: reenableError,
  } = useAPI<User | null, { input: ReenableUserInput }>({
    method: "PUT",
    fieldName: "reenableUser",
    manual: true,
  });

  // For Phone Validation
  const AMHPFormSchemaPhoneMFA = AMHPFormSchema.pick({
    mfa: true,
    phone: true,
  });

  const phoneRefinements = (
    obj: typeof AMHPFormSchema | typeof AMHPFormSchemaPhoneMFA
  ) => {
    return obj
      .refine((data) => !(data.mfa && data.phone.trim().length === 0), {
        message: "Phone is required",
        path: ["phone"],
      })
      .refine(
        async (data) => {
          if (isValidPhone(data.phone)) {
            let response = await fetchPhoneInfo({
              filter: {
                ...(!!user && { id: user.id }),
                phone: data.phone,
              },
            });

            return !response.data?.checkPhoneNumberExists?.exists;
          }
          return true;
        },
        {
          message: MESSAGES.DUPLICATE_PHONE_ERROR_MESSAGE,
          path: ["phone"],
        }
      );
  };

  const AMHPFormSchemaPickedForMFAAndPhoneRefined = phoneRefinements(
    AMHPFormSchemaPhoneMFA
  );

  // For Form Submission Validation
  const AMHPFormSchemaRefined = phoneRefinements(AMHPFormSchema);

  const navigate = useNavigate();
  if (location?.state?.isSameMenu && handleCancel) {
    handleCancel();
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
  }
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  // Initial Values
  let initialState = useMemo<AMHPState>(() => {
    return {
      name: user ? user.name : COMMON.EMPTY_STRING,
      email: user ? user.email : COMMON.EMPTY_STRING,
      phone: user && user.phone ? user.phone : COMMON.EMPTY_STRING,
      teams:
        user && user.teams && user.teams.items
          ? compact(
            user.teams.items.map((val) => {
              if (!val || !val.amhpTeam || !val.amhpTeam.featureFlags) {
                return null;
              }
              return {
                key: val.amhpTeam.id,
                value: val.amhpTeam.name,
                featureFlags: getFeatureFlagObjectAMHPTeam(
                  val.amhpTeam.featureFlags
                ),
              };
            })
          )
          : [],
      sso:
        user && user.sso && user.sso === SSO_LIST.Microsoft
          ? SSO_LIST.Microsoft
          : user
            ? SSO_LIST.None
            : "",
      mfa: user && user.mfa ? user.mfa : false,
      type: user && user.type ? user.type : "normal",
      isTraineeAmhp: user && user.isTraineeAmhp ? user.isTraineeAmhp : false,
    };
  }, [user]);

  const constantState = useRef(initialState);

  // States Manager
  const [state, dispatch] = useReducer(amhpFormReducer, constantState.current);
  const [showAudit, setAudit] = useState(false);

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const { handleConfirmation, handleBannerMessage, handleSnackbar, closeSnackbar } = usePopups();

  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    data: dialogData,
    loading: dialogLoading,
    error: dialogError,
    trigger: refetch,
  } = useAPI<GetUserActivitiesResponseItem, GetUserActivitiesInput>({
    method: "GET",
    fieldName: "getUserActivities",
    manual: true,
  });

  // Queries
  const {
    data: amhpTeamData,
    error: amhpTeamLoadError,
    loading: amhpTeamLoading,
  } = useAPI<Site[] | null>({
    method: "GET",
    fieldName: "listSites",
  });

  // Mutations
  const {
    trigger: createAmhp,
    loading: createLoading,
    error: createError,
  } = useAPI<AmhpProfile | null, { input: AdminCreateAmhpInput }>({
    method: "POST",
    fieldName: "createAmhp",
    manual: true,
  });

  const {
    trigger: updateAmhp,
    loading: updateLoading,
    error: updateError,
  } = useAPI<AmhpProfile | null, { input: AdminUpdateAmhpInput }>({
    method: "POST",
    fieldName: "updateAmhp",
    manual: true,
  });

  const {
    trigger: deleteUser,
    loading: deleteLoading,
    error: deleteError,
  } = useAPI<User | null, { input: DeleteUserInput }>({
    method: "PUT",
    fieldName: "deleteUser",
    manual: true,
  });

  // Use Effects
  useEffect(() => {
    if (
      createError ||
      updateError ||
      amhpTeamLoadError ||
      deleteError ||
      fetchPhoneInfoError
    ) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    amhpTeamLoadError,
    createError,
    deleteError,
    fetchPhoneInfoError,
    handleBannerMessage,
    updateError,
  ]);

  useEffect(() => {
    if (!!initialState) {
      constantState.current = initialState;
    }
  }, [initialState]);

  // This function resets the form to the original values
  const resetForm = useCallback(() => {
    setFormErrors(() => ({}));
    dispatch({ type: AmhpActionTypes.RESET, payload: constantState.current });
  }, []);

  // This Function deals with the submit operation of add/update AMHP
  const handleAddUpdateAMHP = useCallback(async () => {
    await validateFormDataAsync(
      AMHPFormSchemaRefined,
      state,
      () => {
        if (!user) {
          createAmhp({
            input: {
              name: state.name,
              email: state.email,
              phone: state.phone,
              mfa: state.mfa,
              sso: state.sso === SSO_LIST.Microsoft ? state.sso : undefined,
              isTraineeAmhp: state.isTraineeAmhp,
              type: state.type,
              teams: state.teams?.map((val) => {
                return val.key;
              }),
              updatedBy: COMMON.EMPTY_STRING,
            },
          })
            .then((value) => {
              if (!value || !value.data) {
                handleBannerMessage(
                  COMMON.TYPE_ERROR,
                  MESSAGES.UNEXPECTED_ERROR_MESSAGE
                );
              } else {
                if (value.data.id === "alreadyExists") {
                  handleBannerMessage(
                    COMMON.TYPE_ERROR,
                    MESSAGES.EXISTS_ERROR.replace("{name}", "User")
                  );
                } else if (value.data.id === "phoneAlreadyExists") {
                  handleBannerMessage(
                    COMMON.TYPE_ERROR,
                    MESSAGES.EXISTS_ERROR.replace("{name}", "Phone Number")
                  );
                } else {
                  handleBannerMessage(
                    COMMON.TYPE_SUCCESS,
                    MESSAGES.ADDED_SUCCESS.replace(
                      "{name}",
                      `${MESSAGES.AMHP_MESSAGE}${COMMON.EMPTY_SPACE}${value.data.name}`
                    )
                  );
                  resetForm();
                }
              }
            })
            .catch(() => {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            });
        } else {
          if (isEqual(state, constantState.current)) {
            handleBannerMessage(COMMON.TYPE_INFO, MESSAGES.NO_CHANGES);
            return;
          }
          updateAmhp({
            input: {
              id: user.id,
              name: state.name,
              email: state.email,
              phone: state.phone,
              mfa: state.mfa,
              sso: state.sso === SSO_LIST.Microsoft ? state.sso : undefined,
              isTraineeAmhp: state.isTraineeAmhp,
              teams: state.teams?.map((val) => {
                return val.key;
              }),
              updatedBy: COMMON.EMPTY_STRING,
              type: state.type,
            },
          })
            .then((value) => {
              if (!value || !value.data) {
                handleBannerMessage(
                  COMMON.TYPE_ERROR,
                  MESSAGES.UNEXPECTED_ERROR_MESSAGE
                );
              } else {
                if (value.data.id === "invalidInputs") {
                  handleBannerMessage(
                    COMMON.TYPE_ERROR,
                    MESSAGES.INVALID_INPUTS_MESSAGE
                  );
                } else if (value.data.id === "phoneAlreadyExists") {
                  handleBannerMessage(
                    COMMON.TYPE_ERROR,
                    MESSAGES.EXISTS_ERROR.replace("{name}", "Phone Number")
                  );
                } else {
                  constantState.current = {
                    ...constantState.current,
                    isTraineeAmhp: state.isTraineeAmhp,
                    mfa: state.mfa,
                    sso: state.sso,
                    email: state.email,
                    phone: state.phone,
                    name: state.name,
                    type: state.type,
                    teams: state.teams?.map((val) => {
                      return {
                        key: val.key,
                        value: val.value,
                        featureFlags: val.featureFlags,
                      };
                    }),
                  };
                  handleBannerMessage(
                    COMMON.TYPE_SUCCESS,
                    MESSAGES.UPDATED_SUCCESS.replace(
                      "{name}",
                      `${MESSAGES.AMHP_MESSAGE}${COMMON.EMPTY_SPACE}${value.data.name}`
                    )
                  );
                  setPristine();
                }
              }
            })
            .catch(() => {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            });
        }
      },
      setFormErrors
    );
  }, [
    AMHPFormSchemaRefined,
    state,
    user,
    createAmhp,
    handleBannerMessage,
    resetForm,
    updateAmhp,
    setPristine,
  ]);

  const amhpTeamList: readonly SelectListProps[] =
    amhpTeamData && Array.isArray(amhpTeamData)
      ? compact(
        amhpTeamData.flatMap((val) => {
          if (!val.amhpTeams || !val.amhpTeams.items) return null;
          return compact(
            val.amhpTeams &&
            val.amhpTeams.items &&
            val.amhpTeams.items?.map((val) => {
              if (!val || !val.featureFlags) {
                return null;
              }
              return {
                key: val.id,
                value: val.name,
                featureFlags: getFeatureFlagObjectAMHPTeam(
                  val.featureFlags
                ),
              };
            })
          );
        })
      ).sort((a, b) => a.value.localeCompare(b.value))
      : [];

  useEffect(() => {
    if (isEqual(state, constantState.current)) {
      setPristine();
    } else {
      setDirty();
    }
  }, [setDirty, setPristine, state]);

  // This function deals with the delete operation of AMHP
  const handleDelete = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.INVALID_USER_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.CONFIRM_DELETED.replace("this item", `${user?.name}`),
      () => {
        deleteUser({
          input: {
            id: user.id,
            type: "AMHP",
            teams: state.teams?.map((val) => {
              return val.key;
            }),
          },
        })
          .then((value) => {
            if (!value || !value.data) {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            } else {
              handleBannerMessage(
                COMMON.TYPE_SUCCESS,
                MESSAGES.DELETED_SUCCESS.replace(
                  "{name}",
                  `${MESSAGES.AMHP_MESSAGE}${COMMON.EMPTY_SPACE}${user.name}`
                )
              );
              resetForm();
              handleCancel && handleCancel();
            }
          })
          .catch(() => {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          });
      },
      "Warning!"
    );
  }, [
    deleteUser,
    handleCancel,
    handleConfirmation,
    handleBannerMessage,
    resetForm,
    state.teams,
    user,
  ]);

  // This function deals with the reactivation operation of AMHP
  const handleReenable = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.INVALID_USER_MESSAGE);
      return;
    }
    handleConfirmation(MESSAGES.CONFIRMATION_MESSAGE, () => {
      reenableUser({
        input: {
          id: user.id,
          type: "AMHP",
        }
      })
        .then((value) => {
          if (!value || !value.data || !!value.error) {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          } else {
            handleBannerMessage(
              COMMON.TYPE_SUCCESS,
              MESSAGES.RESTORED_SUCCESS.replace(
                "{name}",
                `${MESSAGES.AMHP_MESSAGE}${COMMON.EMPTY_SPACE}${user.name}`
              )
            );
            resetForm();
            handleCancel && handleCancel()
          }
        })
        .catch(() => {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        });
    });
  }, [handleConfirmation, handleBannerMessage, reenableUser, resetForm, user, handleCancel]);

  useEffect(() => {
    if (
      state.teams.some(
        (val) => !!val.featureFlags && val.featureFlags.requiresMFA
      )
    ) {
      dispatch({
        type: AmhpActionTypes.SET_MFA,
        payload: { mfa: true },
      });
    }

    if (
      state.teams.some(
        (val) => !!val.featureFlags && val.featureFlags.requiresSSO
      )
    ) {
      dispatch({
        type: AmhpActionTypes.SET_SSO,
        payload: { sso: "Microsoft" },
      });
    }
  }, [state.teams]);

  // Handles displaying and closing 'user deleted' snackbar
  useEffect(() => {
    if (user?.deleted) {
      handleSnackbar(COMMON.TYPE_ERROR, MESSAGES.USER_DELETED, -1, true)
    }
    return () => {
      closeSnackbar();
    };

  }, [user, handleSnackbar, closeSnackbar])

  return (
    <>
      {Prompt}
      <ActivityDialog
        data={dialogData?.getUserActivities ?? []}
        open={dialogOpen}
        loading={dialogLoading}
        setDialogOpen={setDialogOpen}
        error={dialogError}
      />
      <Grid
        container
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        mt={1}
        spacing={3}
      >
        <Grid container spacing={3} item xs={6}>
          <Grid item mt={1} xs={12}>
            <StyledInput
              disabled={readOnly || !!user?.deleted}
              id={FIELDS.ID_NAME}
              label={FIELDS.LABEL_NAME}
              value={state.name}
              onBlur={(event) => {
                validateInput(
                  AMHPFormSchema,
                  "name",
                  { name: event.target.value },
                  setFormErrors
                );
              }}
              onChange={(event) => {
                resetInput("name", setFormErrors);
                dispatch({
                  type: AmhpActionTypes.SET_NAME,
                  payload: {
                    name: event.target.value,
                  },
                });
              }}
              error={!!formErrors?.name}
              errorText={formErrors?.name}
              maxLength={255}
              required={true}
              endAdornment={
                user && (
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id={FIELDS.ID_NAME}
                      value={state.name}
                    />
                  </InputAdornment>
                )
              }
              autoFocus
            />
          </Grid>
          <Grid item mt={1} xs={12}>
            <StyledInput
              disabled={readOnly || !!user?.deleted}
              id={FIELDS.ID_EMAIL}
              label={FIELDS.LABEL_EMAIL}
              value={state.email}
              onBlur={(event) => {
                validateInput(
                  AMHPFormSchema,
                  "email",
                  { email: event.target.value },
                  setFormErrors
                );
              }}
              onChange={(event) => {
                resetInput("email", setFormErrors);
                dispatch({
                  type: AmhpActionTypes.SET_EMAIL,
                  payload: {
                    email: event.target.value,
                  },
                });
              }}
              error={!!formErrors?.email}
              errorText={formErrors?.email}
              maxLength={255}
              required={true}
              endAdornment={
                user && (
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id={FIELDS.ID_EMAIL}
                      value={state.email}
                    />
                  </InputAdornment>
                )
              }
            />
          </Grid>
          <Grid item mt={1} xs={12}>
            <StyledInput
              disabled={readOnly || !!user?.deleted}
              id={FIELDS.ID_PHONE}
              label={FIELDS.LABEL_PHONE}
              value={state.phone}
              onBlur={async (event) => {
                let phone = event.target.value.replace(
                  PHONE_NUMBER_VALIDATION_REGULAR_EXPRESSION,
                  COMMON.EMPTY_STRING
                );
                await validateInputForZodEffectAsync(
                  AMHPFormSchemaPickedForMFAAndPhoneRefined,
                  "phone",
                  {
                    phone: phone,
                    mfa: state.mfa,
                  },
                  setFormErrors
                );
              }}
              onChange={(event) => {
                resetInput("phone", setFormErrors);
                dispatch({
                  type: AmhpActionTypes.SET_PHONE,
                  payload: {
                    phone: event.target.value,
                  },
                });
              }}
              error={!!formErrors?.phone}
              errorText={formErrors?.phone}
              maxLength={255}
              required={state.mfa}
              /*placeholder="Only numbers and plus sign are allowed"*/
              helperText={MESSAGES.PHONE_NUMBER_FORMAT_HELPER_MESSAGE}
              endAdornment={
                user && (
                  <InputAdornment position="end">
                    <CopyToClipboardButton
                      id={FIELDS.ID_PHONE}
                      value={state.phone}
                    />
                  </InputAdornment>
                )
              }
            />
          </Grid>

          {/* <Grid item mt={1} xs={12}>
              <StyledSelect
                id={FIELDS.ID_AMHP_TYPE}
                label={FIELDS.LABEL_AMHP_TYPE}
                required
                onChange={(event: SelectChangeEvent<unknown>) => {
                  const { value } = event.target;

                  dispatch({
                    type: AmhpActionTypes.SET_TYPE,
                    payload: { type: String(value) },
                  });

                  resetInput("type", setFormErrors);
                }}
                value={!!state.type? state.type : "normal"}
                menuItems={AMHP_TYPE_LIST}
                error={!!formErrors.type}
                errorText={formErrors.type}
              />
            </Grid> */}


          <Grid item mt={1} xs={12}>
            <StyledMultipleSelect
              disabled={readOnly || !!user?.deleted}
              id={FIELDS.ID_TEAMS}
              options={amhpTeamList}
              loading={amhpTeamLoading}
              handlingState={state.teams}
              onBlurHandlingFunction={(_event) => {
                validateInput(
                  AMHPFormSchema,
                  "teams",
                  { teams: state.teams },
                  setFormErrors
                );
              }}
              handlingFunction={(_event, value) => {
                resetInput("teams", setFormErrors);
                dispatch({
                  type: AmhpActionTypes.SET_TEAMS,
                  payload: {
                    teams: compact(
                      value.map((val) => {
                        if (!val.featureFlags) {
                          return null;
                        }
                        return {
                          key: val.key,
                          value: val.value,
                          featureFlags: val.featureFlags,
                        };
                      })
                    ),
                  },
                });
              }}
              userData={constantState.current.teams}
              error={!!formErrors?.teams}
              errorText={formErrors?.teams}
              label={FIELDS.LABEL_TEAMS}
              required={true}
            />
          </Grid>
          <Grid item mt={1} xs={12}>
            <StyledSelect
              id={FIELDS.ID_SSO}
              value={state.sso}
              label={FIELDS.LABEL_SSO}
              required
              disabled={
                readOnly ||
                state.teams.some(
                  (val) => !!val.featureFlags && val.featureFlags.requiresSSO
                ) ||
                constantState.current.sso === "Microsoft" || !!user?.deleted
              }
              onBlur={(event) => {
                if (
                  !state.teams.some(
                    (val) => !!val.featureFlags && val.featureFlags.requiresSSO
                  )
                ) {
                  validateInput(
                    AMHPFormSchema,
                    "sso",
                    { sso: String(event.target.value) },
                    setFormErrors
                  );
                }
              }}
              onChange={(event: SelectChangeEvent<unknown>) => {
                if (
                  !state.teams.some(
                    (val) => !!val.featureFlags && val.featureFlags.requiresSSO
                  )
                ) {
                  resetInput("sso", setFormErrors);
                  dispatch({
                    type: AmhpActionTypes.SET_SSO,
                    payload: { sso: String(event.target.value) },
                  });
                }
              }}
              menuItems={SSO_OPTION_LIST}
              error={!!formErrors?.sso}
              errorText={formErrors?.sso}
              helperText={
                state.sso === SSO_LIST.Microsoft
                  ? MESSAGES.SSO_SELECTED_HELPER_MESSAGE + MESSAGES.MICROSOFT_SSO_SELECTED_HELPER_MESSAGE
                  : COMMON.EMPTY_STRING
              }
            />
          </Grid>
        </Grid>

        <Grid
          container
          display="flex"
          flexDirection="column"
          spacing={1}
          item
          xs={6}
        >
          {!!user && (
            <Grid container spacing={2} item ml={6}>
              <Grid item>
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={async () => {
                    setDialogOpen(true);
                    refetch({
                      username: user.id,
                    });
                  }}
                  style={{ marginRight: 10 }}
                >
                  Login Activity
                </LoadingButton>
                <LoadingButton
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => setAudit(true)}
                >
                  Audit
                </LoadingButton>
              </Grid>
            </Grid>
          )}
          <Grid item ml={8}>
            <StyledCheckBox
              id={FIELDS.ID_MFA}
              disabled={
                readOnly ||
                state.teams.some(
                  (val) => !!val.featureFlags && val.featureFlags.requiresMFA
                ) ||
                constantState.current.mfa
                || !!user?.deleted
              }
              label={FIELDS.LABEL_FORCE_MFA}
              checked={state.mfa}
              handleChange={() => {
                if (
                  !state.teams.some(
                    (val) => !!val.featureFlags && val.featureFlags.requiresMFA
                  )
                ) {
                  dispatch({
                    type: AmhpActionTypes.SET_MFA,
                    payload: { mfa: !state.mfa },
                  });
                }
              }}
            />
            <StyledCheckBox
              id={FIELDS.ID_IS_TRAINEE_AMHP}
              label={FIELDS.LABEL_IS_TRAINEE_AMHP}
              checked={state.isTraineeAmhp}
              handleChange={() =>
                dispatch({
                  type: AmhpActionTypes.SET_IS_TRAINEE_AMHP,
                  payload: { isTraineeAmhp: !state.isTraineeAmhp },
                })
              }
              disabled={readOnly || !!user?.deleted}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12}>

          {!!user ? (
            <>
              {!user.deleted ? (
                <>
                  <Grid item>
                    <LoadingButton
                      disabled={readOnly}
                      id={FIELDS.ID_ADD_UPDATE}
                      variant="contained"
                      color="primary"
                      size="large"
                      loading={!!user ? updateLoading : createLoading}
                      onClick={handleAddUpdateAMHP}
                    >
                      {!!user ? COMMON.TEXT_UPDATE : COMMON.TEXT_ADD}
                      {COMMON.EMPTY_SPACE}AMHP
                    </LoadingButton>
                  </Grid>
                  <Grid item>
                    <LoadingButton
                      disabled={readOnly}
                      id={FIELDS.ID_DELETE}
                      variant="contained"
                      color="error"
                      size="large"
                      loading={deleteLoading}
                      onClick={handleDelete}
                    >
                      Delete AMHP
                    </LoadingButton>
                  </Grid>
                </>) : (<>
                  <Grid item>
                    <LoadingButton
                      id={FIELDS.ID_REACTIVATE}
                      variant="outlined"
                      color="secondary"
                      size="large"
                      loading={reenableLoading}
                      onClick={
                        handleReenable
                      }
                      disabled={readOnly}
                    >
                      Restore AMHP
                    </LoadingButton>
                  </Grid>
                </>)}

              <Grid item>
                <LoadingButton
                  disabled={readOnly}
                  id={FIELDS.ID_CANCEL}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => {
                    handleConfirmation(
                      MESSAGES.CONFIRM_CANCEL,
                      () => {
                        handleCancel();
                      },
                      "Confirm"
                    );
                  }}
                >
                  Cancel
                </LoadingButton>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <LoadingButton
                  disabled={readOnly}
                  id={FIELDS.ID_ADD_UPDATE}
                  variant="contained"
                  color="primary"
                  size="large"
                  loading={ createLoading}
                  onClick={handleAddUpdateAMHP}
                >
                  {COMMON.TEXT_ADD}
                  {COMMON.EMPTY_SPACE}AMHP
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  disabled={readOnly}
                  id={FIELDS.ID_RESET}
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => {
                    handleConfirmation(
                      MESSAGES.CONFIRM_CLEAR,
                      () => {
                        resetForm();
                      },
                      "Warning!"
                    );
                  }}
                >
                  Clear
                </LoadingButton>
              </Grid>
            </>
          )}
        </Grid>
        {user?.id && (
          <Modal open={showAudit} onClose={() => setAudit(false)}>
            <EventModalContent
              filter={{
                fieldName: "getAuditLog",
                category: "amhpProfile",
                id: user.id,
              }}
            />
          </Modal>
        )}
      </Grid >
    </>
  );
};

export default React.memo(AMHPForm);
