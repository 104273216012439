import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { styled } from "@mui/material/styles";
// Redux

// Material UI
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";

// Material UI Icons
import MoreVertIcon from "@mui/icons-material/MoreVert";

// Custom imports
import { useAuth } from "hooks";
import Logo from "components/logo";
import { useStyles } from "./styles";
import * as constants from "common/constants/theme";

import { ProfileOptionsLoggedIn } from "./profileConfig";
import { IconButton } from "@mui/material";
import { Menu } from "@mui/icons-material";
import { DRAWER_WIDTH } from "common/constants/ui";

interface AppBarProps {
  drawerOpen: boolean;
  setDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Appbar: React.FC<AppBarProps> = ({ drawerOpen, setDrawerOpen }) => {
  const { authService, user, clearCredentials } = useAuth();
  const classes = useStyles({ loggedIn: !!user });
  const navigate = useNavigate();
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [anchorPosition, setAnchorPosition] = React.useState({
    x: 2000,
    y: 40,
  });

  const profileOptions = [...ProfileOptionsLoggedIn];

  // The user has clicked on the profile icon while the popover menu is open
  const handleMenuClose = () => {
    setPopoverOpen(false);
  };

  // Close the menu before redirecting to signin screen
  const handleSignout = async () => {
    try {
      handleMenuClose();
      authService && (await authService.signout());
      clearCredentials && clearCredentials();
    } catch (err) {
      console.log("Error signing out: ", err);
    }
  };

  // Handle the user selection from the popover menu
  const handleProfileSelection = (selected: number) => {
    switch (selected) {
      case 0:
        handleSignout();
        break;
      case 99:
        break;
      default:
        navigate(`/${profileOptions[selected].path}`);
    }
  };

  const id = popoverOpen ? "simple-popover" : undefined;
  const renderMenu = (
    <Popover
      data-test-id="user-menu"
      id={id}
      elevation={0}
      PaperProps={{
        classes: {
          root: classes.popoverPaper,
        },
        square: true,
      }}
      open={popoverOpen}
      onClose={handleMenuClose}
      anchorReference="anchorPosition"
      anchorPosition={{
        left: anchorPosition.x,
        top: anchorPosition.y,
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: -11,
        horizontal: "right",
      }}
    >
      <Fade in={popoverOpen} {...(popoverOpen ? { timeout: 300 } : {})}>
        <List classes={{ root: classes.list }}>
          {profileOptions?.map(
            ({ key, label, icon: Icon, divider, testId }) => (
              <ListItemButton
                key={`${key}${label}`}
                data-test-id={testId}
                divider={divider}
                onClick={() => handleProfileSelection(key)}
                classes={{
                  root: classes.listItem,
                }}
              >
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText secondary={label} />
              </ListItemButton>
            )
          )}
        </List>
      </Fade>
    </Popover>
  );

  interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
  }

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      marginLeft: `${DRAWER_WIDTH}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <div className={classes.grow}>
      <AppBar
        data-test-id="application-appbar"
        position="fixed"
        elevation={0}
        className={clsx(classes.appBar)}
        classes={{
          root: classes.root,
        }}
        open={drawerOpen}
      >
        <Toolbar
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: constants.s12Blue,
          }}
        >
          <Box ml={2}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={() => setDrawerOpen(true)}
              edge="start"
              sx={{ ...(drawerOpen && { display: "none" }) }}
            >
              <Menu />
            </IconButton>
          </Box>
          <Box ml={1}>
            <Logo />
          </Box>
          <Box pl={1.5}>
            <Typography variant="h3">S12 Admin</Typography>
          </Box>
          <Box pl={1.5}>
            {(() => {
              switch (process.env.REACT_APP_ENV) {
                case "preprod":
                  return (
                    <Chip
                      label="Preprod"
                      className={classes.modeChipWhite}
                      size="small"
                    />
                  );
                case "dev":
                  return (
                    <Chip
                      label="Development"
                      className={classes.modeChipRed}
                      size="small"
                    />
                  );
                case "sandbox":
                  return (
                    <Chip
                      label="Sandbox"
                      className={classes.modeChipPink}
                      size="small"
                    />
                  );
                default:
                  return null;
              }
            })()}
          </Box>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            {user?.attributes?.name && (
              <Typography
                data-test-id="user-loggedin"
                className={classes.title}
                variant="h6"
                noWrap
              >
                {`${user.attributes.name}`}
              </Typography>
            )}
            <MenuItem
              data-test-id="user-menu-button"
              onClick={(event) => {
                setAnchorPosition({
                  x: event.currentTarget.getBoundingClientRect().right,
                  y: event.currentTarget.getBoundingClientRect().bottom,
                });

                setPopoverOpen((open) => !open);
              }}
              classes={{
                root: clsx(classes.profileButton, !user && classes.loggedOut),
              }}
            >
              <MoreVertIcon />
            </MenuItem>
          </div>
        </Toolbar>
      </AppBar>
      {renderMenu}
    </div>
  );
};

export default React.memo(Appbar);
