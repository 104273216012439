import React from "react";

// Hooks
import { usePopups } from "hooks";

// Material UI
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import * as constants from "common/constants/theme";

interface ConfirmationDialogProps {
  open: boolean;
  message: string;
  title?: string;
  closeConfirmation: () => void;
  successCallback: () => any;
}

// Extracted for ease of testing
export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  message,
  title = "Confirm",
  closeConfirmation,
  successCallback,
}) => (
  <>
    <Dialog
      open={open}
      maxWidth="sm"
      PaperProps={{
        style: {
          borderRadius: 8,
          minHeight: 220,
          // width: window.innerWidth / 3,
        },
      }}
      onBackdropClick={closeConfirmation}
    >
      <DialogTitle data-test-id="title">
        {title === "Warning!" ? (
          <Typography
            component="div"
            variant="h1"
            sx={{ fontWeight: "bold", color: constants.darkRed }}
          >
            {title}
          </Typography>
        ) : (
          <Typography component="div" variant="h1" sx={{ fontWeight: "bold" }}>
            {title}
          </Typography>
        )}
      </DialogTitle>
      <DialogContent dividers>
        <Typography
          sx={{
            paddingRight: 1,
            paddingLeft: 1,
            fontSize: 20,
            whiteSpace: "pre-wrap",
          }}
        >
          {message}
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ paddingRight: 2, paddingBottom: 1.5, paddingTop: 1.5 }}
      >
        <Button
          data-test-id="cancel-button"
          autoFocus
          onClick={closeConfirmation}
          variant={
            title === "Warning!" || title === "Confirm"
              ? "contained"
              : "outlined"
          }
        >
          No
        </Button>
        <Button
          data-test-id="confirm-button"
          onClick={successCallback}
          variant={
            title === "Warning!" || title === "Confirm"
              ? "outlined"
              : "contained"
          }
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

const Confirmation: React.FC = () => {
  const {
    confirmation: { message, successCallback, title },
    closeConfirmation,
  } = usePopups();
  const isOpen = message && successCallback ? true : false;

  if (!isOpen) return <></>;

  return (
    <ConfirmationDialog
      open={isOpen}
      message={message}
      title={title}
      closeConfirmation={closeConfirmation}
      successCallback={successCallback}
    />
  );
};

export default React.memo(Confirmation);
