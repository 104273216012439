import LogoutIcon from "@mui/icons-material/Logout";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
const { version, devBuild } = require("../../../package.json");

type ProfileOptions = {
  key: number;
  label: string;
  icon: any;
  path?: string;
  testId?: string;
  divider: boolean;
};

const ProfileOptionsLoggedIn: ProfileOptions[] = [
  {
    key: 0,
    label: "Sign out",
    divider: true,
    icon: LogoutIcon,
    testId: "user-menu-logout",
  },
  {
    key: 99,
    label: `Version: ${version}${process.env.REACT_APP_ENV !== "production" ? devBuild : ""
      }`,
    divider: false,
    icon: BuildCircleIcon,
    testId: "user-menu-version",
  },
];

export { ProfileOptionsLoggedIn };
