import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";

// Material UI Imports
import { Grid, InputAdornment, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useAPI, useAuth, usePopups } from "hooks";
import { StyledInput } from "components/uiElements";
import TeamFeatures from "components/teamFeatures";
import { compact, isEqual } from "lodash";
import * as COMMON from "common/constants/common";
import * as MESSAGES from "common/constants/messages";
import {
  CreateUserHasFormGroupsInput,
  DeleteUserInput,
  ListUsersHasFormGroupsResponse,
  StatFormGroup,
  UpdateUserHasFormGroupsInput,
  User,
  UserHasFormGroupsResponse,
} from "@s12solutions/types";
import useUnsavedChangesWarning from "hooks/useUnsavedChangesWarning";
import CopyToClipboardButton from "components/clipBoard";
import { useNavigate, useLocation } from "react-router-dom";
import { FormErrors } from "common/types/formErrors";
import {
  validateInput,
  validateInputForZodEffect,
} from "utils/zod/validateInput";
import {
  StatFormHospitalRefined,
  StatFormSchema,
  StatFormSchemaRefined,
} from "utils/zod/schemas/StatFormSchema";
import { resetInput, validateFormData } from "utils/zod";
import ActivityDialog from "components/activityDialog";
import {
  GetUserActivitiesResponseItem,
  GetUserActivitiesInput,
} from "common/types/commonTypes";

interface StatFormUserProps {
  handleCancel?: any;
  user?: ListUsersHasFormGroupsResponse | null;
}

const StatFormUser: React.FC<StatFormUserProps> = ({ handleCancel, user }) => {
  const readOnly = useAuth().user?.attributes["custom:limit_admin"];
  const location: any = useLocation();
  const navigate = useNavigate();
  if (location?.state?.isSameMenu && handleCancel) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
    handleCancel();
  }
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    data: dialogData,
    loading: dialogLoading,
    error: dialogError,
    trigger: refetch,
  } = useAPI<GetUserActivitiesResponseItem, GetUserActivitiesInput>({
    method: "GET",
    fieldName: "getUserActivities",
    manual: true,
  });

  // Initial values stores on a memoized state
  let INITIAL_VALUES = useMemo(() => {
    return {
      email: user ? user.email : "",
      name:
        user &&
        user.name &&
        user.formGroups &&
        user.formGroups.includes("Hospital") &&
        user.name.split("-").length === 2
          ? user.name.split("-")[1].trim()
          : user && user.name
          ? user.name
          : "",
      hospitalName:
        user &&
        user.name &&
        user.formGroups &&
        user.formGroups.includes("Hospital") &&
        user.hospitalName
          ? user.hospitalName
          : user && user.name.split("-").length === 2
          ? user.name.split("-")[0].trim()
          : "",
      formGroups: user && user.formGroups ? compact(user.formGroups) : [],
    };
  }, [user]);
  const constantState = useRef(INITIAL_VALUES);
  // States
  const [email, setEmail] = useState(constantState.current.email);
  const [name, setName] = useState(constantState.current.name);
  const [hospitalName, setHospitalName] = useState(
    constantState.current.hospitalName
  );
  const [formGroups, setFormGroups] = useState(
    constantState.current.formGroups
  );
  // Stat Form Group List values stores on a memoized state
  const statFormGroupList: {
    id: string;
    tooltip: string;
    value: StatFormGroup;
    label: StatFormGroup;
    infoIcon: boolean;
    checked?: boolean;
  }[] = useMemo(() => {
    return [
      {
        id: "admission",
        tooltip: "",
        value: "Admission",
        label: "Admission",
        infoIcon: false,
        checked: formGroups.includes("Admission"),
      },
      {
        id: "cto",
        tooltip: "",
        value: "CTO",
        label: "CTO",
        infoIcon: false,
        checked: formGroups.includes("CTO"),
      },
      {
        id: "guardianship",
        tooltip: "",
        value: "Guardianship",
        label: "Guardianship",
        infoIcon: false,
        checked: formGroups.includes("Guardianship"),
      },
      {
        id: "hospital",
        tooltip: "",
        value: "Hospital",
        label: "Hospital",
        infoIcon: false,
        checked: formGroups.includes("Hospital"),
      },
      {
        id: "medrecs",
        tooltip: "",
        value: "MedRecs",
        label: "MedRecs",
        infoIcon: false,
        checked: formGroups.includes("MedRecs"),
      },
      {
        id: "treatment",
        tooltip: "",
        value: "Treatment",
        label: "Treatment",
        infoIcon: false,
        checked: formGroups.includes("Treatment"),
      },
    ];
  }, [formGroups]);

  // Popup and alert declaration
  const { handleConfirmation, handleBannerMessage } = usePopups();

  // Mutations
  const {
    trigger: createUserHasFormGroups,
    loading,
    error,
  } = useAPI<
    UserHasFormGroupsResponse | null,
    { input: CreateUserHasFormGroupsInput }
  >({
    method: "POST",
    fieldName: "createUserHasFormGroups",
    manual: true,
  });
  const {
    trigger: updateUserHasFormGroups,
    loading: updateLoading,
    error: updateError,
  } = useAPI<
    UserHasFormGroupsResponse | null,
    { input: UpdateUserHasFormGroupsInput }
  >({
    method: "PUT",
    fieldName: "updateUserHasFormGroups",
    manual: true,
  });
  const {
    trigger: deleteUser,
    loading: deleteLoading,
    error: deleteError,
  } = useAPI<User | null, { input: DeleteUserInput }>({
    method: "PUT",
    fieldName: "deleteUser",
    manual: true,
  });

  useEffect(() => {
    if (error || updateError || deleteError) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [deleteError, error, handleBannerMessage, updateError]);
  // Set form groups upon selecting
  const handleFormGroups = (checked: boolean, value: StatFormGroup) => {
    if (checked) {
      setFormGroups((formGroups) => [...formGroups, value]);
    } else {
      setFormGroups((formGroups) => [
        ...formGroups.filter((val) => val !== value),
      ]);
    }
  };

  // Handle Resetting values
  const handleReset = useCallback(() => {
    setEmail(constantState.current.email);
    setName(constantState.current.name);
    setFormGroups(constantState.current.formGroups);
    setHospitalName(constantState.current.hospitalName);
    setFormErrors({});
  }, [user]);

  // This call back function handle the disable stat form user
  const handleDisable = useCallback(() => {
    if (!user) {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.INVALID_USER_MESSAGE);
      return;
    }
    handleConfirmation(
      MESSAGES.CONFIRM_DISABLE.replace(
        "{name}",
        `${
          formGroups.includes("Hospital") && hospitalName.trim().length > 0
            ? `${hospitalName} - ${name}`
            : name
        }`
      ),
      () => {
        deleteUser({
          input: {
            id: user.id,
            type: "STATFORMS",
          },
        })
          .then((result) => {
            if ((!deleteError || !result.error) && result.data) {
              handleBannerMessage(
                COMMON.TYPE_SUCCESS,
                MESSAGES.DISABLED_SUCCESS.replace(
                  "{name}",
                  `${MESSAGES.STAT_FORM_USER}${COMMON.EMPTY_SPACE}${result.data.id}`
                )
              );
              setPristine();
              handleCancel();
            } else {
              handleBannerMessage(
                COMMON.TYPE_ERROR,
                MESSAGES.UNEXPECTED_ERROR_MESSAGE
              );
            }
          })
          .catch(() => {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          });
      },
      "Warning!"
    );
  }, [
    user,
    handleConfirmation,
    formGroups,
    hospitalName,
    name,
    handleBannerMessage,
    deleteUser,
    deleteError,
    setPristine,
    handleCancel,
  ]);

  useEffect(() => {
    if (
      name === constantState.current.name &&
      hospitalName === constantState.current.hospitalName &&
      isEqual(formGroups, constantState.current.formGroups) &&
      email === constantState.current.email
    ) {
      setPristine();
    } else {
      setDirty();
    }
  }, [email, formGroups, hospitalName, name, setDirty, setPristine]);

  // This callback function handles when user click on Add or Update button depending on the form
  const handleSubmit = useCallback(async () => {
    try {
      if (!user) {
        let result = await createUserHasFormGroups({
          input: {
            email,
            name,
            hospitalName,
            formGroups,
          },
        });
        if (!result.error && result.data) {
          if (result.data.exists) {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.STAT_FORM_USER_EXISTS_MESSAGE
            );
          } else {
            handleReset();
            handleBannerMessage(
              COMMON.TYPE_SUCCESS,
              MESSAGES.ADDED_SUCCESS.replace(
                "{name}",
                `${MESSAGES.STAT_FORM_USER}${COMMON.EMPTY_SPACE}${name}`
              )
            );
          }
        } else {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        }
      } else {
        if (
          name === constantState.current.name &&
          hospitalName === constantState.current.hospitalName &&
          isEqual(formGroups, constantState.current.formGroups) &&
          email === constantState.current.email
        ) {
          handleBannerMessage(COMMON.TYPE_INFO, MESSAGES.NO_CHANGES);
          return;
        }
        let result = await updateUserHasFormGroups({
          input: {
            id: user.id,
            email,
            name,
            hospitalName,
            formGroups,
          },
        });

        if (!result.error && result.data) {
          if (!result.data.exists) {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.STAT_FORM_USER_NOT_EXISTS_MESSAGE
            );
          } else {
            constantState.current = {
              ...constantState.current,
              email: email,
              name: name,
              hospitalName: hospitalName,
              formGroups: formGroups,
            };
            handleBannerMessage(
              COMMON.TYPE_SUCCESS,
              MESSAGES.UPDATED_SUCCESS.replace(
                "{name}",
                `${MESSAGES.STAT_FORM_USER}${COMMON.EMPTY_SPACE}${name}`
              )
            );
          }
        } else {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        }
      }
    } catch {
      handleBannerMessage(COMMON.TYPE_ERROR, MESSAGES.UNEXPECTED_ERROR_MESSAGE);
    }
  }, [
    user,
    createUserHasFormGroups,
    email,
    name,
    formGroups,
    hospitalName,
    handleBannerMessage,
    handleReset,
    updateUserHasFormGroups,
  ]);

  const callbackRef = useCallback((inputElement) => {
    if (inputElement) {
      inputElement.focus();
    }
  }, []);

  useEffect(() => {
    if (!!INITIAL_VALUES) {
      constantState.current = INITIAL_VALUES;
    }
  }, [INITIAL_VALUES]);

  return (
    <>
      <ActivityDialog
        data={dialogData?.getUserActivities ?? []}
        open={dialogOpen}
        loading={dialogLoading}
        setDialogOpen={setDialogOpen}
        error={dialogError}
      />
      {Prompt}
      <Grid
        container
        xs={12}
        direction="row"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
        mt={1}
        spacing={3}
      >
        <Grid container item spacing={3} xs={6}>
          <Grid item xs={12}>
            <Stack spacing={3}>
              {formGroups.includes("Hospital") && (
                <StyledInput
                  disabled={readOnly}
                  id="StatFormUserHospitalName"
                  label="Hospital or Trust Name"
                  variant="outlined"
                  error={!!formErrors.hospitalName}
                  errorText={formErrors.hospitalName}
                  value={hospitalName}
                  onBlur={(event) => {
                    validateInputForZodEffect(
                      StatFormHospitalRefined,
                      "hospitalName",
                      {
                        formGroups: formGroups,
                        hospitalName: event.target.value,
                      },
                      setFormErrors
                    );
                  }}
                  onChange={(event) => {
                    resetInput("hospitalName", setFormErrors);
                    setHospitalName(event.target.value);
                  }}
                  maxLength={127}
                  required
                  inputRef={
                    formGroups.includes("Hospital") ? callbackRef : () => {}
                  }
                  endAdornment={
                    user &&
                    user.formGroups &&
                    user.formGroups.includes("Hospital") && (
                      <InputAdornment position="end">
                        <CopyToClipboardButton
                          id="StatFormUserHospitalName"
                          value={hospitalName}
                        />
                      </InputAdornment>
                    )
                  }
                />
              )}
              <StyledInput
                disabled={readOnly}
                id="StatFormUserName"
                label="Ward or Individual Name"
                variant="outlined"
                value={name}
                error={!!formErrors.name}
                errorText={formErrors.name}
                onBlur={(event) => {
                  validateInput(
                    StatFormSchema,
                    "name",
                    { name: event.target.value },
                    setFormErrors
                  );
                }}
                onChange={(event) => {
                  resetInput("name", setFormErrors);
                  setName(event.target.value);
                }}
                maxLength={formGroups.includes("Hospital") ? 127 : 255}
                required
                inputRef={
                  !formGroups.includes("Hospital") ? callbackRef : () => {}
                }
                endAdornment={
                  user && (
                    <InputAdornment position="end">
                      <CopyToClipboardButton
                        id="StatFormUserName"
                        value={name}
                      />
                    </InputAdornment>
                  )
                }
              />

              <StyledInput
                disabled={readOnly}
                id="StatFormUserEmail"
                label="Email address"
                variant="outlined"
                onBlur={(event) => {
                  validateInput(
                    StatFormSchema,
                    "email",
                    { email: event.target.value },
                    setFormErrors
                  );
                }}
                error={!!formErrors.email}
                errorText={formErrors.email}
                value={email}
                onChange={(event) => {
                  resetInput("email", setFormErrors);
                  setEmail(event.target.value);
                }}
                maxLength={255}
                required
                endAdornment={
                  user && (
                    <InputAdornment position="end">
                      <CopyToClipboardButton
                        id="StatFormUserEmail"
                        value={email}
                      />
                    </InputAdornment>
                  )
                }
              />
            </Stack>
          </Grid>
          <Grid item>
            <LoadingButton
              disabled={readOnly}
              id="StatFormSubmit"
              variant="contained"
              color="primary"
              size="large"
              loading={!user ? loading : updateLoading}
              onClick={() => {
                validateFormData(
                  StatFormSchemaRefined,
                  {
                    name: name,
                    hospitalName: hospitalName,
                    email: email,
                    formGroups: formGroups,
                  },
                  handleSubmit,
                  setFormErrors
                );
              }}
            >
              {!user ? "Add User" : "Update User"}
            </LoadingButton>
          </Grid>
          {!user ? (
            <Grid item>
              <LoadingButton
                disabled={readOnly}
                id="StatFormReset"
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => {
                  handleConfirmation(
                    MESSAGES.CONFIRM_CLEAR,
                    () => {
                      handleReset();
                    },
                    "Warning!"
                  );
                }}
              >
                Clear
              </LoadingButton>
            </Grid>
          ) : (
            <>
              <Grid item>
                <LoadingButton
                  disabled={readOnly}
                  id="StatFormDisable"
                  variant="contained"
                  color="error"
                  size="large"
                  loading={deleteLoading}
                  onClick={handleDisable}
                >
                  Disable
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  disabled={readOnly}
                  id="StatFormCancel"
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => {
                    handleConfirmation(
                      MESSAGES.CONFIRM_CANCEL,
                      () => {
                        handleCancel();
                      },
                      "Confirm"
                    );
                  }}
                >
                  Cancel
                </LoadingButton>
              </Grid>
            </>
          )}
        </Grid>
        <Grid container item spacing={3} xs={6}>
          {!!user && (
            <Grid item sm={12} ml={8}>
              <LoadingButton
                id=""
                variant="outlined"
                color="primary"
                size="large"
                onClick={async () => {
                  setDialogOpen(true);

                  refetch({
                    username: user.id,
                  });
                }}
              >
                Login Activity
              </LoadingButton>
            </Grid>
          )}
          <Grid item sm={12} ml={8}>
            <TeamFeatures
              errorText={formErrors.formGroups}
              header="Stat Form Groups"
              handleFeature={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleFormGroups(
                  event.target.checked,
                  event.target.value as StatFormGroup
                )
              }
              teamFeatures={statFormGroupList}
              disabled={readOnly}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default StatFormUser;
