import DoctorForm from "pages/Doctor";
import React, { useEffect, useState } from "react";
import {
  ListUsersFilterInput,
  S12DoctorListItem,
  User,
} from "@s12solutions/types";
import { DoctorSelectForm } from "pages/Doctor";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import {
  EVENTUAL_CONSISTENCY_TIMEOUT,
  HYPHEN,
  MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH,
  TYPE_ERROR,
} from "common/constants/common";
import {
  LABEL_NAME,
  LABEL_EMAIL,
  ID_EMAIL,
  LABEL_PHONE,
  ID_PHONE,
  LABEL_GMC,
  ID_GMC,
  ID_NAME,
  ID_PHONE2,
  LABEL_PHONE2,
  LABEL_STATUS,
  ID_DISABLED,
} from "common/constants/fields";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate, useLocation } from "react-router-dom";
import { UNEXPECTED_ERROR_MESSAGE } from "common/constants/messages";
import { useAPI, usePopups } from "hooks";
import { useDebounce } from "use-debounce";
import Progress from "components/cirularProgress/Progress";

const UpdateDoctor: React.FC = () => {
  const navigate = useNavigate();
  const location: any = useLocation();
  const [doctorId, setDoctorId] = useState<string | null>(null);
  const [doctor, setDoctor] = useState<S12DoctorListItem | null>(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [tableData, setTableData] = useState<User[]>([]);
  const [searchString, setSearchString] = useState("");
  const { handleBannerMessage } = usePopups();

  const [debouncedSearchString] = useDebounce(
    searchString.trim().length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
      ? searchString.trim()
      : "",
    1000
  );

  const {
    trigger: findS12Doctor,
    data: doctorData,
    loading: doctorLoading,
    error: doctorError,
  } = useAPI<
    S12DoctorListItem | null,
    {
      id: string;
    }
  >({
    method: "GET",
    fieldName: "findS12Doctor",
    manual: true,
  });

  // Queries
  const {
    trigger: getS12Doctors,
    data: s12DoctorData,
    loading: s12DoctorLoading,
    error: s12DoctorError,
  } = useAPI<
    User[] | null,
    {
      filter: ListUsersFilterInput;
    }
  >({
    method: "GET",
    fieldName: "listUsers",
    args: {
      filter: {
        searchString: debouncedSearchString,
        userType: "Doctor",
      },
    },
    manual: true,
  });

  useEffect(() => {
    if (s12DoctorData && !s12DoctorLoading && !pageLoading) {
      setTableData(s12DoctorData);
    } else {
      setTableData([]);
    }

    return () => {
      setTableData([]);
    };
  }, [pageLoading, s12DoctorData, s12DoctorLoading]);

  useEffect(() => {
    if (doctorData && !doctorLoading && !doctorError) {
      setDoctor(doctorData);
    } else {
      setDoctor(null);
    }
  }, [doctorData, doctorError, doctorLoading]);

  // Handle Search
  useEffect(() => {
    function handleSearch() {
      getS12Doctors();
    }
    if (debouncedSearchString.length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH) {
      handleSearch();
    }
  }, [debouncedSearchString, getS12Doctors]);

  useEffect(() => {
    if (s12DoctorError || doctorError) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [s12DoctorError, handleBannerMessage, doctorError]);

  useEffect(() => {
    if (doctorId) {
      findS12Doctor({
        id: doctorId,
      });
    }
  }, [doctorId, findS12Doctor]);

  useEffect(() => {
    if (location?.search.length > 0) {
      setTimeout(() => {
        navigate(location.pathname);
      }, 8000);
      setDoctorId(decodeURIComponent(location?.search.substring(1)));
    }
  }, [doctorData, location.pathname, location?.search, navigate]);

  useEffect(() => {
    return () => {
      setDoctorId(null);
      setDoctor(null);
      setTableData([]);
    };
  }, []);

  return (
    <>
      <Progress loading={doctorLoading}>
        {!!doctor ? (
          <DoctorForm
            handleCancel={() => {
              setDoctorId(null);
              setDoctor(null);
              navigate(location.pathname);
              setPageLoading(true);
              setTimeout(() => {
                if (
                  debouncedSearchString.trim().length >
                  MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
                ) {
                  getS12Doctors();
                }
                setPageLoading(false);
              }, EVENTUAL_CONSISTENCY_TIMEOUT);
            }}
            user={doctor}
            title="Update Doctor"
          />
        ) : (
          <DoctorSelectForm
            title="Update Doctor"
            setDoctorId={setDoctorId}
            searchString={searchString}
            setSearchString={setSearchString}
            tableData={tableData}
            tableLoading={s12DoctorLoading || pageLoading}
            tableError={doctorError}
            headerData={[
              {
                field: "actions",
                type: "actions",
                width: 80,
                getActions: (params: any) => [
                  <GridActionsCellItem
                    icon={<EditIcon color="primary" />}
                    label="Edit"
                    onClick={() => {
                      setDoctorId(params.row.id);
                    }}
                  />,
                  <GridActionsCellItem
                    icon={<OpenInNewIcon color="primary" />}
                    label="New Tab"
                    onClick={() => {
                      const queryString = encodeURIComponent(params.row.id);
                      window.open(
                        window.location.href + "?" + queryString,
                        "_blank"
                      );
                    }}
                  />,
                ],
              },
              {
                headerName: LABEL_GMC,
                field: ID_GMC,
                minWidth: 100,
                flex: 1,
              },
              {
                headerName: LABEL_NAME,
                field: ID_NAME,
                minWidth: 200,
                flex: 1,
              },
              {
                headerName: LABEL_EMAIL,
                field: ID_EMAIL,
                minWidth: 200,
                flex: 1,
              },
              {
                headerName: LABEL_PHONE,
                field: ID_PHONE,
                minWidth: 200,
                flex: 1,
                valueGetter: (params: any) => params.row.phone || HYPHEN,
              },
              {
                headerName: LABEL_PHONE2,
                field: ID_PHONE2,
                minWidth: 200,
                flex: 1,
                valueGetter: (params: any) => params.row.phone2 || HYPHEN,
              },
              {
                headerName: LABEL_STATUS,
                field: ID_DISABLED,
                minWidth: 200,
                flex: 1,
                valueGetter: (params: any) =>
                  params.row.disabled ? "Disabled" : "-",
              },
            ]}
          />
        )}
      </Progress>
    </>
  );
};

export default React.memo(UpdateDoctor);
