import { LoadingButton } from "@mui/lab";
import { Grid, Card, Typography, FormControlLabel, Stack } from "@mui/material";
import { StatusCheckResponse } from "@s12solutions/types";
import { DATE_FORMAT_WITHOUT_TIME, TYPE_ERROR } from "common/constants/common";
import { ID_GMC, LABEL_GMC } from "common/constants/fields";
import {
  INVALID_GMC_ERROR_MESSAGE,
  NO_MATCHING_RECORDS,
  UNEXPECTED_ERROR_MESSAGE,
} from "common/constants/messages";
import { isValidGMC } from "common/validators";
import CopyToClipboardButton from "components/clipBoard";
import { StyledInput } from "components/uiElements";
import dayjs from "dayjs";
import { useAPI, usePopups } from "hooks";
import { useCallback, useEffect, useState } from "react";
import { TYPE_INFO } from "common/constants/common";
import { z } from "zod";
import { resetInput, validateFormData, validateInput } from "utils/zod";
import { FormErrors } from "common/types/formErrors";

export default function CheckGMC() {
  const [gmc, setGMC] = useState<string>("");
  const { handleBannerMessage } = usePopups();
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const {
    trigger: fetchDoctorInfo,
    data: fetchDoctorInfoData,
    loading: fetchDoctorInfoLoading,
    error: fetchDoctorInfoError,
  } = useAPI<StatusCheckResponse | null>({
    method: "GET",
    fieldName: "checkGMC",
    manual: true,
  });

  const CheckGMCSchema = z.object({
    gmc: z
      .string()
      .min(1, "GMC is required")
      .length(7, INVALID_GMC_ERROR_MESSAGE)
      .refine(isValidGMC, INVALID_GMC_ERROR_MESSAGE),
  });

  useEffect(() => {
    if (
      (fetchDoctorInfoError && !fetchDoctorInfoLoading) ||
      (fetchDoctorInfoData && !fetchDoctorInfoData && !fetchDoctorInfoLoading)
    ) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
      return;
    }
    if (
      fetchDoctorInfoData &&
      fetchDoctorInfoData.approved === null &&
      fetchDoctorInfoData.gmcNumber === null &&
      fetchDoctorInfoData.section12ExpiryDate === null &&
      fetchDoctorInfoData.surname === null &&
      !fetchDoctorInfoLoading
    ) {
      handleBannerMessage(TYPE_INFO, NO_MATCHING_RECORDS);
      return;
    }
  }, [
    fetchDoctorInfoData,
    fetchDoctorInfoError,
    fetchDoctorInfoLoading,
    handleBannerMessage,
  ]);

  const checkGMC = useCallback(() => {
    validateFormData(
      CheckGMCSchema,
      { gmc: gmc },
      () => {
        fetchDoctorInfo({
          gmc: gmc,
        });
      },
      setFormErrors
    );
  }, [CheckGMCSchema, fetchDoctorInfo, gmc]);

  return (
    <>
      <Grid
        xs={12}
        m={1}
        p={2}
        component={Card}
        direction="column"
        sx={{
          display: "flex",
          borderRadius: 1,
          minHeight: window.innerHeight - 85,
        }}
      >
        <Grid item xs={12}>
          <Typography variant="h1">Check GMC</Typography>
        </Grid>
        <Grid
          container
          xs={12}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          mt={1}
          spacing={3}
        >
          <Grid container item spacing={3} xs={6}>
            <Grid item xs={6}>
              <StyledInput
                id={ID_GMC}
                label={LABEL_GMC}
                value={gmc}
                onBlur={(event) => {
                  validateInput(
                    CheckGMCSchema,
                    "gmc",
                    { gmc: event.target.value },
                    setFormErrors
                  );
                }}
                onChange={(e) => {
                  setGMC(e.target.value);
                  resetInput("gmc", setFormErrors);
                }}
                error={!!formErrors.gmc}
                errorText={formErrors.gmc}
                maxLength={255}
                required={true}
                autoFocus
                fullWidth={false}
              />
            </Grid>
            <Grid item xs={6} mt={1}>
              <LoadingButton
                id="checkGMCButton"
                variant="contained"
                loading={fetchDoctorInfoLoading}
                color="primary"
                size="large"
                onClick={checkGMC}
              >
                Search
              </LoadingButton>
            </Grid>

            <Grid item xs={12} ml={2}>
              <Stack direction="row">
                <FormControlLabel
                  tabIndex={-1}
                  control={<Typography variant="h3">GMC :&nbsp;</Typography>}
                  label={
                    !!fetchDoctorInfoData &&
                    !!fetchDoctorInfoData.gmcNumber &&
                    !fetchDoctorInfoLoading
                      ? fetchDoctorInfoData.gmcNumber
                      : fetchDoctorInfoLoading
                      ? `Loading...`
                      : `N/A`
                  }
                />

                <CopyToClipboardButton
                  id="gmc"
                  value={
                    !!fetchDoctorInfoData &&
                    !!fetchDoctorInfoData.gmcNumber &&
                    !fetchDoctorInfoLoading
                      ? fetchDoctorInfoData.gmcNumber
                      : fetchDoctorInfoLoading
                      ? `Loading...`
                      : `N/A`
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12} ml={2}>
              <Stack direction="row">
                <FormControlLabel
                  tabIndex={-1}
                  control={
                    <Typography variant="h3">GMC Expiry :&nbsp;</Typography>
                  }
                  label={
                    !!fetchDoctorInfoData &&
                    !!fetchDoctorInfoData.section12ExpiryDate &&
                    !fetchDoctorInfoLoading
                      ? dayjs(fetchDoctorInfoData.section12ExpiryDate).format(
                          DATE_FORMAT_WITHOUT_TIME
                        )
                      : fetchDoctorInfoLoading
                      ? `Loading...`
                      : `N/A`
                  }
                />
                <CopyToClipboardButton
                  id="gmc_expiry"
                  value={
                    !!fetchDoctorInfoData &&
                    !!fetchDoctorInfoData.section12ExpiryDate &&
                    !fetchDoctorInfoLoading
                      ? dayjs(fetchDoctorInfoData.section12ExpiryDate).format(
                          DATE_FORMAT_WITHOUT_TIME
                        )
                      : fetchDoctorInfoLoading
                      ? `Loading...`
                      : `N/A`
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12} ml={2}>
              <Stack direction="row">
                <FormControlLabel
                  tabIndex={-1}
                  control={
                    <Typography variant="h3">Approved :&nbsp;</Typography>
                  }
                  label={
                    !!fetchDoctorInfoData && !fetchDoctorInfoLoading
                      ? fetchDoctorInfoData.approved === true
                        ? `Yes`
                        : fetchDoctorInfoData.approved === false
                        ? `No`
                        : `N/A`
                      : fetchDoctorInfoLoading
                      ? `Loading...`
                      : `N/A`
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12} ml={2}>
              <Stack direction="row">
                <FormControlLabel
                  tabIndex={-1}
                  control={
                    <Typography variant="h3">Surname :&nbsp;</Typography>
                  }
                  label={
                    !!fetchDoctorInfoData &&
                    !!fetchDoctorInfoData.surname &&
                    !fetchDoctorInfoLoading
                      ? fetchDoctorInfoData.surname
                      : fetchDoctorInfoLoading
                      ? `Loading...`
                      : `N/A`
                  }
                />

                <CopyToClipboardButton
                  id="surname"
                  value={
                    !!fetchDoctorInfoData &&
                    !!fetchDoctorInfoData.surname &&
                    !fetchDoctorInfoLoading
                      ? fetchDoctorInfoData.surname
                      : fetchDoctorInfoLoading
                      ? `Loading...`
                      : `N/A`
                  }
                />
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
