import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Grid,
  Typography as Text,
  IconButton,
  Box,
  Collapse,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  InputAdornment,
} from "@mui/material";
import { StyledInput } from "components/uiElements";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { useAPI, useAuth, usePopups } from "hooks";
import { CONFIRM_DELETED, CONFIRM_CLEAR } from "common/constants/messages";
import { StyledTreeDataGridPro } from "components/uiElements";
import { makeStyles } from "@mui/styles";
import * as COMMON from "common/constants/common";
import * as MESSAGES from "common/constants/messages";
import {
  UpdateOrganisationInput,
  CreateMhtInput,
  Organisation,
  Maybe,
  RotaItem,
  OrgType,
  Mht,
} from "@s12solutions/types";
import useUnsavedChangesWarning from "hooks/useUnsavedChangesWarning";
import SuccessMessage from "components/successMessage";
import { red, midBaseGrey } from "common/constants/theme";
import { LoadingButton } from "@mui/lab";
import { v4 as uuid } from "uuid";
import ProTable from "components/proTable";
import { useNavigate, useLocation } from "react-router-dom";
import { compact, isEqual } from "lodash";
import { validateInput, resetInput, validateFormData } from "utils/zod";
import { HospitalWardFormSchema } from "utils/zod/schemas/common.zod";
import { FormErrors } from "common/types/formErrors";
import { convertMultipleEmailList } from "common/validators";

const useStyles = makeStyles((theme) => ({
  circle: {
    backgroundColor: midBaseGrey,
    width: 30,
    height: 30,
    borderRadius: 50,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  textColor: {
    color: theme.palette.primary.contrastText,
  },
}));

interface MHTFormProps {
  handleCancel?: any;
  isEditing: boolean;
  org?: Organisation | null;
}

const MHTForm: React.FC<MHTFormProps> = ({ handleCancel, org, isEditing }) => {
  const readOnly = useAuth().user?.attributes["custom:limit_admin"];
  const location: any = useLocation();
  const navigate = useNavigate();
  if (location?.state?.isSameMenu) {
    navigate(location.pathname, {
      state: { isSameMenu: false },
    });
    handleCancel();
  }
  const { handleConfirmation, handleBannerMessage } = usePopups();
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();
  const [hospitalEmailFormErrors, setHospitalEmailFormErrors] =
    useState<FormErrors>({});
  const classes = useStyles();
  const [initialState, setInitialState] = useState<
    Organisation | null | undefined
  >(org);
  const [open, setOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [mHTOrganisationId, setMHTOrganisationId] = useState<string>("");
  const [abbreviation, setAbbreviation] = useState<string>("");
  const [name, setName] = useState<string>(initialState?.name || "");
  const [additionalNotes, setAdditionalNotes] = useState<string>(
    initialState?.additionalNotesText || ""
  );
  const [hospitalEmails, setHospitalEmails] = useState<string>("");
  const [hospitalDialog, setHospitalDialog] = useState(false);
  const [hospitalEmailsData, setHospitalEmailsData] = useState({
    hospital: "",
    ward: "",
    email: "",
  });
  // rotas item data converting

  let tempRotas = useMemo(() => {
    let initialRotas = initialState?.rotas || [];
    let tempRotas: any[] = [];
    initialRotas?.forEach((item) => {
      tempRotas.push({
        id: item?.id,
        name: item?.name,
      });
    });

    return tempRotas;
  }, [initialState?.rotas]);

  const [rotasChips, setRotasChips] = useState<Maybe<RotaItem>[]>(tempRotas);
  const [rotas, setRotas] = useState<string>("");
  const onCellEditCommit = (rowData: any) => {
    setRotasChips((preVState) => {
      return compact(
        preVState.map((item) => {
          if (!item) {
            return null;
          }
          if (item.id === rowData.id) {
            if (rowData.value.length === 0) {
              return {
                id: item.id,
                name: item.name,
              };
            } else {
              return {
                id: item.id,
                name: rowData.value,
              };
            }
          } else {
            return item;
          }
        })
      );
    });
  };
  const rotasColumns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "actions",
      headerName: "",
      flex: 1,
      width: 20,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: any) => {
        return (
          <IconButton
            disabled={readOnly}
            sx={{ color: red }}
            onClick={() => {
              handleConfirmation(CONFIRM_DELETED, () => {
                deleteRotasChips(params);
              });
            }}
          >
            <HighlightOffIcon />
          </IconButton>
        );
      },
    },
    {
      field: "name",
      headerName: "Rotas Name",
      editable: true,
      minWidth: 50,
      flex: 10,
      disableReorder: true,
    },
  ];
  // has email feature data tree
  let temp = useMemo(() => {
    let temp: any[] = [];
    if (initialState?.mhtHospitalEmails) {
      for (let i = 0; i < initialState?.mhtHospitalEmails.length; i++) {
        if (initialState?.mhtHospitalEmails[i]?.wards) {
          let j = initialState?.mhtHospitalEmails[i]?.wards.length || 0;
          for (let k = 0; k < j || 0; k++) {
            temp.push({
              id:
                initialState?.mhtHospitalEmails[i]?.wards[k]?.name?.replace(
                  /\s/g,
                  ""
                ) +
                "_" +
                initialState?.mhtHospitalEmails[i]?.wards[k]?.email?.replace(
                  /\s/g,
                  ""
                ),
              hierarchy: [
                initialState?.mhtHospitalEmails[i]?.name,
                initialState?.mhtHospitalEmails[i]?.wards[k]?.name,
              ],
              wardName: initialState?.mhtHospitalEmails[i]?.wards[k]?.name,
              wardEmail: initialState?.mhtHospitalEmails[i]?.wards[k]?.email,
            });
          }
        }
      }
    }
    return temp;
  }, [initialState?.mhtHospitalEmails]);

  const [proRows, setProRows] = useState<readonly any[]>(temp || []);
  // This logic for tree data converting to object array
  let tempR: any[] = [];
  proRows?.forEach((item) => {
    tempR.push({
      name: item.hierarchy[0],
      wards: [{ name: item.wardName, email: item.wardEmail }],
    });
  });

  const hospitalParams = tempR.reduce((acc, item) => {
    const exist = acc.find((e: any) => e.name === item.name);
    if (exist) {
      exist.wards.push(item.wards[0]);
    } else {
      acc.push({
        name: item.name,
        wards: item.wards,
      });
    }
    return acc;
  }, []);

  const proColumns = [
    {
      field: "wardName",
      headerName: "Ward Name",
      minWidth: 50,
      flex: 4,
      disableReorder: true,
    },
    {
      field: "wardEmail",
      headerName: "Ward Emails",
      minWidth: 50,
      flex: 6,
      disableReorder: true,
    },
    {
      field: "actions",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      width: 20,
      flex: 1,
      renderCell: (params: any) => {
        if (params.rowNode.depth !== 0) {
          return (
            <IconButton
              disabled={readOnly}
              sx={{ color: red }}
              onClick={() => {
                handleConfirmation(CONFIRM_DELETED, () => {
                  deleteHospitalEmail(params);
                });
              }}
            >
              <HighlightOffIcon />
            </IconButton>
          );
        } else return null;
      },
    },
  ];

  const deleteHospitalEmail = (params: any) => {
    setProRows((proRows) => proRows.filter((el) => el.id !== params.id));
  };

  const addHospitalEmail = useCallback(() => {
    validateFormData(
      HospitalWardFormSchema,
      {
        hospitalName: hospitalEmailsData.hospital,
        wardName: hospitalEmailsData.ward,
        wardEmail: hospitalEmailsData.email,
      },
      () => {
        setShowMessage(true);
        let data = {
          hierarchy: [hospitalEmailsData.hospital, hospitalEmailsData.ward],
          wardName: hospitalEmailsData.ward,
          wardEmail: convertMultipleEmailList(hospitalEmailsData.email),
          id:
            hospitalEmailsData.ward.replace(/\s/g, "") +
            "_" +
            convertMultipleEmailList(hospitalEmailsData.email).replace(
              /\s/g,
              ""
            ),
        };
        const found = proRows.some((el) => el.id === data.id);
        if (!found) {
          setProRows((proRows) => [...proRows, data]);
          setHospitalEmailsData((hospitalEmailsData) => {
            return {
              hospital: hospitalEmailsData.hospital,
              ward: "",
              email: "",
            };
          });
        }
      },
      setHospitalEmailFormErrors
    );
  }, [
    hospitalEmailsData.email,
    hospitalEmailsData.hospital,
    hospitalEmailsData.ward,
    proRows,
  ]);

  const deleteRotasChips = (chipToDelete: Maybe<RotaItem>) => {
    setRotasChips((rotasChips) =>
      rotasChips.filter((el) => el?.id !== chipToDelete?.id)
    );
  };

  const addRotasChips = useCallback(() => {
    if (rotasChips.some((i) => i?.name === rotas)) {
      setRotas("");
      return;
    } else {
      setRotasChips((rotasChips) => [
        ...rotasChips,
        {
          id: uuid(),
          name: rotas,
        },
      ]);
      setRotas("");
    }
  }, [rotas, rotasChips]);

  // Mutations
  const { trigger: createMHT, error: addError } = useAPI<
    Mht,
    { input: CreateMhtInput }
  >({
    method: "POST",
    fieldName: "createMHT",
    manual: true,
  });

  const { trigger: createOrganisation, error: createError } = useAPI<
    Organisation,
    { input: UpdateOrganisationInput }
  >({
    method: "POST",
    fieldName: "createOrganisation",
    manual: true,
  });

  const {
    trigger: updateMHT,
    loading: updateLoading,
    error: updateError,
  } = useAPI<Organisation, { input: UpdateOrganisationInput }>({
    method: "PUT",
    fieldName: "updateOrganisation",
    manual: true,
  });

  const mhtUpdate = useCallback(() => {
    if (isUpdate) {
      if (!initialState) {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.UNEXPECTED_ERROR_MESSAGE
        );
        return;
      }
      updateMHT({
        input: {
          id: initialState?.id,
          additionalNotesText: additionalNotes,
          name: name,
          features: initialState?.features,
          type: initialState?.type,
          mhtHospitalEmails: hospitalParams.length > 0 ? hospitalParams : [],
          rotas: rotasChips.length > 0 ? rotasChips : [],
        },
      }).then((value) => {
        if (value && value.data && !updateError) {
          setIsUpdate(false);
          setPristine();
          setInitialState((currentState) => ({
            ...currentState,
            id: initialState.id,
            name: initialState.name,
            type: initialState.type,
            features: initialState.features,
            additionalNotesText: additionalNotes,
            mhtHospitalEmails: hospitalParams.length > 0 ? hospitalParams : [],
            rotas: rotasChips.length > 0 ? rotasChips : [],
          }));
          handleBannerMessage(
            COMMON.TYPE_SUCCESS,
            MESSAGES.UPDATED_SUCCESS.replace("{name}", "MHT")
          );
        } else {
          handleBannerMessage(
            COMMON.TYPE_ERROR,
            MESSAGES.UNEXPECTED_ERROR_MESSAGE
          );
        }
      });
    } else {
      handleBannerMessage(COMMON.TYPE_INFO, MESSAGES.NO_CHANGES);
    }
  }, [
    additionalNotes,
    handleBannerMessage,
    hospitalParams,
    initialState,
    isUpdate,
    name,
    rotasChips,
    setPristine,
    updateError,
    updateMHT,
  ]);

  //Reset Add MHT data
  const resetData = () => {
    setName("");
    setProRows([]);
    setRotasChips([]);
    setAdditionalNotes("");
    setAbbreviation("");
    setMHTOrganisationId("");
    setHospitalEmailFormErrors({});
  };

  const mhtCreate = () => {
    createMHT({
      input: {
        mHTOrganisationId: mHTOrganisationId,
        abbreviation: abbreviation,
      },
    }).then((value) => {
      if (value && value.data && !addError) {
        createOrganisation({
          input: {
            id: value?.data.mHTOrganisationId,
            additionalNotesText: additionalNotes || "",
            name: name || "",
            features: 0,
            type: "MHT" as OrgType,
            mhtHospitalEmails: hospitalParams.length > 0 ? hospitalParams : [],
            rotas: rotasChips.length > 0 ? rotasChips : [],
          },
        }).then((value) => {
          if (value && value.data && !createError) {
            handleBannerMessage(
              COMMON.TYPE_SUCCESS,
              `${MESSAGES.MHT_CREATED_SUCCESS_MESSAGE}`
            );
            resetData();
          } else {
            handleBannerMessage(
              COMMON.TYPE_ERROR,
              MESSAGES.UNEXPECTED_ERROR_MESSAGE
            );
          }
        });
      } else {
        handleBannerMessage(
          COMMON.TYPE_ERROR,
          MESSAGES.UNEXPECTED_ERROR_MESSAGE
        );
      }
    });
  };

  //check page whether edit or not
  useEffect(() => {
    if (!isEditing) {
      if (
        mHTOrganisationId.length > 0 ||
        name.length > 0 ||
        abbreviation.length > 0 ||
        proRows.length > 0 ||
        rotasChips.length > 0 ||
        rotas.length > 0 ||
        additionalNotes.length > 0
      ) {
        setDirty();
      } else {
        setPristine();
      }
    } else {
      if (
        !isEqual(proRows, temp) ||
        !isEqual(rotasChips, tempRotas) ||
        rotas.length > 0 ||
        !isEqual(additionalNotes, initialState?.additionalNotesText)
      ) {
        setIsUpdate(true);
        setDirty();
      } else {
        setIsUpdate(false);
        setPristine();
      }
    }
  }, [
    mHTOrganisationId,
    name,
    abbreviation,
    proRows,
    rotasChips,
    rotas,
    additionalNotes,
    isEditing,
    setDirty,
    setPristine,
    temp,
    tempRotas,
    initialState?.additionalNotesText,
  ]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setShowMessage(false);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [showMessage]);

  return (
    <Grid container mt={3}>
      {Prompt}
      <Grid container spacing={3} xs={6} sx={{ height: "fit-content" }}>
        {!isEditing && (
          <Grid item mt={1} xs={12}>
            <StyledInput
              disabled={readOnly}
              id="mht_organisation_id"
              name="MHTOrganisationID"
              label="MHT Organisation ID"
              value={mHTOrganisationId}
              required
              maxLength={255}
              onChange={(e: any) => {
                setMHTOrganisationId(e.target.value);
              }}
            />
          </Grid>
        )}
        <Grid item mt={1} xs={12}>
          <StyledInput
            id="mht_name"
            name="Name"
            label="Name"
            value={name}
            required
            disabled={readOnly || isEditing ? true : false}
            maxLength={255}
            onChange={(e: any) => {
              setName(e.target.value);
            }}
          />
        </Grid>
        {!isEditing && (
          <Grid item mt={1} xs={12}>
            <StyledInput
              disabled={readOnly}
              id="mht_abbreviation"
              name="Abbreviation"
              label="Abbreviation"
              value={abbreviation}
              required
              maxLength={255}
              onChange={(e: any) => {
                setAbbreviation(e.target.value);
              }}
            />
          </Grid>
        )}
        <Grid item mt={1} xs={12}>
          <StyledInput
            disabled={readOnly}
            id="amhp_team_has_hospital_emails"
            name="AMHP TEAM HAS HOSPITAL EMAILS"
            label="Hospital Emails"
            value={hospitalEmails}
            onChange={(e: any) => {
              setHospitalEmails("");
              setHospitalDialog(true);
            }}
            onClick={() => {
              setHospitalDialog(true);
            }}
          />
          {proRows.length > 0 && (
            <>
              <Stack
                direction="row"
                sx={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Box component="span" className={classes.circle}>
                  <Text className={classes.textColor}>{proRows.length}</Text>
                </Box>
                <IconButton
                  disabled={readOnly}
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen((open) => !open)}
                >
                  {open ? (
                    <KeyboardArrowUpIcon fontSize="large" />
                  ) : (
                    <KeyboardArrowDownIcon fontSize="large" />
                  )}
                </IconButton>
              </Stack>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <StyledTreeDataGridPro
                  rows={proRows}
                  columns={proColumns}
                  getRowId={(row: any) =>
                    row.wardName.replace(/\s/g, "") +
                    "_" +
                    row.wardEmail.replace(/\s/g, "")
                  }
                />
              </Collapse>
            </>
          )}
        </Grid>
        <Grid item mt={1} xs={12}>
          <StyledInput
            disabled={readOnly}
            id="mht_Rotas"
            name="Rotas "
            label="Rotas"
            value={rotas}
            maxLength={255}
            onChange={(e: any) => {
              setRotas(e.target.value);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  id="add_mht_Rotas"
                  data-testId="add_mht_Rotas"
                  edge="end"
                  disabled={readOnly || rotas.length === 0 || rotas.length < 2}
                  color={rotas.length === 0 ? "default" : "primary"}
                  onClick={() => {
                    addRotasChips();
                  }}
                >
                  <AddBoxIcon fontSize="large" />
                </IconButton>
              </InputAdornment>
            }
          />
          {rotasChips.length > 0 && (
            <Grid xs={12}>
              <ProTable
                rows={rotasChips}
                columns={rotasColumns}
                onCellEditCommit={onCellEditCommit}
              />
            </Grid>
          )}
        </Grid>
        <Grid item mt={1} xs={12}>
          <StyledInput
            disabled={readOnly}
            id="mht_additional_notes"
            name="Additional Notes"
            label="Additional Notes"
            value={additionalNotes}
            multiline
            maxRows={10}
            minRows={4}
            placeholder="Additional Notes"
            onChange={(e: any) => {
              setAdditionalNotes(e.target.value);
            }}
          />
        </Grid>
        <Grid item mt={1} xs={12}>
          <LoadingButton
            disabled={readOnly}
            loading={updateLoading}
            sx={{
              marginRight: 1,
            }}
            variant="contained"
            onClick={() => {
              isEditing ? mhtUpdate() : mhtCreate();
            }}
          >
            {isEditing ? "Update MHT" : "Add MHT"}
          </LoadingButton>
          <Button
            disabled={readOnly}
            sx={{
              marginLeft: 1,
            }}
            variant="outlined"
            onClick={
              isEditing
                ? () => {
                    handleConfirmation(
                      MESSAGES.CONFIRM_CANCEL,
                      () => {
                        handleCancel();
                      },
                      "Confirm"
                    );
                  }
                : () => {
                    handleConfirmation(
                      CONFIRM_CLEAR,
                      () => {
                        resetData();
                      },
                      "Warning!"
                    );
                  }
            }
          >
            {isEditing ? "Cancel" : "Clear"}
          </Button>
        </Grid>
      </Grid>
      {/* this Dialog for add hospital emails  */}
      <Dialog
        open={hospitalDialog}
        maxWidth="sm"
        fullWidth={false}
        PaperProps={{
          style: {
            borderRadius: 8,
            width: 750,
          },
        }}
      >
        <DialogTitle>
          <Text>Add Hospital Email</Text>
          <IconButton
            disabled={readOnly}
            aria-label="close"
            onClick={() => {
              setHospitalEmailsData({
                hospital: "",
                ward: "",
                email: "",
              });
              setHospitalDialog(false);
              setHospitalEmailFormErrors({});
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ padding: 3 }}>
          {showMessage && (
            <SuccessMessage message={MESSAGES.SUCCESS_EMAIL_ADDED} />
          )}
          <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
            <StyledInput
              disabled={readOnly}
              id="hospital_name"
              name="Hospital Name"
              label="Hospital Name"
              value={hospitalEmailsData.hospital}
              error={!!hospitalEmailFormErrors.hospitalName}
              errorText={hospitalEmailFormErrors.hospitalName}
              maxLength={255}
              required
              onBlur={(event) => {
                validateInput(
                  HospitalWardFormSchema,
                  "hospitalName",
                  {
                    hospitalName: event.target.value,
                  },
                  setHospitalEmailFormErrors
                );
              }}
              onChange={(e: any) => {
                resetInput("hospitalName", setHospitalEmailFormErrors);
                setHospitalEmailsData((val) => {
                  return {
                    ...val,
                    hospital: e.target.value,
                  };
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingBottom: 3, paddingTop: 1 }}>
            <StyledInput
              disabled={readOnly}
              id="ward_name"
              name="Ward Name"
              label="Ward Name"
              value={hospitalEmailsData.ward}
              error={!!hospitalEmailFormErrors.wardName}
              errorText={hospitalEmailFormErrors.wardName}
              maxLength={255}
              required
              onBlur={(event) => {
                validateInput(
                  HospitalWardFormSchema,
                  "wardName",
                  {
                    wardName: event.target.value,
                  },
                  setHospitalEmailFormErrors
                );
              }}
              onChange={(e: any) => {
                resetInput("wardName", setHospitalEmailFormErrors);
                setHospitalEmailsData((val) => {
                  return {
                    ...val,
                    ward: e.target.value,
                  };
                });
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ paddingBottom: 1, paddingTop: 1 }}>
            <StyledInput
              disabled={readOnly}
              id="ward_email"
              name="Ward Email"
              label="Ward Emails"
              multiline={true}
              value={hospitalEmailsData.email}
              error={!!hospitalEmailFormErrors.wardEmail}
              errorText={hospitalEmailFormErrors.wardEmail}
              maxLength={255}
              required
              onBlur={(event) => {
                validateInput(
                  HospitalWardFormSchema,
                  "wardEmail",
                  {
                    wardEmail: event.target.value,
                  },
                  setHospitalEmailFormErrors
                );
              }}
              onChange={(e: any) => {
                resetInput("wardEmail", setHospitalEmailFormErrors);
                setHospitalEmailsData((val) => {
                  return {
                    ...val,
                    email: e.target.value,
                  };
                });
              }}
            />
          </Grid>
        </DialogContent>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
          <DialogActions>
            <Button
              disabled={readOnly}
              sx={{ marginRight: 1 }}
              variant="outlined"
              onClick={() => {
                setHospitalEmailsData({
                  hospital: "",
                  ward: "",
                  email: "",
                });
                setHospitalDialog(false);
                setHospitalEmailFormErrors({});
              }}
            >
              Close
            </Button>
            <Button
              sx={{ marginRight: 2 }}
              disabled={readOnly}
              variant="contained"
              onClick={() => addHospitalEmail()}
            >
              Add
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </Grid>
  );
};
export default React.memo(MHTForm);
