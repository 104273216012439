import React, { useContext } from "react";
import AppVersionContext from "store/appVersionContext";

import AMHPIcon from "@mui/icons-material/Person";
import AMHPTeamIcon from "@mui/icons-material/PeopleAlt";
import CCGIcon from "@mui/icons-material/CorporateFare";
import DoctorIcon from "@mui/icons-material/MedicalServices";
import GeneralIcon from "@mui/icons-material/Assignment";
import MHTIcon from "@mui/icons-material/Biotech";
import SiteIcon from "@mui/icons-material/LocationCity";
import FormIcon from "@mui/icons-material/TextSnippetOutlined";
import Search from "@mui/icons-material/Search";

import { MenuItems } from "common/types/sidemenu";
import {
  AMHP_ADD,
  AMHP_TEAM_ADD,
  AMHP_TEAM_UPDATE,
  AMHP_UPDATE,
  CCG_UPDATE,
  CCG_UPDATE_ORG_USER,
  DOCTOR_ADD,
  GENERAL_CHECK_GMC,
  DOCTOR_MANAGE_AVAILABILITY_V3,
  DOCTOR_MANAGE_TIME_AWAY,
  DOCTOR_UPDATE,
  GENERAL_RESET_USER_PASSWORD,
  //MHT_ADD,
  MHT_UPDATE,
  SITES_ADD,
  //SITES_LINK,
  STAT_FORM_ASSIGNED_FORMS,
  STAT_FORM_USERS,
  GENERAL_RESEND_WELCOME_EMAIL,
  CCG_ADD_ORG_USER,
  SIFT,
  GENERAL_GLOBAL_SETTINGS,
  GENERAL_CCG_AND_ICB_LOOKUP,
} from "layout/routes/RouteConstants";

const UseMenuConfig = () => {
  const { version } = useContext(AppVersionContext);

  const amhpItems: MenuItems = [
    { index: 1, testId: "sidemenu-ahmp-add", name: "Add AMHP", href: AMHP_ADD },
    {
      index: 2,
      testId: "sidemenu-ahmp-update",
      name: "Update AMHP",
      href: AMHP_UPDATE,
    },
  ];

  const ahmpTeamItems: MenuItems = [
    {
      index: 3,
      testId: "sidemenu-ahmp-teams-add",
      name: "Add AMHP Team",
      href: AMHP_TEAM_ADD,
    },
    {
      index: 4,
      testId: "sidemenu-ahmp-teams-update",
      name: "Update AMHP Team",
      href: AMHP_TEAM_UPDATE,
    },
  ];

  const ccgItems: MenuItems = [
    // {
    //   index: 5,
    //   testId: "sidemenu-ccg-add",
    //   name: "Add CCG",
    //   href: CCG_ADD,
    // },
    {
      index: 5,
      testId: "sidemenu-ccg-update",
      name: "Update CCG",
      href: CCG_UPDATE,
    },
    {
      index: 6,
      testId: "sidemenu-ccg-add-org",
      name: "Add Org User",
      href: CCG_ADD_ORG_USER,
    },
    {
      index: 7,
      testId: "sidemenu-ccg-update-org",
      name: "Update Org User",
      href: CCG_UPDATE_ORG_USER,
    },
  ];

  const doctorItems: MenuItems = [
    {
      index: 8,
      testId: "sidemenu-doctor-add",
      name: "Add Doctor",
      href: DOCTOR_ADD,
    },
    {
      index: 9,
      testId: "sidemenu-doctor-update",
      name: "Update Doctor",
      href: DOCTOR_UPDATE,
    },
    {
      index: 10,
      testId: "sidemenu-doctor-manage-av-v3",
      name: "Manage Doctor Availability V3",
      href: DOCTOR_MANAGE_AVAILABILITY_V3,
    },
  ];

  const statFormItems: MenuItems = [
    {
      index: 12,
      testId: "sidemenu-users",
      name: "Add Stat Form User",
      href: STAT_FORM_USERS,
    },
    {
      index: 13,
      testId: "sidemenu-assigned-forms",
      name: "Update Stat Form User",
      href: STAT_FORM_ASSIGNED_FORMS,
    },
  ];

  const generalItems: MenuItems = [
    {
      index: 14,
      testId: "sidemenu-general-reset-pass",
      name: "Reset User Password",
      href: GENERAL_RESET_USER_PASSWORD,
    },
    {
      index: 15,
      testId: "sidemenu-general-resend-welcome",
      name: "Resend Welcome Email",
      href: GENERAL_RESEND_WELCOME_EMAIL,
    },
    {
      index: 16,
      testId: "sidemenu-doctor-check-gmc",
      name: "Check GMC",
      href: GENERAL_CHECK_GMC,
    },
    {
      index: 17,
      testId: "sidemenu-general-global-settings",
      name: "Global Settings",
      href: GENERAL_GLOBAL_SETTINGS,
    },
    {
      index: 18,
      testId: "sidemenu-general-ccg-icb-lookup",
      name: "CCG/ICB Lookup",
      href: GENERAL_CCG_AND_ICB_LOOKUP,
    },
  ];

  const mhtItems: MenuItems = [
    // ? 2022-11-02, temporary disable this page for initial release
    // {
    //   index: 15,
    //   testId: "sidemenu-mht-add",
    //   name: "Add MHT",
    //   href: MHT_ADD,
    // },
    {
      index: 19,
      testId: "sidemenu-mht-update",
      name: "Update MHT",
      href: MHT_UPDATE,
    },
  ];

  const siteItems: MenuItems = [
    {
      index: 20,
      testId: "sidemenu-site-add",
      name: "Add Site",
      href: SITES_ADD,
    },
    /*{
    index: 18,
    testId: "sidemenu-site-link",
    name: "Link Site",
    href: SITES_LINK,
  },*/
  ];

  const siftItems: MenuItems = [
    {
      index: 19,
      testId: "sidemenu-sift-main",
      name: "Explore data history",
      href: SIFT,
    },
    /*{
    index: 18,
    testId: "sidemenu-site-link",
    name: "Link Site",
    href: SITES_LINK,
  },*/
  ];

  return [
    {
      name: "AMHP",
      testId: "sidemenu-ahmp",
      icon: <AMHPIcon />,
      items: amhpItems,
    },
    {
      name: "AMHP Team",
      testId: "sidemenu-ahmp-teams",
      icon: <AMHPTeamIcon />,
      items: ahmpTeamItems,
    },
    { name: "CCG", testId: "sidemenu-ccg", icon: <CCGIcon />, items: ccgItems },
    {
      name: "Doctor",
      testId: "sidemenu-doctor",
      icon: <DoctorIcon />,
      items: doctorItems,
    },
    {
      name: "Stat Forms",
      testId: "sidemenu-stat-forms",
      icon: <FormIcon />,
      items: statFormItems,
    },
    {
      name: "General",
      testId: "sidemenu-general",
      icon: <GeneralIcon />,
      items: generalItems,
    },
    { name: "MHT", testId: "sidemenu-mht", icon: <MHTIcon />, items: mhtItems },
    {
      name: "Site",
      testId: "sidemenu-site",
      icon: <SiteIcon />,
      items: siteItems,
    },
    {
      name: "SIFT",
      testId: "sidemenu-sift",
      icon: <Search />,
      items: siftItems,
    },
  ];
};
export default UseMenuConfig;
