import { LocationInput, LocationNameInput } from "@s12solutions/types";
import { EMPTY_STRING } from "common/constants/common";
import {
  DoctorState,
  DoctorAction,
  DoctorActionTypes,
  AMHPState,
  AmhpAction,
  AmhpActionTypes,
  AMHPTeamState,
  AmhpTeamAction,
  AmhpTeamActionTypes,
} from "common/types/actions";
import { PHONE_NUMBER_VALIDATION_REGULAR_EXPRESSION } from "common/validators";

export const amhpFormReducer = (
  state: AMHPState,
  action: AmhpAction
): AMHPState => {
  const { type, payload } = action;

  switch (type) {
    case AmhpActionTypes.SET_NAME:
      return {
        ...state,
        name: payload.name || EMPTY_STRING,
      };

    case AmhpActionTypes.SET_EMAIL:
      return {
        ...state,
        email: payload.email || EMPTY_STRING,
      };
    case AmhpActionTypes.SET_PHONE:
      return {
        ...state,
        phone: payload.phone
          ? payload.phone.replace(
              PHONE_NUMBER_VALIDATION_REGULAR_EXPRESSION,
              EMPTY_STRING
            )
          : EMPTY_STRING,
      };
    case AmhpActionTypes.SET_TEAMS:
      return {
        ...state,
        teams: payload.teams || [],
      };
    case AmhpActionTypes.SET_TYPE:
      return {
        ...state,
        type: payload.type || EMPTY_STRING,
      };
    case AmhpActionTypes.SET_SSO:
      return {
        ...state,
        sso: payload.sso || EMPTY_STRING,
      };
    case AmhpActionTypes.SET_MFA:
      return {
        ...state,
        mfa: payload.mfa || false,
      };
    case AmhpActionTypes.SET_IS_TRAINEE_AMHP:
      return {
        ...state,
        isTraineeAmhp: payload.isTraineeAmhp || false,
      };
    case AmhpActionTypes.RESET:
      return {
        name: payload.name || EMPTY_STRING,
        email: payload.email || EMPTY_STRING,
        phone: payload.phone || EMPTY_STRING,
        teams: payload.teams || [],
        mfa: payload.mfa || false,
        sso: payload.sso || EMPTY_STRING,
        type: payload.type || EMPTY_STRING,
        isTraineeAmhp: payload.isTraineeAmhp || false,
      };
    default:
      return state;
  }
};

export const amhpTeamFormReducer = (
  state: AMHPTeamState,
  action: AmhpTeamAction
): AMHPTeamState => {
  const { type, payload } = action;

  switch (type) {
    case AmhpTeamActionTypes.SET_SITE:
      return {
        ...state,
        siteAmhpTeamsId: payload.siteAmhpTeamsId ?? state.siteAmhpTeamsId,
      };

    case AmhpTeamActionTypes.ADD_COMMON_ADDRESS:
      return {
        ...state,
        commonAddresses: [
          ...state.commonAddresses,
          ...(payload.commonAddresses ?? []),
        ],
      };

    case AmhpTeamActionTypes.REMOVE_COMMON_ADDRESS:
      return {
        ...state,
        commonAddresses: state.commonAddresses.filter(
          (val) => val.id !== payload.deleteId
        ),
      };
    case AmhpTeamActionTypes.SET_LOCAL_AUTHORITY:
      return {
        ...state,
        localAuthority: payload.localAuthority ?? state.localAuthority,
      };
    case AmhpTeamActionTypes.ADD_APPROVED_RECIPIENT:
      return {
        ...state,
        mhaEmailAddresses: [
          ...state.mhaEmailAddresses,
          ...(payload.mhaEmailAddresses ?? []),
        ],
      };

    case AmhpTeamActionTypes.REMOVE_APPROVED_RECIPIENT:
      return {
        ...state,
        mhaEmailAddresses: state.mhaEmailAddresses.filter(
          (val) => val.id !== payload.deleteId
        ),
      };

    case AmhpTeamActionTypes.SET_TEAM_NAME:
      return {
        ...state,
        name: payload.name ?? state.name,
      };

    case AmhpTeamActionTypes.SET_FULL_TEAM_NAME:
      return {
        ...state,
        fullTeamName: payload.fullTeamName ?? state.fullTeamName,
      };

    case AmhpTeamActionTypes.TOGGLE_FEATURE_FLAGS:
      if (payload.featureFlags?.amhpShouldProvideNhsNumber) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            amhpShouldProvideNhsNumber:
              !state.featureFlags.amhpShouldProvideNhsNumber,
          },
        };
      }

      if (payload.featureFlags?.amhpTeamDownloadForms) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            amhpTeamDownloadForms: !state.featureFlags.amhpTeamDownloadForms,
          },
        };
      }

      if (payload.featureFlags?.amhpTeamEmailFormsToApprovedLists) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            amhpTeamEmailFormsToApprovedLists:
              !state.featureFlags.amhpTeamEmailFormsToApprovedLists,
          },
        };
      }

      if (payload.featureFlags?.amhpTeamEmailFormsToSelf) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            amhpTeamEmailFormsToSelf:
              !state.featureFlags.amhpTeamEmailFormsToSelf,
          },
        };
      }

      if (payload.featureFlags?.amhpTeamInbox) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            amhpTeamInbox: !state.featureFlags.amhpTeamInbox,
          },
        };
      }

      if (payload.featureFlags?.amhpTeamMHAExternalDoctors) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            amhpTeamMHAExternalDoctors:
              !state.featureFlags.amhpTeamMHAExternalDoctors,
          },
        };
      }

      if (payload.featureFlags?.amhpTeamMHAForms) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            amhpTeamMHAForms: !state.featureFlags.amhpTeamMHAForms,
          },
        };
      }

      if (payload.featureFlags?.amhpTeamMhaHospitalEmails) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            amhpTeamMhaHospitalEmails:
              !state.featureFlags.amhpTeamMhaHospitalEmails,
          },
        };
      }

      if (payload.featureFlags?.amhpTeamUsesConveyanceForms) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            amhpTeamUsesConveyanceForms:
              !state.featureFlags.amhpTeamUsesConveyanceForms,
          },
        };
      }

      if (payload.featureFlags?.amhpTeamUsesJointMedRecs) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            amhpTeamUsesJointMedRecs:
              !state.featureFlags.amhpTeamUsesJointMedRecs,
          },
        };
      }

      if (payload.featureFlags?.amhpTeamVideoMeetings) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            amhpTeamVideoMeetings: !state.featureFlags.amhpTeamVideoMeetings,
          },
        };
      }

      if (payload.featureFlags?.docInformalMedRecs) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            docInformalMedRecs: !state.featureFlags.docInformalMedRecs,
          },
        };
      }

      if (payload.featureFlags?.requiresMFA) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            requiresMFA: !state.featureFlags.requiresMFA,
          },
        };
      }

      if (payload.featureFlags?.requiresSSO) {
        return {
          ...state,
          featureFlags: {
            ...state.featureFlags,
            requiresSSO: !state.featureFlags.requiresSSO,
          },
        };
      }

      return {
        ...state,
      };
    case AmhpTeamActionTypes.ADD_HOSPITAL_EMAIL:
      return {
        ...state,
        hospitalEmails: [
          ...state.hospitalEmails,
          ...(payload.hospitalEmails ?? []),
        ],
      };
    case AmhpTeamActionTypes.REMOVE_HOSPITAL_EMAIL:
      return {
        ...state,
        hospitalEmails: state.hospitalEmails.filter(
          (val) => val.id !== payload.deleteId
        ),
      };
    case AmhpTeamActionTypes.SET_MHA_INBOX_INSTRUCTION:
      return {
        ...state,
        mhaInboxInstructions:
          payload.mhaInboxInstructions ?? state.mhaInboxInstructions,
      };

    case AmhpTeamActionTypes.ADD_TEAM_EMAIL:
      return {
        ...state,
        emails: [...state.emails, ...(payload.emails ?? [])],
      };
    case AmhpTeamActionTypes.REMOVE_TEAM_EMAIL:
      return {
        ...state,
        emails: state.emails.filter((val) => val.key !== payload.deleteId),
      };
    case AmhpTeamActionTypes.ADD_TEAM_PHONE_NUMBER:
      return {
        ...state,
        phoneNumbers: [...state.phoneNumbers, ...(payload.phoneNumbers ?? [])],
      };
    case AmhpTeamActionTypes.REMOVE_TEAM_PHONE_NUMBER:
      return {
        ...state,
        phoneNumbers: state.phoneNumbers.filter(
          (val) => val.key !== payload.deleteId
        ),
      };
    case AmhpTeamActionTypes.RESET:
      return {
        ...state,
        siteAmhpTeamsId: payload.siteAmhpTeamsId ?? state.siteAmhpTeamsId,
        commonAddresses: payload.commonAddresses ?? state.commonAddresses,
        localAuthority: payload.localAuthority ?? state.localAuthority,
        mhaEmailAddresses: payload.mhaEmailAddresses ?? state.mhaEmailAddresses,
        name: payload.name || EMPTY_STRING,
        featureFlags: {
          amhpShouldProvideNhsNumber:
            payload.featureFlags?.amhpShouldProvideNhsNumber ??
            state.featureFlags.amhpShouldProvideNhsNumber,
          amhpTeamVideoMeetings:
            payload.featureFlags?.amhpTeamVideoMeetings ??
            state.featureFlags.amhpTeamVideoMeetings,
          amhpTeamMHAExternalDoctors:
            payload.featureFlags?.amhpTeamMHAExternalDoctors ??
            state.featureFlags.amhpTeamMHAExternalDoctors,
          amhpTeamMHAForms:
            payload.featureFlags?.amhpTeamMHAForms ??
            state.featureFlags.amhpTeamMHAForms,
          amhpTeamMhaHospitalEmails:
            payload.featureFlags?.amhpTeamMhaHospitalEmails ??
            state.featureFlags.amhpTeamMhaHospitalEmails,
          amhpTeamEmailFormsToSelf:
            payload.featureFlags?.amhpTeamEmailFormsToSelf ??
            state.featureFlags.amhpTeamEmailFormsToSelf,
          amhpTeamEmailFormsToApprovedLists:
            payload.featureFlags?.amhpTeamEmailFormsToApprovedLists ??
            state.featureFlags.amhpTeamEmailFormsToApprovedLists,
          amhpTeamDownloadForms:
            payload.featureFlags?.amhpTeamDownloadForms ??
            state.featureFlags.amhpTeamDownloadForms,
          amhpTeamUsesJointMedRecs:
            payload.featureFlags?.amhpTeamUsesJointMedRecs ??
            state.featureFlags.amhpTeamUsesJointMedRecs,
          amhpTeamInbox:
            payload.featureFlags?.amhpTeamInbox ??
            state.featureFlags.amhpTeamInbox,
          amhpTeamUsesConveyanceForms:
            payload.featureFlags?.amhpTeamUsesConveyanceForms ??
            state.featureFlags.amhpTeamUsesConveyanceForms,
          docInformalMedRecs:
            payload.featureFlags?.docInformalMedRecs ??
            state.featureFlags.docInformalMedRecs,
          requiresSSO:
            payload.featureFlags?.requiresSSO ?? state.featureFlags.requiresSSO,
          requiresMFA:
            payload.featureFlags?.requiresMFA ?? state.featureFlags.requiresMFA,
        },
        hospitalEmails: payload.hospitalEmails ?? state.hospitalEmails,
        mhaInboxInstructions:
          payload.mhaInboxInstructions ?? state.mhaInboxInstructions,
        emails: payload.emails ?? state.emails,
        phoneNumbers: payload.phoneNumbers ?? state.phoneNumbers,
      };
    default:
      return state;
  }
};

export const doctorFormReducer = (
  state: DoctorState,
  action: DoctorAction
): DoctorState => {
  const { type, payload } = action;

  switch (type) {
    case DoctorActionTypes.SET_DISABLED:
      return {
        ...state,
        disabled: payload.disabled || false,
      };

    case DoctorActionTypes.SET_GMC:
      return {
        ...state,
        gmc: payload.gmc || EMPTY_STRING,
      };
    case DoctorActionTypes.SET_LEGAL_NAME:
      return {
        ...state,
        legalName: payload.legalName || EMPTY_STRING,
      };
    case DoctorActionTypes.SET_NAME:
      return {
        ...state,
        name: payload.name || EMPTY_STRING,
      };

    case DoctorActionTypes.SET_EMAIL:
      return {
        ...state,
        email: payload.email || EMPTY_STRING,
      };
    case DoctorActionTypes.SET_PHONE:
      return {
        ...state,
        phone: payload.phone
          ? payload.phone.replace(
              PHONE_NUMBER_VALIDATION_REGULAR_EXPRESSION,
              EMPTY_STRING
            )
          : EMPTY_STRING,
      };
    case DoctorActionTypes.SET_ADDITIONAL_PHONE:
      return {
        ...state,
        phone2: payload.phone2
          ? payload.phone2.replace(
              PHONE_NUMBER_VALIDATION_REGULAR_EXPRESSION,
              EMPTY_STRING
            )
          : EMPTY_STRING,
      };
    case DoctorActionTypes.SET_POSTCODE:
      return {
        ...state,
        postcode: payload.postcode || EMPTY_STRING,
      };
    case DoctorActionTypes.SET_LOCATION:
      return {
        ...state,
        location: payload.location as LocationInput,
      };
    case DoctorActionTypes.SET_LOCATION_NAME:
      return {
        ...state,
        locationName: payload.locationName as LocationNameInput,
      };
    case DoctorActionTypes.SET_GENDER:
      return {
        ...state,
        gender: payload.gender || EMPTY_STRING,
      };
    case DoctorActionTypes.SET_EMPLOYER:
      return {
        ...state,
        employer: payload.employer || EMPTY_STRING,
      };
    case DoctorActionTypes.SET_SITE:
      return {
        ...state,
        siteName: payload.siteName || EMPTY_STRING,
      };
    case DoctorActionTypes.SET_MHTS:
      return {
        ...state,
        mhts: payload.mhts || [],
      };
    case DoctorActionTypes.SET_SPECIALTIES:
      return {
        ...state,
        specialties: payload.specialties || [],
      };
    case DoctorActionTypes.SET_LANGUAGES:
      return {
        ...state,
        languages: payload.languages || [],
      };
    case DoctorActionTypes.SET_NOTES:
      return {
        ...state,
        notes: payload.notes || EMPTY_STRING,
      };
    case DoctorActionTypes.SET_SSO:
      return {
        ...state,
        sso: payload.sso || EMPTY_STRING,
      };
    case DoctorActionTypes.SET_MFA:
      return {
        ...state,
        mfa: payload.mfa || false,
      };
    case DoctorActionTypes.SET_IS_DOCTOR_OUTSIDE_ENGLAND:
      return {
        ...state,
        isOutsideEngland: payload.isOutsideEngland || false,
      };
    case DoctorActionTypes.SET_HIDE_DOCTOR_FROM_SEARCH:
      return {
        ...state,
        hideFromSearch: payload.hideFromSearch || false,
      };
    case DoctorActionTypes.RESET:
      return payload as DoctorState;
    default:
      return state;
  }
};
