import { useAPI } from "hooks";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const EventModalContent = ({ filter }: any) => {
  const style = {
    box: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      height: 400,
      width: "80%",
      bgcolor: "background.paper",
      borderRadius: 1,
      boxShadow: 24,
      p: 4,
    },
  };

  const { data } = useAPI({
    method: "GET",
    fieldName: "getAuditLog",
    args: {
      ...filter,
    },
  });

  return (
    <Box sx={style.box} id="event-modal-wrapper">
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        hideFooterPagination
        rows={data && data.map((d: any, i: number) => ({ id: i, ...d }))}
        columns={[
          {
            field: "createdAt",
            headerName: "Timestamp (GMT)",
            width: 170,
            valueGetter: (params: any) => {
              if (params.row.createdAt) {
                const date = new Date(Number(params.row.createdAt));
                const day = date.getDate();
                const month = date.getMonth();
                const year = date.getFullYear();
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();
                return (
                  day +
                  "/" +
                  month +
                  "/" +
                  year +
                  "  " +
                  hours +
                  ":" +
                  minutes +
                  ":" +
                  seconds
                );
              }
              return "";
            },
          },
          {
            field: "user",
            headerName: "Agent",
            width: 150,
          },
          {
            field: "action",
            headerName: "Action",
            width: 90,
          },
          {
            field: "name",
            headerName: "Identifier",
            width: 250,
          },
          {
            field: "details",
            headerName: "Details",
            width: 250,
            renderCell: ({ value }) => {
              const items = Object.entries(value);
              return (
                <ul>
                  {items.map((item) => (
                    <li>
                      <p>
                        {item[0]}: {item[1]}
                      </p>
                    </li>
                  ))}
                </ul>
              );
            },
          },
        ]}
      />
    </Box>
  );
};

export default EventModalContent;
