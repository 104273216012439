import React, { useEffect, useMemo, useState } from "react";

// Material UI Imports
import { Card, Chip, Grid, Typography as Text } from "@mui/material";
// Custom Types
import {
  FindOrgUserInput,
  FindOrgUserResponseItem,
  ListUsersFilterInput,
  User,
} from "@s12solutions/types";

// Custom Imports
import OrgUserForm from "../OrgUserForm";
import { OrgUserSelectForm } from "../OrgUserSelectForm";
import { useLocation, useNavigate } from "react-router-dom";
import { CCG_UPDATE } from "layout/routes/RouteConstants";
import {
  EVENTUAL_CONSISTENCY_TIMEOUT,
  MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH,
  TYPE_ERROR,
} from "common/constants/common";
import { UNEXPECTED_ERROR_MESSAGE } from "common/constants/messages";
import { useAPI, usePopups } from "hooks";
import { useDebounce } from "use-debounce";
import Progress from "components/cirularProgress/Progress";

const UpdateOrgUser: React.FC = () => {
  const [orgUser, setOrgUser] = useState<FindOrgUserResponseItem | null>(null);
  const [orgUserId, setOrgUserId] = useState<string | null>(null);
  const [pageLoading, setPageLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const locationState = location.state as any;

  const [searchString, setSearchString] = useState<string>("");
  const [tableData, setTableData] = useState<User[]>([]);
  const { handleBannerMessage } = usePopups();
  const [debouncedSearchString] = useDebounce(
    searchString.trim().length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
      ? searchString.trim()
      : "",
    1000
  );

  // Queries
  const {
    trigger: getUsers,
    data: userListData,
    loading: userListDataLoading,
    error: userListDataError,
  } = useAPI<
    User[] | null,
    {
      filter: ListUsersFilterInput;
    }
  >({
    method: "GET",
    fieldName: "listUsers",
    args: {
      filter: {
        searchString: debouncedSearchString,
        userType: "OrgClaims",
      },
    },
    manual: true,
  });

  const {
    trigger: getUser,
    data: userData,
    loading: userLoading,
    error: userError,
  } = useAPI<
    FindOrgUserResponseItem | null,
    {
      filter: FindOrgUserInput;
    }
  >({
    method: "GET",
    fieldName: "findOrgUser",
    manual: true,
  });

  // Handle Search
  useEffect(() => {
    function handleSearch() {
      getUsers();
    }
    if (debouncedSearchString.length > MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH) {
      handleSearch();
    }
  }, [debouncedSearchString, getUsers]);

  useEffect(() => {
    if (userListDataError || userError) {
      handleBannerMessage(TYPE_ERROR, UNEXPECTED_ERROR_MESSAGE);
    }
  }, [userListDataError, handleBannerMessage, userError]);

  useEffect(() => {
    if (location?.search.length > 0) {
      setTimeout(() => {
        navigate(location.pathname);
      }, 8000);
      setOrgUserId(decodeURIComponent(location?.search.substring(1)));
    }
  }, [location?.search, location.pathname, navigate]);

  useEffect(() => {
    if (
      locationState &&
      locationState.fromUserList &&
      locationState.userId &&
      typeof locationState.userId === "string"
    ) {
      setOrgUserId(locationState.userId);
    }
  }, [locationState]);

  useEffect(() => {
    if (!!orgUserId) {
      getUser({
        filter: {
          organisationUserUserId: orgUserId,
        },
      });
    }
  }, [getUser, orgUserId]);

  useEffect(() => {
    if (userListData && !userListDataLoading && !pageLoading) {
      setTableData(userListData);
    } else {
      setTableData([]);
    }
  }, [pageLoading, userListData, userListDataLoading]);

  useEffect(() => {
    if (userData && userData && !userError && !userLoading) {
      setOrgUser(userData);
    } else {
      setOrgUser(null);
    }
  }, [userData, userError, userLoading]);

  useEffect(() => {
    return () => {
      setOrgUser(null);
      setOrgUserId(null);
      setTableData([]);
    };
  }, []);

  return (
    <>
      <Progress loading={userLoading}>
        <Grid
          xs={12}
          m={1}
          p={2}
          component={Card}
          direction="row"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderRadius: 1,
            minHeight: window.innerHeight - 85,
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid display={"flex"}>
                <Text variant="h1">Update Org User</Text>
                {!!orgUser?.deleted && (
                  <Chip
                    style={{ borderRadius: ".3rem", marginLeft: "10px" }}
                    label="Deleted"
                    color="error"
                  />
                )}
              </Grid>
              {!!orgUser ? (
                <OrgUserForm
                  handleCancel={() => {
                    if (
                      locationState &&
                      locationState.fromUserList &&
                      locationState.userId
                    ) {
                      navigate(CCG_UPDATE);
                    } else {
                      setPageLoading(true);
                      setOrgUser(null);
                      setOrgUserId(null);
                      navigate(location.pathname);
                      setTimeout(() => {
                        if (
                          debouncedSearchString.trim().length >
                          MINIMUM_NUMBER_OF_CHARACTERS_TO_SEARCH
                        ) {
                          getUsers();
                        }
                        setPageLoading(false);
                      }, EVENTUAL_CONSISTENCY_TIMEOUT);
                    }
                  }}
                  orgUser={orgUser}
                  isEditing={true}
                />
              ) : (
                <OrgUserSelectForm
                  setOrgUserId={setOrgUserId}
                  searchString={searchString}
                  setSearchString={setSearchString}
                  tableData={tableData}
                  tableLoading={userListDataLoading || pageLoading}
                  tableError={userListDataError}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Progress>
    </>
  );
};

export default React.memo(UpdateOrgUser);
